<template>
  <div>
    <!-- <h2 class="text-xl font-bold text-base mt-5">
            {{ i18n('liveVideos.title') }}
          </h2> -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-xl font-bold text-base mt-5">
        {{ i18n('liveVideos.title') }}
      </h2>
      <div class="btn btn-primary mt-6 mb-4 sm:mt-2 mx-3 w-full sm:w-28 p-1" :class="isRTL ? 'mr-auto' : 'ml-auto'"
        @click="addLiveVideo">
        <PlusIcon class="mr-2" /> {{ i18n('common.add') }}
      </div>
    </div>
    <div class="intro-y box dark:bg-dark-8 p-5 mt-5 flex grid grid-cols-12 gap-10">
      <div class="flex justify-center col-span-12" style="width: 100%; height: 80vh" v-if="loading">
        <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
      </div>
      <!-- <div v-else> -->
      <div class="
                                md:col-span-6
                                col-span-12
                                xl:col-span-4
                                col-span-xl-4
                                xl:h-96
                                h-96
                                overflow-hidden
                                md:h-64
                                relative
                              " v-for="row in rows" :key="row.id">
        <a href="javascript:;" data-toggle="modal" data-target="#delete-modal-preview" class="
                                  absolute
                                  right-1
                                  top-1
                                  rounded-full
                                  bg-primary-3
                                  border-white border-2
                                " @click="deleteId = row.id">
          <XIcon class="w-6 h-6 text-white rounded-full" />
        </a>
        <span v-html="row.html"></span>
      </div>
      <app-empty-page v-if="!rows.length" :label="i18n('empty.live')" label-class="text-lg font-medium" icon-size="4x"
        class="box w-full py-64 col-span-12"></app-empty-page>
      <!-- </div> -->
    </div>
    <DeleteModal item="live" @delete="doDestroy(deleteId)" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { useRouter } from 'vue-router'
import DeleteModal from '@/components/modals/delete-modal.vue'

export default {
  components: {
    DeleteModal
  },
  setup() {
    const router = useRouter()
    const addLiveVideo = () => {
      router.push('/live-videos/add')
    }
    const deleteId = ''
    return {
      deleteId,
      addLiveVideo
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
    console.log(this.rows)
  },
  methods: {
    ...mapActions({
      doFetch: 'live/list/doFetch',
      doFetchNextPage: 'live/list/doFetchNextPage',
      doFetchPreviousPage: 'live/list/doFetchPreviousPage',
      doChangePaginationCurrentPage: 'live/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'live/list/doChangePaginationPageSize',
      doDestroy: 'live/list/doDestroy'
    }),
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: {},
        pagination: {
          limit: 10,
          sortBy: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'live/list/rows',
      currentPage: 'live/list/currentPage',
      isFirstPage: 'live/list/isFirstPage',
      isLastPage: 'live/list/isLastPage',
      loading: 'live/list/loading',
      pagination: 'live/list/pagination'
    })
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.liveVideos')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.liveVideos')
    }
  }
}
</script>

<style scoped>
::v-deep iframe {
  width: 100% !important;
}
</style>
