<template>
  <div class="i18n-select q-pa-sm row justify-center cursor-pointer">
    <div
      v-if="$i18n.locale == 'ar'"
      flat
      round
      dense
      class="q-mx-xs text-white"
      @click="doChangeLanguage('en')"
    >
      <img
        src="https://firebasestorage.googleapis.com/v0/b/home-food-plus.appspot.com/o/admin%2Fpublic%2Fflags%2Fen.svg?alt=media&token=6f7493f8-c219-4504-a0c0-47d0f562dd7d"
        alt=""
        :class="inAuth ? 'language-auth' : 'language'"
      />
    </div>
    <div
      v-else
      flat
      round
      dense
      class="q-mx-xs text-white"
      @click="doChangeLanguage('ar')"
    >
      <img
        src="/images/png/public/flags/ic-lang-ar.png"
        style="border-radius:50%"
        alt=""
        :class="inAuth ? 'language-auth' : 'language'"
      />
    </div>
  </div>
</template>

<script>
import { setLanguageCode } from '@/locales/i18n'
import i18n from '@/vueI18n'
// import { Quasar } from 'quasar'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'app-i18n-flags',
  setup() {
    const ar = ref(localStorage.getItem('language'))
    const value = ref(i18n.locale)
    return {
      ar,
      value
    }
  },
  computed: {
    inAuth() {
      return this.$route.path.includes('/auth')
    }
  },
  methods: {
    doChangeLanguage(locale) {
      this.$i18n.locale = locale
      setLanguageCode(locale)
      localStorage.setItem('language', locale)
      console.log(locale)
      if (locale == 'ar') {
        this.ar = true
        document.body.setAttribute('dir', 'rtl')
        // import('quasar/lang/ar').then(lang => {
        //   //   // eslint-disable-next-line
        //   Quasar.lang.set(lang.default)
        // })
      } else {
        this.ar = false
        // import('quasar/lang/en-US').then(lang => {
        //       // eslint-disable-next-line
        // Quasar.lang.set(lang.default)
        // })
        document.body.setAttribute('dir', 'ltr')
      }
    }
    // doChangeLanguage(language) {
    //   console.log(language)
    //   i18n.locale = language
    //   localStorage.setItem('language', language)
    //   if (language === 'ar') {
    //     this.ar = true
    //     // import(`quasar/lang/ar`).then(lang => {
    //     //   // eslint-disable-next-line
    //     //   Quasar.lang.set(lang.default)
    //     // })
    //   } else {
    //     this.ar = false
    //     // import(`quasar/lang/en-us`).then(lang => {
    //     //   // eslint-disable-next-line
    //     //   Quasar.lang.set(lang.default)
    //     // })
    //   }

    //   // const { lang } = this.$route.params
    //   // let route = ''
    //   // if (lang && (lang === 'ar' || lang === 'en')) {
    //   //   route = `/${language}${this.$route.fullPath.substring(3)}`
    //   // } else {
    //   //   route = `/${language}${this.$route.fullPath}`
    //   // }
    //   // this.$router.push(route)
    // }
  },
  mounted() {
    if (this.value === 'ar') {
      this.ar = true
    } else {
      this.ar = false
    }
  }
})
</script>

<style lang="scss">
.i18n-select {
  border-radius: 4px;
  width: 60px !important;
  text-align: center !important;
}
.language {
  width: 30px;
}
.language-auth {
  width: 50px;
}
</style>
