/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlForms)

export class ContactService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST(
          $filter: FormsFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          formsList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              name
              email
              phoneNumber
              companyName
              companyEmail
              type

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.formsList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query FIND($id: String!) {
          parkingFind(id: $id) {
            id
            branchId
            banner
            description {
              en
              ar
            }
            adminName {
              en
              ar
            }
            adminPhoneNumber
            annualSubscription
            openHours {
              en
              ar
            }
            places {
              en
              ar
            }

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id
      }
    })

    return response.data.parkingFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE($data: ParkingInput!) {
          parkingCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.parkingCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation UPDATE($id: String!, $data: ParkingInput!) {
          parkingUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.parkingUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation DESTROY($id: String!) {
          formsDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.formsDestroy
  }

  static async addPlaces(id, places) {
    console.log(places)
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADD_NEW_PLACES_TO_PARKING(
          $id: String!
          $places: [LocalizationInput!]
        ) {
          addNewPlacesToParking(id: $id, places: $places)
        }
      `,
      variables: {
        id,
        places
      }
    })
    return response.data.addNewPlacesToParking
  }

  static async removePlaces(id, places) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REMOVE_PLACES_FROM_PARKING(
          $id: String!
          $places: [LocalizationInput!]
        ) {
          removePlacesFromParking(id: $id, places: $places)
        }
      `,
      variables: {
        id,
        places
      }
    })
    return response.data.removePlacesFromParking
  }
}
