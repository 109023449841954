<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n
              code="library.form.title.edit"
              v-if="isEditing"
            ></app-i18n>
            <app-i18n code="library.form.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !model"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('library.fields.name') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.name.en"
                    :placeholder="i18n('library.fields.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.name.ar"
                    :placeholder="i18n('library.fields.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('library.fields.location') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.location.en"
                    :placeholder="i18n('library.fields.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.location.ar"
                    :placeholder="i18n('library.fields.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('library.fields.description') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    id="description-en"
                    :rows="4"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.description.en"
                    :placeholder="i18n('library.fields.english')"
                  ></textarea>
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <textarea
                    id="description-ar"
                    :rows="4"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.description.ar"
                    :placeholder="i18n('library.fields.arabic')"
                  ></textarea>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('branches.fields.branch') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 items-center"
                >
                  <v-select
                    :options="branchOptions || []"
                    :label="labelLocalized"
                    v-model="model.branch"
                    class="select-center"
                    title="Select item"
                  >
                    <template #no-options>
                      <div class="loading-loader"></div> </template
                  ></v-select>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('library.fields.phoneNumber') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <vue-tel-input
                    mode="international"
                    class="mt-4 p-2"
                    dir="ltr"
                    :inputOptions="isRTL ? telOptionsAr : telOptions"
                    :dropdownOptions="telDropOptions"
                    validCharactersOnly
                    v-model="model.phoneNumber"
                  >
                  </vue-tel-input>
                  <!-- <input
                    id="phone"
                    type="number"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.phoneNumber"
                    :placeholder="i18n('library.fields.phoneNumber')"
                  /> -->
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('library.fields.openHours') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.openHours.en"
                    :placeholder="i18n('common.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.openHours.ar"
                    :placeholder="i18n('common.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('library.fields.banner') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && bannerUrl">
                    <img
                      :src="bannerUrl"
                      class="object-cover object-center"
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="
                        delete-icon
                        w-12
                        h-12
                        absolute
                        bottom-0
                        left-0
                        cursor-pointer
                      "
                      @click="model.banner = undefined"
                    />
                  </div>
                  <Uploader ref="uploaderBanner" v-else></Uploader>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('library.fields.images') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && imagesUrls">
                    <div class="flex gap-0 flex-wrap">
                      <div
                        class="relative h-32 w-36"
                        v-for="(image, index) in imagesUrls"
                        :key="index"
                      >
                        <img :src="image" class="object-cover h-full w-full" />
                        <img
                          src="/images/deleteIcon.png"
                          alt=""
                          class="
                            delete-icon
                            w-12
                            h-12
                            absolute
                            bottom-0
                            left-0
                            cursor-pointer
                          "
                          @click="deleteImage(index)"
                        />
                      </div>
                    </div>
                    <Uploader
                      ref="uploaderImagesAdd"
                      message="common.addMoreImages"
                      :multiple="true"
                    ></Uploader>
                  </div>
                  <Uploader
                    ref="uploaderImagesNew"
                    :multiple="true"
                    v-else
                  ></Uploader>
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-36 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading || uploadLoading"
                  :loading="saveLoading"
                  @click="doSubmit"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="
                    btn
                    bg-theme-31
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  "
                  :disabled="saveLoading || findLoading || uploadLoading"
                  @click="doCancel"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import Uploader from '@/views/library/uploader.vue'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: {
    Uploader
  },
  data() {
    return {
      model: null,
      branchOptions: [],
      errorMessage: null,
      telDropOptions: { showFlags: true },
      telOptions: {
        placeholder: 'Enter library Phone Number',
        showDialCode: true
      },
      telOptionsAr: { placeholder: 'ادخل رقم المكتبة', showDialCode: true }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      branches: 'branch/list/rows',
      record: 'library/form/record',
      findLoading: 'library/form/findLoading',
      saveLoading: 'library/form/saveLoading'
    }),
    labelLocalized() {
      return this.isRTL ? 'nameAr' : 'nameEn'
    },
    isEditing() {
      return !!this.id
      // return true
    },
    filesToSaveBanner() {
      return this.$refs.uploaderBanner?.filesToSave || []
    },
    filesToSaveImagesAdd() {
      return this.$refs.uploaderImagesAdd?.filesToSave || []
    },
    filesToSaveImagesNew() {
      return this.$refs.uploaderImagesNew?.filesToSave || []
    },
    bannerUrl() {
      return this.model && this.model.banner ? this.model.banner : null
    },
    imagesUrls() {
      return this.model && this.model.images && this.model.images.length > 0
        ? this.model.images
        : null
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.library')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.library')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
      console.log(this.record)
    } else {
      this.doNew()
    }
    await this.doFetchBranches({
      filter: {},
      pagination: {
        limit: 10,
        sortBy: 'asc'
      }
    })
    this.branchOptions = this.branches.map((branch) => ({
      ...branch,
      nameAr: branch.name.ar,
      nameEn: branch.name.en
    }))
    this.model = this.record || {
      name: {
        en: '',
        ar: ''
      },
      description: {
        en: '',
        ar: ''
      },
      location: {
        en: '',
        ar: ''
      },
      branch: '',
      phoneNumber: '',
      openHours: {
        en: '',
        ar: ''
      },
      images: [],
      banner: ''
    }
    if (this.isEditing) {
      this.model.name = this.record.name
      this.model.description = this.record.description
      this.model.branch = this.branchOptions.filter(
        (branch) => branch.id === this.record.branchId
      )[0]
    }
    console.log(this.model)
  },
  methods: {
    ...mapActions({
      doNew: 'library/form/doNew',
      doFetchBranches: 'branch/list/doFetch',
      doFind: 'library/form/doFind',
      doCreate: 'library/form/doCreate',
      doUpdate: 'library/form/doUpdate'
    }),
    deleteImage(index) {
      this.model.images.splice(index, 1)
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.name.en.length == 0 || this.model.name.ar.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      if (
        this.model.description.en.length == 0 ||
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.location.en.length == 0 ||
        this.model.location.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      if (this.model.phoneNumber.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.openHours.en.length == 0 ||
        this.model.openHours.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      if (this.isEditing) {
        if (!(this.bannerUrl || this.filesToSaveBanner.length !== 0)) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
        if (
          !(
            this.imagesUrls ||
            this.filesToSaveImagesAdd.length !== 0 ||
            this.filesToSaveImagesNew.length !== 0
          )
        ) {
          this.errorMessage = 'branch.edit.errors.emptyFields'
          return false
        }
        // if (!(this.imagesUrls || this.filesToSaveImagesNew.length !== 0)) {
        //   this.errorMessage = 'branch.edit.errors.emptyFields'
        //   return false
        // }
      } else {
        if (
          this.filesToSaveBanner.length === 0 ||
          (this.filesToSaveImagesAdd.length === 0 &&
            this.filesToSaveImagesNew.length === 0)
        ) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      // this.$router.back()
      this.$router.push('/libraries')
    },
    doReset() {
      // this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      // const { id, ...values } = formSchema.cast(this.model)
      const { name, location, description, phoneNumber, openHours, branch } =
        this.model
      if (this.isEditing) {
        await this.doUpdate({
          id: this.id,
          name,
          location,
          description,
          phoneNumber,
          branchId: branch.id,
          openHours,
          banner: this.bannerUrl || this.filesToSaveBanner[0],
          images: this.imagesUrls
            ? [...this.imagesUrls, ...this.filesToSaveImagesAdd]
            : this.filesToSaveImagesNew
        })
      } else {
        await this.doCreate({
          name,
          location,
          description,
          branchId: branch.id,
          phoneNumber,
          openHours,
          banner: this.filesToSaveBanner[0],
          images: this.filesToSaveImagesNew
        })
      }
    }
  }
})
</script>

<style lang="scss">
.dropzone {
  .dz-preview {
    .dz-details {
      font-size: 12px !important;
      padding: 0em 1em !important;
    }
    .dz-image {
      width: 80px !important;
      height: 80px !important;
    }
  }
}
</style>
