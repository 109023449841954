<template>
  <div>
    <div class="intro-y px-5 flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('library.title') }}</h2>
      <div
        class="btn btn-primary mt-6 sm:mt-2 mx-3 w-full sm:w-28 p-1"
        :class="isRTL ? 'mr-auto' : 'ml-auto'"
        @click="openCreateLibrary()"
      >
        <PlusIcon class="mr-2" /> {{ i18n('common.create') }}
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box dark:bg-dark-8 p-5 sm:mt-5">
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <div class="overflow-x-auto g">
        <table class="table table-report sm:mt-2 hidden md:table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('library.table.name') }}
              </th>
              <!-- <th class="text-center whitespace-nowrap">
                                {{ i18n('library.table.logo') }}
                              </th> -->
              <th class="text-center whitespace-nowrap">
                {{ i18n('library.table.description') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('library.table.location') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('library.table.phoneNumber') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('library.table.openHours') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('common.table.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" :key="index">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ index + 1 }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row, 'name') }}
                </div>
              </td>
              <!-- <td class="text-center flex items-center justify-center">
                                  <app-list-item-image
                                    :value="row.logo"
                                  ></app-list-item-image>
                                </td> -->
              <td class="text-center">
                <div
                  class="font-medium whitespace-nowrap truncate truncate w-72"
                >
                  {{ presenterLocalization(row, 'description') }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row, 'location') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap truncate">
                  {{ row.phoneNumber }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap truncate">
                  {{ presenterLocalization(row, 'openHours') }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div
                  class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer"
                >
                  <div @click="viewLibrary(row.id)">
                    <EyeIcon class="w-4 h-4 mr-1" />
                  </div>
                  <div
                    @click="editLibrary(row.id)"
                    class="text-theme-14 dark:text-white ml-2"
                  >
                    <EditIcon class="w-4 h-4 mr-1" />
                  </div>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    class="text-theme-24 ml-2"
                    @click="deleteId = row.id"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mobile-table md:hidden mt-8">
          <mobile-table-comp
            v-for="(row, index) in rows"
            :key="index"
            :row="row"
            class="md:hidden"
          >
            <div class="field">
              <span>{{ i18n('library.table.name') }} </span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterLocalization(row, 'name') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('library.table.description') }} :</span>
              <div class="font-medium whitespace-nowrap truncate truncate">
                {{ presenterLocalization(row, 'description') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('library.table.location') }} :</span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterLocalization(row, 'location') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('library.table.phoneNumber') }} :</span>
              <div class="font-medium whitespace-nowrap truncate">
                {{ row.phoneNumber }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('library.table.openHours') }} :</span>
              <div class="font-medium whitespace-nowrap truncate">
                {{ presenterLocalization(row, 'openHours') }}
              </div>
            </div>

            <div class="field">
              <span> {{ i18n('common.table.actions') }} :</span>
              <div
                class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer"
              >
                <div @click="viewLibrary(row.id)">
                  <EyeIcon class="w-4 h-4 mr-1" />
                </div>
                <div
                  @click="editLibrary(row.id)"
                  class="text-theme-14 dark:text-white ml-2"
                >
                  <EditIcon class="w-4 h-4 mr-1" />
                </div>
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-modal-preview"
                  class="text-theme-24 ml-2"
                  @click="deleteId = row.id"
                >
                  <Trash2Icon class="w-4 h-4 mr-1" />
                </a>
              </div>
            </div>
          </mobile-table-comp>
        </div>
        <app-empty-page
          v-if="!rows.length && !loading"
          :label="i18n('empty.library')"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
      <Pagination
        v-if="rows.length"
        :page="currentPage"
        :pageSize="pagination.limit"
        :rowsNumber="pagination.total"
        :showSizeChanger="true"
        :isFirstPage="isFirstPage"
        :isLastPage="isLastPage"
        :disabled="!rows.length"
        position="center"
        @next-page="doNextPage"
        @prev-change="doPrevPage"
        @size-change="doChangePaginationPageSize"
      >
      </Pagination>
    </div>
    <!-- END: HTML Table Data -->
    <DeleteModal item="library" @delete="doDestroy(deleteId)" />
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { mapActions, mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.library')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.library')
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'library/list/rows',
      currentPage: 'library/list/currentPage',
      isFirstPage: 'library/list/isFirstPage',
      isLastPage: 'library/list/isLastPage',
      loading: 'library/list/loading',
      pagination: 'library/list/pagination'
    })
  },
  components: {
    DeleteModal
  },
  setup() {
    const router = useRouter()

    const deleteId = ''

    const viewLibrary = (id) => {
      router.push(`/libraries/${id}`)
    }
    const editLibrary = (id) => {
      router.push(`/library-form-page/${id}`)
    }

    const openCreateLibrary = () => {
      router.push('/library-form-page')
    }

    return {
      deleteId,
      openCreateLibrary,
      editLibrary,
      viewLibrary
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'library/list/doFetch',
      doFetchNextPage: 'library/list/doFetchNextPage',
      doFetchPreviousPage: 'library/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'library/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'library/list/doChangePaginationPageSize',
      doDestroy: 'library/list/doDestroy'
    }),
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: {},
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenterArrLocalized(row, fieldName) {
      const locale = this.isRTL ? 'ar' : 'en'
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].map((val) => val[locale]).join(',')
        : 'ـــ'
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    }
  }
}
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
