/* eslint-disable */

import activityListStore from '@/store/activity/activity-list-store'
import activityFormStore from '@/store/activity/activity-form-store'
// import activityViewStore from '@/store/activity/activity-view-store'

export default {
  namespaced: true,

  modules: {
    list: activityListStore,
    form: activityFormStore,
    // view: activityViewStore
  }
}
