<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('parking.view.title') }}
      </h2>
    </div>

    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        style="width: 100%; height: 30vh"
        class="flex justify-center"
        v-if="loading"
      >
        <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
      </div>
      <div v-if="record && !loading">
        <div class="p-5">
          <div class="relative flex items-center">
            <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
              <div class="font-medium mb-1">
                {{ i18n('parking.form.description') }}
              </div>
              <div class="text-gray-600 dark:text-gray-300">
                {{ presenterLocalization(record, 'description') }}
              </div>
            </div>
          </div>
        </div>
        <div class="p-5 border-t">
          <div class="relative flex items-center">
            <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
              <div class="font-medium mb-1">
                {{ i18n('parking.form.adminName') }}
              </div>
              <div class="text-gray-600 dark:text-gray-300">
                {{ presenterLocalization(record, 'adminName') }}
              </div>
            </div>
          </div>
        </div>
        <div class="p-5 border-t">
          <div class="relative flex items-center">
            <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
              <div class="font-medium mb-1">
                {{ i18n('parking.form.phoneNumber') }}
              </div>
              <div class="text-gray-600 dark:text-gray-300">
                {{ record.adminPhoneNumber }}
              </div>
            </div>
          </div>
        </div>
        <div class="p-5 border-t">
          <div class="relative flex items-center">
            <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
              <div class="font-medium mb-1">
                {{ i18n('parking.table.annualSubscription') }}
              </div>
              <div class="text-gray-600 dark:text-gray-300">
                {{ record.annualSubscription }}
              </div>
            </div>
          </div>
        </div>
        <div class="p-5 border-t">
          <div class="relative flex items-center">
            <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
              <div class="font-medium mb-1">
                {{ i18n('parking.table.openHours') }}
              </div>
              <div
                class="text-gray-600 dark:text-gray-300"
                v-for="(hours, index) in record.openHours"
                :key="index"
              >
                {{ isRTL ? hours.ar : hours.en }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="
            flex flex-col
            lg:flex-row
            border-b border-gray-200
            dark:border-dark-5
            pb-5
            -mx-5
          "
        >
          <div
            class="
              px-5
              flex flex-1
              items-center
              justify-center
              lg:justify-start
            "
          >
            <div class="ml-5 mr-5 flex justify-between flex-wrap flex-1">
              <div>
                <div
                  class="font-medium text-center mt-3 text-xl"
                  :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
                >
                  {{ i18n('parking.form.code') }}
                </div>
                <div
                  class="
                    flex flex-col
                    justify-center
                    items-center
                    lg:items-start
                    mt-2
                  "
                >
                  {{ presenter(record, 'code') }}
                </div>
              </div>
              <div>
                <div
                  class="font-medium text-center mt-3 text-xl"
                  :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
                >
                  {{ i18n('units.fields.project') }}
                </div>
                <div
                  class="
                    flex flex-col
                    justify-center
                    items-center
                    lg:items-start
                    mt-2
                  "
                >
                  {{
                    presenterLocalization(record.unitTemplate.project, 'name')
                  }}
                </div>
              </div>
              <div>
                <div
                  class="font-medium text-center mt-3 text-xl"
                  :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
                >
                  {{ i18n('units.fields.unitTemplate') }}
                </div>
                <div
                  class="
                    flex flex-col
                    justify-center
                    items-center
                    lg:items-start
                    mt-2
                  "
                >
                  {{ presenterLocalization(record.unitTemplate, 'title') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            nav nav-tabs
            flex-col
            sm:flex-row
            justify-center
            lg:justify-start
          "
          role="tablist"
        >
          <a
            id="owners-tab"
            data-toggle="tab"
            data-target="#owners"
            href="javascript:;"
            class="py-4 sm:mr-8 active"
            role="tab"
            aria-controls="owners"
            aria-selected="true"
            @click="tab = 'owners'"
          >
            {{ i18n('units.fields.owners') }}
          </a>
          <a
            id="members-tab"
            data-toggle="tab"
            data-target="#members"
            href="javascript:;"
            class="py-4 sm:mr-8"
            role="tab"
            aria-selected="false"
            @click="tab = 'members'"
          >
            {{ i18n('units.fields.members') }}
          </a>
        </div> -->
      </div>
    </div>
    <div class="col-span-12">
      <table-view-page
        :places="record?.places || []"
        :id="id"
        @doRefresh="doFind(id)"
      ></table-view-page>
    </div>
    <!-- <div class="intro-y tab-content mt-5">
      <div
        id="owners"
        class="tab-pane active"
        role="tabpanel"
        aria-labelledby="owners-tab"
      >
        <usersTab />
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import TableViewPage from '@/views/parking/parking-view-table-page.vue'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    [TableViewPage.name]: TableViewPage
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'parking/view/record',
      loading: 'parking/view/loading'
    })
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.parking')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.parking')
    }
  },
  async mounted() {
    await this.doFind(this.id)
    console.log(this.record)
  },
  methods: {
    ...mapActions({
      doFind: 'parking/view/doFind'
    }),
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
