/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlArticle)

export class ArticleService {
  //#region [ Mutation APIs ]
  /**
   * Enable article in authentication table and firestore database
   * @param {String[]} ids
   * @returns
   */
  static async enable(ids) {
    return this._changeStatus(ids, true)
  }

  /**
   * Disable article in authentication table and firestore database
   * @param {String[]} ids
   * @returns
   */
  static async disable(ids) {
    return this._changeStatus(ids, false)
  }

  static async _changeStatus(ids, disabled) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_CHANGE_STATUS($adminsIds: [String!]!, $status: Boolean!) {
          setAdminsStatus(adminsIds: $adminsIds, status: $status)
        }
      `,

      variables: {
        adminsIds: ids,
        status: !!disabled
      }
    })

    // return response.data.setAdminsStatus
    if (response.data.setAdminsStatus && response.data.setAdminsStatus.status) {
      return response.data.setAdminsStatus.result
    } else {
      console.error(response.data.setAdminsStatus.error)
      throw response.data.setAdminsStatus.error
    }
  }

  /**
   * Set page access permissions for the admin
   * - setAdminAccess(adminId: String!, pagesAccess: [String]!): JSON!
   * @param {String} id
   * @param {String[]} pagesAccess
   * @returns
   */
  static async setAdminAccess(id, pagesAccess) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_CHANGE_ROLES($adminId: String!, $pagesAccess: [String]!) {
          setAdminAccess(adminId: $adminId, pagesAccess: $pagesAccess)
        }
      `,

      variables: {
        adminId: id,
        pagesAccess
      }
    })

    // return response.data.setAdminAccess
    if (response.data.setAdminAccess && response.data.setAdminAccess.status) {
      return response.data.setAdminAccess.result
    } else {
      console.error(response.data.setAdminAccess.error)
      throw response.data.setAdminAccess.error
    }
  }

  /**
   * Create new admin
   * @param {Object} data
   * @param {String} data.email
   * @param {String} data.password
   * @param {String} data.articleName
   * @param {String} data.phoneNumber
   * @param {String[]} data.pagesAccess
   * @param {String} data.avatar
   * @param {'en'|'ar' } data.lang
   * @returns
   */
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ARTICLE_CREATE($data: ArticleInput!) {
          articleCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })

    return response.data.articleCreate
  }

  static async accept(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ARTICLE_ACCEPT($id: String!) {
          articleAccept(id: $id)
        }
      `,
      variables: {
        id
      }
    })

    return response.data.articleAccept
  }

  static async reject(id, reason) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ARTICLE_REJECT($id: String!, $reason: String) {
          articleReject(id: $id, reason: $reason)
        }
      `,
      variables: {
        id,
        reason
      }
    })

    return response.data.articleReject
  }
  static async edit(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ARTICLE_UPDATE($id: String!, $data: ArticleInput!) {
          articleUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data
      }
    })
    return response.data.articleUpdate
  }

  static async removeArticles(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ARTICLE_DESTROY($id: String!) {
          articleDestroy(id: $id)
        }
      `,

      variables: {
        id
      }
    })

    return response.data.articleDestroy
  }
  static async removeComment(articleId, id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation DELETE_COMMENT($articleId: String!, $id: String!) {
          deleteComment(articleId: $articleId, id: $id)
        }
      `,

      variables: {
        articleId,
        id
      }
    })

    return response.data.deleteComment
  }

  // static async remove(emails, roles, all = false) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation USER_REMOVE(
  //         $emails: [String!]!
  //         $roles: [String!]!
  //         $all: Boolean
  //       ) {
  //         iamRemove(
  //           emails: $emails
  //           roles: $roles
  //           all: $all
  //         )
  //       }
  //     `,

  //     variables: {
  //       emails,
  //       roles,
  //       all
  //     }
  //   })

  //   return response.data.iamRemove
  // }

  // static async destroy(id) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation USER_DESTROY($id: String!) {
  //         iamDestroy(id: $id)
  //       }
  //     `,

  //     variables: {
  //       id
  //     }
  //   })
  //   return response.data.iamDestroy
  // }
  //#endregion

  //#region [ Query APIs ]
  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query ARTICLE_FIND($id: String!) {
          articleFind(id: $id) {
            id
            title {
              en
              ar
            }
            content {
              en
              ar
            }
            postDate
            platforms
            images
            postDetails {
              id
              platform
              postUrl
            }
            videoUrl
            tags
            categories {
              id
              name {
                en
                ar
              }
            }
            inReview
            showAuthorName
            likes
            views
            commentsCount
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.articleFind
  }

  static async listComments(articleId) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST_COMMENTS(
          $articleId: String!
          $limit: Int
          $orderBy: String
          $sortBy: SortByType
        ) {
          listComments(
            articleId: $articleId
            limit: $limit
            orderBy: $orderBy
            sortBy: $sortBy
          ) {
            hasMoreComment
            totalCount
            rows {
              id
              articleId
              comment
              createdBy {
                id
                fullName
                avatar {
                  name
                  publicUrl
                }
              }
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables: {
        articleId,
        limit: 0,
        orderBy: 'createdAt',
        sortBy: 'asc'
      }
    })

    return response.data.listComments
  }

  static async fetchArticles(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query ARTICLE_LIST(
          $filter: ArticleFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          articleList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              title {
                en
                ar
              }
              content {
                en
                ar
              }
              status
              reason
              socialPostId
              postDetails {
                id
                platform
                postUrl
              }
              postDate
              platforms
              images
              videoUrl
              tags
              categories {
                id
                name {
                  en
                  ar
                }
              }
              inReview
              showAuthorName

              likes
              views
              commentsCount

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        orderBy: 'createdAt',
        filter,
        pagination
      }
    })
    console.log('data', response.data.articleList)

    return response.data.articleList
  }
}
