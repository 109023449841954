<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            {{i18n('sports.playerForm.title')}}
          </h2>

          <div class="grid grid-cols-12 mt-5">
            <h4
              class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
            >
              {{i18n('sports.playerForm.name')}}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:placeholder-white"
                v-model="form.title.en"
                :placeholder="i18n('sports.playerForm.english')"
              />
            </div>

            <div
              class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
              :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
            >
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:placeholder-white"
                v-model="form.title.ar"
                :placeholder="i18n('sports.playerForm.arabic')"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <h4
              class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
            >
               {{i18n('sports.playerForm.picture')}}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <Dropzone
                ref-key="dropzoneValidationRef"
                :options="{
                  url: 'https://httpbin.org/post',
                  thumbnailWidth: 150,
                  maxFilesize: 0.5,
                  acceptedFiles: 'image/jpeg|image/png|image/jpg|image/webp',
                  headers: { 'My-Awesome-Header': 'header value' }
                }"
                class="dropzone"
              >
                <div class="text-lg font-medium">
                   {{i18n('sports.playerForm.fileUploaderHint')}}
                </div>
              </Dropzone>
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <h4
              class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
            >
               {{i18n('sports.playerForm.position')}}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:placeholder-white"
                v-model="form.title.en"
                :placeholder="i18n('sports.playerForm.english')"
              />
            </div>

            <div
              class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
              :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
            >
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:placeholder-white"
                v-model="form.title.ar"
                :placeholder="i18n('sports.playerForm.arabic')"
              />
            </div>
          </div>
         <div class="grid grid-cols-12 mt-5">
            <h4
              class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
            >
               {{i18n('sports.playerForm.country')}}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:placeholder-white"
                v-model="form.title.en"
                :placeholder="i18n('sports.playerForm.english')"
              />
            </div>

            <div
              class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
              :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
            >
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:placeholder-white"
                v-model="form.title.ar"
                :placeholder="i18n('sports.playerForm.arabic')"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <h4
              class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
            >
               {{i18n('sports.playerForm.age')}}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input
                id="crud-form-1"
                type="number"
                class="form-control w-full dark:placeholder-white"
                v-model="form.title.en"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <h4
              class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
            >
               {{i18n('sports.playerForm.height')}}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input
                id="crud-form-1"
                type="number"
                class="form-control w-full dark:placeholder-white"
                v-model="form.title.en"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <h4
              class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
            >
               {{i18n('sports.playerForm.weight')}}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input
                id="crud-form-1"
                type="number"
                class="form-control w-full dark:placeholder-white"
                v-model="form.title.en"
              />
            </div>
          </div>
          <!-- END -->
          <div class="mt-5">
            <button
              type="button"
              class="btn btn-primary w-24 cursor-pointer"
              @click="doSubmit()"
            >
              {{ i18n('common.save') }}
            </button>
            <button
              type="button"
              class="btn btn-secondary w-24 ml-3 mr-3 cursor-pointer"
              @click="doCancel()"
            >
              {{ i18n('common.cancel') }}
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
    <ErrorNotification :message="errorMessage" />
    <SuccessNotification
      message="Sport Created Successfully"
      button="Click Here to go back"
      link="sports"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import ErrorNotification from '@/components/notifications/error-notification.vue'
import SuccessNotification from '@/components/notifications/success-notification-with-buttons.vue'
import Toastify from 'toastify-js'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.sports')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.sports')
    }
  },
  components: {
    ErrorNotification,
    SuccessNotification
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  setup() {
    const form = ref({
      title: {
        en: '',
        ar: ''
      }
    })
    const errorMessage = ref('')
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: -1,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    return {
      form,
      errorMessage,
      errorNotificationToggle
    }
  },
  methods: {
    successNotificationToggle() {
      Toastify({
        node: cash('#success-notification-with-buttons')
          .clone()
          .removeClass('hidden')[0],
        duration: -1,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
        onClick: () => {
          this.$router.push('/notifications')
          const element = document.getElementById(
            'success-notification-with-buttons'
          )
          element.remove()
        }
      }).showToast()
    },
    doSubmit() {
      if (this.isFormValid()) {
        this.successNotificationToggle()
      } else {
        setTimeout(() => {
          this.errorNotificationToggle()
        }, 100)
      }
    },
    doCancel() {
      this.$router.push('/team-view-page')
    },
    isFormValid() {
      for (var key in this.form) {
        var value = this.form[key]
        for (var groupKey in value) {
          var value2 = value[groupKey]
          if (value2.length == 0) {
            this.errorMessage = 'notifications.create.popup.emptyFields'
            return false
          }
        }
      }
      console.log(this.form)
      return true
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1; /* Firefox */
  }
}
</style>
