<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('articles.title') }}</h2>
    </div>
    <div class="intro-y box dark:bg-dark-8 px-5 pt-5 mt-5">
      <div
        class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start"
        role="tablist"
      >
        <a
          id="chefs-tab"
          data-toggle="tab"
          data-target="#chefs"
          href="javascript:;"
          class="py-4 my-2 sm:mr-8 active badged"
          role="tab"
          aria-controls="chefs"
          aria-selected="true"
          @click="fetchAll"
        >
          <div class="bad blue">{{ published + inReview + rejected }}</div>
          <!-- <div class="bad all">2</div> -->
          {{ i18n('articles.tabs.all') }}
        </a>
        <a
          id="chefs-tab"
          data-toggle="tab"
          data-target="#chefs"
          href="javascript:;"
          class="py-4 my-2 sm:mr-8 badged"
          role="tab"
          aria-controls="chefs"
          aria-selected="true"
          @click="fetchPublished"
        >
          <div class="bad green">{{ published }}</div>
          {{ i18n('articles.tabs.published') }}
        </a>
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 my-2 sm:mr-8 badged"
          role="tab"
          aria-selected="false"
          @click="fetchInReview"
        >
          <div class="bad pending">{{ inReview }}</div>
          {{ i18n('articles.tabs.inReview') }}
        </a>

        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 my-2 sm:mr-8 badged"
          role="tab"
          aria-selected="false"
          @click="fetchRejected"
        >
          <div class="bad">{{ rejected }}</div>
          {{ i18n('articles.tabs.rejected') }}
        </a>

        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 my-2 sm:mr-8 badged"
          role="tab"
          aria-selected="false"
          @click="setTabToCat"
        >
          {{ i18n('articles.tabs.categories') }}
        </a>
      </div>
    </div>
    <categories v-if="taby == 'categories'" />
    <!-- asdasd -->
    <div class="intro-y tab-content mt-5" v-if="taby == 'article'">
      <TableFilter
        :disabled="loading"
        :filter-fields="filterFields"
        @filter="doFilter"
        @reset="doResetFilter"
      >
      </TableFilter>
      <div class="intro-y box dark:bg-dark-8 p-5 mt-5">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div
          class="flex flex-col sm:flex-row sm:items-end items-end justify-end w-full"
        >
          <div class="flex mt-5 sm:mt-0 w-full">
            <button
              class="btn btn-primary sm:w-auto pb-2 pt-2 sm:pb-1 sm:pt-1 w-full"
              @click="openCreateArticle()"
            >
              <PlusIcon />
              {{ i18n('common.create') }}
            </button>
          </div>
        </div>
        <div class="overflow-x-auto g">
          <table class="table table-report sm:mt-2 hidden md:table">
            <thead>
              <tr>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  #
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('articles.table.title') }}
                </th>

                <th class="text-center whitespace-nowrap">
                  {{ i18n('articles.table.categories') }}
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('articles.form.postDate') }}
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('articles.form.socialsLinks') }}
                </th>
                <th class="text-center whitespace-nowrap" v-if="!rejectedTable">
                  {{ i18n('articles.table.numberOfLikes') }}
                </th>
                <th class="text-center whitespace-nowrap" v-if="!rejectedTable">
                  {{ i18n('articles.table.numberOfComments') }}
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('common.status') }}
                </th>
                <th class="text-center whitespace-nowrap" v-if="rejectedTable">
                  {{ isRTL ? `سبب الرفض` : `Rejection Reason` }}
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('common.table.actions') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in rows" :key="index">
                <td class="w-40">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                    #{{ index + 1 }}
                  </div>
                </td>
                <td>
                  <div
                    class="font-medium whitespace-nowrap truncate text-center"
                  >
                    {{ presenterLocalization(row, 'title') }}
                  </div>
                </td>
                <td class="text-center flex items-center justify-center">
                  <div
                    v-for="category in row.categories"
                    :key="category"
                    class="font-medium whitespace-nowrap truncate text-gray-600 mx-1"
                  >
                    {{ isRTL ? category.name.ar : category.name.en }}
                  </div>
                </td>
                <td>
                  <div
                    class="font-medium whitespace-nowrap truncate text-center"
                  >
                    {{ presenterDate(row, 'postDate') }}
                  </div>
                </td>

                <td
                  class="text-center flex items-center justify-center gap-2 p-0 custome-td"
                  v-if="row.postDetails.length > 0 && row.status == 'accepted'"
                >
                  <a
                    v-for="(detail, index) in row.postDetails"
                    :key="index"
                    :href="detail.postUrl"
                    target="_blank"
                  >
                    <img
                      :src="
                        detail.platform.toLowerCase() == 'facebook'
                          ? '/images/fb.svg'
                          : detail.platform.toLowerCase() == 'youtube'
                          ? '/images/youtube.svg'
                          : detail.platform.toLowerCase() == 'twitter'
                          ? '/images/twitter.svg'
                          : detail.platform.toLowerCase() == 'tiktok'
                          ? '/images/tiktok.svg'
                          : detail.platform.toLowerCase() == 'system'
                          ? '/images/web.svg'
                          : '/images/linkedin.svg'
                      "
                      height="25"
                      width="25"
                      alt="platform"
                    />
                  </a>
                </td>
                <td class="text-center" v-else>
                  {{ isRTL ? `لم ينشر ` : `Not Posted` }}
                </td>

                <td class="text-center" v-if="!rejectedTable">
                  <div class="font-medium whitespace-nowrap truncate">
                    {{ row.likes.length }}
                  </div>
                </td>
                <td class="text-center" v-if="!rejectedTable">
                  <div class="font-medium whitespace-nowrap truncate">
                    {{ row.commentsCount ? row.commentsCount : 0 }}
                  </div>
                </td>
                <td class="text-center" v-if="!rejectedTable">
                  <div
                    class="font-medium whitespace-nowrap truncate text-theme-10"
                  >
                    {{
                      row.inReview
                        ? i18n('articles.inReview')
                        : i18n('articles.notReview')
                    }}
                  </div>
                </td>
                <td class="text-center" v-else>
                  <div
                    class="font-medium text-center whitespace-nowrap truncate text-theme-24"
                  >
                    {{ isRTL ? `مرفوض` : `Rejected` }}
                  </div>
                </td>

                <td class="text-center" v-if="rejectedTable">
                  <div
                    class="font-medium text-center whitespace-nowrap truncate truncate w-80"
                  >
                    {{ row.reason }}
                  </div>
                </td>
                <td class="table-report__action">
                  <div
                    class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer gap-4"
                  >
                    <div @click="viewArticle(row.id)">
                      <EyeIcon class="w-4 h-4" />
                    </div>
                    <div @click="editArtical(row.id)" v-if="!rejectedTable">
                      <EditIcon class="w-4 h-4" />
                    </div>
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#delete-modal-preview"
                      class="text-theme-24"
                      @click="deleteId = row.id"
                    >
                      <Trash2Icon class="w-4 h-4" />
                    </a>

                    <div
                      class="accept"
                      @click="doAccept(row.id)"
                      v-if="
                        underReviewTable && currentUser.roles.includes('owner')
                      "
                    >
                      <img src="@/assets/images/ok.svg" width="30px" alt="" />
                    </div>
                    <!-- <div class="reject" @click="doReject(row.id)">
                      <img
                        src="@/assets/images/notok.svg"
                        width="30px"
                        alt=""
                      />
                    </div> -->
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#reject-article-modal"
                      @click="selectedId = row.id"
                      class="text-theme-24 flex items-center gap-1"
                      v-if="
                        underReviewTable && currentUser.roles.includes('owner')
                      "
                    >
                      <XCircleIcon class="w-4 h-4" />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mobile-table md:hidden mt-8">
            <mobile-table-comp
              v-for="(row, index) in rows"
              :key="index"
              :row="row"
              class="md:hidden"
            >
              <div class="field">
                <span>{{ i18n('articles.table.title') }} </span>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row, 'title') }}
                </div>
              </div>
              <div class="field" v-if="row.categories">
                <span> {{ i18n('articles.table.categories') }} :</span>
                <div
                  v-for="category in row.categories"
                  :key="category"
                  class="font-medium whitespace-nowrap truncate text-gray-600 mx-1"
                >
                  {{ isRTL ? category.name.ar : category.name.en }}
                </div>
              </div>
              <div class="field">
                <span> {{ i18n('articles.form.postDate') }} :</span>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterDate(row, 'postDate') }}
                </div>
              </div>

              <div class="field">
                <span> {{ i18n('articles.form.socialsLinks') }} :</span>
                <div
                  v-if="row.postDetails.length > 0 && row.status == 'accepted'"
                  class="text-center flex items-center justify-center gap-1 p-0 custome-td"
                >
                  <a
                    v-for="(detail, index) in row.postDetails"
                    :key="index"
                    :href="detail.postUrl"
                  >
                    <img
                      :src="
                        detail.postUrl == 'Facebook'
                          ? '/images/fb.svg'
                          : detail.postUrl == 'Youtube'
                          ? '/images/youtube.svg'
                          : detail.postUrl == 'Twitter'
                          ? '/images/twitter.svg'
                          : detail.postUrl == 'Tiktok'
                          ? '/images/tiktok.svg'
                          : '/images/linkedin.svg'
                      "
                      height="23"
                      width="23"
                      alt="platform"
                    />
                  </a>
                </div>

                <div
                  v-else
                  class="text-center flex items-center justify-center gap-1 p-0 custome-td"
                >
                  {{ isRTL ? `لم ينشر ` : `Not Posted ` }}
                </div>
              </div>

              <div class="field">
                <span> {{ i18n('articles.table.numberOfLikes') }} :</span>
                <div class="font-medium whitespace-nowrap truncate">
                  {{ row.likes.length }}
                </div>
              </div>
              <div class="field">
                <span> {{ i18n('articles.table.numberOfComments') }} :</span>
                <div class="font-medium whitespace-nowrap truncate">
                  {{ row.commentsCount }}
                </div>
              </div>
              <div class="field">
                <span>{{ i18n('common.status') }} :</span>
                <div
                  class="font-medium whitespace-nowrap truncate text-theme-10"
                >
                  {{
                    row.inReview
                      ? i18n('articles.inReview')
                      : i18n('articles.notReview')
                  }}
                </div>
              </div>

              <div class="field" v-if="rejectedTable">
                <span> {{ isRTL ? `سبب الرفض` : `Rejection Reason` }} :</span>
                <div class="font-medium whitespace-nowrap truncate truncate">
                  {{ row.reason }}
                </div>
              </div>

              <div class="field">
                <span> {{ i18n('common.table.actions') }} :</span>
                <div
                  class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer gap-4"
                >
                  <div @click="viewArticle(row.id)">
                    <EyeIcon class="w-4 h-4" />
                  </div>
                  <div @click="editArtical(row.id)" v-if="!rejectedTable">
                    <EditIcon class="w-4 h-4" />
                  </div>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    class="text-theme-24"
                    @click="deleteId = row.id"
                  >
                    <Trash2Icon class="w-4 h-4" />
                  </a>

                  <div
                    class="accept"
                    @click="doAccept(row.id)"
                    v-if="
                      underReviewTable && currentUser.roles.includes('owner')
                    "
                  >
                    <img src="@/assets/images/ok.svg" width="30px" alt="" />
                  </div>
                  <!-- <div class="reject" @click="doReject(row.id)">
                      <img
                        src="@/assets/images/notok.svg"
                        width="30px"
                        alt=""
                      />
                    </div> -->
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#reject-article-modal"
                    @click="selectedId = row.id"
                    class="text-theme-24 flex items-center gap-1"
                    v-if="
                      underReviewTable && currentUser.roles.includes('owner')
                    "
                  >
                    <XCircleIcon class="w-4 h-4" />
                  </a>
                </div>
              </div>
            </mobile-table-comp>
          </div>

          <app-empty-page
            v-if="!rows.length && !loading"
            :label="i18n('empty.article')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
        <Pagination
          v-if="rows.length"
          :page="currentPage"
          :pageSize="pagination.limit"
          :rowsNumber="pagination.total"
          :showSizeChanger="true"
          :isFirstPage="isFirstPage"
          :isLastPage="isLastPage"
          :disabled="!rows.length"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
        >
        </Pagination>
      </div>
      <DeleteModal item="article" @delete="doDestroy(deleteId)" />
      <rejectModal :id="selectedId" />
    </div>

    <!-- asdasd -->
  </div>
</template>

<script>
import DeleteModal from '@/components/modals/delete-modal.vue'
import rejectModal from '@/components/modals/reject-article-modal.vue'

import { mapActions, mapGetters } from 'vuex'
import { ref } from 'vue'
import { GenericModel } from '@/shared/model/generic-model'
import categories from '@/views/categories/categories-list-page'
// import rejected from '@/views/rejected-articles/view'

export default {
  async created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.articles')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.articles')
    }
  },
  components: {
    DeleteModal,
    categories,
    rejectModal
    // rejected
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'article/list/rows',
      currentPage: 'article/list/currentPage',
      isFirstPage: 'article/list/isFirstPage',
      isLastPage: 'article/list/isLastPage',
      loading: 'article/list/loading',
      inReview: 'article/list/inReview',
      published: 'article/list/published',
      rejected: 'article/list/rejected',
      pagination: 'article/list/pagination',
      currentUser: 'auth/currentUser'
    }),
    filterFields() {
      return [
        {
          label: this.i18n('articles.table.title'),
          value: 'title',
          type: 'text'
        }
      ]
    },
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  setup() {
    const rejectedTable = ref(null)
    const underReviewTable = ref(null)

    const selectedId = ref('')
    const deleteId = ''
    const taby = ref('article')
    const filterInput = null
    const postDetails = [
      {
        id: '213123',
        platform: '@/assets/images/tiktok.svg',
        postUrl: 'Youtube'
      },
      {
        id: '213123',
        platform: '@/assets/images/tiktok.svg',
        postUrl: 'Facebook'
      },
      {
        id: '213123',
        platform: '@/assets/images/tiktok.svg',
        postUrl: 'Linkedin'
      },
      {
        id: '213123',
        platform: '@/assets/images/tiktok.svg',
        postUrl: 'Tiktok'
      }
    ]
    return {
      deleteId,
      filterInput,
      postDetails,
      taby,
      selectedId,
      rejectedTable,
      underReviewTable
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
    await this.doListenToInreivew()
  },
  methods: {
    ...mapActions({
      doFetch: 'article/list/doFetch',
      doReject: 'article/list/doReject',
      doAccept: 'article/list/doAccept',
      doListenToInreivew: 'article/list/doListenToInreivew',
      doFetchNextPage: 'article/list/doFetchNextPage',
      doFetchPreviousPage: 'article/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'article/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'article/list/doChangePaginationPageSize',
      doDestroy: 'article/list/doDestroy'
    }),
    setTabToCat() {
      this.taby = 'categories'
      console.log('categories')
    },
    async fetchAll() {
      this.rejectedTable = false
      this.underReviewTable = false
      this.tab = 'all'
      this.taby = 'article'
      await this.doFetch({
        filter: {},
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
      console.log('gg')
    },
    async fetchPublished() {
      this.rejectedTable = false
      this.underReviewTable = false
      this.tab = 'Published'
      this.taby = 'article'
      await this.doFetch({
        filter: {
          inReview: false,
          status: 'accepted',
          categories: []
        },
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async fetchInReview() {
      this.rejectedTable = false
      this.underReviewTable = true
      this.tab = 'inReview'
      this.taby = 'article'
      await this.doFetch({
        filter: {
          inReview: true,
          categories: []
        },
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },

    async fetchRejected() {
      this.tab = 'inReview'
      this.taby = 'article'
      this.underReviewTable = false
      this.rejectedTable = true
      await this.doFetch({
        filter: {
          status: 'rejected',
          categories: []
        },
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async doFilter(filter) {
      this.filterInput = null
      if (!filter) return
      if (filter.title) {
        const val = filter.title
        this.filterInput = {
          title: {
            lang: this.isArabic(val) ? 'ar' : 'en',
            value: val
          }
        }
      } else {
        this.filterInput = filter
      }
      await this.doFetchFirstPage()
    },
    isArabic(str) {
      return /[\u0600-\u06FF\u0750-\u077F]/.test(str)
    },
    async doResetFilter() {
      this.filterInput = null
      await this.doFetchFirstPage()
    },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: this.filterInput,
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach((element) => {
          this.selected.push(element.id)
        })
      }
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterArr(row, fieldName) {
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].join(', ')
        : 'ـــ'
    },
    presenterArrLocalized(row, fieldName) {
      const locale = this.isRTL ? 'ar' : 'en'
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].map((val) => val[locale]).join(',')
        : 'ـــ'
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    viewArticle(id) {
      this.$router.push(`/article/${id}`)
    },
    editArtical(id) {
      this.$router.push(`/article-form-page/${id}`)
    },
    openCreateArticle() {
      this.$router.push('/article-form-page')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}

.custome-td {
  padding: 5px;
}
.badged {
  position: relative;
}
.bad {
  position: absolute;
  top: -3px;
  right: -18px;
  background: rgb(239, 45, 45);
  min-width: 18px;
  height: 18px;
  padding: 0px 2px;
  text-align: center;
  border-radius: 3px;
  font-size: 12px;
  color: white;
  font-weight: 900;
  vertical-align: top;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.green {
  background: rgb(0, 165, 0);
}
.pending {
  background: rgb(255, 230, 0);
  color: rgb(68, 68, 68);
}
.blue {
  background: rgb(27, 69, 146);
}

.A_R {
  width: fit-content;
  min-width: 45px;
  display: flex;
  box-shadow: none;
  gap: 10px;
}
.accept img,
.reject img {
  width: 100%;
  height: 100%;
  box-shadow: none;
}
.accept {
  width: 17.7px;
  box-shadow: none;
}

.reject {
  min-width: 20px;
  box-shadow: none;
}
</style>
