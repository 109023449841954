/* eslint-disable */
import { UserService } from '@/store/user/user-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n';

const INITIAL_PAGE_SIZE = 10
const INITIAL_PAGINATION_INPUT = {
  limit: INITIAL_PAGE_SIZE,
  sortBy: 'asc'
}

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    isFirstPage: null,
    isLastPage: null,
    loading: false,
    filter: [],
    currentPage: 1,
    pagination: INITIAL_PAGINATION_INPUT
  },

  getters: {
    loading: state => state.loading,
    rows: state => state.rows || [],
    count: state => state.count,
    isFirstPage: state => state.isFirstPage,
    isLastPage: state => state.isLastPage,
    hasRows: (state, getters) => getters.count > 0,
    filter: state => state.filter,
    currentPage: state => state.currentPage,
    limit: state => {
      const pagination = state.pagination

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE
      }

      return pagination.pageSize
    },
    offset: state => {
      const pagination = state.pagination

      if (!pagination || !pagination.pageSize) {
        return 0
      }

      const currentPage = pagination.currentPage || 1

      return (currentPage - 1) * pagination.pageSize
    },
    pagination: state => state.pagination
  },

  mutations: {
    RESETED(state) {
      state.rows = []
      state.count = 0
      state.loading = false
      state.filter = []
      state.pagination = INITIAL_PAGINATION_INPUT
      state.currentPage = 1
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || INITIAL_PAGINATION_INPUT
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      state.currentPage =
        payload && payload > 0 ? payload : state.currentPage || 1
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT

      state.currentPage = state.currentPage || 1
      state.pagination = {
        ...previousPagination,
        limit: payload || INITIAL_PAGE_SIZE
      }
    },

    FETCH_STARTED(state, payload) {
      state.loading = true

      state.filter = payload && payload.filter ? payload.filter : state.filter
      state.pagination =
        payload && payload.pagination
          ? payload.pagination
          : payload.keepPagination
          ? state.pagination
          : {}
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false
      state.rows = payload.rows
      state.count = payload.count
      state.isFirstPage = payload.isFirstPage
      state.isLastPage = payload.isLastPage
    },

    FETCH_ERROR(state) {
      state.loading = false
      state.rows = []
      state.count = 0
    },
    ADD_STARTED(state) {
      state.loading = true
    },
    ADD_SUCCESS(state) {
      state.loading = false
    },
    ADD_ERROR(state) {
      state.loading = false
    },
    DESTROY_STARTED(state) {
      state.loading = true
    },

    DESTROY_SUCCESS(state) {
      state.loading = false
    },

    DESTROY_ERROR(state) {
      state.loading = false
    }
  },

  actions: {
    async doReset({ commit, dispatch }) {
      commit('RESETED')
      return dispatch('doFetch')
    },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize)
      const filter = getters.filter
      const pagination = getters.pagination
      dispatch('doFetch', { filter, pagination, keepPagination: true })
    },

    doChangePaginationCurrentPage({ commit }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage)
    },

    async doFetchNextPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      if (rows.length) {
        const currentPage = getters.currentPage || 1
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const pagination = {
          ...previousPagination,
          action: 'next',
          page: currentPage
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage + 1)
        await dispatch('doFetch', { pagination })
      }
    },
    async doFetchPreviousPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      const currentPage = getters.currentPage || 1
      if (currentPage > 1 && rows.length) {
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const pagination = {
          ...previousPagination,
          action: 'prev',
          page: currentPage
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage - 1)
        await dispatch('doFetch', { pagination })
      }
    },
    async doFetch(
      { commit, getters },
      { filter, pagination, keepPagination = true } = {}
    ) {
      try {
        commit('FETCH_STARTED', { filter, pagination, keepPagination })

        const PaginationInput = {
          ...pagination,
          limit: pagination?.limit || getters.pagination.limit,
          sortBy: pagination?.sortBy || getters.pagination.sortBy
        }

        const response = await UserService.fetchAdmins(
          getters.filter,
          PaginationInput
        )
        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
          isFirstPage: response.pagination?.isFirstPage,
          isLastPage: response.pagination?.isLastPage
        })
      } catch (error) {
        Errors.handle(error)
        commit('FETCH_ERROR')
      }
    },
    async doDisableAllSelected({ getters, commit, dispatch }, ids) {
      try {
        const selectedRows = ids

        commit('CHANGE_STATUS_SELECTED_STARTED')

        // await UserService.disable(selectedRows.map((user) => user.id));
        await UserService.disable(selectedRows)

        commit('CHANGE_STATUS_SELECTED_SUCCESS')

        Message.success(i18n('iam.doDisableAllSuccess'))

        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_STATUS_SELECTED_ERROR')
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      }
    },
    async doEnableAllSelected({ commit, getters, dispatch }, ids) {
      try {
        // const selectedRows = getters.selectedRows;
        const selectedRows = ids

        commit('CHANGE_STATUS_SELECTED_STARTED')

        // await UserService.enable(selectedRows.map((user) => user.id));
        await UserService.enable(selectedRows)

        commit('CHANGE_STATUS_SELECTED_SUCCESS')

        Message.success(i18n('iam.doEnableAllSuccess'))

        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      } catch (error) {
        Errors.handle(error)

        commit('CHANGE_STATUS_SELECTED_ERROR')

        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      }
    },
    async doDisable({ getters, commit, dispatch }, id) {
      try {

        commit('CHANGE_STATUS_SELECTED_STARTED')

        await UserService.disableUser(id)

        commit('CHANGE_STATUS_SELECTED_SUCCESS')

        Message.success(i18n('iam.doDisableSuccess'))

        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_STATUS_SELECTED_ERROR')
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      }
    },
    async doEnable({ commit, getters, dispatch }, id) {
      try {

        commit('CHANGE_STATUS_SELECTED_STARTED')

        await UserService.enableUser(id)

        commit('CHANGE_STATUS_SELECTED_SUCCESS')

        Message.success(i18n('iam.doEnableSuccess'))

        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      } catch (error) {
        Errors.handle(error)

        commit('CHANGE_STATUS_SELECTED_ERROR')

        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination
        })
      }
    }

    // async doRemoveAllSelected({ commit, getters, dispatch }) {
    //   try {
    //     const selectedRows = getters.selectedRows;

    //     commit('REMOVE_ALL_SELECTED_STARTED');

    //     await UserService.remove(
    //       selectedRows.map((row) => row.email),
    //       [],
    //       true,
    //     );

    //     dispatch('REMOVE_ALL_SELECTED_SUCCESS');

    //     Message.success(i18n('iam.doRemoveAllSelectedSuccess'));

    //     const filter = getters.filter;
    //     dispatch('doFetch', { filter });
    //   } catch (error) {
    //     Errors.handle(error);

    //     commit('REMOVE_ALL_SELECTED_ERROR');

    //     const filter = getters.filter;
    //     dispatch('doFetch', { filter });
    //   }
    // },
    // async doDestroy({ commit }, id) {
    //   try {
    //     await UserService.destroy(id);
    //     Message.success(i18n('entities.admin.destroy.success'));
    //   } catch (error) {
    //     console.log(commit);
    //     Errors.handle(error);
    //   }
    // },
  }
}
