/* eslint-disable */

import socialMediaListStore from '@/store/social-media/social-media-list-store'
import socialMediaFormStore from '@/store/social-media/social-media-form-store'

export default {
  namespaced: true,

  modules: {
    list: socialMediaListStore,
    form: socialMediaFormStore,
    // destroy: socialMediaDestroyStore
  }
}
