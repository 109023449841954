<template>
  <div id="error-notify-content" class="toastify-content hidden flex">
    <InfoIcon class="text-theme-5" style="color: red !important;" />
    <div class="ml-4 mr-4">
      <div class="font-medium" style="color: red !important;">{{ i18n('notificationPopup.error') }}</div>
      <div id="error-message-text" class="text-gray-600 mt-1">{{ i18n(message) }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    message: {
      type: String
    }
  },
  // created() {
  //   console.log(this.message)
  // },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style></style>
