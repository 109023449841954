<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n
              code="liveVideos.form.title.edit"
              v-if="isEditing"
            ></app-i18n>
            <app-i18n code="liveVideos.form.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !form"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="form">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('liveVideos.form.link') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    id="crud-form-1"
                    rows="4"
                    class="form-control w-full dark:placeholder-white"
                    v-model="form.html"
                    :placeholder="i18n('liveVideos.form.link')"
                  />
                </div>
              </div>
              <!-- END -->
              <div class="mt-5">
                <button
                  type="button"
                  class="btn btn-primary w-24 cursor-pointer"
                  @click="doSubmit()"
                >
                  {{ i18n('common.save') }}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary w-24 ml-3 mr-3 cursor-pointer"
                  @click="doCancel()"
                >
                  {{ i18n('common.cancel') }}
                </button>
              </div>
            </form>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'

export default {
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.live')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.live')
    }
  },
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      form: null,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'live/form/record',
      findLoading: 'live/form/findLoading',
      saveLoading: 'live/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    this.form = this.record || {
      html: ''
    }
  },
  methods: {
    ...mapActions({
      doNew: 'live/form/doNew',
      doFind: 'live/form/doFind',
      doCreate: 'live/form/doCreate',
      doUpdate: 'live/form/doUpdate'
    }),
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      if (this.isEditing) {
        await this.doUpdate({
          id: this.id,
          html: this.form.html
        })
      } else {
        await this.doCreate({
          html: this.form.html
        })
      }
    },
    doCancel() {
      this.$router.push('/live-videos')
    },
    isFormValid() {
      if (!this.form.html || this.form.html.length === 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      console.log(
        /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/.test(this.form.html)
      )
      if (
        !(/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/.test(this.form.html))
      ) {
        this.errorMessage = 'liveVideos.invalid'
        return false
      }
      for (var key in this.form) {
        var value = this.form[key]
        for (var groupKey in value) {
          var value2 = value[groupKey]
          if (value2.length == 0) {
            this.errorMessage = 'restaurants.edit.errors.emptyFields'
            return false
          }
        }
      }
      console.log(this.form)
      return true
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1; /* Firefox */
  }
}
</style>
