<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="ads.form.title.edit" v-if="isEditing"></app-i18n>
            <app-i18n code="ads.form.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !model"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('ads.fields.title') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.title.en"
                    :placeholder="i18n('restaurants.fields.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.title.ar"
                    :placeholder="i18n('restaurants.fields.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('ads.fields.description') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    id="description-en"
                    :rows="4"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.description.en"
                    :placeholder="i18n('restaurants.fields.english')"
                  ></textarea>
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <textarea
                    id="description-ar"
                    :rows="4"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.description.ar"
                    :placeholder="i18n('restaurants.fields.arabic')"
                  ></textarea>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('ads.fields.link') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.link"
                    :placeholder="i18n('ads.fields.link')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('ads.fields.endDate') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <Litepicker
                    id="post-form-2"
                    v-model="model.endDate"
                    :options="{
                      autoApply: false,
                      showWeekNumbers: true,
                      dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true
                      }
                    }"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('ads.fields.appearIn') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 items-center"
                >
                  <v-select
                    :options="
                      isRTL ? appearOptionsAr || [] : appearOptions || []
                    "
                    label="name"
                    v-model="model.appearIn"
                    class="select-center"
                    title="Select item"
                  >
                    <template #no-options>
                      <div class="loading-loader"></div> </template
                  ></v-select>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('ads.fields.type') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 items-center"
                >
                  <v-select
                    :options="isRTL ? typeOptionsAr || [] : typeOptions || []"
                    label="name"
                    v-model="model.type"
                    class="select-center"
                    title="Select item"
                  >
                    <template #no-options>
                      <div class="loading-loader"></div> </template
                  ></v-select>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('ads.fields.position') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 items-center"
                >
                  <v-select
                    :options="
                      isRTL ? positionOptionsAr || [] : positionOptions || []
                    "
                    label="name"
                    v-model="model.position"
                    class="select-center"
                    title="Select item"
                  >
                    <template #no-options>
                      <div class="loading-loader"></div> </template
                  ></v-select>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('ads.fields.image') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && imageUrl">
                    <img
                      :src="imageUrl"
                      class="object-cover object-center"
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="
                        delete-icon
                        w-12
                        h-12
                        absolute
                        bottom-0
                        left-0
                        cursor-pointer
                      "
                      @click="model.image = undefined"
                    />
                  </div>
                  <Uploader ref="uploaderImage" v-else></Uploader>
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-36 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading || uploadLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="
                    btn
                    bg-theme-31
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  "
                  :disabled="saveLoading || findLoading || uploadLoading"
                  @click="doCancel"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import Uploader from '@/views/ads/uploader.vue'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: {
    Uploader
  },
  data() {
    return {
      model: null,
      errorMessage: null,
      appearOptions: [
        { name: 'Web', value: 'web' },
        { name: 'Mobile', value: 'mobile' },
        { name: 'Both', value: 'both' }
      ],
      appearOptionsAr: [
        { name: 'الويب', value: 'web' },
        { name: 'الموبيل', value: 'mobile' },
        { name: 'الاثنين', value: 'both' }
      ],
      typeOptions: [
        { name: 'Home Page', value: 'homePage' },
        { name: 'Article', value: 'articlePage' }
      ],
      typeOptionsAr: [
        { name: 'الصفحة الرئيسية', value: 'homePage' },
        { name: 'مقاله', value: 'articlePage' }
      ],
      positionOptions: [
        { name: 'Top', value: 'top' },
        { name: 'Bottom', value: 'bottom' }
      ],
      positionOptionsAr: [
        { name: 'اعلى', value: 'top' },
        { name: 'اسفل', value: 'bottom' }
      ],
      telDropOptions: { showFlags: true },
      telOptions: {
        placeholder: 'Enter restaurant Phone Number',
        showDialCode: true
      },
      telOptionsAr: { placeholder: 'ادخل رقم المطعم', showDialCode: true }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'ads/form/record',
      findLoading: 'ads/form/findLoading',
      saveLoading: 'ads/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    },
    filesToSaveImage() {
      return this.$refs.uploaderImage?.filesToSave || []
    },
    imageUrl() {
      return this.model && this.model.image ? this.model.image : null
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.ads')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.ads')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    this.model = this.record || {
      image: '',
      link: '',
      title: {
        en: '',
        ar: ''
      },
      description: {
        en: '',
        ar: ''
      },
      endDate: '',
      type: '',
      appearIn: '',
      position: ''
    }
    console.log(this.model)
    if (this.isEditing) {
      this.model.endDate = this.record.endDate || ''
      this.model.type = this.typeOptions.filter(type => type.value === this.record.type)[0]
      this.model.appearIn = this.appearOptions.filter(appearIn => appearIn.value === this.record.appearIn)[0]
      this.model.position = this.positionOptions.filter(position => position.value === this.record.position)[0]
    }
  },
  methods: {
    ...mapActions({
      doNew: 'ads/form/doNew',
      doFetchBranches: 'branch/list/doFetch',
      doFind: 'ads/form/doFind',
      doCreate: 'ads/form/doCreate',
      doUpdate: 'ads/form/doUpdate'
    }),
    deleteMenu(index) {
      this.model.menus.splice(index, 1)
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.title.en.length == 0 || this.model.title.ar.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.description.en.length == 0 ||
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.link.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.endDate.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.type.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.appearIn.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.position.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      if (this.isEditing) {
        if (!(this.imageUrl || this.filesToSaveImage.length !== 0)) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      } else {
        if (this.filesToSaveImage.length === 0) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/ads')
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const { title, description, link, endDate, type, appearIn, position } =
        this.model
      if (this.isEditing) {
        // console.log({
        //   id: this.id,
        //   title,
        //   description,
        //   link,
        //   endDate: new Date(endDate),
        //   type: type.value,
        //   appearIn: appearIn.value,
        //   position: position.value,
        //   image: this.imageUrl || this.filesToSaveImage[0]
        // })
        await this.doUpdate({
          id: this.id,
          title,
          description,
          link,
          endDate: new Date(endDate),
          type: type.value,
          appearIn: appearIn.value,
          position: position.value,
          image: this.imageUrl || this.filesToSaveImage[0]
        })
      } else {
        await this.doCreate({
          title,
          description,
          link,
          endDate: new Date(endDate),
          type: type.value,
          appearIn: appearIn.value,
          position: position.value,
          image: this.filesToSaveImage[0]
        })
      }
    }
  }
})
</script>

<style lang="scss">
.dropzone {
  .dz-preview {
    .dz-details {
      font-size: 12px !important;
      padding: 0em 1em !important;
    }
    .dz-image {
      width: 80px !important;
      height: 80px !important;
    }
  }
}
</style>
