/* eslint-disable */

import adsListStore from '@/store/logs/logs-list-store'
export default {
  namespaced: true,

  modules: {
    list: adsListStore
  }
}
