<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('administration.view.title') }}
      </h2>
      <button
        class="btn bg-theme-32 text-white sm:w-auto pb-2 pt-2"
        @click="$router.push('administration/edit')"
      >
        <EditIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.edit') }}
      </button>
    </div>

    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        style="width: 100%; height: 30vh"
        class="flex justify-center"
        v-if="loading"
      >
        <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
      </div>
      <div v-if="record && !loading">
        <div class="p-5">
          <div class="flex flex-col px-5 gap-3 items-center justify-center">
            <div
              class="
                w-40
                h-40
                sm:w-24 sm:h-24
                flex-none
                lg:w-32 lg:h-32
                image-fit
                relative
              "
            >
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-full"
                data-action="zoom"
                :src="
                  record.presidentPhoto
                    ? record.presidentPhoto?.publicUrl
                    : '/images/default-avatar.svg'
                "
              />
            </div>
            <div class="ml-5 mr-5">
              <div
                class="
                  w-24
                  sm:w-40 sm:whitespace-normal
                  text-center
                  font-medium
                  text-lg
                "
              >
                {{ presenterLocalization(record, 'presidentName') }}
              </div>
            </div>
          </div>
        </div>
        <div class="p-5 border-t">
          <div class="relative flex items-center">
            <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
              <div class="font-medium mb-1">
                {{ i18n('administration.form.description') }}
              </div>
              <div class="text-gray-600 dark:text-gray-300">
                {{ presenterLocalization(record, 'description') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12">
      <table-view-page
        :places="record?.places || []"
        :id="id"
        @doRefresh="doFind()"
      ></table-view-page>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import TableViewPage from '@/views/administration/administration-view-table-page.vue'

export default {
  components: {
    [TableViewPage.name]: TableViewPage
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'administration/form/record',
      loading: 'administration/form/findLoading'
    })
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.administration')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.administration')
    }
  },
  async mounted() {
    await this.doFind()
    console.log(this.record)
  },
  methods: {
    ...mapActions({
      doFind: 'administration/form/doFindAdministration'
    }),
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
