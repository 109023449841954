/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlSponsor)

export class SponsorService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query SPONSOR_LIST(
          $filter: SponsorFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          sponsorList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              name {
                en
                ar
              }
              link
              logo
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.sponsorList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query SPONSOR_FIND($id: String!) {
          sponsorFind(id: $id) {
            id
            name {
              en
              ar
            }
            link
            logo
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id
      }
    })

    return response.data.sponsorFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SPONSOR_CREATE($data: SponsorInput!) {
          sponsorCreate(data: $data) {
            id
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.sponsorCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SPONSOR_UPDATE($id: String!, $data: SponsorInput!) {
          sponsorUpdate(id: $id, data: $data) {
            id
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.sponsorUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SPONSOR_DESTROY($id: String!) {
          sponsorDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.sponsorDestroy
  }
}
