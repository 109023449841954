/* eslint-disable */ 
// import { routerAsync } from '@/app-module';
import { UserService } from '@/store/user/user-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';
import router from '@/router';

// import lodash from 'lodash';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
// import { firestoreAction, vuexfireMutations } from 'vuexfire'
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore'

export default {
  namespaced: true,

  state: {
    record: null,
    owner: null,
    loading: false,
    statusLoading: false,
    addresses: [],
    addressLoading: false,
  },

  getters: {
    record: (state) => state.record,
    loading: (state) => !!state.loading,
    owner: (state) => state.owner,
    statusLoading: (state) => !!state.statusLoading,
    addresses: (state) => state.addresses || [],
    addressLoading: (state) => !!state.addressLoading,
  },

  mutations: {
    // ...vuexfireMutations, // adds Vuexfire built-in mutations
    // VUEXFIRE_FIND_SUCCESS(state) {
    //   state.loading = false;
    // },
    // VUEXFIRE_FIND_OWNER_SUCCESS(state, payload) {
    //   if (lodash.isArray(payload)) {
    //     state.record = payload.length ? payload[0] : null
    //   } else {
    //     state.record = payload
    //   }
    //   state.loading = false;
    // },

    CHANGE_STATUS_STARTED(state) {
      state.statusLoading = true;
    },
    CHANGE_STATUS_SUCCESS(state) {
      state.statusLoading = false;
    },
    CHANGE_STATUS_ERROR(state) {
      state.statusLoading = false;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },
    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },

    FIND_ADDRESSES_STARTED(state) {
      state.addresses = [];
      state.addressLoading = true;
    },
    FIND_ADDRESSES_SUCCESS(state, payload) {
      state.addresses = payload;
      state.addressLoading = false;
    },
    FIND_ADDRESSES_ERROR(state) {
      state.addresses = [];
      state.addressLoading = false;
    },
  },

  actions: {
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        console.log('start find ===>')
        const record = await UserService.findAdmin(id);
        // const record = await FirebaseRepository.findDocument('users', id);
        console.log('start find ===>', record)
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        router.push('/admins')
      }
    },

    async doFindAddresses({ commit, rootGetters }, id) {
      try {
        commit('FIND_ADDRESSES_STARTED');
        const addresses = await UserService.listUserAddresses(id);
        commit('FIND_ADDRESSES_SUCCESS', addresses);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ADDRESSES_ERROR');
        rootGetters[`user/list/type`] == 'admin'
          ? router.push('/admins')
          : router.push('/users')
      }
    },

    // doFindByVuexfire: firestoreAction(async (context, id) => {
    //   try {
    //     context.commit('FIND_STARTED');

    //     const customSerializer = (doc) => {
    //       const data = FirebaseRepository.mapDocument(doc)
    //       Object.defineProperty(data, '_doc', { value: doc })
    //       return data
    //     }

    //     const db = firebase.firestore()
    //     const UserQuery = db.collection('user').doc(id)
          
    //     await context.bindFirestoreRef(
    //       'record',
    //       UserQuery,
    //       { serialize: customSerializer }
    //     )
        
    //     context.commit('VUEXFIRE_FIND_SUCCESS');
    //   } catch (error) {
    //     Errors.handle(error);
    //     context.commit('FIND_ERROR');
    //     router.push('/admins');
    //   }
    // }),

    // async doToggleStatus({ getters, commit, dispatch }) {
    //   try {
    //     const record = getters.record;

    //     commit('CHANGE_STATUS_STARTED');

    //     const isEnabling = !!record.disabled;

    //     if (isEnabling) {
    //       await UserService.enable([record.id]);
    //     } else {
    //       await UserService.disable([record.id]);
    //     }

    //     if (isEnabling) {
    //       Message.success(i18n('user.doEnableSuccess'));
    //     } else {
    //       Message.success(i18n('user.doDisableSuccess'));
    //     }

    //     dispatch('doFind', record.id);
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('CHANGE_STATUS_ERROR');
    //   }
    // },

    // async doToggleUserAccountStatus(context, id) {
    //   try {
    //     // debugger
        
    //     const db = firebase.firestore();
    //     const doc = await db.collection('user').doc(id).get();
    //     const record = FirebaseRepository.mapDocument(doc)

    //     context.commit('CHANGE_STATUS_STARTED');

    //     const isEnabling = !!record.disabled;

    //     if (isEnabling) {
    //       await UserService.enable([id]);
    //     } else {
    //       await UserService.disable([id]);
    //     }

    //     if (isEnabling) {
    //       Message.success(i18n('user.doEnableSuccess'));
    //     } else {
    //       Message.success(i18n('user.doDisableSuccess'));
    //     }

    //     context.commit('CHANGE_STATUS_SUCCESS');
    //     // context.dispatch('doFind', record.id);
    //   } catch (error) {
    //     Errors.handle(error);
    //     context.commit('CHANGE_STATUS_ERROR');
    //   }
    // },
  },
};
