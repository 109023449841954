<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('tags.title') }}</h2>
      <div
        class="btn btn-primary"
        :class="isRTL ? 'mr-auto' : 'ml-auto'"
        @click="createTags()"
      >
        <PlusIcon class="mr-2" /> {{ i18n('common.create') }}
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box dark:bg-dark-8 p-5 mt-5">
      <div class="overflow-x-auto g">
        <table class="table table-report sm:mt-2">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('socials.table.title') }}
              </th>
              <!-- <th class="text-center whitespace-nowrap">
                {{i18n('tags.table.logo')}}
              </th>
              <th class="text-center whitespace-nowrap">
                {{i18n('tags.table.link')}}
              </th>
              <th class="text-center whitespace-nowrap">
                {{i18n('tags.table.status')}}
              </th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" :key="index">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ index + 1 }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ row.title }}
                </div>
              </td>
              <!-- <td class="text-center flex items-center justify-center">
                <div href="" class="font-medium whitespace-nowrap truncate text-gray-600">
                  <div class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden">
                    <img class="rounded-full" :src="require(`@/assets/images/${row.logo}`)" />
                  </div>
                </div>
              </td> -->
              <!-- <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{row.link}}
                </div>
              </td> -->
              <!-- <td class="w-40">
                <div class="flex items-center justify-center" :style="{
                'color': row.enabled ? 'green' : 'red'}">
                  <XSquareIcon v-if="!row.enabled" class="w-4 h-4 mr-2 ml-2" />
                  <CheckSquareIcon v-else class="w-4 h-4 mr-2 ml-2" />
                  {{
                  row.enabled
                  ? i18n('common.activated')
                  : i18n('common.disabled')
                  }}
                </div>
              </td> -->
              <td class="table-report__action w-56">
                <div
                  class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer"
                >
                  <div
                    @click="editTags(row.id)"
                    class="text-theme-14 dark:text-white ml-2"
                  >
                    <EditIcon class="w-4 h-4 mr-1" />
                  </div>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    class="text-theme-24 ml-2"
                    @click="deleteId = row.id"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" />
                  </a>
                  <!-- <button v-if="!row.enabled" @click="toggleStatus(row.id, true)" style="color:green" class="
                        flex
                        justify-center
                        items-center
                        p-2
                        transition
                        duration-300
                        ease-in-out
                        bg-white
                        hover:bg-gray-200
                        rounded-md
                        text-green
                      ">
                    <UnlockIcon class="w-4 h-4 mr-1 ml-1" />
                    {{ i18n('common.activate') }}
                  </button> -->
                  <!-- <button v-if="row.enabled" @click="toggleStatus(row.id, false)" style="color:red" class="
                        flex
                        justify-center
                        items-center
                        p-2
                        transition
                        duration-300
                        ease-in-out
                        bg-white
                        hover:bg-gray-200
                        rounded-md
                        text-theme-24
                      ">
                    <LockIcon class="w-4 h-4 mr-1 ml-1" />
                    {{ i18n('common.disable') }}
                  </button> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <DeleteModal item="tags" @delete="deleteSocials(deleteId)" />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.socials')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.socials')
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  components: {
    DeleteModal
  },
  setup() {
    const router = useRouter()
    const rows = ref([
      {
        id: '1',
        title: 'Facebook',
        logo: 'fb.svg',
        link: 'https://www.facebook.com/SmouhaOfficial',
        enabled: true
      },
      {
        id: '2',
        title: 'Instagram',
        logo: 'instagram.svg',
        link: 'https://www.instagram.com/accounts/login/?next=%2Fsmouha.official%2F',
        enabled: true
      },
      {
        id: '3',
        title: 'Youtube',
        logo: 'youtube.svg',
        link: 'https://www.youtube.com/channel/UC7gh5etPfQ6cUJb8hnF98fw',
        enabled: false
      }
    ])
    const tableLimit = ref(10)
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)
    const filter = reactive({
      field: 'username',
      value: ''
    })

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'username'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    const deleteId = ''

    const editTags = (id) => {
      router.push(`/tags-form-page/${id}`)
    }
    const deleteSocials = (id) => {
      console.log(rows)
      rows.value = rows.value.filter((row) => row.id !== id)
    }

    const createTags = () => {
      router.push('/tags-form-page')
    }

    const toggleStatus = (id, status) => {
      rows.value = rows.value.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            enabled: status
          }
        } else return row
      })
    }

    return {
      rows,
      selected,
      selectAll,
      tableLimit,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      deleteId,
      createTags,
      deleteSocials,
      editTags,
      toggleStatus
    }
  },
  methods: {
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach((element) => {
          this.selected.push(element.id)
        })
      }
    },
    presenter(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
