<template>
  <!-- BEGIN: Modal Content -->
  <div id="view-logs-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2
            class="font-medium text-base"
            :class="isRTL ? 'ml-auto' : 'mr-auto'"
          >
            {{ isRTL ? `عرض السجل` : `Log View` }}
          </h2>
        </div>
        <!-- END: Modal Header -->

        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
          <div class="col-span-12" v-for="(pice, index) in data" :key="index">
            <label for="modal-form-3" class="form-label">
              {{ typeof index == 'number' ? index + 1 : index }}
            </label>
            <div v-if="typeof pice == 'object'">
              <div class="value" v-for="(item, indexx) in pice" :key="indexx">
                <label for="modal-form-3" class="form-label">
                  {{ typeof indexx == 'number' ? indexx + 1 : indexx }} :
                </label>
                <a v-if="isValidURL(item)" target="_blank" :href="item">{{
                  item ? item : `0`
                }}</a>
                <span v-else>{{ item ? item : `0` }}</span>
              </div>
            </div>
            <!-- <div v-else-if="typeof pice == 'object' && pice[0]">
              <div class="value" v-for="(item, indexxx) in pice" :key="indexxx">
                <a v-if=" target="_blank" :href="item" target="_blank">{{
                  item
                }}</a>
                <p v-else>{{ item }}</p>
              </div>
            </div> -->
            <div v-else class="value">
              <a v-if="isValidURL(pice)" target="_blank" :href="pice">{{
                pice ? pice : `0`
              }}</a>
              <span v-else>{{ pice ? pice : `0` }}</span>
            </div>
          </div>
        </div>
        <!-- END: Modal Body -->

        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer flex justify-center items-center">
          <!-- <AppButton
            id="submit-btn"
            type="button"
            class="btn bg-theme-24 text-white cursor-pointer"
            :class="!saveLoading ? 'w-24' : ''"
            :disabled="saveLoading"
            :loading="saveLoading"
            @click="doSubmit"
          >
            <strong>{{ i18n('common.save') }}</strong>
            <template v-slot:loading>
              <span>{{ i18n('common.loading') }}</span>
              <LoadingIcon
                icon="three-dots"
                color="#FFFFFF"
                style="margin: 0 4px"
              />
            </template>
          </AppButton> -->
          <button
            type="button"
            data-dismiss="modal"
            class="btn bg-theme-31 text-black w-24 ml-1 mr-1"
          >
            {{ isRTL ? `غلق` : `Exit` }}
          </button>
          <!-- <div @click="dothat">asdasd</div> -->
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  setup() {
    const hideModal = () => {
      cash('#view-logs-modall').modal('hide')
    }
    return {
      hideModal
    }
  },
  data() {
    return {}
  },

  methods: {
    isValidURL(url) {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ) // fragment locator
      return !!pattern.test(url)
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style  scoped>
.value {
  border: 1px solid #eee;
  padding: 6px 2px;
  word-break: break-all;
  margin-top: 5px;
}
label {
  font-weight: 700;
}
a {
  color: rgb(21, 21, 97);
}
</style>
