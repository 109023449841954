/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlLibrary)

export class LibraryService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LIBRARY_LIST(
          $filter: LibraryFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          libraryList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              name {
                en
                ar
              }
              description {
                en
                ar
              }
              location {
                en
                ar
              }
              phoneNumber
              openHours {
                en
                ar
              }
              banner
              images
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.libraryList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query LIBRARY_FIND($id: String!) {
          libraryFind(id: $id) {
            id
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            location {
              en
              ar
            }
            phoneNumber
            openHours {
              en
              ar
            }
            images
            banner
            branchId
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id
      }
    })

    return response.data.libraryFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation LIBRARY_CREATE($data: LibraryInput!) {
          libraryCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.libraryCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation LIBRARY_UPDATE($id: String!, $data: LibraryInput!) {
          libraryUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.libraryUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation LIBRARY_DESTROY($id: String!) {
          libraryDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.libraryDestroy
  }
}
