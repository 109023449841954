<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{i18n('sports.teamView.title')}}
      </h2>
    </div>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box dark:bg-dark-8 px-5 pt-5 mt-5">
      <div
        class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start"
        role="tablist"
      >
        <a
          id="chefs-tab"
          data-toggle="tab"
          data-target="#chefs"
          href="javascript:;"
          class="py-4 sm:mr-8 active"
          role="tab"
          aria-controls="chefs"
          aria-selected="true"
          @click="tab = 'matches'"
        >
          {{i18n('sports.teamView.tabs.matches')}}
        </a>
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'players'"
          > {{i18n('sports.teamView.tabs.playersInfo')}}</a
        >
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'standings'"
          > {{i18n('sports.teamView.tabs.teamStandings')}}</a
        >
      </div>
    </div>
    <!-- END: Profile Info -->
    <div class="intro-y tab-content mt-5">
      <Matches v-if="tab == 'matches'" />
      <Players v-if="tab == 'players'" />
      <Standings v-if="tab == 'standings'" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive } from 'vue'
import Matches from '@/views/sports/matches-tab.vue'
import Players from '@/views/sports/players-tab.vue'
import Standings from '@/views/sports/standings-tab.vue'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.sports')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.sports')
    }
  },
  components: {
    Matches,
    Players,
    Standings
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  setup() {
    const activated = ref(true)
    const tab = ref('matches')
    const filter = reactive({
      field: 'name',
      value: ''
    })
    const rows = ref([
      {
        id: '1',
        username: 'Ahmed',
        phoneNumber: '+966123456',
        email: 'ahmed@gmail.com',
        deposit: '50.0 SAR',
        payment: '50.0 SAR',
        income: '50.0 SAR',
        currentBalance: '100.0 SAR'
      },
      {
        id: '2',
        username: 'Ahmed',
        phoneNumber: '+966123456',
        email: 'ahmed@gmail.com',
        deposit: '50.0 SAR',
        payment: '50.0 SAR',
        income: '50.0 SAR',
        currentBalance: '100.0 SAR'
      },
      {
        id: '3',
        username: 'Ahmed',
        phoneNumber: '+966123456',
        email: 'ahmed@gmail.com',
        deposit: '50.0 SAR',
        payment: '50.0 SAR',
        income: '50.0 SAR',
        currentBalance: '100.0 SAR'
      },
      {
        id: '4',
        username: 'Ahmed',
        phoneNumber: '+966123456',
        email: 'ahmed@gmail.com',
        deposit: '50.0 SAR',
        payment: '50.0 SAR',
        income: '50.0 SAR',
        currentBalance: '100.0 SAR'
      },
      {
        id: '5',
        username: 'Ahmed',
        phoneNumber: '+966123456',
        email: 'ahmed@gmail.com',
        deposit: '50.0 SAR',
        payment: '50.0 SAR',
        income: '50.0 SAR',
        currentBalance: '100.0 SAR'
      },
      {
        id: '6',
        username: 'Ahmed',
        phoneNumber: '+966123456',
        email: 'ahmed@gmail.com',
        deposit: '50.0 SAR',
        payment: '50.0 SAR',
        income: '50.0 SAR',
        currentBalance: '100.0 SAR'
      },
      {
        id: '7',
        username: 'Ahmed',
        phoneNumber: '+966123456',
        email: 'ahmed@gmail.com',
        deposit: '50.0 SAR',
        payment: '50.0 SAR',
        income: '50.0 SAR',
        currentBalance: '100.0 SAR'
      },
      {
        id: '8',
        username: 'Ahmed',
        phoneNumber: '+966123456',
        email: 'ahmed@gmail.com',
        deposit: '50.0 SAR',
        payment: '50.0 SAR',
        income: '50.0 SAR',
        currentBalance: '100.0 SAR'
      },
      {
        id: '9',
        username: 'Ahmed',
        phoneNumber: '+966123456',
        email: 'ahmed@gmail.com',
        deposit: '50.0 SAR',
        payment: '50.0 SAR',
        income: '50.0 SAR',
        currentBalance: '100.0 SAR'
      },
      {
        id: '10',
        username: 'Ahmed',
        phoneNumber: '+966123456',
        email: 'ahmed@gmail.com',
        deposit: '50.0 SAR',
        payment: '50.0 SAR',
        income: '50.0 SAR',
        currentBalance: '100.0 SAR'
      }
    ])

    onMounted(() => {
      // document.getElementById('clients').style.display = 'none'
    })
    return {
      activated,
      tab,
      filter,
      rows
    }
  },
  methods: {
    openTransactions() {
      this.$router.push('/transaction')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
