/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(
  config.backendUrlSocialMedia
)

export class SocialMediaService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query SOCIAL_MEDIA_LIST(
          $filter: SocialMediaFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          socialMediaList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              name {
                en
                ar
              }
              link
              logo
              status

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.socialMediaList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query SOCIAL_MEDIA_FIND($id: String!) {
          socialMediaFind(id: $id) {
            id
            name {
              en
              ar
            }
            link
            logo
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id
      }
    })

    return response.data.socialMediaFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SOCIAL_MEDIA_CREATE($data: SocialMediaInput!) {
          socialMediaCreate(data: $data) {
            id
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.socialMediaCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SOCIAL_MEDIA_UPDATE($id: String!, $data: SocialMediaInput!) {
          socialMediaUpdate(id: $id, data: $data) {
            id
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.socialMediaUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SOCIAL_MEDIA_DESTROY($id: String!) {
          socialMediaDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.socialMediaDestroy
  }

  static async enable(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SOCIAL_MEDIA_ENABLE_MANY($ids: [String!]!) {
          socialMediaEnable(ids: $ids)
        }
      `,

      variables: {
        ids
      }
    })

    return response.data.socialMediaEnable
  }
  static async disable(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SOCIAL_MEDIA_DISABLE_MANY($ids: [String!]!) {
          socialMediaDisable(ids: $ids)
        }
      `,

      variables: {
        ids
      }
    })

    return response.data.socialMediaDisable
  }
}
