<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">
        <app-i18n code="media.view.videos"></app-i18n>
      </h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-2 sm:p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            class="btn btn-primary sm:w-auto pb-1 pt-1"
            @click="$router.push(`/media/${id}/media-form-page/videos`)"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
        </div>
      </div>
      <div ref="table" class="relative">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#000"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <div class="gallery">
            <VuePlayerVideo
              v-for="(url, index) in media"
              :key="index"
              :controls="true"
              :src="url"
              class=""
            />
            <img />
          </div>
          <!-- <table class="table table-report sm:mt-2 hidden md:table" v-if="media?.length">
            <thead>
              <tr>
                <th class="whitespace-nowrap">#</th>
                <th v-for="col in columns" :key="col.name" class="text-center whitespace-nowrap">
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in media" :key="index" class="intro-x">
                <td class="w-40">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                    {{ index + 1 }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap truncate">
                    {{ presenterLocalization(row, 'title') }}
                  </div>
                </td>
                <td class="text-center flex items-center justify-center">
                  <VuePlayerVideo :controls="true" :src="row.url" class="h-48 w-64" />
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap truncate w-72 truncate">
                    {{ presenterLocalization(row, 'description') }}
                  </div>
                </td>
                <td class="table-report__action w-60">
                  <div class="flex justify-center items-center gap-4">
                    <a href="javascript:;" data-toggle="modal" data-target="#delete-modal-preview"
                      @click="$emit('deleteMedia', row)" class="
                                                                            text-theme-24
                                                                            flex
                                                                            items-center
                                                                            gap-1
                                                                            whitespace-nowrap
                                                                          ">
                      <Trash2Icon class="w-4 h-4" /> {{ i18n('common.delete') }}
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table> -->
          <!-- <div class="mobile-table md:hidden mt-8">
            <mobile-table-comp v-for="(row, index) in media" :key="index" :row="row" class="md:hidden">
              <div class="field">
                <span>{{ i18n('media.form.title') }} </span>
                <div class="font-medium whitespace-nowrap truncate">
                  {{ presenterLocalization(row, 'title') }}
                </div>
              </div>
              <div class="field">
                <span> {{ i18n('media.form.description') }} :</span>
                <div class="font-medium whitespace-nowrap truncate  truncate">
                  {{ presenterLocalization(row, 'description') }}
                </div>
              </div>
              <div class="field">
                <span> {{ i18n('media.form.video') }} :</span>
                <div class="text-center flex items-center justify-center">
                  <VuePlayerVideo :controls="true" :src="row.url" class="h-36 w-64" />
                </div>
              </div>

              <div class="field">
                <span> {{ i18n('common.table.actions') }} :</span>
                <div class="flex justify-center items-center gap-4">
                  <a href="javascript:;" data-toggle="modal" data-target="#delete-modal-preview"
                    @click="$emit('deleteMedia', row)" class="
                                                                            text-theme-24
                                                                            flex
                                                                            items-center
                                                                            gap-1
                                                                            whitespace-nowrap
                                                                          ">
                    <Trash2Icon class="w-4 h-4" /> {{ i18n('common.delete') }}
                  </a>
                </div>
              </div>
            </mobile-table-comp>
          </div> -->
          <div>
            <app-empty-page
              v-if="!media.length"
              :label="i18n('empty.media')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
        </div>
      </div>
    </div>
    <!-- <DeleteModal item="media" @delete="doDeleteVideo()" /> -->
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
// import DeleteModal from '@/components/modals/delete-modal.vue'
import VuePlayerVideo from 'vue3-player-video'

export default {
  name: 'table-view-page',
  components: {
    // DeleteModal,
    VuePlayerVideo
  },
  props: ['id', 'media'],
  data() {
    return {
      selectedMedia: null,
      columns: [
        {
          name: 'title',
          field: 'title',
          label: 'media.form.title',
          align: 'center'
        },
        {
          name: 'video',
          field: 'video',
          label: 'media.form.video',
          align: 'center'
        },
        {
          name: 'description',
          field: 'description',
          label: 'media.form.description',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    })
  },
  methods: {
    ...mapActions({
      doDestroy: 'media/form/doRemoveFromAlbum'
    }),
    // async doDeleteVideo() {
    //   console.log('vidoe selected==> ', this.selectedMedia)
    //   await this.doDestroy({ id: this.id, videos: this.selectedMedia })
    //   this.$emit('doRefresh')
    // },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      if (!row) return 'ـــ'
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      if (!row) return 'ـــ'
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      if (!row) return 'ـــ'
      return GenericModel.presenterLocalization(row, fieldName)
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
.vue3-player-video {
  width: 100% !important;
}
::v-deep .vue3-player-video > div {
  height: 100% !important;
  width: 100%;
}

::v-deep .vue3-player-video > div > div {
  height: 100% !important;
  width: 100%;
}
.gallery {
  margin-top: 50px;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
</style>
