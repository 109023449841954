<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5 grid grid-cols-12">
          <h2
            class="text-xl font-medium mr-auto border-b pb-5 w-full col-span-12"
          >
            {{ i18n('nonAthletes.form.title') }}
          </h2>
          <div class="flex gap-5 col-span-12 flex-col lg:flex-row" v-if="model">
            <div class="intro-y mt-5 grid grid-cols-12 flex-1">
              <h3 class="text-lg font-normal mr-auto pb-5 w-full col-span-12">
                {{ i18n('common.english') }}
              </h3>
              <input
                type="text"
                class="intro-y form-control py-3 px-4 box dark:bg-dark-8 pr-10 placeholder-theme-8 dark:placeholder-white col-span-12"
                :placeholder="i18n('nonAthletes.form.titlePlaceholder')"
                v-model="model.title.en"
              />
              <div
                class="post intro-y overflow-hidden box dark:bg-dark-8 mt-5 col-span-12"
              >
                <div class="post__content tab-content">
                  <div
                    id="content"
                    class="tab-pane p-5 active"
                    role="tabpanel"
                    aria-labelledby="content-tab"
                  >
                    <div
                      class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
                    >
                      <div
                        class="font-medium flex items-center border-b border-gray-200 dark:border-dark-5 pb-5"
                      >
                        <ChevronDownIcon class="w-4 h-4 mr-2" />
                        {{ i18n('nonAthletes.form.descriptionText') }}
                      </div>
                      <div class="mt-5">
                        <CKEditor
                          v-model="model.subject.en"
                          :editor="classicEditor"
                          :config="editorConfig"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="intro-y mt-5 grid grid-cols-12 flex-1">
              <h3 class="text-lg font-normal mr-auto pb-5 w-full col-span-12">
                {{ i18n('common.arabic') }}
              </h3>
              <input
                type="text"
                class="intro-y form-control py-3 px-4 box dark:bg-dark-8 pr-10 placeholder-theme-8 dark:placeholder-white col-span-12"
                :placeholder="i18n('nonAthletes.form.titlePlaceholder')"
                v-model="model.title.ar"
              />
              <div
                class="post intro-y overflow-hidden box dark:bg-dark-8 mt-5 col-span-12"
              >
                <div class="post__content tab-content">
                  <div
                    id="content"
                    class="tab-pane p-5 active"
                    role="tabpanel"
                    aria-labelledby="content-tab"
                  >
                    <div
                      class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
                    >
                      <div
                        class="font-medium flex items-center border-b border-gray-200 dark:border-dark-5 pb-5"
                      >
                        <ChevronDownIcon class="w-4 h-4 mr-2" />
                        {{ i18n('nonAthletes.form.descriptionText') }}
                      </div>
                      <div class="mt-5">
                        <CKEditor
                          v-model="model.subject.ar"
                          :editor="classicEditor"
                          :config="{ ...editorConfig, language: isRTL }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END -->
          <div class="flex justify-center items-center mt-10 col-span-12">
            <AppButton
              type="button"
              class="btn btn-primary w-24 cursor-pointer text-white cursor-pointer"
              :class="!saveLoading ? 'w-24' : ''"
              :disabled="saveLoading || findLoading"
              :loading="saveLoading"
              @click="doSubmit()"
            >
              <strong>{{ i18n('common.save') }}</strong>
              <template v-slot:loading>
                <app-i18n code="common.loading"></app-i18n>
                <LoadingIcon
                  icon="three-dots"
                  color="#FFFFFF"
                  style="margin: 0 4px"
                />
              </template>
            </AppButton>
            <button
              type="button"
              class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer"
              @click="doCancel"
            >
              {{ i18n('common.cancel') }}
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import Message from '@/shared/message/toastify'
import { mapGetters, mapActions } from 'vuex'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import CodePlugin from '@ckeditor/ckeditor5-basic-styles/src/code'
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import CloudServicesPlugin from '@ckeditor/ckeditor5-cloud-services/src/cloudservices'
import Font from '@ckeditor/ckeditor5-font/src/font'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import HeadingButtonsUI from '@ckeditor/ckeditor5-heading/src/headingbuttonsui'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight'

export default {
  async created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.nonAthletes')
    this.$route.fullPath.includes('edit')
      ? await this.doFind(this.$route.query.id)
      : ''
    this.model = this.record.details[[parseInt(this.$route.query.index)]]
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.nonAthletes')
    }
  },
  props: ['id', 'index'],
  async mounted() {
    await this.doNew()

    this.model = {
      title: {
        en: '',
        ar: ''
      },
      subject: {
        en: '',
        ar: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'activity/form/record',
      findLoading: 'activity/form/findLoading',
      saveLoading: 'activity/form/saveLoading'
    }),
    isEditing() {
      return this.$route.fullPath.includes('edit')
    }
  },

  data() {
    return {
      model: null,
      errorMessage: null
    }
  },
  setup() {
    const classicEditor = ClassicEditor
    const editorConfig = {
      cloudServices: {
        tokenUrl: '',
        uploadUrl: ''
      },
      plugins: [
        Font,
        EssentialsPlugin,
        BoldPlugin,
        Alignment,
        UnderlinePlugin,
        StrikethroughPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        CodePlugin,
        SubscriptPlugin,
        SuperscriptPlugin,
        EasyImagePlugin,
        ImagePlugin,
        ImageUploadPlugin,
        CloudServicesPlugin,
        Heading,
        HeadingButtonsUI,
        Highlight
      ],
      toolbar: {
        items: [
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          'bold',
          'alignment',
          'italic',
          'underline',
          'strikethrough',
          'code',
          'subscript',
          'superscript',
          'link',
          'undo',
          'redo',
          'imageUpload',
          'highlight'
        ]
      }
    }

    return {
      classicEditor,
      editorConfig
    }
  },
  methods: {
    ...mapActions({
      doNew: 'activity/form/doNew',
      doFind: 'activity/form/doFind',
      doCreate: 'activity/form/doAddSubActivities',
      doSubUpdate: 'activity/form/doSubUpdate'
    }),
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const { title, subject } = this.model
      if (this.isEditing) {
        await this.doSubUpdate({
          id: this.$route.query.id,
          itemIndex: parseInt(this.$route.query.index),
          title,
          subject
        })
      } else {
        await this.doCreate({
          id: this.id,
          details: [{ title, subject }]
        })
      }
    },
    doCancel() {
      this.$router.back()
    },
    isFormValid() {
      if (this.model.title.en.length == 0 || this.model.title.ar.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.subject.en.length == 0 ||
        this.model.subject.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      return true
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
