/* eslint-disable */

import restaurantListStore from '@/store/restaurant/restaurant-list-store'
import restaurantViewStore from '@/store/restaurant/restaurant-view-store'
import restaurantFormStore from '@/store/restaurant/restaurant-form-store'

export default {
  namespaced: true,

  modules: {
    list: restaurantListStore,
    form: restaurantFormStore,
    view: restaurantViewStore
    // destroy: restaurantDestroyStore
  }
}
