<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="socials.form.title.edit" v-if="isEditing"></app-i18n>
            <app-i18n code="socials.form.title.new" v-else></app-i18n>
          </h2>

          <section class="relative">
            <div class="flex justify-center" style="width: 100%; height: 80vh" v-if="findLoading">
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                                                    text-lg
                                                    font-medium
                                                    leading-none
                                                    items-center
                                                    mr-2
                                                    lg:col-span-2
                                                  ">
                  {{ i18n('socials.form.name') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input id="title-en" type="text" class="
                                                      form-control
                                                      w-full
                                                      dark:bg-dark-9 dark:placeholder-white
                                                    " v-model="model.name.en" :placeholder="i18n('common.english')" />
                </div>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2" :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'">
                  <input id="title-ar" type="text" class="
                                                      form-control
                                                      w-full
                                                      dark:bg-dark-9 dark:placeholder-white
                                                    " v-model="model.name.ar" :placeholder="i18n('common.arabic')" />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                                                    text-lg
                                                    font-medium
                                                    leading-none
                                                    items-center
                                                    mr-2
                                                    lg:col-span-2
                                                  ">
                  {{ i18n('socials.form.link') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input id="crud-form-1" type="text" class="form-control w-full dark:placeholder-white"
                    v-model="model.link" :placeholder="i18n('socials.form.link')" />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center items-center">
                <h4 class="
                                                    text-lg
                                                    font-medium
                                                    leading-none
                                                    items-center
                                                    mr-2
                                                    lg:col-span-2
                                                  ">
                  {{ i18n('socials.form.logo') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <div class="
                                                      w-20
                                                      h-20
                                                      sm:w-24 sm:h-24
                                                      flex-none
                                                      lg:w-32 lg:h-32
                                                      image-fit
                                                      relative
                                                      m-auto
                                                      cursor-pointer
                                                    ">
                    <div>
                      <img alt="logo" class="rounded-full" :src="model.logo ? model.logo : '/images/preview-1.jpg'" />
                      <div class="
                                                          absolute
                                                          mb-1
                                                          mr-1
                                                          flex
                                                          items-center
                                                          justify-center
                                                          bottom-0
                                                          right-0
                                                          bg-theme-17
                                                          rounded-full
                                                          p-2
                                                        ">
                        <CameraIcon class="w-4 h-4 text-white" />
                      </div>
                    </div>
                    <input type="file" id="profileImage" name="upload" accept="image/png, image/jpeg, image/jpg"
                      style="position: absolute; top: 0%; opacity: 0 !important" class="
                                                        cursor-pointer
                                                        w-20
                                                        h-20
                                                        sm:w-24 sm:h-24
                                                        flex-none
                                                        lg:w-32 lg:h-32
                                                        image-fit
                                                        relative
                                                        m-auto
                                                      " @input="emitEvent($event)" />
                  </div>
                </div>
              </div>
              <!-- END -->
              <div class="flex justify-center items-center mt-10">
                <AppButton type="button" class="
                                                    btn btn-primary
                                                    w-24
                                                    cursor-pointer
                                                    text-white
                                                    cursor-pointer
                                                  " :class="!saveLoading ? 'w-24' : ''" :id="dis ? 'test' : ``"
                  :disabled="saveLoading || findLoading" :loading="saveLoading" @click="doSubmit()">
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px" />
                  </template>
                </AppButton>
                <button type="button" class="
                                                    btn
                                                    bg-theme-31
                                                    text-black
                                                    w-24
                                                    ml-3
                                                    mr-3
                                                    cursor-pointer
                                                  " @click="doCancel">
                  {{ i18n('common.cancel') }}
                </button>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import Message from '@/shared/message/toastify'
import { FileUploader } from '@/shared/uploader/file-uploader'
import { mapGetters, mapActions } from 'vuex'
import { collection, doc, getFirestore } from 'firebase/firestore'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.socials')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.socials')
    }
  },
  props: {
    id: String
  },
  data() {
    return {
      model: null,
      imageUrl: '',
      uploadLoading: false,
      image: null,
      errorMessage: null,
      dis: false
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'socialMedia/form/record',
      findLoading: 'socialMedia/form/findLoading',
      saveLoading: 'socialMedia/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }

    this.model = this.record || {
      name: {
        en: '',
        ar: ''
      },
      link: '',
      logo: ''
    }
  },
  methods: {
    ...mapActions({
      doNew: 'socialMedia/form/doNew',
      doFind: 'socialMedia/form/doFind',
      doCreate: 'socialMedia/form/doCreate',
      doUpdate: 'socialMedia/form/doUpdate'
    }),
    isFormValid() {
      if (this.model.name.en.length == 0 || this.model.name.ar.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      if (this.model.link.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.logo.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      return true
    },
    doCancel() {
      this.$router.push('/socials')
    },
    emitEvent(event) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (e) => {
        const image = {
          name: event.target.files[0].name,
          size: event.target.files[0].size,
          lastModifiedDate: event.target.files[0].lastModifiedDate,
          base64: reader.result
        }
        this.image = image
        const file = event.target.files[0]
        this.imageUrl = URL.createObjectURL(file)
        this.model.logo = this.imageUrl
      }
    },
    async doSubmit() {
      this.dis = true
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        this.dis = false
        return Message.error(ERROR)
      }
      if (this.image) {
        this.uploadLoading = true
        const logo = document.getElementById('profileImage').files[0]
        const id = doc(collection(getFirestore(), 'ids')).id
        const path = `social-media/logo/${id}`
        const { publicUrl } = await FileUploader.upload(path, logo, 'logo')
        this.model.logo = publicUrl
      }
      if (this.isEditing) {
        const { name, link, logo } = this.model
        await this.doUpdate({
          id: this.id,
          name,
          link,
          logo,
          status: this.record.status
        })
        this.dis = false
      } else {
        await this.doCreate(this.model)
        this.dis = false
      }
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss" scoped>
#test {
  pointer-events: none !important;
}

.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {

  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
