<template>
  <!-- BEGIN: Modal Content -->
  <div id="reject-article-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2
            class="font-medium text-base"
            :class="isRTL ? 'ml-auto' : 'mr-auto'"
          >
            {{ isRTL ? `رفض المقالة` : `Rejecting Article` }}
          </h2>
        </div>
        <!-- END: Modal Header -->

        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
          <div class="col-span-12">
            <label for="modal-form-3" class="form-label">
              {{ isRTL ? `سبب الرفض` : `Rejecting Reason` }}
            </label>
            <textarea
              id="description-en"
              :rows="4"
              class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
              v-model="notes"
              :placeholder="isRTL ? `سبب الرفض` : `Rejecting Reason`"
            ></textarea>
          </div>
        </div>
        <!-- END: Modal Body -->

        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer flex justify-center items-center">
          <AppButton
            id="submit-btn"
            type="button"
            class="btn bg-theme-24 text-white cursor-pointer"
            :class="!saveLoading ? 'w-24' : ''"
            :disabled="saveLoading"
            :loading="saveLoading"
            @click="doSubmit"
          >
            <strong>{{ i18n('common.save') }}</strong>
            <template v-slot:loading>
              <span>{{ i18n('common.loading') }}</span>
              <LoadingIcon
                icon="three-dots"
                color="#FFFFFF"
                style="margin: 0 4px"
              />
            </template>
          </AppButton>
          <button
            type="button"
            data-dismiss="modal"
            class="btn bg-theme-31 text-black w-24 ml-1 mr-1"
            :disabled="saveLoading"
            @click="doReset"
          >
            {{ i18n('common.cancel') }}
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'

export default {
  props: {
    id: {
      type: String,
      required: false
    },
    route: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const hideModal = () => {
      cash('#reject-article-modal').modal('hide')
    }
    return {
      hideModal
    }
  },
  data() {
    return {
      notes: undefined,
      errorMessage: 'Please fill all the fields'
    }
  },
  computed: {
    ...mapGetters({
      saveLoading: 'article/form/saveLoading',
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    })
  },
  methods: {
    ...mapActions({
      doReject: 'article/list/doReject',
      doFetch: 'article/list/doFetch'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (!this.notes) {
        this.errorMessage = this.isRTL
          ? 'من فضلك ادخل سبب الرفض'
          : 'Please enter cancel reason'
        return false
      }
      return true
    },
    doReset() {
      this.notes = undefined
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      await this.doReject({
        id: this.id,
        reason: this.notes
      })
      this.doReset()
      this.hideModal()
    }
  }
}
</script>

<style>
</style>
