/* eslint-disable */

import DiscountListStore from '@/store/discount/discount-list-store'
import DiscountViewStore from '@/store/discount/discount-view-store'
import DiscountFormStore from '@/store/discount/discount-form-store'

export default {
  namespaced: true,

  modules: {
    list: DiscountListStore,
    form: DiscountFormStore,
    view: DiscountViewStore
    // destroy: DiscountDestroyStore
  }
}
