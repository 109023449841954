/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'

// #region [ Main Pages ]
const SideMenu = () => import('@/layouts/side-menu/Main.vue')
const ErrorPage = () => import('@/views/error-page/Main.vue')
const Error403Page = () => import('@/views/error-page/error-403-page.vue')
const Error404Page = () => import('@/views/error-page/error-404-page.vue')
const Error500Page = () => import('@/views/error-page/error-500-page.vue')
// #endregion

const Login = () => import('@/views/auth/signin-page.vue')
const ForgotPassword = () => import('@/views/auth/forgot-password.vue')
const Profile = () => import('@/views/profile/profile.vue')
const ChangePassword = () => import('@/views/profile/change-password.vue')
const HomePage = () => import('@/views/recent-materials/recent-materials.vue')

import Users from '../views/users/user-list-page.vue'
import UserViewPage from '../views/users/user-view-page.vue'
const AdminFormPage = () => import('@/views/users/user-form-page')
import Articles from '../views/article/article-list-page.vue'
import CommentListPage from '../views/article/comment-list-page.vue'
import ArticleViewPage from '../views/article/article-view-page.vue'
import ArticleFormPage from '../views/article/article-form-page.vue'

import Ads from '../views/ads/ad-list-page.vue'
import Logs from '../views/logs/logs-list-page.vue'
import AdViewPage from '../views/ads/ad-view-page.vue'
import AdFormPage from '../views/ads/ad-form-page.vue'
// import Media from '../views/media/media-list-page-old.vue'
// import Album from '../views/media/album.vue'
import LiveVideos from '../views/live-videos/live-videos.vue'
import LiveVideosFormPage from '../views/live-videos/live-videos-form-page.vue'
import Auctions from '../views/auction/auction-list-page.vue'
import AuctionViewPage from '../views/auction/auction-view-page.vue'
import AuctionFormPage from '../views/auction/auction-form-page.vue'
import Branches from '../views/branch/branch-list-page.vue'
import BranchViewPage from '../views/branch/branch-view-page.vue'
import BranchFormPage from '../views/branch/branch-form-page.vue'
import Restaurants from '../views/restaurant/restaurant-list-page.vue'
import RestaurantViewPage from '../views/restaurant/restaurant-view-page.vue'
import RestaurantFormPage from '../views/restaurant/restaurant-form-page.vue'
import Sports from '../views/sports/sports.vue'
import SportsFormPage from '../views/sports/sport-form-page.vue'
import SportViewPage from '../views/sports/sport-view-page.vue'
import TeamViewPage from '../views/sports/team-view-page.vue'
import MatchFormPage from '../views/sports/match-form-page.vue'
import PlayerFormPage from '../views/sports/player-form-page.vue'
import PlayerViewPage from '../views/sports/player-view-page.vue'
import LibraryListPage from '../views/library/library-list-page.vue'
import LibraryEditPage from '../views/library/library-edit-page.vue'
import LibraryViewPage from '../views/library/library-view-page.vue'
import Clinic from '../views/clinic/clinics-list-page.vue'
import ClinicFormPage from '../views/clinic/clinic-form-page.vue'
import ClinicViewPage from '../views/clinic/clinic-view-page.vue'
import MedicalClinicFormPage from '../views/clinic/Medical-clinic-form-page.vue'
import Media from '../views/media/media-list-page.vue'
import MediaFormPage from '../views/media/media-form-page.vue'
import MediaViewPage from '../views/media/media-view-page.vue'
import AlbumFormPage from '../views/media/album-form-page.vue'
import Sponsors from '../views/sponsors/sponsors-list-page.vue'
import SponsorsFormPage from '../views/sponsors/sponsors-form-page.vue'
import Socials from '../views/socialMedia/socials-list-page.vue'
import SocialsFormPage from '../views/socialMedia/socials-form-page.vue'
import Tags from '../views/tags/tags-list-page.vue'
import TagsFormPage from '../views/tags/tags-form-page.vue'
import Categories from '../views/categories/categories-list-page.vue'
import CategoriesFormPage from '../views/categories/categories-form-page.vue'
import Telecom from '../views/telecom/telecom-list-page.vue'
import TelecomFormPage from '../views/telecom/telecom-form-page.vue'
import Cultural from '../views/cultural-service/cultural-service-list-page.vue'
import CulturalFormPage from '../views/cultural-service/cultural-service-form-page.vue'
import Kindergarten from '../views/kindergarten/kindergarten-list-page.vue'
import KindergartenFormPage from '../views/kindergarten/kindergarten-form-page.vue'
import discounts from '../views/discounts/discounts-list-page.vue'
import discountsFormPage from '../views/discounts/discounts-form-page.vue'
// import NonAthletes from '../views/nonAthletes/nonAthletes-list-page-old.vue'
import NonAthletesFormPage from '../views/nonAthletes/nonAthletes-form-page-old.vue'
import Others from '../views/others/Main.vue'
import Parking from '../views/parking/parking-list-page.vue'
import ParkingFormPage from '../views/parking/parking-form-page.vue'
import ParkingViewPage from '../views/parking/parking-view-page.vue'
import PlacesFormPage from '../views/parking/places-form-page.vue'
import nonAthletes from '../views/nonAthletes/nonAthletes-list-page.vue'
import nonAthletesFormPage from '../views/nonAthletes/nonAthletes-form-page.vue'
import subFormPage from '../views/nonAthletes/sub-form-page.vue'

import nonAthletesViewPage from '../views/nonAthletes/nonAthletes-view-page.vue'
import SubActivityFormPage from '../views/nonAthletes/subActivity-form-page.vue'
import Administration from '../views/administration/administration-view-page.vue'
import AdministrationFormPage from '../views/administration/administration-form-page.vue'
import DirectorsFormPage from '../views/administration/directors-form-page.vue'

const routes = [
  {
    path: '',
    component: SideMenu,
    meta: { auth: true },
    children: [
      // {
      //   path: '',
      //   name: 'home',
      //   component: HomePage,
      //   meta: {
      //     auth: true
      //     // permission: Permissions.values.adminView,
      //   }
      // },
      {
        path: 'article',
        name: 'article',
        component: Articles,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'article/:id',
        name: 'article/:id',
        props: true,
        component: ArticleViewPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'article/:id/comments',
        name: 'article/:id/comments',
        props: true,
        component: CommentListPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'article-form-page',
        name: 'article-form-page',
        component: ArticleFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'article-form-page/:id',
        name: 'article-form-page/edit',
        component: ArticleFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'ads',
        name: 'ads',
        component: Ads,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'logs',
        name: 'logs',
        component: Logs,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'ad-view-page',
        name: 'ad-view-page',
        component: AdViewPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'ad-form-page',
        name: 'ad-form-page',
        component: AdFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'ad-form-page/:id',
        name: 'ad-form-page/edit',
        component: AdFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'auction',
        name: 'auction',
        component: Auctions,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'auction-view-page/:id',
        name: 'auction-view-page',
        component: AuctionViewPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'auction-form-page',
        name: 'auction-form-page',
        component: AuctionFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'auction-form-page/:id',
        name: 'auction-edit-page',
        component: AuctionFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        name: 'admins',
        path: '/admins',
        component: Users,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'admins/new',
        name: 'admins-create-page',
        component: AdminFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'admins/:id/edit',
        name: 'admins-edit-page',
        component: AdminFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'admins/:id',
        name: 'admins-view-page',
        component: UserViewPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      // {
      //   path: 'media',
      //   name: 'media',
      //   component: Media,
      //   meta: {
      //     auth: true
      //     // permission: Permissions.values.adminView,
      //   }
      // },
      // {
      //   path: 'album',
      //   name: 'album',
      //   component: Album,
      //   meta: {
      //     auth: true
      //     // permission: Permissions.values.adminView,
      //   }
      // },
      {
        path: 'live-videos',
        name: 'live-videos',
        component: LiveVideos,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'live-videos/add',
        name: 'add-live-videos',
        component: LiveVideosFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'branch',
        name: 'branch',
        component: Branches,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'branch-form-page',
        name: 'branch-form-page',
        component: BranchFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'branch-form-page/:id',
        name: 'branch-edit',
        component: BranchFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'branch-view-page/:id',
        name: 'branch-view-page',
        component: BranchViewPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'restaurant',
        name: 'restaurant',
        component: Restaurants,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'restaurant-view-page',
        name: 'restaurant-view-page',
        component: RestaurantViewPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'restaurant-form-page',
        name: 'restaurant-form-page',
        component: RestaurantFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'restaurant-form-page/:id',
        name: 'restaurant-edit',
        component: RestaurantFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'sports',
        name: 'sports',
        component: Sports,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'sport-form-page',
        name: 'sport-form-page',
        component: SportsFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'sport-view-page',
        name: 'sport-view-page',
        component: SportViewPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'team-view-page',
        name: 'team-view-page',
        component: TeamViewPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'match-form-page',
        name: 'match-form-page',
        component: MatchFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'player-form-page',
        name: 'player-form-page',
        component: PlayerFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'player-view-page',
        name: 'player-view-page',
        component: PlayerViewPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'libraries',
        name: 'libraries-page',
        component: LibraryListPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'library-form-page',
        name: 'library-new-page',
        component: LibraryEditPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'library-form-page/:id',
        name: 'library-edit-page',
        component: LibraryEditPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'libraries/:id',
        name: 'library-view-page',
        component: LibraryViewPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'clinic',
        name: 'clinic',
        component: Clinic,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'clinic/:id',
        name: 'clinic-view',
        component: ClinicViewPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'clinic/:id/clinic-form-page',
        name: 'clinics-add',
        props: true,
        component: ClinicFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: '/clinic-form-page/edit',
        name: 'clinics-edit',
        props: true,
        component: ClinicFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'clinic-form-page',
        name: 'clinic-add',
        component: MedicalClinicFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView
        }
      },
      {
        path: 'clinic-form-page/:id',
        name: 'clinic-edit',
        component: MedicalClinicFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'media',
        name: 'media',
        component: Media,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'media/:id',
        name: 'media-view',
        component: MediaViewPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'media/:id/media-form-page/:mediaType',
        name: 'medias-add',
        props: true,
        component: MediaFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'media/:id/media-form-page/edit',
        name: 'medias-edit',
        props: true,
        component: MediaFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'media-form-page',
        name: 'media-add',
        component: AlbumFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView
        }
      },
      {
        path: 'media-form-page/:id',
        name: 'media-edit',
        component: AlbumFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'parking',
        name: 'parking',
        component: Parking,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'parking/:id/places-form-page',
        name: 'places-add',
        props: true,
        component: PlacesFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'parking/:id/places-form-page/edit',
        name: 'places-edit',
        props: true,
        component: PlacesFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'parking/:id',
        name: 'parking-view',
        component: ParkingViewPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'parking-form-page',
        name: 'parking-add',
        component: ParkingFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView
        }
      },
      {
        path: 'parking-form-page/:id',
        name: 'parking-edit',
        component: ParkingFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'activity',
        name: 'activity',
        component: nonAthletes,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'activity/:id/subActivity-form-page',
        name: 'SubActivity-add',
        props: true,
        component: SubActivityFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: '/subActivity-form-page/edit/:id',
        name: 'SubActivity-edit',
        props: true,
        component: SubActivityFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'activity/:id',
        name: 'activity-view',
        component: nonAthletesViewPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'activity-form-page',
        name: 'activity-add',
        component: nonAthletesFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView
        }
      },
      {
        path: 'activity-form-page/:id',
        name: 'activity-edit',
        component: nonAthletesFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        // path: 'administration',
        name: 'administration',
        path: '',
        alias: '/administration',
        component: Administration,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'administration/edit',
        name: 'administration-form-page',
        props: true,
        component: AdministrationFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'administration/add',
        name: 'directors-form-page',
        props: true,
        component: DirectorsFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'administration/director/:id',
        name: 'directors-edit-page',
        props: true,
        component: DirectorsFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'sponsor',
        name: 'sponsor',
        component: Sponsors,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'sponsor-form-page',
        name: 'sponsor-add',
        component: SponsorsFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'sponsor-form-page/:id',
        name: 'sponsor-edit',
        component: SponsorsFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'socials',
        name: 'socials',
        component: Socials,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'socials-form-page',
        name: 'socials-add',
        component: SocialsFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'socials-form-page/:id',
        name: 'socials-edit',
        component: SocialsFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'tags',
        name: 'tags',
        component: Tags,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'tags-form-page',
        name: 'tags-add',
        component: TagsFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'tags-form-page/:id',
        name: 'tags-edit',
        component: TagsFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'categories',
        name: 'categories',
        component: Categories,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'categories-form-page',
        name: 'categories-add',
        component: CategoriesFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'categories-form-page/:id',
        name: 'categories-edit',
        component: CategoriesFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'telecom',
        name: 'telecom',
        component: Telecom,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'telecom-form-page',
        name: 'telecom-add',
        component: TelecomFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'cultural',
        name: 'cultural',
        component: Cultural,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'cultural-form-page',
        name: 'cultural-add',
        component: CulturalFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'cultural-form-page/:id',
        name: 'cultural-edit',
        props: true,
        component: CulturalFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'telecom-form-page/:id',
        name: 'telecom-edit',
        component: TelecomFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'kindergarten',
        name: 'kindergarten',
        component: Kindergarten,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'kindergarten-form-page',
        name: 'kindergarten-add',
        component: KindergartenFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'kindergarten-form-page/:id',
        name: 'kindergarten-edit',
        component: KindergartenFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'discounts',
        name: 'discounts',
        component: discounts,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'discounts-form-page',
        name: 'discounts-add',
        component: discountsFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'discounts-form-page/:id',
        name: 'discounts-edit',
        component: discountsFormPage,
        props: true,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      // {
      //   path: 'nonAthletes',
      //   name: 'nonAthletes',
      //   component: NonAthletes,
      //   meta: {
      //     auth: true
      //     // permission: Permissions.values.adminView,
      //   }
      // },
      {
        path: 'nonAthletes-form-page',
        name: 'nonAthletes-add',
        component: NonAthletesFormPage,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      // {
      //   path: 'nonAthletes-form-page/:id',
      //   name: 'nonAthletes-edit',
      //   component: NonAthletesFormPage,
      //   props: true,
      //   meta: {
      //     auth: true
      //     // permission: Permissions.values.adminView,
      //   }
      // },
      {
        path: 'others',
        name: 'others',
        component: Others,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      },
      {
        path: 'change-password',
        name: 'side-menu-change-password',
        component: ChangePassword,
        meta: {
          auth: true
          // permission: Permissions.values.adminView,
        }
      }
    ]
  },
  {
    path: '/auth/login',
    name: 'login',
    component: Login,
    meta: { unauth: true }
  },
  {
    name: 'forgot-password',
    path: '/auth/forgot-password',
    component: ForgotPassword,
    meta: { unauth: true }
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage
  },
  {
    name: 'error403',
    path: '/403',
    component: Error403Page
  },
  {
    name: 'error404',
    path: '/404',
    component: Error404Page
  },
  {
    name: 'error500',
    path: '/500',
    component: Error500Page
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

// eslint-disable-next-line
import authGuard from '@/router/auth-guard'
router.beforeResolve(async (to, from, next) => {
  await authGuard.beforeRouteEnter(to, from, next)
})

export default router
