/* eslint-disable */

import contactusListStore from '@/store/contactUs/contactus-list-store'

export default {
  namespaced: true,

  modules: {
    list: contactusListStore
  }
}
