/* eslint-disable */ // import firebase from 'firebase/compat/app'
// import 'firebase/compat/storage'
// import * as uuid from 'uuid/v4'
import filesize from 'filesize'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject
} from 'firebase/storage'

function extractExtensionFrom(filename) {
  if (!filename) {
    return null
  }

  const regex = /(?:\.([^.]+))?$/
  return regex.exec(filename)[1]
}
export class FileUploader {
  static validate(file, schema) {
    if (!schema) {
      return
    }
    if (schema.image) {
      if (!file.type.startsWith('image')) {
        throw new Error('fileUploader.image')
      }
    }

    if (schema.size && file.size > schema.size) {
      throw new Error('fileUploader.size', filesize(schema.size))
    }

    const extension = extractExtensionFrom(file.name)

    if (schema.formats && !schema.formats.includes(extension)) {
      throw new Error('fileUploader.formats', schema.formats.join('/'))
    }
  }

  static uploadFromRequest(path, request, schema, imageName) {
    try {
      FileUploader.validate(request.file, schema)
    } catch (error) {
      request.onError(error)
      return
    }

    const ref = firebase.storage().ref()
    const extension = extractExtensionFrom(request.file.name)
    // const id = Math.floor((new Date()).getTime() / 1000);
    const fullPath = `${path}/${imageName}.${extension}`
    const task = ref.child(fullPath).put(request.file)

    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

        setTimeout(() => {
          request.onProgress({ percent })
        }, 0)
      },
      error => {
        request.onError(error)
      },
      () => {
        task.snapshot.ref
          .getDownloadURL()
          .then(url => {
            request.onSuccess({
              id: imageName,
              name: request.file.name,
              sizeInBytes: task.snapshot.totalBytes,
              privateUrl: fullPath,
              publicUrl: url,
              new: true
            })
          })
          .catch(error => {
            request.onError(error)
          })
      }
    )
  }

  // eslint-disable-next-line
  // static async uploadImage(path, request) {
  //   var uploads
  //   const promises = []
  //   const ref = firebase.storage().ref()
  //   const file = request
  //   const currentDate = new Date()
  //   const timestamp = currentDate.getTime()
  //   const fullPath = `${path}/${timestamp}`
  //   const task = ref.child(fullPath)
  //   const uploadPromise = task.put(file).then(async () => {
  //     const downloadURL = await task.getDownloadURL()
  //     uploads = {
  //       name: file.name,
  //       publicUrl: downloadURL
  //     }
  //   })
  //   promises.push(uploadPromise)
  //   await Promise.all(promises)
  //   console.log(uploads)
  //   return uploads
  // }

  static async upload(path, request, name) {
    // debugger
    return new Promise((resolve, reject) => {
      let uploads
      const promises = []
      const file = request
      const extension = extractExtensionFrom(file.name)
      const fileName = name || file.name.replace(`.${extension}`, '')
      const fullPath = `${path}/${fileName}.${extension}`

      const storage = getStorage()
      const storageRef = ref(storage, fullPath)
      const uploadTask = uploadBytesResumable(storageRef, file)
      uploadTask.on(
        'state_changed',
        snapshot => {},
        error => {
          console.log('ERROR', error)
          reject()
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
          console.log('File available at', downloadURL)
          uploads = {
            name: file.name,
            publicUrl: downloadURL,
            extension: extension
          }
          resolve(uploads)
        }
      )
    })
  }

  static async delete(path, file, name) {
    const extension = extractExtensionFrom(file.name)
    const fileName = name || file.name.replace(`.${extension}`, '')
    const fullPath = `${path}/${fileName}.${extension}`
    const storage = getStorage()
    const storageRef = ref(storage, fullPath)
    deleteObject(storageRef)
      .then(() => console.log('file deleted'))
      .catch(err => console.error(err))
  }

  // static async upload(path, request, name) {
  //   // debugger
  //   var uploads
  //   const promises = []
  //   const file = request
  //   const extension = extractExtensionFrom(file.name)
  //   const fileName = name || file.name.replace(`.${extension}`, '')
  //   const fullPath = `${path}/${fileName}.${extension}`;

  //   const ref = firebase.storage().ref()
  //   const task = ref.child(fullPath)
  //   const uploadPromise = task.put(file).then(async () => {
  //     const downloadURL = await task.getDownloadURL()
  //     uploads = {
  //       name: file.name,
  //       publicUrl: downloadURL
  //     }
  //   })
  //   promises.push(uploadPromise)
  //   await Promise.all(promises)
  //   return uploads
  // }
}
