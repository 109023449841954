<template>
  <div>
    <div class="intro-y flex px-5 flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('branches.title') }}</h2>
      <div
        class="btn btn-primary mt-6 sm:mt-2 mx-3 w-full sm:w-28 p-1"
        :class="isRTL ? 'lg:mr-auto' : 'lg:ml-auto'"
        @click="openCreateBranch()"
      >
        <PlusIcon class="mr-2" /> {{ i18n('common.create') }}
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box dark:bg-dark-8 p-5 sm:mt-5">
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <div class="overflow-x-auto g">
        <table class="table table-report sm:mt-2 hidden md:table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('branches.table.name') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('branches.table.description') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('branches.table.address') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('common.table.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" :key="index">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ index + 1 }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row, 'name') }}
                </div>
              </td>
              <td class="text-center w-72">
                <div
                  class="font-medium whitespace-nowrap truncate w-72 truncate"
                >
                  {{ presenterLocalization(row, 'description') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap truncate">
                  {{ presenterLocalization(row, 'address') }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div
                  class="flex justify-center items-center dark:text-white cursor-pointer"
                >
                  <div
                    @click="viewBranch(row.id)"
                    class="text-theme-14 dark:text-white"
                  >
                    <EyeIcon class="w-4 h-4 mr-1" />
                  </div>
                  <div
                    @click="editBranch(row.id)"
                    class="text-theme-14 dark:text-white ml-2"
                  >
                    <EditIcon class="w-4 h-4 mr-1" />
                  </div>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    class="text-theme-24 ml-2"
                    @click="deleteId = row.id"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mobile-table md:hidden mt-8">
        <mobile-table-comp
          v-for="(row, index) in rows"
          :key="index"
          class="md:hidden"
        >
          <div class="field">
            <span> {{ i18n('branches.table.name') }} </span>
            <div class="font-medium text-center">
              {{ presenterLocalization(row, 'name') }}
            </div>
          </div>
          <div class="field">
            <span> {{ i18n('branches.table.description') }} :</span>
            <div class="font-medium long-text truncate">
              {{ presenterLocalization(row, 'description') }}
            </div>
          </div>
          <div class="field">
            <span> {{ i18n('branches.table.address') }} :</span>
            <div class="font-medium">
              {{ presenterLocalization(row, 'address') }}
            </div>
          </div>
          <div class="field">
            <span> {{ i18n('common.table.actions') }} :</span>
            <div
              class="flex justify-center items-center dark:text-white cursor-pointer"
            >
              <div
                @click="viewBranch(row.id)"
                class="text-theme-14 dark:text-white"
              >
                <EyeIcon class="w-4 h-4 mr-1" />
              </div>
              <div
                @click="editBranch(row.id)"
                class="text-theme-14 dark:text-white ml-2"
              >
                <EditIcon class="w-4 h-4 mr-1" />
              </div>
              <a
                href="javascript:;"
                data-toggle="modal"
                data-target="#delete-modal-preview"
                class="text-theme-24 ml-2"
                @click="deleteId = row.id"
              >
                <Trash2Icon class="w-4 h-4 mr-1" />
              </a>
            </div>
          </div>
        </mobile-table-comp>
      </div>
    </div>
    <app-empty-page
      v-if="!rows.length && !loading"
      :label="i18n('empty.branches')"
      label-class="text-lg font-medium"
      icon-size="4x"
      class="box w-full py-64"
    ></app-empty-page>
    <!-- END: HTML Table Data -->
    <DeleteModal item="branch" @delete="doDestroy(deleteId)" />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import feather from 'feather-icons'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { mapActions, mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.branches')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.branches')
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'branch/list/rows',
      currentPage: 'branch/list/currentPage',
      isFirstPage: 'branch/list/isFirstPage',
      isLastPage: 'branch/list/isLastPage',
      loading: 'branch/list/loading',
      pagination: 'branch/list/pagination'
    })
  },
  components: {
    DeleteModal
  },
  setup() {
    const router = useRouter()
    const tabulator = ref()

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }
    onMounted(() => {
      reInitOnResizeWindow()
    })

    const deleteId = ''

    const viewBranch = (id) => {
      router.push(`/branch-view-page/${id}`)
    }
    const editBranch = (id) => {
      router.push(`/branch-form-page/${id}`)
    }
    const openCreateBranch = () => {
      router.push('/branch-form-page')
    }

    return {
      viewBranch,
      openCreateBranch,
      editBranch,
      deleteId
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'branch/list/doFetch',
      doFetchNextPage: 'branch/list/doFetchNextPage',
      doFetchPreviousPage: 'branch/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'branch/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'branch/list/doChangePaginationPageSize',
      doDestroy: 'branch/list/doDestroy'
    }),
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: {},
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
