/* eslint-disable */

import liveListStore from '@/store/live/live-list-store'
import liveFormStore from '@/store/live/live-form-store'
// import liveViewStore from '@/store/live/live-view-store'

export default {
  namespaced: true,

  modules: {
    list: liveListStore,
    form: liveFormStore,
    // view: liveViewStore
  }
}
