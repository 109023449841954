<template>
  <div class="flex justify-center">
    <div
      style="width: 100%; height: 30vh"
      class="flex justify-center"
      v-if="loading"
    >
      <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
    </div>
    <div
      class="intro-y news xl:w-3/5 p-5 box dark:bg-dark-8 mt-8"
      v-if="record"
    >
      <!-- BEGIN: Blog Layout -->
      <div class="flex">
        <h2 class="text-xl font-bold text-base mt-5">
          {{ presenterLocalization(record, 'name') }}
        </h2>
        <h2 class="mt-5 cursor-pointer" :class="isRTL ? 'mr-auto' : 'ml-auto'">
          <button
            class="btn btn-primary sm:w-auto pb-1 pt-1 flex gap-2"
            @click="goToEditPage(record.id)"
          >
            <EditIcon class="text-white w-4" />
            {{ i18n('common.edit') }}
          </button>
          <!-- <div class="dropdown" :class="isRTL ? 'mr-auto' : 'ml-auto'">
            <button class="dropdown-toggle" aria-expanded="false">
              <div
                class="dropdown-toggle options cursor-pointer mr-4"
                role="button"
                aria-expanded="false"
              >
                <MoreHorizontalIcon />
              </div>
            </button>
            <div class="dropdown-menu w-20">
              <div
                class="
                  dropdown-menu__content
                  notificationDropDown
                  box
                  dark:bg-dark-1
                  p-3
                  w-20
                "
                style="width: 200px !important"
              >
                <div
                  class="notification-content__title mb-2 cursor-pointer"
                  @click="goToEditPage('AR7HmAODh8fmWgZw466Y')"
                >
                  <EditIcon class="text-theme-14" /> {{ i18n('common.edit') }}
                </div>
              </div>
            </div>
          </div> -->
        </h2>
      </div>
      <div class="intro-y mt-6">
        <div class="news__preview image-fit">
          <img
            alt="Icewall Tailwind HTML Admin Template"
            class="rounded-md"
            :src="
              record.banner || require(`@/assets/images/${$f()[0].images[0]}`)
            "
          />
        </div>
        <!-- <div class="sm:whitespace-normal flex items-center mt-3">
          <PhoneIcon class="w-6 h-6 mx-2" />
          01001234567
        </div>
        <div class="sm:whitespace-normal flex items-center mt-3">
          <MapPinIcon class="w-6 h-6 mx-2" />
          Next to Gate 3
        </div> -->
      </div>
      <div class="intro-y text-justify leading-relaxed mt-3">
        <p class="mb-5" v-if="isRTL">{{ record.description?.ar }}</p>
        <p class="mb-5" v-else>{{ record.description?.en }}</p>
        <!-- <p class="mb-5">{{ $f()[2].news[0].content }}</p> -->
        <!-- <p>{{ $f()[3].news[0].content }}</p> -->
      </div>
      <h2 class="mt-5 intro-y font-medium text-xl sm:text-2xl">
        {{ i18n('library.images') }}
      </h2>
      <div
        class="intro-y p-5 mt-5"
        style="height: 220px; direction: ltr !important"
      >
        <TinySlider
          :options="{
            autoplay: false,
            controls: true,
            items: 1,
            nav: true,
            responsive: {
              600: {
                items: 3
              },
              480: {
                items: 2
              }
            }
          }"
        >
          <div class="h-40 px-2" v-for="image in record.images" :key="image">
            <div class="h-full image-fit rounded-md overflow-hidden">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                :src="image"
                data-action="zoom"
              />
            </div>
          </div>
        </TinySlider>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.library')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.library')
    }
  },
  props: ['id'],
  async mounted() {
    await this.doFind(this.id)
    console.log('record', this.record)
  },
  computed: {
    ...mapGetters({
      record: 'library/view/record',
      loading: 'library/view/loading'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    ...mapActions({
      doFind: 'library/view/doFind'
    }),
    goToEditPage(id) {
      this.$router.push(`/library-form-page/${id}`)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
<style scoped>
.validity {
  position: absolute !important;
  z-index: 20 !important;
  background: #d42428 !important;
  padding: 15px !important;
  border-bottom-right-radius: 20px !important;
  color: white !important;
}
</style>
