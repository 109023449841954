<template>
  <div>
    <div class="
        intro-y
        flex flex-wrap
        sm:flex-row sm:flex-nowrap
        items-center
        mt-3
        py-3
        px-0
        sm:px-3
      " :style="`justify-content: ${showSizeChanger ? 'center' : position}`">
      <!-- :class="showSizeChanger ? 'justify-center': `justify-${position}`" -->
      <div class="flex items-center justify-center gap-3 mb-3 sm:mb-0" :class="showSizeChanger ? 'pagination' : ''">
        <button class="btn bg-theme-31 text-black  py-1 text-xs sm:text-sm w-20" :disabled="isFirstPage || disabled"
          @click="$emit('prev-change')" style="background-color: #edf2f7">
          {{ i18n('common.previous') }}
        </button>

        <span class="block whitespace-nowrap text-xs sm:text-sm ">{{ i18n('common.page') }} {{
          page
        }}</span>

        <button class="btn bg-theme-32 text-white  py-1 text-xs sm:text-sm w-20" :disabled="isLastPage || disabled"
          @click="$emit('next-page')">
          {{ i18n('common.next') }}
        </button>
      </div>

      <div class="flex items-center gap-3 mt-3 sm:mt-0">
        <app-i18n code="common.recordsPerPage" :args="[':']" class="text-xs sm:text-sm"
          v-if="showSizeChanger"></app-i18n>
        <select class="w-18  sm:w-20  p-1 sm:form-select box" v-model="limit" @change="$emit('size-change', limit)"
          v-if="showSizeChanger">
          <option v-for="limit in limits" :key="limit" :value="limit">
            {{ limit }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
// :current-page="pagination.currentPage || 1"
// :disabled="loading"
// :layout="paginationLayout"
// :total="count"
// @current-change="doChangePaginationCurrentPage"
// @size-change="doChangePaginationPageSize"

export default defineComponent({
  props: {
    page: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    rowsNumber: {
      type: Number
    },
    disabled: {
      type: Boolean
    },
    showSizeChanger: {
      type: Boolean,
      default: true
    },
    position: {
      type: String,
      default: 'center'
    },
    isFirstPage: {
      type: Boolean
    },
    isLastPage: {
      type: Boolean
    }
  },
  data() {
    return {
      size: this.pageSize,
      defaultLimits: [5, 10, 15, 20, 30, 40, 50]
    }
  },
  computed: {
    limit: {
      get() {
        return this.size
      },
      set(newValue) {
        this.size = newValue
      }
    },
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    limits() {
      const arr = [...new Set([...this.defaultLimits, this.pageSize])]
      return arr.sort((a, b) => {
        return a - b
      })
    }
    // isFirstPage() {
    //   return this.page === 1
    // },
    // isLastPage() {
    //   return this.rowsNumber < this.size
    // }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style lang="scss">
[dir='rtl'] .pagination {
  margin-right: auto !important;
  margin-left: unset !important;
}

[dir='rtl'] .pagination {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
