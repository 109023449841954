<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('media.view.title') }}
      </h2>
    </div>

    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        style="width: 100%; height: 30vh"
        class="flex justify-center"
        v-if="loading"
      >
        <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
      </div>
      <div v-if="record && !loading">
        <div class="p-5">
          <div class="relative flex items-center">
            <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
              <div class="font-medium mb-1">
                {{ i18n('media.form.title') }}
              </div>
              <div class="text-gray-600 dark:text-gray-300">
                {{ presenterLocalization(record, 'title') }}
              </div>
            </div>
          </div>
        </div>
        <div class="p-5 border-t">
          <div class="relative flex items-center">
            <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
              <div class="font-medium mb-1">
                {{ i18n('media.form.description') }}
              </div>
              <div class="text-gray-600 dark:text-gray-300">
                {{ presenterLocalization(record, 'description') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12">
      <images-view-page
        @deleteMedia="
          (row) => {
            selectedImage = row
          }
        "
        :media="record?.images || []"
        :id="id"
        @doRefresh="doFind(id)"
      ></images-view-page>
    </div>
    <div class="col-span-12">
      <videos-view-page
        @deleteMedia="
          (row) => {
            selectedVideo = row
          }
        "
        :media="record?.videos || []"
        :id="id"
        @doRefresh="doFind(id)"
      ></videos-view-page>
    </div>
    <DeleteModal item="media" @delete="doDelete()" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import ImagesViewPage from '@/views/media/images-view-table-page.vue'
import VideosViewPage from '@/views/media/videos-view-table-page.vue'
import DeleteModal from '@/components/modals/delete-modal.vue'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    ImagesViewPage,
    VideosViewPage,
    DeleteModal
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'media/form/record',
      loading: 'media/form/findLoading'
    })
  },
  data() {
    return {
      tab: 'owners',
      selectedImage: null,
      selectedVideo: null
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.media')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.media')
    }
  },
  async mounted() {
    await this.doFind(this.id)
    console.log(this.record)
  },
  methods: {
    ...mapActions({
      doFind: 'media/form/doFind',
      doDestroy: 'media/form/doRemoveFromAlbum'
    }),
    async doDelete() {
      console.log('SELECTED ===>', this.selectedImage, this.selectedVideo)
      await this.doDestroy({
        id: this.id,
        images: this.selectedImage ? [this.selectedImage] : [],
        videos: this.selectedVideo ? [this.selectedVideo] : []
      })
      await this.doFind(this.id)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
