/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlUser)

export class UserService {
  //#region [ Mutation APIs ]
  /**
   * Enable user in authentication table and firestore database
   * @param {String[]} ids
   * @returns
   */
  static async enable(ids) {
    return this._changeStatus(ids, true)
  }

  static async enableUser(id) {
    return this._changeUserStatus([id], false)
  }

  /**
   * Disable user in authentication table and firestore database
   * @param {String[]} ids
   * @returns
   */
  static async disable(ids) {
    return this._changeStatus(ids, false)
  }

  static async disableUser(id) {
    return this._changeUserStatus([id], true)
  }

  static async _changeStatus(ids, disabled) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADMIN_CHANGE_STATUS($ids: [String!]!, $disabled: Boolean) {
          adminChangeStatus(ids: $ids, disabled: $disabled)
        }
      `,

      variables: {
        adminsIds: ids,
        status: !!disabled
      }
    })

    return response.data.adminChangeStatus
  }

  static async _changeUserStatus(ids, disabled) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADMIN_CHANGE_STATUS($ids: [String!]!, $disabled: Boolean) {
          adminChangeStatus(ids: $ids, disabled: $disabled)
        }
      `,

      variables: {
        ids,
        disabled
      }
    })

    return response.data.adminChangeStatus
  }

  /**
   * Set page access permissions for the admin
   * - setAdminAccess(adminId: String!, pagesAccess: [String]!): JSON!
   * @param {String} id
   * @param {String[]} pagesAccess
   * @returns
   */
  static async setAdminAccess(id, pagesAccess) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_CHANGE_ROLES($adminId: String!, $pagesAccess: [String]!) {
          setAdminAccess(adminId: $adminId, pagesAccess: $pagesAccess)
        }
      `,

      variables: {
        adminId: id,
        pagesAccess
      }
    })

    // return response.data.setAdminAccess
    if (response.data.setAdminAccess && response.data.setAdminAccess.status) {
      return response.data.setAdminAccess.result
    } else {
      console.error(response.data.setAdminAccess.error)
      throw response.data.setAdminAccess.error
    }
  }

  /**
   * Create new admin
   * @param {Object} data
   * @param {String} data.email
   * @param {String} data.password
   * @param {String} data.userName
   * @param {String} data.phoneNumber
   * @param {String[]} data.pagesAccess
   * @param {String} data.avatar
   * @param {'en'|'ar' } data.lang
   * @returns
   */
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADMIN_CREATE($data: AdminInput!) {
          adminCreate(data: $data)
        }
      `,
      variables: { data }
    })

    return response.data.adminCreate
  }

  /**
   * Update admin
   * @param {String} id
   * @param {Object} data
   * @param {String} data.email
   * @param {String} data.userName
   * @param {String} data.phoneNumber
   * @param {String[]} data.pagesAccess
   * @param {String} data.avatar
   * @param {'en'|'ar' } data.lang
   * @returns
   */
  static async edit(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADMIN_UPDATE($data: IamEditInput!) {
          adminUpdate(data: $data)
        }
      `,

      variables: {
        data
      }
    })

    return response.data.adminUpdate
  }

  static async removeUsers(ids) {
    console.log(ids)
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation USER_DESTROY($adminsIds: [String]!) {
          removeAdmins(adminsIds: $adminsIds)
        }
      `,

      variables: {
        adminsIds: ids
      }
    })

    // return response.data.removeAdmins
    if (response.data.removeAdmins && response.data.removeAdmins.status) {
      return response.data.removeAdmins.result
    } else {
      console.error(response.data.removeAdmins.error)
      throw response.data.removeAdmins.error
    }
  }

  // static async remove(emails, roles, all = false) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation USER_REMOVE(
  //         $emails: [String!]!
  //         $roles: [String!]!
  //         $all: Boolean
  //       ) {
  //         iamRemove(
  //           emails: $emails
  //           roles: $roles
  //           all: $all
  //         )
  //       }
  //     `,

  //     variables: {
  //       emails,
  //       roles,
  //       all
  //     }
  //   })

  //   return response.data.iamRemove
  // }

  // static async destroy(id) {
  //   const response = await graphqlClient.mutate({
  //     mutation: gql`
  //       mutation USER_DESTROY($id: String!) {
  //         iamDestroy(id: $id)
  //       }
  //     `,

  //     variables: {
  //       id
  //     }
  //   })
  //   return response.data.iamDestroy
  // }
  //#endregion

  //#region [ Query APIs ]
  static async findAdmin(id) {
    const response = await graphqlClient.query({
      query: gql`
        query ADMIN_FIND($id: String!) {
          adminFind(id: $id) {
            id
            authenticationUid
            fullName
            firstName
            lastName
            email
            phoneNumber

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.adminFind
  }

  /**
   * Retrieve admins with pagination
   * @param {Object[]} filter
   * @param {String} filter.key
   * @param {String} filter.value
   * @param {'less'|'lessEqual'|'greater'|'greaterEqual'|'equal'|'notEqual'|'startsWith'} filter.operator
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   * @returns
   */
  static async fetchAdmins(filter, pagination) {
    console.log('PAGINATION service', pagination)
    const response = await graphqlClient.query({
      query: gql`
        query ADMIN_LIST(
          $filter: IamListUsersFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          adminList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              authenticationUid
              fullName
              firstName
              lastName
              phoneNumber
              email
              roles
              disabled
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.adminList
  }

  /**
   * Find list of user addresses
   * - listUserAddresses(userId: String!): [Address]!
   * @param {String} id - User ID
   * @returns {Promise<Address[]>}
   */
  static async listUserAddresses(id) {
    const response = await graphqlClient.query({
      query: gql`
        query USER_LIST_ADDRESSES($userId: String!) {
          listUserAddresses(userId: $userId) {
            id
            name
            description
            lat
            long
            phoneNumber
            isDefault
            city {
              id
              en
              ar
            }
          }
        }
      `,

      variables: {
        userId: id
      }
    })

    return response.data.listUserAddresses
  }
  //#endregion
}
