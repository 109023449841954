<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="nonAthletes.title.edit" v-if="isEditing"></app-i18n>
            <app-i18n code="nonAthletes.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !model"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('nonAthletes.form.name') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.name.en"
                    :placeholder="i18n('common.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.name.ar"
                    :placeholder="i18n('common.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('branches.fields.branch') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 items-center"
                >
                  <v-select
                    :options="branchOptions || []"
                    :label="labelLocalized"
                    v-model="model.branch"
                    class="select-center"
                    title="Select item"
                  >
                    <template #no-options
                      ><div class="loading-loader"></div> </template
                  ></v-select>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('nonAthletes.form.logo') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && logoUrl">
                    <img
                      :src="logoUrl"
                      class="object-cover object-center"
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.logo = undefined"
                    />
                  </div>
                  <Uploader ref="uploaderLogo" v-else></Uploader>
                </div>
              </div>
              <!-- END -->
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn btn-primary w-24 cursor-pointer text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer"
                  @click="doCancel"
                >
                  {{ i18n('common.cancel') }}
                </button>
              </div>
            </form>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import Message from '@/shared/message/toastify'
import { mapGetters, mapActions } from 'vuex'
import Uploader from '@/views/nonAthletes/uploader.vue'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.nonAthletes')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.nonAthletes')
    }
  },
  props: {
    id: String
  },
  components: {
    Uploader
  },
  data() {
    return {
      model: null,
      branchOptions: [],
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'activity/form/record',
      branches: 'branch/list/rows',
      findLoading: 'activity/form/findLoading',
      saveLoading: 'activity/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    },
    labelLocalized() {
      return this.isRTL ? 'nameAr' : 'nameEn'
    },
    filesToSaveLogo() {
      return this.$refs.uploaderLogo?.filesToSave || []
    },
    logoUrl() {
      return this.model && this.model.logo ? this.model.logo : null
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    await this.doFetchBranches({
      filter: {},
      pagination: {
        limit: 10,
        sortBy: 'asc'
      }
    })
    this.branchOptions = this.branches.map((branch) => ({
      ...branch,
      nameAr: branch.name.ar,
      nameEn: branch.name.en
    }))
    // this.model = this.record || {
    this.model = this.record || {
      name: {
        en: '',
        ar: ''
      },
      branch: '',
      details: [],
      logo: ''
    }
    if (this.isEditing) {
      this.model.branch = this.branchOptions.filter(
        (branch) => branch.id === this.record.branchId
      )[0]
    }
  },
  methods: {
    ...mapActions({
      doNew: 'activity/form/doNew',
      doFetchBranches: 'branch/list/doFetch',
      doFind: 'activity/form/doFind',
      doCreate: 'activity/form/doCreate',
      doUpdate: 'activity/form/doUpdate'
    }),
    isFormValid() {
      if (this.model.name.en.length == 0 || this.model.name.ar.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (!this.model.branch || this.model.branch.id.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      // if (this.isEditing) {
      //   if (!(this.bannerUrl || this.filesToSaveLogo.length !== 0)) {
      //     this.errorMessage = 'restaurants.edit.errors.emptyFields'
      //     return false
      //   }
      // } else {
      if (this.isEditing) {
        if (this.filesToSaveLogo.length === 0 && this.model.logo.length === 0) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          console.log('false heere')
          return false
        }
      } else {
        if (this.filesToSaveLogo.length === 0) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          console.log('false ss')
          return false
        }
      }
      // }
      return true
    },
    doCancel() {
      this.$router.push('/activity')
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const { name, branch, details } = this.model
      if (this.isEditing) {
        await this.doUpdate({
          id: this.id,
          name,
          branchId: branch.id,
          details,
          logo: this.logoUrl || this.filesToSaveLogo[0]
        })
      } else {
        await this.doCreate({
          name,
          branchId: branch.id,
          details,
          logo: this.filesToSaveLogo[0]
        })
      }
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
