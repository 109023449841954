<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n
              code="kindergarten.form.title.edit"
              v-if="isEditing"
            ></app-i18n>
            <app-i18n code="kindergarten.form.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !model"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('kindergarten.form.name') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.name.en"
                    :placeholder="i18n('common.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.name.ar"
                    :placeholder="i18n('common.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('branches.fields.description') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    id="description-en"
                    :rows="4"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.description.en"
                    :placeholder="i18n('branches.fields.english')"
                  ></textarea>
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <textarea
                    id="description-ar"
                    :rows="4"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.description.ar"
                    :placeholder="i18n('branches.fields.arabic')"
                  ></textarea>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('kindergarten.form.admin') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.adminName.en"
                    :placeholder="i18n('common.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.adminName.ar"
                    :placeholder="i18n('common.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('kindergarten.form.time') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.openHours.en"
                    :placeholder="i18n('common.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.openHours.ar"
                    :placeholder="i18n('common.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('kindergarten.form.activities') }}
                </h4>
                <div
                  class="lg:col-span-8 col-span-12 lg:mt-0 mt-2 grid gap-y-2 items-center"
                >
                  <div
                    class="grid grid-cols-12 relative"
                    v-for="(input, index) in model.activities"
                    :key="`activity-${index}`"
                  >
                    <div class="lg:col-span-6 col-span-11 lg:mt-0 mt-2">
                      <input
                        id="crud-form-1"
                        type="text"
                        class="form-control w-full dark:placeholder-white"
                        v-model="input.en"
                        :placeholder="i18n('common.english')"
                      />
                    </div>
                    <div
                      class="lg:col-span-6 col-span-11 lg:mt-0 mt-2"
                      :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                    >
                      <input
                        id="crud-form-1"
                        type="text"
                        class="form-control w-full dark:placeholder-white"
                        v-model="input.ar"
                        :placeholder="i18n('common.arabic')"
                      />
                    </div>
                    <div>
                      <svg
                        v-if="index === model.activities.length - 1"
                        @click="addField"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="ml-2 cursor-pointer relative lg:absolute lg:top-2 -top-4"
                        :class="
                          isRTL ? `-left-4  lg:-left-8` : `right-0 lg:-right-8`
                        "
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill="green"
                          d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                        />
                      </svg>
                      <svg
                        v-show="model.activities.length > 1"
                        @click="removeField(index)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="ml-2 cursor-pointer relative lg:absolute -top-4 lg:top-2"
                        :class="
                          isRTL
                            ? `-left-4  lg:-left-16`
                            : `right-0 lg:-right-16`
                        "
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          fill="#ff0000"
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('branches.fields.branch') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 items-center"
                >
                  <v-select
                    :options="branchOptions || []"
                    :label="labelLocalized"
                    v-model="model.branch"
                    class="select-center"
                    title="Select item"
                  >
                    <template #no-options>
                      <div class="loading-loader"></div> </template
                  ></v-select>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('kindergarten.form.phoneNumber') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  style="dir: ltr"
                >
                  <vue-tel-input
                    v-model="model.adminPhoneNumber"
                    mode="international"
                    :inputOptions="isRTL ? optionsAr : options"
                  ></vue-tel-input>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('restaurants.fields.logoThumbnail') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && logoThumbnailUrl">
                    <img
                      :src="logoThumbnailUrl"
                      class="object-cover object-center"
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.logo_thumbnail = undefined"
                    />
                  </div>
                  <Uploader ref="uploaderLogoThumbnail" v-else></Uploader>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('restaurants.fields.logo') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && logoUrl">
                    <img
                      :src="logoUrl"
                      class="object-cover object-center"
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.logo = undefined"
                    />
                  </div>
                  <Uploader ref="uploaderLogo" v-else></Uploader>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('restaurants.fields.banner') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && bannerUrl">
                    <img
                      :src="bannerUrl"
                      class="object-cover object-center"
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.banner = undefined"
                    />
                  </div>
                  <Uploader ref="uploaderBanner" v-else></Uploader>
                </div>
              </div>
            </div>
            <!-- END -->
            <div class="flex justify-center items-center mt-10">
              <AppButton
                type="button"
                class="btn btn-primary w-24 cursor-pointer text-white cursor-pointer"
                :class="!saveLoading ? 'w-24' : ''"
                :disabled="saveLoading || findLoading"
                :loading="saveLoading"
                @click="doSubmit()"
              >
                <strong>{{ i18n('common.save') }}</strong>
                <template v-slot:loading>
                  <app-i18n code="common.loading"></app-i18n>
                  <LoadingIcon
                    icon="three-dots"
                    color="#FFFFFF"
                    style="margin: 0 4px"
                  />
                </template>
              </AppButton>
              <button
                type="button"
                class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer"
                @click="doCancel"
              >
                {{ i18n('common.cancel') }}
              </button>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import Uploader from '@/views/kindergarten/uploader.vue'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.kindergarten')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.kindergarten')
    }
  },
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      model: null,
      branchOptions: [],
      errorMessage: null,
      telDropOptions: { showFlags: true },
      telOptions: {
        placeholder: 'Enter kindergarten Phone Number',
        showDialCode: true
      },
      telOptionsAr: { placeholder: 'ادخل رقم الحضانة', showDialCode: true }
    }
  },
  components: {
    Uploader
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      branches: 'branch/list/rows',
      record: 'kindergarten/form/record',
      findLoading: 'kindergarten/form/findLoading',
      saveLoading: 'kindergarten/form/saveLoading'
    }),
    labelLocalized() {
      return this.isRTL ? 'nameAr' : 'nameEn'
    },
    isEditing() {
      return !!this.id
    },
    filesToSaveLogoThumbnail() {
      return this.$refs.uploaderLogoThumbnail?.filesToSave || []
    },
    filesToSaveLogo() {
      return this.$refs.uploaderLogo?.filesToSave || []
    },
    filesToSaveBanner() {
      return this.$refs.uploaderBanner?.filesToSave || []
    },
    logoThumbnailUrl() {
      return this.model && this.model.logo_thumbnail
        ? this.model.logo_thumbnail
        : null
    },
    logoUrl() {
      return this.model && this.model.logo ? this.model.logo : null
    },
    bannerUrl() {
      return this.model && this.model.banner ? this.model.banner : null
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    console.log('record1', this.record)
    await this.doFetchBranches({
      filter: {},
      pagination: {
        limit: 10,
        sortBy: 'asc'
      }
    })
    this.branchOptions = this.branches.map((branch) => ({
      ...branch,
      nameAr: branch.name.ar,
      nameEn: branch.name.en
    }))
    this.model = this.record || {
      name: {
        en: '',
        ar: ''
      },
      description: {
        en: '',
        ar: ''
      },
      adminName: {
        en: '',
        ar: ''
      },
      adminPhoneNumber: '',
      branch: '',
      openHours: {
        en: '',
        ar: ''
      },
      activities: [{ en: '', ar: '' }],
      logo_thumbnail: '',
      logo: '',
      banner: ''
    }
    if (this.isEditing) {
      this.model.activities =
        this.record && this.record.activities.length > 0
          ? this.record.activities
          : [{ en: '', ar: '' }]
      this.model.branch = this.branchOptions.filter(
        (branch) => branch.id === this.record.branchId
      )[0]
    }
    console.log('record', this.record)
    console.log('model', this.model)
  },
  methods: {
    ...mapActions({
      doNew: 'kindergarten/form/doNew',
      doFetchBranches: 'branch/list/doFetch',
      doFind: 'kindergarten/form/doFind',
      doCreate: 'kindergarten/form/doCreate',
      doUpdate: 'kindergarten/form/doUpdate'
    }),
    addField() {
      this.model.activities.push({ en: '', ar: '' })
    },
    removeField(index) {
      this.model.activities.splice(index, 1)
    },
    isFormValid() {
      if (this.model.name.en.length == 0 || this.model.name.ar.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      if (
        this.model.adminName.en.length == 0 ||
        this.model.adminName.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.description.en.length == 0 ||
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      if (this.model.adminPhoneNumber.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.openHours.en.length == 0 ||
        this.model.openHours.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.activities.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      if (this.isEditing) {
        if (
          !(this.logoThumbnailUrl || this.filesToSaveLogoThumbnail.length !== 0)
        ) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
        if (!(this.logoUrl || this.filesToSaveLogo.length !== 0)) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
        if (!(this.bannerUrl || this.filesToSaveBanner.length !== 0)) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      } else {
        if (
          this.filesToSaveLogoThumbnail.length === 0 ||
          this.filesToSaveLogo.length === 0 ||
          this.filesToSaveBanner.length === 0
        ) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      }
      return true
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const {
        name,
        adminName,
        adminPhoneNumber,
        description,
        openHours,
        branch,
        activities
      } = this.model
      if (this.isEditing) {
        await this.doUpdate({
          id: this.id,
          name,
          branchId: branch.id,
          adminName,
          adminPhoneNumber,
          openHours,
          description,
          activities,
          logo_thumbnail:
            this.logoThumbnailUrl || this.filesToSaveLogoThumbnail[0],
          logo: this.logoUrl || this.filesToSaveLogo[0],
          banner: this.bannerUrl || this.filesToSaveBanner[0]
        })
      } else {
        console.log({
          name,
          adminName,
          adminPhoneNumber,
          openHours,
          branchId: branch.id,
          activities,
          description,
          logo_thumbnail: this.filesToSaveLogoThumbnail[0],
          logo: this.filesToSaveLogo[0],
          banner: this.filesToSaveBanner[0]
        })
        await this.doCreate({
          name,
          adminName,
          adminPhoneNumber,
          openHours,
          branchId: branch.id,
          activities,
          description,
          logo_thumbnail: this.filesToSaveLogoThumbnail[0],
          logo: this.filesToSaveLogo[0],
          banner: this.filesToSaveBanner[0]
        })
      }
    },
    doCancel() {
      this.$router.push('/kindergarten')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
