/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlMedia)

export class MediaService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query ALBUM_LIST(
          $filter: AlbumFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          albumList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              title {
                en
                ar
              }
              description {
                en
                ar
              }
              images
              videos

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.albumList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query ALBUM_FIND($id: String!) {
          albumFind(id: $id) {
            id
            title {
              en
              ar
            }
            description {
              en
              ar
            }
            images
            videos

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.albumFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ALBUM_CREATE($data: AlbumInput!) {
          albumCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.albumCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ALBUM_UPDATE($id: String!, $data: AlbumInput!) {
          albumUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.albumUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ALBUM_DESTROY($id: String!) {
          albumDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.albumDestroy
  }

  static async addToAlbum(id, images, videos) {
    console.log(id, images, videos)
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADD_NEW_MEDIA_TO_ALBUM(
          $id: String!
          $images: [String!]
          $videos: [String!]
        ) {
          addNewMediaToAlbum(id: $id, images: $images, videos: $videos)
        }
      `,
      variables: {
        id,
        images,
        videos
      }
    })
    return response.data.addNewMediaToAlbum
  }

  static async removeFromAlbum(id, images, videos) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REMOVE_FROM_MEDIA_TO_ALBUM(
          $id: String!
          $images: [MediaInput!]
          $videos: [MediaInput!]
        ) {
          removeMediaFromAlbum(id: $id, images: $images, videos: $videos)
        }
      `,
      variables: {
        id,
        images,
        videos
      }
    })
    return response.data.removeMediaFromAlbum
  }
}
