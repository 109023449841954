<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n
              code="categories.form.title.edit"
              v-if="isEditing"
            ></app-i18n>
            <app-i18n code="categories.form.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !form"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="form">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('categories.form.name') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="crud-form-1"
                    type="text"
                    class="form-control w-full dark:placeholder-white"
                    v-model="form.name.en"
                    :placeholder="i18n('categories.form.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="crud-form-1"
                    type="text"
                    class="form-control w-full dark:placeholder-white"
                    v-model="form.name.ar"
                    :placeholder="i18n('categories.form.arabic')"
                  />
                </div>
              </div>
              <!-- <div class="grid grid-cols-12 mt-5 items-center">
            <h4 class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2">
              {{ i18n('categories.form.link') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <input id="crud-form-1" type="text" class="form-control w-full dark:placeholder-white" v-model="form.link"
                :placeholder="i18n('categories.form.link')" />
            </div>
          </div> -->
              <!-- <div class="grid grid-cols-12 mt-5 items-center items-center">
            <h4 class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2">
              {{ i18n('categories.form.logo') }}
            </h4>
            <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
              <div class="relative" v-if="isEditing && logoUrl">
                <img :src="require(`@/assets/images/${logoUrl}`)" alt="" style="width: 100%; height: 200px;" />
                <img src="@/assets/images/deleteIcon.png" alt=""
                  class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                  @click="form.logo = undefined" />
              </div>
              <Uploader ref="uploader" v-else></Uploader>
            </div>
          </div> -->
              <!-- END -->
              <div class="flex justify-center items-center mt-10">
                <AppButton type="button" class="btn btn-primary w-24 cursor-pointer text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''" :disabled="saveLoading || findLoading" :loading="saveLoading"
                  @click="doSubmit()">
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px;" />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer"
                  @click="doCancel"
                >
                  {{ i18n('common.cancel') }}
                </button>
              </div>
            </form>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.categories')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.categories')
    }
  },
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      form: null,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      branches: 'branch/list/rows',
      record: 'category/form/record',
      findLoading: 'category/form/findLoading',
      saveLoading: 'category/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    this.form = this.record || {
      name: {
        en: '',
        ar: ''
      }
    }
  },
  methods: {
    ...mapActions({
      doNew: 'category/form/doNew',
      doFind: 'category/form/doFind',
      doCreate: 'category/form/doCreate',
      doUpdate: 'category/form/doUpdate'
    }),
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      if (this.isEditing) {
        await this.doUpdate({
          id: this.id,
          name: this.form.name
        })
      } else {
        await this.doCreate({
          name: this.form.name
        })
      }
    },
    doCancel() {
      this.$router.push('/categories')
    },
    isFormValid() {
      for (var key in this.form) {
        var value = this.form[key]
        for (var groupKey in value) {
          var value2 = value[groupKey]
          if (value2.length == 0) {
            this.errorMessage = 'restaurants.edit.errors.emptyFields'
            return false
          }
        }
      }
      return true
    },
    filesToSave() {
      return this.$refs.uploader.filesToSave
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
