import { i18n, i18nExists } from '@/locales/i18n'
import router from '@/router'
import Message from '@/shared/message/toastify'
import lodash from 'lodash'
const DEFAULT_ERROR_MESSAGE = i18n('errors.defaultErrorMessage')
// function isJson(obj) {
//   const t = typeof obj
//   return ['boolean', 'number', 'string', 'symbol', 'function'].indexOf(t) == -1
// }
function isJsonObject(strData) {
  try {
    if (typeof str != 'string') {
      strData = JSON.stringify(strData)
    }
    JSON.parse(strData)
  } catch (e) {
    return false
  }
  return true
}
function isFirebaseAuthError(error) {
  return (
    error.code && lodash.isString(error.code) && error.code.startsWith('auth')
  )
}
function selectErrorMessage(error) {
  if (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors.length &&
    error.graphQLErrors[0].message
  ) {
    return error.graphQLErrors[0].message
  }
  if (
    error &&
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors &&
    error.networkError.result.errors.length &&
    error.networkError.result.errors[0].message
  ) {
    return error.networkError.result.errors[0].message
  }
  // console.log(error, 'isFirebaseAuthError', isFirebaseAuthError(error))
  if (isFirebaseAuthError(error)) {
    if (i18nExists(`firebaseErrors.${error.code}`)) {
      return i18n(`firebaseErrors.${error.code}`)
    }
    return DEFAULT_ERROR_MESSAGE
  }
  if (error && isJsonObject(error)) {
    return error.message
  }
  return error.message || DEFAULT_ERROR_MESSAGE
}
function selectErrorCode(error) {
  if (error && error.networkError) {
    if (
      error.networkError.result &&
      error.networkError.result.errors &&
      error.networkError.result.errors.length &&
      error.networkError.result.errors[0].code
    ) {
      return Number(error.networkError.result.errors[0].code)
    }
    if (error.networkError.statusCode) {
      return Number(error.networkError.statusCode)
    }
  }
  if (error && error.graphQLErrors && error.graphQLErrors.length) {
    return 400
  }

  if (isFirebaseAuthError(error)) {
    return 400
  }

  if (error && isJsonObject(error)) {
    return 400
  }
  return 500
}

export default class Errors {
  static handle(error) {
    const errorMessage = selectErrorMessage(error)
    const errorCode = selectErrorCode(error)

    if (process.env.NODE_ENV !== 'test') {
      console.error(errorMessage)
      console.error(error)
    }

    if (errorCode === 403) {
      router.push('/403')
      return
    }

    if (errorCode === 400) {
      Message.error(errorMessage)
      return
    }
    Message.error(errorMessage)
    router.push('/500')
  }

  static errorCode(error) {
    return selectErrorCode(error)
  }

  static selectMessage(error) {
    return selectErrorMessage(error)
  }

  static showMessage(error) {
    Message.error(selectErrorMessage(error))
  }
}
