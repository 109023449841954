/* eslint-disable */

import { BranchService } from '@/store/branch/branch-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.record = null
    },

    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },
    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },

    CREATE_STARTED(state) {
      state.saveLoading = true
    },
    CREATE_SUCCESS(state) {
      state.saveLoading = false
    },
    CREATE_ERROR(state) {
      state.saveLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },
    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },
    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED')
        const record = await BranchService.find(id)
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/branch')
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED')
        console.log('SERVICE', values)
        await BranchService.create(values)
        commit('CREATE_SUCCESS')
        Message.success(i18n('branches.create.success'))
        router.push('/branch')
      } catch (error) {
        Errors.handle(error)
        commit('CREATE_ERROR')
      }
    },

    async doUpdate({ commit }, { id, ...values }) {
      try {
        commit('UPDATE_STARTED')
        console.log('UPDATE_VALUES', values)
        await BranchService.update(id, values)
        commit('UPDATE_SUCCESS')

        Message.success(i18n('branches.update.success'))
        router.push('/branch')
      } catch (error) {
        console.log('UPDATE_ERROR', error)
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    }
  }
}
