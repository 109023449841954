<template>
  <div>
    <div class="intro-y flex px-5 flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('restaurants.title') }}</h2>
      <div
        class="btn btn-primary mt-6 sm:mt-2 mx-3 w-full sm:w-28 p-1"
        :class="isRTL ? 'lg:mr-auto' : 'lg:ml-auto'"
        @click="openCreateRestaurant()"
      >
        <PlusIcon class="mr-2" /> {{ i18n('common.create') }}
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box dark:bg-dark-8 p-5 sm:mt-5">
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <div class="overflow-x-auto g">
        <table class="table table-report sm:mt-2 hidden md:table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('restaurants.table.name') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('restaurants.table.logo') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('restaurants.table.description') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('restaurants.table.location') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('restaurants.table.phoneNumber') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('restaurants.table.openHours') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('common.table.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" :key="index">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ index + 1 }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row, 'name') }}
                </div>
              </td>
              <td class="text-center flex items-center justify-center">
                <app-list-item-image
                  :value="row.logo"
                  type="user"
                ></app-list-item-image>
              </td>
              <td class="text-center">
                <div
                  class="font-medium whitespace-nowrap truncate truncate w-72"
                >
                  {{ presenterLocalization(row, 'description') }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row, 'location') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap truncate">
                  {{ row.phoneNumber }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap truncate">
                  {{ presenterLocalization(row, 'openHours') }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div
                  class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer"
                >
                  <!-- <div @click="viewRestaurant()">
                            <EyeIcon class="w-4 h-4 mr-1" />
                          </div> -->
                  <div
                    @click="editRestaurant(row.id)"
                    class="text-theme-14 dark:text-white ml-2"
                  >
                    <EditIcon class="w-4 h-4 mr-1" />
                  </div>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    class="text-theme-24 ml-2"
                    @click="deleteId = row.id"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mobile-table md:hidden mt-8">
          <mobile-table-comp
            v-for="(row, index) in rows"
            :key="index"
            :row="row"
            class="md:hidden"
          >
            <div class="field">
              <span>{{ i18n('restaurants.table.name') }} </span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterLocalization(row, 'name') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('restaurants.table.logo') }} :</span>
              <app-list-item-image
                :value="row.logo"
                type="user"
              ></app-list-item-image>
            </div>
            <div class="field">
              <span> {{ i18n('restaurants.table.description') }} :</span>
              <div class="font-medium whitespace-nowrap truncate truncate">
                {{ presenterLocalization(row, 'description') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('restaurants.table.location') }} :</span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterLocalization(row, 'location') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('restaurants.table.phoneNumber') }} :</span>
              <div class="font-medium whitespace-nowrap truncate">
                {{ row.phoneNumber }}
              </div>
            </div>
            <div class="field">
              <span>{{ i18n('restaurants.table.openHours') }} :</span>
              <div class="font-medium whitespace-nowrap truncate">
                {{ presenterLocalization(row, 'openHours') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('common.table.actions') }} :</span>
              <div
                class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer"
              >
                <!-- <div @click="viewRestaurant()">
                            <EyeIcon class="w-4 h-4 mr-1" />
                          </div> -->
                <div
                  @click="editRestaurant(row.id)"
                  class="text-theme-14 dark:text-white ml-2"
                >
                  <EditIcon class="w-4 h-4 mr-1" />
                </div>
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-modal-preview"
                  class="text-theme-24 ml-2"
                  @click="deleteId = row.id"
                >
                  <Trash2Icon class="w-4 h-4 mr-1" />
                </a>
              </div>
            </div>
          </mobile-table-comp>
        </div>
        <app-empty-page
          v-if="!rows.length && !loading"
          :label="i18n('empty.restaurant')"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
      <Pagination
        v-if="rows.length"
        :page="currentPage"
        :pageSize="pagination.limit"
        :rowsNumber="pagination.total"
        :showSizeChanger="true"
        :isFirstPage="isFirstPage"
        :isLastPage="isLastPage"
        :disabled="!rows.length"
        position="center"
        @next-page="doNextPage"
        @prev-change="doPrevPage"
        @size-change="doChangePaginationPageSize"
      >
      </Pagination>
    </div>
    <!-- END: HTML Table Data -->
    <DeleteModal item="restaurant" @delete="doDestroy(deleteId)" />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import feather from 'feather-icons'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { mapActions, mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.restaurants')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.restaurants')
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'restaurant/list/rows',
      currentPage: 'restaurant/list/currentPage',
      isFirstPage: 'restaurant/list/isFirstPage',
      isLastPage: 'restaurant/list/isLastPage',
      loading: 'restaurant/list/loading',
      pagination: 'restaurant/list/pagination'
    })
  },
  components: {
    DeleteModal
  },
  setup() {
    const router = useRouter()
    const tabulator = ref()

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    const deleteId = ''

    const viewRestaurant = () => {
      router.push('/restaurant-view-page')
    }
    const editRestaurant = (id) => {
      router.push(`/restaurant-form-page/${id}`)
    }

    const openCreateRestaurant = () => {
      router.push('/restaurant-form-page')
    }

    return {
      deleteId,
      openCreateRestaurant,
      editRestaurant,
      viewRestaurant
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'restaurant/list/doFetch',
      doFetchNextPage: 'restaurant/list/doFetchNextPage',
      doFetchPreviousPage: 'restaurant/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'restaurant/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'restaurant/list/doChangePaginationPageSize',
      doDestroy: 'restaurant/list/doDestroy'
    }),
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: {},
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
