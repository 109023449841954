/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlBranch)

export class BranchService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query BRANCHT_LIST(
          $filter: BranchFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          branchList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              name {
                en
                ar
              }
              description {
                en
                ar
              }
              address {
                en
                ar
              }
              images
              backgroundVideo
              thumbnail
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.branchList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query BRANCHT_FIND($id: String!) {
          branchFind(id: $id) {
            id
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            address {
              en
              ar
            }
            images
            backgroundVideo
            thumbnail
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.branchFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation BRANCH_CREATE($data: BranchInput!) {
          branchCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.branchCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation BRANCH_UPDATE($id: String!, $data: BranchInput!) {
          branchUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.branchUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation BRANCHT_DESTROY($id: String!) {
          branchDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.branchDestroy
  }
}
