<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <div class="btn btn-primary" @click="openCreateMatchPage()" :class="isRTL ? 'mr-auto' : 'ml-auto'">
        <PlusIcon class="mr-2" /> {{i18n('common.create')}}
      </div>
    </div>
    <div class="box dark:bg-dark-8 p-5 mt-5" v-for="match in 5" :key="match">
      <div class="flex">
        <div class="home" :class="isRTL ? 'ml-auto' : 'mr-auto'">
          <div class="flex-none image-fit rounded-md overflow-hidden">
            <div class="w-10 h-10 justify-center m-auto">
              <img
                class="rounded-full"
                :src="require(`@/assets/images/profile-1.jpg`)"
              />
            </div>
          </div>
          <div class="mt-2">
            Smouha SC
          </div>
        </div>
        <div class="flex justify-center text-center">
          <div class="middle">
            <div class="result font-bold text-theme-10">
              2-1
            </div>
            <div class="date">
              12/12/2021
            </div>
            <div class="time">
              7:00 PM
            </div>
          </div>
        </div>

        <div class="other" :class="isRTL ? 'mr-auto' : 'ml-auto'">
          <div class="flex-none image-fit rounded-md overflow-hidden">
            <div class="w-10 h-10 justify-center m-auto">
              <img
                class="rounded-full"
                :src="require(`@/assets/images/profile-1.jpg`)"
              />
            </div>
          </div>
          <div class="mt-2">
            Al Ahly SC
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.articles')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    openCreateMatchPage() {
      this.$router.push('/match-form-page')
    }
  }
})
</script>

<style></style>
