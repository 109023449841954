<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="media.title.edit" v-if="isEditing"></app-i18n>
            <app-i18n code="media.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <!-- <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !model"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div> -->
            <form>
              <div>
                <div class="grid grid-cols-12 mt-5 items-center">
                  <h4
                    class="text-lg font-medium leading-none items-center mr-2 col-span-12 lg:col-span-2"
                  >
                    {{ i18n(`media.form.${mediaType}`) }}
                  </h4>
                  <div class="lg:col-span-8 col-span-10 lg:mt-0 mt-2">
                    <Uploader
                      @addedFile="(file) => entries.push(file)"
                      ref="uploaderMedia"
                      :type="mediaType"
                      :id="id"
                    >
                    </Uploader>
                  </div>
                </div>
                <!-- <button
                  type="button"
                  v-if="index == entries.length - 1"
                  class="btn bg-theme-35 text-white w-10 h-10 ml-3 mr-3 cursor-pointer rounded-full absolute top-1/2 lg:top-16 md:right-4 right-0 lg:right-20"
                  @click="removeFields(index)"
                >
                  <XIcon />
                </button> -->
              </div>
              <!-- <div class="flex justify-center items mt-5">
                <button
                  type="button"
                  class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer"
                  @click="addFields"
                >
                  {{ i18n('common.addMore') }}
                </button>
              </div> -->
              <!-- END -->
              <div class="flex justify-center items-center mt-5">
                <AppButton
                  type="button"
                  class="btn btn-primary w-24 cursor-pointer text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer"
                  @click="doCancel"
                >
                  {{ i18n('common.cancel') }}
                </button>
              </div>
            </form>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import Message from '@/shared/message/toastify'
import { mapGetters, mapActions } from 'vuex'
import Uploader from '@/views/media/uploader.vue'

export default {
  components: { Uploader },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.media')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.media')
    }
  },
  props: {
    id: String,
    mediaType: String
  },
  data() {
    return {
      model: null,
      errorMessage: null,
      entries: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'media/form/record',
      findLoading: 'media/form/findLoading',
      saveLoading: 'media/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    }
  },
  async mounted() {
    this.doNew()
    this.entries = []
  },
  methods: {
    ...mapActions({
      doNew: 'media/form/doNew',
      doFind: 'media/form/doFind',
      doCreate: 'media/form/doAddToAlbum'
    }),
    addFields() {
      this.entries.push()
    },
    removeFields(index) {
      this.entries.splice(index, 1)
    },
    isFormValid() {
      if (this.entries.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      return true
    },
    doCancel() {
      this.$router.push('/media')
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      await this.doCreate({
        id: this.id,
        [this.mediaType]: this.entries
      })
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
