/* eslint-disable */

import auctionListStore from '@/store/auction/auction-list-store'
import auctionViewStore from '@/store/auction/auction-view-store'
import auctionFormStore from '@/store/auction/auction-form-store'

export default {
  namespaced: true,

  modules: {
    list: auctionListStore,
    form: auctionFormStore,
    view: auctionViewStore
    // destroy: branchDestroyStore
  }
}
