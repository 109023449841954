/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlLogs)

export class LogsService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query AUDITLOG_LIST(
          $filter: AuditLogListFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          auditLogList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              entityName
              entityId
              action
              timestamp
              createdByEmail
              createdById
              createdBy {
                id
                fullName
              }
              values
            }
          }
        }
      `,
      variables: {
        filter,
        orderBy: 'timestamp',
        pagination
      }
    })

    return response.data.auditLogList
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADS_DESTROY($id: String!) {
          adsDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.adsDestroy
  }
}
