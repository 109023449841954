<template>
  <div class="flex justify-center">
    <div style="width: 100%; height: 30vh" class="flex justify-center" v-if="loading || !record">
      <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
    </div>
    <div class="intro-y news xl:w-3/5 p-5 box dark:bg-dark-8 mt-8" v-else>
      <!-- BEGIN: Blog Layout -->
      <h2 class="intro-y font-medium text-xl sm:text-2xl">
        {{ presenterLocalization(record, 'title') }}
      </h2>
      <div class="intro-y text-gray-700 dark:text-white mt-3 text-xs sm:text-sm">
        {{ presenterDate(record, 'postDate') }}<span class="mx-1">•</span>
        <a class="dark:text-white p-2 text-white" style="border-radius: 20px; background: #d42428" href="">{{
          i18n('auctions.view.startPrice') }}:
          {{ formatPrice(record.reservePrice) }}</a>
      </div>
      <div class="intro-y mt-6">
        <div class="news__preview image-fit">
          <div class="validity">
            {{ i18n('auctions.view.validUntil') }}
            {{ presenterDate(record, 'expiryDate') }}
          </div>
          <img alt="Icewall Tailwind HTML Admin Template" class="rounded-md" :src="
            record.banner || require(`@/assets/images/${$f()[0].images[0]}`)
          " />
        </div>
      </div>
      <div class="intro-y flex relative pt-16 sm:pt-6 items-center pb-6">
        <div class="
                    absolute
                    sm:relative
                    -mt-12
                    sm:mt-0
                    w-full
                    flex
                    text-gray-700
                    dark:text-gray-600
                    text-xs
                    sm:text-sm
                  ">
          <div class="intro-x mr-1 sm:mr-3">
            <!-- Comments:
            <span class="font-medium">{{ $f()[0].totals[0] }}</span> -->
          </div>
          <div class="intro-x mr-1 sm:mr-3">
            {{ i18n('auctions.view.views') }}:
            <span class="font-medium">{{ record.views.length }}</span>
          </div>
          <!-- <div class="intro-x sm:mr-3 ml-auto">
            Likes: <span class="font-medium">{{ $f()[0].totals[2] }}k</span>
          </div> -->
        </div>
        <!-- <Tippy
          tag="a"
          href=""
          class="
            intro-x
            w-8
            h-8
            sm:w-10 sm:h-10
            flex flex-none
            items-center
            justify-center
            rounded-full
            bg-theme-31
            dark:bg-dark-5 dark:text-gray-300
            text-theme-26
            ml-auto
            sm:ml-0
          "
          content="Share"
        >
          <Share2Icon class="w-3 h-3" />
        </Tippy> -->
      </div>
      <div class="intro-y text-justify leading-relaxed">
        <h2 class="text-lg font-medium">
          {{ i18n('auctions.table.description') }}
        </h2>
        <span v-html="record.description.en" v-if="!isRTL"></span>
        <span v-html="record.description.ar" v-else></span>
      </div>
      <div class="intro-y text-justify leading-relaxed my-10">
        <h2 class="text-lg font-medium">
          {{ i18n('auctions.form.termsAndConditions') }}
        </h2>
        <span v-html="record.termsAndConditions.en" v-if="!isRTL"></span>
        <span v-html="record.termsAndConditions.ar" v-else></span>
      </div>
      <div class="
                  intro-y
                  flex
                  text-xs
                  sm:text-sm
                  flex-col
                  items-start
                  gap-5
                  mt-5
                  pt-5
                  border-t border-gray-200
                  dark:border-dark-5
                ">
        <h2 class="text-lg font-medium">
          {{ i18n('auctions.form.tcTerms') }}
        </h2>
        <div class="relative w-full mb-10 mt-5">
          <iframe :src="record.TCTerms" class="object-cover object-center" style="width: 100%; height: 350px" />
          <a :href="record.TCTerms" target="_black" class="
                      delete-icon
                      w-8
                      h-8
                      flex
                      items-center
                      justify-center
                      absolute
                      bottom-2
                      rounded
                      right-8
                      cursor-pointer
                    " style="background: #f0f4f8">
            <EyeIcon class="w-4 h-4" />
          </a>
        </div>
        <button class="btn btn-secondary shadow-md flex items-center px-4 self-center" @click="$router.push('/auction')">
          {{ i18n('common.back') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.auctions')
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.auctions')
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'auction/view/record',
      loading: 'auction/view/loading'
    })
  },
  async mounted() {
    await this.doFind(this.id)
    console.log(this.record)
  },
  methods: {
    ...mapActions({
      doFind: 'auction/view/doFind'
    }),
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} جنية` : `${PRICE} EGP`
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style scoped>
.validity {
  position: absolute !important;
  z-index: 20 !important;
  background: #d42428 !important;
  padding: 15px !important;
  border-bottom-right-radius: 20px !important;
  color: white !important;
}
</style>
