<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('users.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    >
    </TableFilter>
    <div class="intro-y box dark:bg-dark-8 p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end sm:justify-end">
        <div class="flex mt-5 sm:mt-0">
          <button
            class="bg-theme-32 btn btn-primary sm:mt-2 mx-3 w-full sm:w-28 p-2"
            @click="$router.push('/admins/new')"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
        </div>
      </div>
      <div class="overflow-x-auto g relative">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <table
          class="table table-report sm:mt-2 hidden md:table"
          id="adminTable"
          ref="tableRef"
        >
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>

              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.username') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.email') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.phoneNumber') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.role') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.joinDate') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('users.table.status') }}
              </th>
              <th class="text-center whitespace-nowrap" id="ignore-2">
                {{ i18n('common.table.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" :key="index">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ index + 1 }}
                </div>
              </td>
              <td class="text-center">
                <div
                  @click="goToUser(row.id)"
                  class="font-medium whitespace-nowrap truncate cursor-pointer"
                >
                  {{ presenter(row, 'fullName') }}
                </div>
              </td>
              <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap truncate">
                  {{ presenter(row, 'email') }}
                </div>
              </td>
              <td class="text-center">
                <div
                  class="font-medium whitespace-nowrap truncate"
                  style="direction: ltr !important"
                >
                  {{ presenter(row, 'phoneNumber') }}
                </div>
              </td>
              <td class="text-center">
                <div
                  class="font-medium whitespace-nowrap text-theme-10 dark:text-theme-30"
                  style="direction: ltr !important"
                >
                  {{ presenterArr(row, 'roles') }}
                </div>
              </td>
              <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap truncate">
                  {{ presenterDate(row, 'createdAt') }}
                </div>
              </td>
              <td class="w-40">
                <div
                  class="flex items-center justify-center whitespace-nowrap"
                  :class="{
                    'text-theme-24': row.disabled,
                    'text-theme-10': !row.disabled
                  }"
                >
                  <CheckSquareIcon class="w-4 h-4 mr-2 ml-2" />
                  {{
                    !row.disabled
                      ? i18n('common.activated')
                      : i18n('common.disabled')
                  }}
                </div>
              </td>
              <td class="table-report__action w-56" id="ignore-4">
                <div
                  class="flex justify-center items-center text-theme-24 gap-3 cursor-pointer"
                >
                  <router-link
                    class="text-theme-21 dark:text-theme-30 flex items-center gap-1 whitespace-nowrap"
                    :to="`/admins/${row.id}`"
                  >
                    <EyeIcon class="w-4 h-4" />
                  </router-link>
                  <router-link
                    class="text-theme-21 dark:text-theme-30 flex items-center gap-1 whitespace-nowrap"
                    :to="`admins/${row.id}/edit`"
                  >
                    <EditIcon class="w-4 h-4" />
                  </router-link>
                  <a
                    v-if="row.disabled"
                    href="javascript:;"
                    data-toggle="modal"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md text-theme-10"
                    data-target="#activate-modal-preview"
                    @click="doEnable(row.id)"
                  >
                    <UnlockIcon class="w-4 h-4 mr-1 ml-1" />
                  </a>
                  <a
                    v-if="!row.disabled"
                    href="javascript:;"
                    data-toggle="modal"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    data-target="#disable-modal-preview"
                    @click="doDisable(row.id)"
                  >
                    <LockIcon class="w-4 h-4 mr-1 ml-1" />
                  </a>
                  <!-- <a
                                          href="javascript:;"
                                          data-toggle="modal"
                                          data-target="#delete-modal-preview"
                                          class="flex items-center whitespace-nowrap"
                                          @click="selectedId = row.id"
                                        >
                                          <Trash2Icon class="w-4 h-4 mr-1" />{{
                                            i18n('common.delete')
                                          }}
                                        </a> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mobile-table md:hidden mt-8">
          <mobile-table-comp
            v-for="(row, index) in rows"
            :key="index"
            :row="row"
            class="md:hidden"
          >
            <div class="field">
              <span>{{ i18n('users.table.username') }} </span>
              <div
                @click="goToUser(row.id)"
                class="font-medium whitespace-nowrap truncate cursor-pointer"
              >
                {{ presenter(row, 'fullName') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('users.table.email') }} :</span>
              <div href="" class="font-medium whitespace-nowrap truncate">
                {{ presenter(row, 'email') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('users.table.phoneNumber') }} :</span>
              <div
                class="font-medium whitespace-nowrap truncate"
                style="direction: ltr !important"
              >
                {{ presenter(row, 'phoneNumber') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('users.table.role') }} :</span>
              <div
                class="font-medium whitespace-nowrap text-theme-10 dark:text-theme-30"
                style="direction: ltr !important"
              >
                {{ presenterArr(row, 'roles') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('users.table.joinDate') }} :</span>
              <div href="" class="font-medium whitespace-nowrap truncate">
                {{ presenterDate(row, 'createdAt') }}
              </div>
            </div>
            <div class="field">
              <span>{{ i18n('users.table.status') }} :</span>
              <div
                class="flex items-center justify-center whitespace-nowrap"
                :class="{
                  'text-theme-24': row.disabled,
                  'text-theme-10': !row.disabled
                }"
              >
                <CheckSquareIcon class="w-4 h-4 mr-2 ml-2" />
                {{
                  !row.disabled
                    ? i18n('common.activated')
                    : i18n('common.disabled')
                }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('common.table.actions') }} :</span>
              <div
                class="flex justify-center items-center text-theme-24 gap-3 cursor-pointer"
              >
                <router-link
                  class="text-theme-21 dark:text-theme-30 flex items-center gap-1 whitespace-nowrap"
                  :to="`/admins/${row.id}`"
                >
                  <EyeIcon class="w-4 h-4" />
                </router-link>
                <router-link
                  class="text-theme-21 dark:text-theme-30 flex items-center gap-1 whitespace-nowrap"
                  :to="`admins/${row.id}/edit`"
                >
                  <EditIcon class="w-4 h-4" />
                </router-link>
                <a
                  v-if="row.disabled"
                  href="javascript:;"
                  data-toggle="modal"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md text-theme-10"
                  data-target="#activate-modal-preview"
                  @click="doEnable(row.id)"
                >
                  <UnlockIcon class="w-4 h-4 mr-1 ml-1" />
                </a>
                <a
                  v-if="!row.disabled"
                  href="javascript:;"
                  data-toggle="modal"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  data-target="#disable-modal-preview"
                  @click="doDisable(row.id)"
                >
                  <LockIcon class="w-4 h-4 mr-1 ml-1" />
                </a>
              </div>
            </div>
          </mobile-table-comp>
        </div>
        <app-empty-page
          v-if="!rows.length && !loading"
          :label="i18n('empty.admin')"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
      <Pagination
        v-if="rows.length"
        :page="currentPage"
        :pageSize="pagination.limit"
        :rowsNumber="pagination.total"
        :showSizeChanger="true"
        :isFirstPage="isFirstPage"
        :isLastPage="isLastPage"
        :disabled="!rows.length"
        position="center"
        @next-page="doNextPage"
        @prev-change="doPrevPage"
        @size-change="doChangePaginationPageSize"
      >
      </Pagination>
    </div>
    <!-- END: HTML Table Data -->
    <!-- START: Delete Modal -->
    <DeleteModal item="user" @delete="doDestroy(selectedId)" />
    <ActivateModal item="user" />
    <DisableModal item="user" />
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import feather from 'feather-icons'
import DeleteModal from '@/components/modals/delete-modal.vue'
import ActivateModal from '@/components/modals/activate-modal.vue'
import DisableModal from '@/components/modals/disable-modal.vue'
import { GenericModel } from '@/shared/model/generic-model'
import { mapActions, mapGetters } from 'vuex'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'

export default defineComponent({
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    if (!this.currentUser.roles.includes('owner')) {
      this.$router.push('/404')
    }
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    }
  },
  components: {
    DeleteModal,
    ActivateModal,
    DisableModal
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'user/list/rows',
      currentPage: 'user/list/currentPage',
      isFirstPage: 'user/list/isFirstPage',
      isLastPage: 'user/list/isLastPage',
      loading: 'user/list/loading',
      pagination: 'user/list/pagination',
      currentUser: 'auth/currentUser'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    filterFields() {
      return [
        {
          label: this.i18n('users.table.username'),
          value: 'name',
          type: 'text'
        },
        {
          label: this.i18n('users.table.email'),
          value: 'email',
          type: 'text'
        }
      ]
    },
    currentPage() {
      return this.pagination.currentPage || 1
    }
  },
  setup() {
    const filterInput = []
    const tableLimit = ref(10)
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectedId = ref('')
    const selectAll = ref(false)
    const filter = reactive({
      field: 'username',
      value: ''
    })

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'username'
      filter.value = ''
      onFilter()
    }

    // Export
    // const onExportCsv = () => {
    //   tabulator.value.download('csv', 'data.csv')
    // }

    // const onExportJson = () => {
    //   tabulator.value.download('json', 'data.json')
    // }

    // const onExportXlsx = () => {
    //   const win = window
    //   win.XLSX = xlsx
    //   tabulator.value.download('xlsx', 'data.xlsx', {
    //     sheetName: 'Products'
    //   })
    // }

    // const onExportHtml = () => {
    //   tabulator.value.download('html', 'data.html', {
    //     style: true
    //   })
    // }

    // Print
    // const onPrint = () => {
    //   tabulator.value.print()
    // }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      selected,
      selectedId,
      selectAll,
      tableLimit,
      tableRef,
      filter,
      filterInput,
      onFilter,
      onResetFilter
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
    console.log(this.rows)
  },
  methods: {
    ...mapActions({
      doFetch: 'user/list/doFetch',
      doFetchNextPage: 'user/list/doFetchNextPage',
      doFetchPreviousPage: 'user/list/doFetchPreviousPage',
      doChangePaginationCurrentPage: 'user/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'user/list/doChangePaginationPageSize',
      doEnable: 'user/list/doEnable',
      doDisable: 'user/list/doDisable',
      doDestroy: 'user/destroy/doDestroy'
    }),
    async doFilter(filter) {
      this.filterInput = {}
      if (!filter) return
      this.filterInput = filter
      await this.doFetchFirstPage()
    },
    async doResetFilter() {
      this.filterInput = {}
      await this.doFetchFirstPage()
    },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: this.filterInput,
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    async doEnableAllSelected() {
      await this.doEnableAll(this.selected)
      this.clearSelection()
    },
    async doDisableAllSelected() {
      await this.doDisableAll(this.selected)
      this.clearSelection()
    },
    async doDestroyAllSelected() {
      if (this.selected && this.selected.length === 0) {
        await this.doDestroy(this.selectedId)
      } else {
        console.log(this.selected)
        await this.doDestroyAll(this.selected)
        this.clearSelection()
      }
    },
    clearSelection() {
      this.selected = []
      this.selectAll = false
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterArr(row, fieldName) {
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].join(', ')
        : 'ـــ'
    },
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach((element) => {
          this.selected.push(element.id)
        })
      }
    },
    goToUser(id) {
      this.$router.push(`/admins/${id}`)
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'adminTable', 'admins')
    },
    onDownloadPdf() {
      $h.downloadPdf('#adminTable', 'admin', [1, 2, 8, 9])
    },
    onPrint() {
      print({
        printable: 'adminTable',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 300,
        ignoreElements: ['ignore-1', 'ignore-2', 'ignore-3', 'ignore-4']
      })
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
