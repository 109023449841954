/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlAds)

export class AdsService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query ADS_LIST(
          $filter: AdsFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          adsList(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              title {
                en
                ar
              }
              description {
                en
                ar
              }
              endDate
              appearIn
              type
              views
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.adsList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query ADS_FIND($id: String!) {
          adsFind(id: $id) {
            id
            title {
              en
              ar
            }
            description {
              en
              ar
            }
            endDate
            appearIn
            link
            position
            image
            type
            views
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.adsFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADS_CREATE($data: AdsInput!) {
          adsCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.adsCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADS_UPDATE($id: String!, $data: AdsInput!) {
          adsUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.adsUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADS_DESTROY($id: String!) {
          adsDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.adsDestroy
  }
}
