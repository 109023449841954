<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="administration.directors.title.edit" v-if="isEditing"></app-i18n>
            <app-i18n code="administration.directors.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div class="flex justify-center" style="width: 100%; height: 80vh" v-if="findLoading || !model">
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                      text-lg
                      font-medium
                      leading-none
                      items-center
                      mr-2
                      lg:col-span-2
                    ">
                  {{ i18n('administration.form.name') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input id="title-en" type="text" class="
                        form-control
                        w-full
                        dark:bg-dark-9 dark:placeholder-white
                      " v-model="model.name.en" :placeholder="i18n('common.english')" />
                </div>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2" :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'">
                  <input id="title-ar" type="text" class="
                        form-control
                        w-full
                        dark:bg-dark-9 dark:placeholder-white
                      " v-model="model.name.ar" :placeholder="i18n('common.arabic')" />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                      text-lg
                      font-medium
                      leading-none
                      items-center
                      mr-2
                      lg:col-span-2
                    ">
                  {{ i18n('administration.form.jobTitle') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input id="title-en" type="text" class="
                        form-control
                        w-full
                        dark:bg-dark-9 dark:placeholder-white
                      " v-model="model.jobTitle.en" :placeholder="i18n('common.english')" />
                </div>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2" :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'">
                  <input id="title-ar" type="text" class="
                        form-control
                        w-full
                        dark:bg-dark-9 dark:placeholder-white
                      " v-model="model.jobTitle.ar" :placeholder="i18n('common.arabic')" />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                      text-lg
                      font-medium
                      leading-none
                      items-center
                      mr-2
                      lg:col-span-2
                    ">
                  {{ i18n('administration.form.order') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input id="crud-form-1" type="number" class="form-control w-full dark:placeholder-white"
                    v-model="model.order" :placeholder="i18n('administration.form.orderPlaceholder')" />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                      text-lg
                      font-medium
                      leading-none
                      items-center
                      mr-2
                      lg:col-span-2
                    ">
                  {{ i18n('administration.form.facebookUrl') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input id="crud-form-1" type="text" class="form-control w-full dark:placeholder-white"
                    v-model="model.facebookUrl" :placeholder="i18n('administration.form.facebookUrl')" />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                      text-lg
                      font-medium
                      leading-none
                      items-center
                      mr-2
                      lg:col-span-2
                    ">
                  {{ i18n('administration.form.instagramUrl') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input id="crud-form-1" type="text" class="form-control w-full dark:placeholder-white"
                    v-model="model.instagramUrl" :placeholder="i18n('administration.form.instagramUrl')" />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                      text-lg
                      font-medium
                      leading-none
                      items-center
                      mr-2
                      lg:col-span-2
                    ">
                  {{ i18n('administration.form.twitterUrl') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input id="crud-form-1" type="text" class="form-control w-full dark:placeholder-white"
                    v-model="model.twitterUrl" :placeholder="i18n('administration.form.twitterUrl')" />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                      text-lg
                      font-medium
                      leading-none
                      items-center
                      mr-2
                      lg:col-span-2
                    ">
                  {{ i18n('administration.form.avatar') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <div class="
                        w-20
                        h-20
                        sm:w-24 sm:h-24
                        flex-none
                        lg:w-32 lg:h-32
                        image-fit
                        relative
                        m-auto
                        cursor-pointer
                      ">
                    <div>
                      <img alt="Icewall Tailwind HTML Admin Template" class="rounded-full" :src="
                        model.avatar
                          ? model.avatar?.publicUrl ||
                          '/images/default-avatar.svg'
                          : '/images/default-avatar.svg'
                      " />
                      <div class="
                            absolute
                            mb-1
                            mr-1
                            flex
                            items-center
                            justify-center
                            bottom-0
                            right-0
                            bg-theme-17
                            rounded-full
                            p-2
                          ">
                        <CameraIcon class="w-4 h-4 text-white" />
                      </div>
                    </div>
                    <input type="file" id="profileImage" name="upload" accept="image/png, image/jpeg, image/jpg"
                      style="position: absolute; top: 0%; opacity: 0 !important" class="
                          cursor-pointer
                          w-20
                          h-20
                          sm:w-24 sm:h-24
                          flex-none
                          lg:w-32 lg:h-32
                          image-fit
                          relative
                          m-auto
                        " @input="emitEvent($event)" />
                  </div>
                </div>
              </div>
              <!-- END -->
              <div class="flex justify-center items-center mt-10">
                <AppButton type="button" class="
                      btn btn-primary
                      w-24
                      cursor-pointer
                      text-white
                      cursor-pointer
                    " :class="!saveLoading ? 'w-24' : ''" :disabled="saveLoading || findLoading"
                  :loading="saveLoading || uploadLoading" @click="doSubmit()">
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px" />
                  </template>
                </AppButton>
                <button type="button" class="
                      btn
                      bg-theme-31
                      text-black
                      w-24
                      ml-3
                      mr-3
                      cursor-pointer
                    " @click="doCancel">
                  {{ i18n('common.cancel') }}
                </button>
              </div>
            </form>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import Message from '@/shared/message/toastify'
import { mapGetters, mapActions } from 'vuex'
import { FileUploader } from '@/shared/uploader/file-uploader'
import { collection, doc, getFirestore } from 'firebase/firestore'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.administration')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.administration')
    }
  },
  props: {
    id: String
  },
  data() {
    return {
      model: null,
      errorMessage: null,
      imageUrl: '',
      uploadLoading: false,
      image: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      saveLoading: 'administration/form/saveLoading',
      record: 'administration/form/record',
      findLoading: 'administration/form/findLoading'
    }),
    isEditing() {
      return !!this.id
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    this.model = this.record || {
      order: undefined,
      avatar: {
        name: '',
        publicUrl: ''
      },
      name: {
        en: '',
        ar: ''
      },
      jobTitle: {
        en: '',
        ar: ''
      },
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: ''
    }
  },
  methods: {
    ...mapActions({
      doNew: 'administration/form/doNew',
      doCreate: 'administration/form/doCreate',
      doFind: 'administration/form/doFind',
      doUpdate: 'administration/form/doUpdate'
    }),
    isFormValid() {
      if (this.model.name.en.length == 0 || this.model.name.ar.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.jobTitle.en.length == 0 ||
        this.model.jobTitle.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.order.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.avatar.publicUrl.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      return true
    },
    doCancel() {
      this.$router.push('/administration')
    },
    emitEvent(event) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (e) => {
        const image = {
          name: event.target.files[0].name,
          size: event.target.files[0].size,
          lastModifiedDate: event.target.files[0].lastModifiedDate,
          base64: reader.result
        }
        this.image = image
        const file = event.target.files[0]
        this.imageUrl = URL.createObjectURL(file)
        this.model.avatar.publicUrl = this.imageUrl
      }
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      if (this.image) {
        this.uploadLoading = true
        const profileImage = document.getElementById('profileImage').files[0]
        const id = doc(collection(getFirestore(), 'ids')).id
        const path = `administration/directors/${id}`
        const { publicUrl, name } = await FileUploader.upload(
          path,
          profileImage,
          'directorPhoto'
        )
        this.model.avatar = {
          name,
          publicUrl
        }
        this.uploadLoading = false
      }
      const {
        name,
        order,
        avatar,
        jobTitle,
        facebookUrl,
        instagramUrl,
        twitterUrl
      } = this.model
      if (this.isEditing) {
        await this.doUpdate({
          id: this.id,
          name,
          order,
          avatar,
          jobTitle,
          facebookUrl,
          instagramUrl,
          twitterUrl
        })
      } else {
        await this.doCreate({
          name,
          order,
          avatar,
          jobTitle,
          facebookUrl,
          instagramUrl,
          twitterUrl
        })
      }
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {

  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
