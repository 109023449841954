/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlDiscounts)

export class DiscountService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query DISCOUNTS_LIST(
          $filter: DiscountsFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          discountsList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              name {
                en
                ar
              }
              description {
                en
                ar
              }
              addresses {
                en
                ar
              }
              pohoneNumbers
              offers
              banner
              logo
              logo_thumbnail
              type
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.discountsList
  }

  static async find(id) {
    // graphqlClient.cache.reset()
    const response = await graphqlClient.query({
      query: gql`
        query DISCOUNTS_FIND($id: String!) {
          discountsFind(id: $id) {
            id
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            addresses {
              en
              ar
            }
            branchId
            pohoneNumbers
            offers
            banner
            logo
            logo_thumbnail
            type
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id
      }
    })
    return response.data.discountsFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation DISCOUNTS_CREATE($data: DiscountsInput!) {
          discountsCreate(data: $data) {
            id
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.discountsCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation DISCOUNTS_UPDATE($id: String!, $data: DiscountsInput!) {
          discountsUpdate(id: $id, data: $data) {
            id
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.discountsUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation DISCOUNTS_DESTROY($id: String!) {
          discountsDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.discountsDestroy
  }
}
