<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n
              code="culturalService.title.edit"
              v-if="isEditing"
            ></app-i18n>
            <app-i18n code="culturalService.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !model"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('ads.fields.description') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    id="description-en"
                    :rows="4"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.description.en"
                    :placeholder="i18n('restaurants.fields.english')"
                  ></textarea>
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <textarea
                    id="description-ar"
                    :rows="4"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.description.ar"
                    :placeholder="i18n('restaurants.fields.arabic')"
                  ></textarea>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('branches.fields.branch') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 items-center"
                >
                  <v-select
                    :options="branchOptions || []"
                    :label="labelLocalized"
                    v-model="model.branch"
                    class="select-center"
                    title="Select item"
                  >
                    <template #no-options
                      ><div class="loading-loader"></div> </template
                  ></v-select>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('restaurants.fields.banner') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && bannerUrl">
                    <img
                      :src="bannerUrl"
                      class="object-cover object-center"
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="
                        delete-icon
                        w-12
                        h-12
                        absolute
                        bottom-0
                        left-0
                        cursor-pointer
                      "
                      @click="model.banner = undefined"
                    />
                  </div>
                  <Uploader ref="uploaderBanner" v-else></Uploader>
                </div>
              </div>
              <!-- END -->
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="
                    btn btn-primary
                    w-24
                    cursor-pointer
                    text-white
                    cursor-pointer
                  "
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="
                    btn
                    bg-theme-31
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  "
                  @click="doCancel"
                >
                  {{ i18n('common.cancel') }}
                </button>
              </div>
            </div>
          </section>
          <!-- END: Form Layout -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import Uploader from '@/views/cultural-service/uploader.vue'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.culturalService')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.culturalService')
    }
  },
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      model: null,
      branchOptions: [],
      errorMessage: null
    }
  },
  components: {
    Uploader
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      branches: 'branch/list/rows',
      record: 'cultural/form/record',
      findLoading: 'cultural/form/findLoading',
      saveLoading: 'cultural/form/saveLoading'
    }),
    labelLocalized() {
      return this.isRTL ? 'nameAr' : 'nameEn'
    },
    isEditing() {
      return !!this.id
    },
    filesToSaveBanner() {
      return this.$refs.uploaderBanner?.filesToSave || []
    },
    bannerUrl() {
      return this.model && this.model.banner ? this.model.banner : null
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    await this.doFetchBranches({
      filter: {},
      pagination: {
        limit: 10,
        sortBy: 'asc'
      }
    })
    this.branchOptions = this.branches.map((branch) => ({
      ...branch,
      nameAr: branch.name.ar,
      nameEn: branch.name.en
    }))
    this.model = this.record || {
      description: {
        en: '',
        ar: ''
      },
      branch: '',
      banner: ''
    }
    if (this.isEditing) {
      this.model.branch = this.branchOptions.filter(
        (branch) => branch.id === this.record.branchId
      )[0]
    }
  },
  methods: {
    ...mapActions({
      doNew: 'cultural/form/doNew',
      doFetchBranches: 'branch/list/doFetch',
      doFind: 'cultural/form/doFind',
      doCreate: 'cultural/form/doCreate',
      doUpdate: 'cultural/form/doUpdate'
    }),
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const { branch, description } = this.model
      if (this.isEditing) {
        await this.doUpdate({
          id: this.id,
          branchId: branch.id,
          description,
          banner: this.bannerUrl || this.filesToSaveBanner[0]
        })
      } else {
        await this.doCreate({
          branchId: branch.id,
          description,
          banner: this.filesToSaveBanner[0]
        })
      }
    },
    doCancel() {
      this.$router.push('/cultural')
    },
    isFormValid() {
      if (
        this.model.description.en.length == 0 ||
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      // if (!this.model.branch?.id?.length !== 0) {
      //   this.errorMessage = 'restaurants.edit.errors.emptyFields'
      //   return false
      // }
      if (this.isEditing) {
        if (!(this.bannerUrl || this.filesToSaveBanner.length !== 0)) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      } else {
        if (this.filesToSaveBanner.length === 0) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      }
      return true
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
