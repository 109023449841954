<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <div class="btn btn-primary" :class="isRTL ? 'mr-auto' : 'ml-auto '" @click="openCreatePlayerPage()">
        <PlusIcon class="mr-2" /> {{i18n('common.create')}}
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y"
        v-for="sport in 10"
        :key="sport"
      >
        <div class="report-box zoom-in">
          <div class="box p-5 dark:bg-dark-2">
            <div class="dropdown" style="position: absolute; right:10px; z-index:20 !important; color: white">
              <button class="dropdown-toggle " aria-expanded="false">
                <div
                  class="dropdown-toggle options cursor-pointer mr-4"
                  role="button"
                  aria-expanded="false"
                >
                  <MoreHorizontalIcon />
                </div>
              </button>
              <div class="dropdown-menu">
                <div
                  class="dropdown-menu__content notificationDropDown box dark:bg-dark-1 p-3 w-10"
                  style="width:150px !important"
                >
                  <div
                    class="notification-content__title mb-2 items-center flex text-theme-21"
                  >
                    <EditIcon class="mx-1" /> {{i18n('common.edit')}}
                  </div>
                  <div
                    class="notification-content__title mb-2 items-center flex text-theme-24"
                  >
                    <Trash2Icon class="mx-1" /> {{i18n('common.delete')}}
                  </div>
                </div>
              </div>
            </div>
            <div @click="goToPlayer()">
              <div class="flex justify-center">
                <img src="/images/png/teams/smouha.jpeg">
              </div>
              <div
                class="text-3xl font-medium leading-8 mt-6 flex justify-center"
              >
                Ahmed Mohammed
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.articles')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    goToPlayer() {
      this.$router.push('/player-view-page')
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    openCreatePlayerPage() {
      this.$router.push('/player-form-page')
    }
  }
})
</script>

<style></style>
