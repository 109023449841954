/* eslint-disable */

import administrationListStore from '@/store/administration/administration-list-store'
import administrationFormStore from '@/store/administration/administration-form-store'
// import administrationViewStore from '@/store/administration/administration-view-store'

export default {
  namespaced: true,

  modules: {
    list: administrationListStore,
    form: administrationFormStore
    // view: administrationViewStore
  }
}
