/* eslint-disable */

import adsListStore from '@/store/ads/ads-list-store'
import adsFormStore from '@/store/ads/ads-form-store'
// import adsViewStore from '@/store/ads/ads-view-store'

export default {
  namespaced: true,

  modules: {
    list: adsListStore,
    form: adsFormStore,
    // view: adsViewStore
  }
}
