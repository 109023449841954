<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('ads.title') }}</h2>
      <div
        class="btn btn-primary mt-6 sm:mt-2 mx-3 w-full sm:w-28 p-1"
        :class="isRTL ? 'mr-auto' : 'ml-auto'"
        @click="openCreateAd()"
      >
        <PlusIcon class="mr-2" /> {{ i18n('common.create') }}
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box dark:bg-dark-8 p-5 mt-5">
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <div class="overflow-x-auto g">
        <table class="table table-report sm:mt-2 hidden md:table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('ads.table.title') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('ads.table.description') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('ads.table.endDate') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('ads.table.appearsOn') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('ads.table.type') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('ads.table.views') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('common.table.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" :key="index">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ index + 1 }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row, 'title') }}
                </div>
              </td>
              <td>
                <div
                  class="font-medium whitespace-nowrap text-center truncate w-72"
                >
                  {{ presenterLocalization(row, 'description') }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterDate(row, 'endDate') }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenter(row, 'appearIn') }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenter(row, 'type') }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ row.views.length }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div
                  class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer gap-3"
                >
                  <!-- <div @click="viewAd(row.id)">
                            <EyeIcon class="w-4 h-4 mr-1" />
                          </div> -->
                  <div @click="editAds(row.id)">
                    <EditIcon class="w-4 h-4 mr-1" />
                  </div>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    class="text-theme-24 ml-2"
                    @click="deleteId = row.id"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mobile-table md:hidden mt-8">
          <mobile-table-comp
            v-for="(row, index) in rows"
            :key="index"
            :row="row"
            class="md:hidden"
          >
            <div class="field">
              <span> {{ i18n('ads.table.title') }} </span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterLocalization(row, 'title') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('ads.table.description') }} </span>
              <div class="font-medium whitespace-nowrap text-center truncate">
                {{ presenterLocalization(row, 'description') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('ads.table.endDate') }} </span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterDate(row, 'endDate') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('ads.table.appearsOn') }} </span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenter(row, 'appearIn') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('ads.table.type') }} </span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenter(row, 'type') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('ads.table.views') }} :</span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ row.views.length }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('common.table.actions') }} :</span>
              <div
                class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer gap-3"
              >
                <div @click="editAds(row.id)">
                  <EditIcon class="w-4 h-4 mr-1" />
                </div>
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-modal-preview"
                  class="text-theme-24 ml-2"
                  @click="deleteId = row.id"
                >
                  <Trash2Icon class="w-4 h-4 mr-1" />
                </a>
              </div>
            </div>
          </mobile-table-comp>
        </div>
        <app-empty-page
          v-if="!rows.length && !loading"
          :label="i18n('empty.ads')"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
      <Pagination
        v-if="rows.length"
        :page="currentPage"
        :pageSize="pagination.limit"
        :rowsNumber="pagination.total"
        :showSizeChanger="true"
        :isFirstPage="isFirstPage"
        :isLastPage="isLastPage"
        :disabled="!rows.length"
        position="center"
        @next-page="doNextPage"
        @prev-change="doPrevPage"
        @size-change="doChangePaginationPageSize"
      >
      </Pagination>
    </div>
    <!-- END: HTML Table Data -->
    <DeleteModal item="Ad" @delete="doDestroy(deleteId)" />
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { mapActions, mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.ads')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.ads')
    }
  },
  components: {
    DeleteModal
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'ads/list/rows',
      currentPage: 'ads/list/currentPage',
      isFirstPage: 'ads/list/isFirstPage',
      isLastPage: 'ads/list/isLastPage',
      loading: 'ads/list/loading',
      pagination: 'ads/list/pagination'
    })
  },
  setup() {
    const router = useRouter()
    const deleteId = ''

    const editAds = (id) => {
      router.push(`/ad-form-page/${id}`)
    }

    return {
      deleteId,
      editAds
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'ads/list/doFetch',
      doFetchNextPage: 'ads/list/doFetchNextPage',
      doFetchPreviousPage: 'ads/list/doFetchPreviousPage',
      doChangePaginationCurrentPage: 'ads/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'ads/list/doChangePaginationPageSize',
      doDestroy: 'ads/list/doDestroy'
    }),
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: {},
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    viewAd() {
      this.$router.push('/ad-view-page')
    },
    openCreateAd() {
      this.$router.push('/ad-form-page')
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} جنية` : `${PRICE} EGP`
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
