/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlAuction)

export class AuctionService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query AUCTION_LIST(
          $filter: AuctionFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          auctionList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              banner
              title {
                en
                ar
              }
              description {
                en
                ar
              }
              termsAndConditions {
                en
                ar
              }
              postDate
              expiryDate
              reservePrice
              TCTerms
              status
              views
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.auctionList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query AUCTION_FIND($id: String!) {
          auctionFind(id: $id) {
            id
            banner
            title {
              en
              ar
            }
            description {
              en
              ar
            }
            termsAndConditions {
              en
              ar
            }
            postDate
            expiryDate
            reservePrice
            TCTerms
            branchId
            status
            views
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.auctionFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUCTION_CREATE($data: AuctionInput!) {
          auctionCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.auctionCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUCTION_UPDATE($id: String!, $data: AuctionInput!) {
          auctionUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.auctionUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUCTION_DESTROY($id: String!) {
          auctionDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.auctionDestroy
  }
}
