<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('others.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box dark:bg-dark-8 px-5 pt-5 mt-5">
      <div
        class="nav nav-tabs flex-wrap flex-row justify-center lg:justify-start gap-3 sm:gap-0"
        role="tablist"
      >
        <!-- <a
                                                        id="clients-tab"
                                                        data-toggle="tab"
                                                        data-target="#clients"
                                                        href="javascript:;"
                                                        class="py-4 sm:mr-8"
                                                        role="tab"
                                                        aria-selected="false"
                                                        @click="tab = 'sports'"
                                                      >
                                                        {{i18n('sports.title')}}
                                                      </a> -->
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'branches'"
        >
          {{ i18n('branches.title') }}
        </a>
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'restaurants'"
        >
          {{ i18n('restaurants.title') }}
        </a>
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'sponsors'"
        >
          {{ i18n('sponsors.title') }}
        </a>
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'socials'"
        >
          {{ i18n('socials.title') }}
        </a>
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'auctions'"
        >
          {{ i18n('auctions.title') }}
        </a>
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'library'"
        >
          {{ i18n('library.title') }}
        </a>
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'clinics'"
        >
          {{ i18n('clinic.title.title') }}
        </a>
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'telecom'"
        >
          {{ i18n('telecom.title') }}
        </a>
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'kindergarten'"
        >
          {{ i18n('kindergarten.title') }}
        </a>
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'discounts'"
        >
          {{ i18n('discounts.title') }}
        </a>
        <a
          id="clients-tab"
          data-toggle="tab"
          data-target="#clients"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'nonAthletes'"
        >
          {{ i18n('nonAthletes.title.title') }}
        </a>

        <a
          id="tags-tab"
          data-toggle="tab"
          data-target="#tags"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'contactUs'"
        >
          {{ i18n('contactUs.title.title') }}
        </a>
        <a
          id="tags-tab"
          data-toggle="tab"
          data-target="#tags"
          href="javascript:;"
          class="py-4 sm:mr-8"
          role="tab"
          aria-selected="false"
          @click="tab = 'parking'"
        >
          {{ i18n('parking.title.title') }}
        </a>
      </div>
    </div>
    <div class="intro-y box sm:p-5 pt-5 mt-5">
      <!-- <Sports v-if="tab == 'sports'" /> -->
      <Branches v-if="tab == 'branches'" :key="isRTL" />
      <Restaurants v-if="tab == 'restaurants'" :key="isRTL" />
      <Auctions v-if="tab == 'auctions'" :key="isRTL" />
      <Library v-if="tab == 'library'" :key="isRTL" />
      <Clinics v-if="tab == 'clinics'" :key="isRTL" />
      <Sponsors v-if="tab == 'sponsors'" :key="isRTL" />
      <Socials v-if="tab == 'socials'" :key="isRTL" />
      <Telecom v-if="tab == 'telecom'" :key="isRTL" />
      <Kindergarten v-if="tab == 'kindergarten'" :key="isRTL" />
      <discounts v-if="tab == 'discounts'" :key="isRTL" />
      <NonAthletes v-if="tab == 'nonAthletes'" :key="isRTL" />
      <categories v-if="tab == 'cat'" :key="isRTL" />
      <parking v-if="tab == 'parking'" :key="isRTL" />
      <contactUs v-if="tab == 'contactUs'" :key="isRTL" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
// import Sports from '@/views/sports/sports.vue'
import Branches from '@/views/branch/branch-list-page.vue'
import Restaurants from '@/views/restaurant/restaurant-list-page.vue'
import Auctions from '@/views/auction/auction-list-page.vue'
import Library from '@/views/library/library-list-page.vue'
import Clinics from '@/views/clinic/clinics-list-page.vue'
import Sponsors from '@/views/sponsors/sponsors-list-page.vue'
import Socials from '@/views/socialMedia/socials-list-page.vue'
import Telecom from '@/views/telecom/telecom-list-page.vue'
import Kindergarten from '@/views/kindergarten/kindergarten-list-page.vue'
import discounts from '@/views/discounts/discounts-list-page.vue'
import NonAthletes from '@/views/nonAthletes/nonAthletes-list-page.vue'
import categories from '@/views/categories/categories-list-page.vue'
import parking from '@/views/parking/parking-list-page.vue'

import contactUs from '@/views/contactUs/contactus-list-page.vue'

// import administration from '@/views/administration/administration-view-page.vue'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.mediaDashboard')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.mediaDashboard')
    }
  },
  components: {
    // Sports,
    Branches,
    Restaurants,
    Auctions,
    Library,
    Clinics,
    Sponsors,
    Socials,
    Telecom,
    Kindergarten,
    discounts,
    NonAthletes,
    categories,
    parking,
    contactUs
    // administration
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  setup() {
    const tab = ref('branches')
    return {
      tab
    }
  },
  methods: {
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach((element) => {
          this.selected.push(element.id)
        })
      }
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}

@media (max-width: 768px) {
  .nav-tabs a {
    width: 90px !important;
  }
}
</style>
