<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        <app-i18n code="articles.form.title.edit" v-if="isEditing"></app-i18n>
        <app-i18n code="articles.form.title.new" v-else></app-i18n>
      </h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0 gap-2">
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton
              class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
            >
              {{
                lang === 'ar'
                  ? i18n('articles.form.languages.arabic')
                  : i18n('articles.form.languages.english')
              }}
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-10 mt-2 w-28 text-center origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="javascript:;"
                    @click="switchLang"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    ]"
                  >
                    {{ i18n('articles.form.languages.english') }}
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="javascript:;"
                    @click="switchLang"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    ]"
                    >{{ i18n('articles.form.languages.arabic') }}</a
                  >
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>

        <AppButton
          type="button"
          class="btn btn-primary w-24 cursor-pointer text-white cursor-pointer"
          :class="!saveLoading ? 'w-24' : ''"
          :id="dis ? 'test' : ``"
          :disabled="saveLoading || findLoading"
          :loading="saveLoading"
          @click="doSubmit()"
        >
          <strong>{{ i18n('common.save') }}</strong>
          <template v-slot:loading>
            <app-i18n code="common.loading"></app-i18n>
            <LoadingIcon
              icon="three-dots"
              color="#FFFFFF"
              style="margin: 0 4px"
            />
          </template>
        </AppButton>
        <!-- <div class="dropdown">
          <button
            class="dropdown-toggle btn btn-primary shadow-md flex items-center"
            aria-expanded="false"
            @click="doSubmit"
          >
            {{ i18n('common.save') }}
          </button>
        </div> -->
      </div>
    </div>
    <div
      class="flex justify-center"
      style="width: 100%; height: 80vh"
      v-if="findLoading || !model"
    >
      <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
    </div>
    <div class="pos intro-y grid grid-cols-12 gap-5 mt-5" v-if="model">
      <!-- BEGIN: Post Content -->
      <div class="intro-y col-span-12 lg:col-span-8">
        <input
          type="text"
          class="intro-y form-control py-3 px-4 box dark:bg-dark-8 pr-10 placeholder-theme-8 dark:placeholder-white"
          :placeholder="i18n('articles.form.titlePlaceholder')"
          v-model="title"
        />
        <div class="post intro-y overflow-hidden box dark:bg-dark-8 mt-5">
          <div
            class="post__tabs nav nav-tabs flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600"
            role="tablist"
          >
            <Tippy
              id="content-tab"
              tag="a"
              content="Fill in the article content"
              data-toggle="tab"
              data-target="#content"
              href="javascript:;"
              class="w-full sm:w-40 py-4 text-center flex justify-center items-center active"
              role="tab"
              aria-controls="content"
              aria-selected="true"
            >
              <FileTextIcon class="w-4 h-4 mr-2" />
              {{ i18n('articles.form.content') }}
            </Tippy>
          </div>
          <div class="post__content tab-content">
            <div
              id="content"
              class="tab-pane p-5 active"
              role="tabpanel"
              aria-labelledby="content-tab"
            >
              <div
                class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
              >
                <div
                  class="font-medium flex items-center border-b border-gray-200 dark:border-dark-5 pb-5"
                >
                  <ChevronDownIcon class="w-4 h-4 mr-2" />
                  {{ i18n('articles.form.textContent') }}
                </div>
                <CKEditor
                  v-model="content"
                  :editor="classicEditor"
                  :config="{ ...editorConfig, language: isRTL }"
                />
              </div>
              <div
                class="border border-gray-200 dark:border-dark-5 rounded-md p-5 mt-5"
                v-if="model.media == 'video'"
              >
                <div
                  class="font-medium text-lg flex items-center border-b border-gray-200 dark:border-dark-5 pb-5"
                >
                  {{ i18n('articles.form.video') }}
                </div>
                <div class="mt-5">
                  <div class="preview">
                    <div class="relative" v-if="isEditing && videoUrl">
                      <video
                        controls
                        autoplay
                        style="width: 100%; height: 150px"
                        type="video/*"
                      >
                        <source :src="videoUrl" />
                        Your browser does not support the video tag.
                      </video>
                      <img
                        src="/images/deleteIcon.png"
                        alt=""
                        class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                        @click="model.videoUrl = undefined"
                      />
                    </div>
                    <Uploader
                      ref="uploaderVideo"
                      @fileAdded="pushUploadedFiles"
                      @clear="clear()"
                      @removedfile="removeFileFromUploadList"
                      folder="video"
                      v-else
                    >
                    </Uploader>
                  </div>
                </div>
              </div>
              <div
                class="border border-gray-200 dark:border-dark-5 rounded-md p-5 mt-5"
                v-else-if="model.media == 'image'"
              >
                <div
                  class="font-medium text-lg flex items-center border-b border-gray-200 dark:border-dark-5 pb-5"
                >
                  <ChevronDownIcon class="w-4 h-4 mr-2" />
                  {{ i18n('articles.form.images') }}
                </div>
                <div class="mt-5">
                  <div class="preview">
                    <div class="relative" v-if="isEditing && imagesUrls">
                      <div class="flex gap-0 flex-wrap">
                        <div
                          class="relative h-32 w-36"
                          v-for="(image, index) in imagesUrls"
                          :key="index"
                        >
                          <img
                            :src="image"
                            class="object-cover h-full w-full"
                          />
                          <img
                            src="/images/deleteIcon.png"
                            alt=""
                            class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                            @click="deleteImage(index)"
                          />
                        </div>
                      </div>
                      <Uploader
                        ref="uploaderImagesAdd"
                        folder="images"
                        message="common.addMoreImages"
                        :multiple="true"
                        :maxFiles="
                          model.platforms.includes('twitter')
                            ? 4
                            : model.platforms.includes('linkedin')
                            ? 9
                            : 10
                        "
                        @removedfile="removeFileFromUploadList"
                        @fileAdded="pushUploadedFiles"
                        @clear="clear()"
                      >
                      </Uploader>
                    </div>
                    <Uploader
                      :multiple="true"
                      :maxFiles="
                        model.platforms.includes('twitter')
                          ? 4
                          : model.platforms.includes('linkedin')
                          ? 9
                          : 10
                      "
                      ref="uploaderImagesNew"
                      @removedfile="removeFileFromUploadList"
                      @fileAdded="pushUploadedFiles"
                      @clear="clear()"
                      folder="images"
                      v-else
                    ></Uploader>
                  </div>
                </div>
              </div>
              <div class="media-handler" v-else>
                <lottie-player
                  src="https://lottie.host/3d7e4547-b2c6-4d02-a9c8-63edc3ae3743/6PfaAC7xBb.json"
                  background="transparent"
                  style="width: 100px; height: 100px"
                  loop
                  autoplay
                ></lottie-player>

                <div>
                  {{
                    isRTL
                      ? `اختر نوع الميديا لتتمكن من اضافتها`
                      : 'Select Media Type to add media to the article'
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Post Content -->
      <!-- BEGIN: Post Info -->
      <div class="col-span-12 lg:col-span-4">
        <div class="intro-y box p-5">
          <div class="mt-3">
            <label for="post-form-2" class="form-label">{{
              i18n('articles.form.postDate')
            }}</label>
            <Litepicker
              id="post-form-2"
              v-model="model.postDate"
              :options="{
                autoApply: false,
                showWeekNumbers: true,
                dropdowns: {
                  minYear: 1990,
                  maxYear: null,
                  months: true,
                  years: true
                }
              }"
              class="form-control"
            />
          </div>
          <div class="mt-3">
            <label for="post-form-3" class="form-label">{{
              i18n('articles.form.categories')
            }}</label>
            <TSelect
              id="post-form-3"
              v-model="model.categories"
              class="w-full"
              multiple
            >
              <option
                v-for="(category, index) in categories"
                :key="index"
                :value="category.id"
                class="text-black"
              >
                {{ isRTL ? category.name.ar : category.name.en }}
              </option>
            </TSelect>
          </div>

          <div class="mt-3">
            <label for="post-form-4" class="form-label">{{
              i18n('articles.form.tags')
            }}</label>
            <TSelect
              id="post-form-4"
              v-model="model.tags"
              class="w-full"
              :create="true"
              multiple
            >
              <option value=""></option>
            </TSelect>
          </div>
          <div class="mt-3" v-if="!isEditing">
            <label for="post-form-5" class="form-label">{{
              i18n('articles.form.platforms')
            }}</label>
            <TSelect
              id="post-form-5"
              v-model="model.platforms"
              class="w-full"
              multiple
            >
              <option value="facebook">
                {{ i18n('articles.form.facebook') }}
              </option>
              <option value="twitter">
                {{ i18n('articles.form.twitter') }}
              </option>
              <option value="youtube">
                {{ i18n('articles.form.youtube') }}
              </option>
              <option value="tiktok">
                {{ i18n('articles.form.tiktok') }}
              </option>

              <option value="linkedin">
                {{ i18n('articles.form.linkedin') }}
              </option>
              <option value="system">
                {{ i18n('articles.form.system') }}
              </option>
            </TSelect>
          </div>

          <div class="mt-3">
            <label for="post-form-3" class="form-label">{{
              i18n('common.media')
            }}</label>
            <TSelect id="post-form-6" v-model="model.media" class="w-full">
              <option></option>
              <option
                v-if="
                  (model.platforms.indexOf('tiktok') == -1) &
                  (model.platforms.indexOf('youtube') == -1)
                "
                value="image"
              >
                {{ i18n('media.form.image') }}
              </option>
              <option value="video">
                {{ i18n('media.form.video') }}
              </option>
            </TSelect>
          </div>
          <div
            class="form-check flex-col items-start mt-3"
            v-if="
              (isEditing && currentUser.roles.includes('owner')) || !isEditing
            "
          >
            <label for="post-form-5" class="form-check-label ml-0 mb-2">{{
              i18n('articles.form.inReview')
            }}</label>
            <input
              id="post-form-5"
              class="form-check-switch"
              type="checkbox"
              v-model="model.inReview"
            />
          </div>
          <div class="form-check flex-col items-start mt-3">
            <label for="post-form-6" class="form-check-label ml-0 mb-2">{{
              i18n('articles.form.showAuthorName')
            }}</label>
            <input
              id="post-form-6"
              class="form-check-switch"
              type="checkbox"
              v-model="model.showAuthorName"
            />
          </div>

          <!-- <div class="form-check items-start mt-3 gap-2">
                                                                                                                            <label for="post-form-6" class="form-check-label ml-0 mb-2">{{
                                                                                                                              i18n('media.form.image')
                                                                                                                            }}</label>
                                                                                                                            <input id="post-form-6" class="form-check-switch" type="checkbox" v-model="model.media" />
                                                                                                                            <label for="post-form-6" class="form-check-label ml-0 mb-2">{{
                                                                                                                              i18n('media.form.video')
                                                                                                                            }}</label>
                                                                                                                          </div> -->
        </div>
      </div>
      <!-- END: Post Info -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import CodePlugin from '@ckeditor/ckeditor5-basic-styles/src/code'
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import CloudServicesPlugin from '@ckeditor/ckeditor5-cloud-services/src/cloudservices'
import Font from '@ckeditor/ckeditor5-font/src/font'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import HeadingButtonsUI from '@ckeditor/ckeditor5-heading/src/headingbuttonsui'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import Uploader from '@/views/article/uploader.vue'
import { FileUploader } from '@/shared/uploader/file-uploader'

export default defineComponent({
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Uploader
  },
  props: {
    id: {
      type: String,
      required: false
    }
  },
  async created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.articles')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.articles')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
      this.baseImages = this.record.images
    } else {
      this.doNew()
    }
    this.model = this.record || {
      title: { ar: '', en: '' },
      content: { ar: '', en: '' },
      postDate: '',
      platforms: [],
      tags: [],
      categories: [],
      images: [],
      videoUrl: '',
      inReview: false,
      showAuthorName: false
    }
    this.model.images.length > 0
      ? (this.model.media = 'image')
      : this.model.videoUrl.length > 0
      ? (this.model.media = 'video')
      : ''

    await this.doFetchCategory({
      filter: {},
      pagination: {
        limit: 100,
        sortBy: 'asc'
      }
    })
    if (this.isEditing) {
      console.log('sasdasdas record', this.record, this.model)
      this.model.content.en ? '' : (this.model.content.en = '.')
      this.model.content.ar ? '' : (this.model.content.ar = '.')
      this.model.categories = this.record.categories.map((cat) => cat.id)
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      categories: 'category/list/rows',
      language: 'layout/currentLanguageCode',
      record: 'article/form/record',
      findLoading: 'article/form/findLoading',
      saveLoading: 'article/form/saveLoading',
      currentUser: 'auth/currentUser'
    }),
    labelLocalized() {
      return this.isRTL ? 'nameAr' : 'nameEn'
    },
    isEditing() {
      return !!this.id
    },
    filesToSaveImagesAdd() {
      return this.$refs.uploaderImagesAdd?.filesToSave || []
    },
    filesToSaveImagesNew() {
      return this.filesToSave
    },
    filesToSaveVideo() {
      return this.filesToSave || []
    },
    videoUrl() {
      return this.model && this.model.videoUrl ? this.model.videoUrl : null
    },
    imagesUrls() {
      return this.model && this.model.images && this.model.images.length > 0
        ? this.model.images
        : null
    },
    title: {
      get() {
        return this.lang === 'ar' ? this.model?.title.ar : this.model?.title.en
      },
      set(val) {
        this.lang === 'ar'
          ? (this.model.title.ar = val)
          : (this.model.title.en = val)
      }
    },
    content: {
      get() {
        return this.lang === 'ar'
          ? this.model?.content.ar
          : this.model?.content.en
      },
      set(val) {
        this.lang === 'ar'
          ? (this.model.content.ar = val)
          : (this.model.content.en = val)
      }
    }
  },
  data() {
    return {
      model: null,
      errorMessage: null,
      baseImages: []
    }
  },
  setup() {
    const dis = ref(false)
    const filesToSave = ref([])
    const filesToUpload = ref([])
    const lang = ref('ar')
    const switchLang = () => {
      lang.value = lang.value === 'en' ? 'ar' : 'en'
    }
    const classicEditor = ClassicEditor
    const editorConfig = {
      cloudServices: {
        tokenUrl: '',
        uploadUrl: ''
      },
      plugins: [
        Font,
        EssentialsPlugin,
        BoldPlugin,
        Alignment,
        UnderlinePlugin,
        StrikethroughPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        CodePlugin,
        SubscriptPlugin,
        SuperscriptPlugin,
        EasyImagePlugin,
        ImagePlugin,
        ImageUploadPlugin,
        CloudServicesPlugin,
        Heading,
        HeadingButtonsUI,
        Highlight
      ],
      toolbar: {
        items: [
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          'bold',
          'alignment',
          'italic',
          'underline',
          'strikethrough',
          'code',
          'subscript',
          'superscript',
          'link',
          'undo',
          'redo',
          'imageUpload',
          'highlight'
        ]
      }
    }
    const editorData = ref('')

    return {
      classicEditor,
      editorConfig,
      editorData,
      lang,
      switchLang,
      filesToSave,
      filesToUpload,
      dis
    }
  },
  methods: {
    ...mapActions({
      doNew: 'article/form/doNew',
      doFind: 'article/form/doFind',
      doFetchCategory: 'category/list/doFetch',
      doCreate: 'article/form/doAdd',
      doUpdate: 'article/form/doUpdate'
    }),
    deleteImage(index) {
      this.baseImages.splice(index, 1)
    },
    pushUploadedFiles(uploadedDataObj) {
      this.filesToUpload.push(uploadedDataObj)
      console.log(this.filesToUpload)
    },
    removeFileFromUploadList(name) {
      this.filesToUpload = this.filesToUpload.filter(
        (obj) => obj.file.name != name
      )
      console.log(this.filesToUpload)
    },
    clear() {
      this.filesToUpload = []
      console.log(this.filesToUpload)
    },
    isFormValid() {
      if (
        this.model.platforms.includes('system') &&
        (this.model.title.en.length == 0 || this.model.title.ar.length == 0)
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      } else if (
        this.model.title.en &&
        this.model.title.en.length == 0 &&
        this.model.title.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.platforms.includes('system') &&
        (this.model.content.en.length == 0 || this.model.content.ar.length == 0)
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      } else if (
        this.model.content.en &&
        this.model.content.en.length == 0 &&
        this.model.content.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.postDate.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.platforms.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.categories.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.isEditing) {
        if (
          this.filesToSaveImagesAdd.length === 0 &&
          this.filesToUpload.length === 0 &&
          this.baseImages.length === 0
        ) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      } else {
        if (
          this.filesToSaveImagesAdd.length === 0 &&
          this.filesToUpload.length === 0 &&
          this.baseImages.length === 0
        ) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      }
      return true
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    doCancel() {
      this.$router.back()
    },
    async doSubmit() {
      this.dis = true
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        this.dis = false
        return Message.error(ERROR)
      }
      const {
        title,
        content,
        postDate,
        platforms,
        tags,
        categories,
        inReview,
        showAuthorName
      } = this.model
      if (this.isEditing) {
        console.log('started')
        await Promise.all(
          this.filesToUpload.map(async (el) => {
            console.log('ghhhhhh')
            const uploadedFile = await FileUploader.upload(
              el.path,
              el.file,
              el.name
            )
            this.filesToSave.push(uploadedFile.publicUrl)
          })
        )
        console.log('asdasdasdasdas', this.filesToUpload)
        await this.doUpdate({
          id: this.id,
          title,
          content,
          postDate: new Date(postDate),
          platforms,
          tags,
          categories,
          inReview,
          showAuthorName,
          videoUrl:
            this.model.media == 'video' ? this.filesToSaveVideo[0] : null,
          images:
            this.model.media == 'image'
              ? [...this.baseImages, ...this.filesToSaveImagesNew]
              : []
        })
        this.dis = false
      } else {
        await Promise.all(
          this.filesToUpload.map(async (el) => {
            const uploadedFile = await FileUploader.upload(
              el.path,
              el.file,
              el.name
            )
            this.filesToSave.push(uploadedFile.publicUrl)
          })
        )

        this.doCreate({
          title,
          content,
          postDate: new Date(postDate),
          platforms,
          tags,
          categories,
          inReview,
          showAuthorName,
          videoUrl:
            this.model.media == 'video' ? this.filesToSaveVideo[0] : null,
          images: this.model.media == 'image' ? this.filesToSaveImagesNew : []
        })

        this.dis = false
      }
    }
  }
})
</script>

<style scoped>
#test {
  pointer-events: none !important;
  opacity: 0.5;
}
.media-handler {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  padding: 80px;
}

@media (max-width: 768px) {
  .media-handler {
    flex-direction: column;
    padding: 20px 10px;
  }

  .media-handler div {
    text-align: center;
    line-height: 1.3;
  }
}

.media-handler div {
  font-size: 19px;
}
</style>
