/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlLive)

export class LiveService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LIVE_LIST(
          $filter: LiveFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          liveList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              html
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.liveList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query LIVE_FIND($id: String!) {
          liveFind(id: $id) {
            id
            html
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.liveFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation LIVE_CREATE($data: LiveInput!) {
          liveCreate(data: $data) {
            id
            html
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.liveCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation LIVE_UPDATE($id: String!, $data: LiveInput!) {
          liveUpdate(id: $id, data: $data) {
            id
            html
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.liveUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation LIVE_DESTROY($id: String!) {
          liveDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.liveDestroy
  }
}
