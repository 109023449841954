<template>
  <div>
    <Dropzone ref-key="dropzoneRef" id="dropzoneRef" :options="{
      url: 'https://httpbin.org/post',
      paramName: 'file',
      thumbnailWidth: 150,
      // maxFilesize: 0.9,
      // clickable: !isMaxUploaded,
      uploadMultiple: isMultiple,
      addRemoveLinks: true,
      maxFiles: max,
      headers: { 'My-Awesome-Header': 'header value' }
    }" class="dropzone cursor-pointer h-36">
      <div class="text-lg font-medium">
        {{ message ? i18n(message) : i18n('common.fileUploaderHint') }}
      </div>
    </Dropzone>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, provide } from 'vue'
import Dropzone from 'dropzone'
import { FileUploader } from '@/shared/uploader/file-uploader'
import { collection, doc, getFirestore } from 'firebase/firestore'

export default defineComponent({
  props: {
    multiple: Boolean,
    message: String
  },
  computed: {
    isMultiple() {
      return this.multiple || false
    },
    max() {
      return this.multiple ? null : 1
    }
  },
  mounted() {
  },
  setup() {
    let uploadLoading = ref(false)
    const dropzoneRef = ref()
    const filesToSave = ref([])
    provide('bind[dropzoneRef]', (el) => {
      console.log('el =', el)
      dropzoneRef.value = el
    })
    const clearDropzone = () => {
      Dropzone.forElement('#dropzoneRef').removeAllFiles(true)
      while (filesToSave.value.length > 0) {
        filesToSave.value.pop()
      }
    }
    const getDropZoneInstance = () => {
      return dropzoneRef.value
    }
    const setupDropZone = () => {
      const name = new Date()
      const elDropzoneRef = getDropZoneInstance()
      const id = doc(collection(getFirestore(), 'ids')).id
      const path = `parking/${id}`
      elDropzoneRef.dropzone.on('addedfile', async (file) => {
        const uploadedFile = await FileUploader.upload(path, file, file.upload.uuid)
        filesToSave.value.push(uploadedFile.publicUrl)
      })

      elDropzoneRef.dropzone.on('removedfile', async (file) => {
        await FileUploader.delete(path, file, file.upload.uuid)
        filesToSave.value.pop()
      })

      elDropzoneRef.dropzone.on('complete', () => {
        uploadLoading = false
      })

      elDropzoneRef.dropzone.on('uploadprogress', () => {
        uploadLoading = true
      })
    }
    onMounted(() => {
      setupDropZone()
    })
    return {
      setupDropZone,
      uploadLoading,
      filesToSave,
      getDropZoneInstance,
      clearDropzone
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
