<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('comments.title') }}</h2>
    </div>
    <!-- <TableFilter
                      :disabled="loading"
                      :filter-fields="filterFields"
                      @filter="doFilter"
                      @reset="doResetFilter"
                    ></TableFilter> -->
    <div class="intro-y tab-content mt-5">
      <div class="intro-y box dark:bg-dark-8 p-5 mt-5">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div
          class="flex flex-col sm:flex-row sm:items-end items-end justify-end"
        >
          <!-- <div class="flex mt-5 sm:mt-0">
                            <button
                              class="btn btn-primary sm:w-auto pb-1 pt-1"
                              @click="openCreateArticle()"
                            >
                              <PlusIcon />
                              {{ i18n('common.create') }}
                            </button>
                          </div> -->
        </div>
        <div class="overflow-x-auto g">
          <table class="table table-report sm:mt-2 hidden md:table">
            <thead>
              <tr>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  #
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('comments.table.comment') }}
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('comments.table.createdBy') }}
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('comments.table.createdAt') }}
                </th>
                <th class="text-center whitespace-nowrap">
                  {{ i18n('common.table.actions') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in rows" :key="index">
                <td class="w-40">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                    #{{ index + 1 }}
                  </div>
                </td>
                <td>
                  <div
                    class="font-medium whitespace-nowrap truncate text-center"
                  >
                    {{ row.comment }}
                  </div>
                </td>
                <td>
                  <div
                    class="font-medium whitespace-nowrap truncate text-center"
                  >
                    {{ presenterDate(row, 'createdAt') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap truncate">
                    {{ row.createdBy.fullName }}
                  </div>
                </td>
                <td class="table-report__action w-56">
                  <div
                    class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer gap-4"
                  >
                    <a
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#delete-modal-preview"
                      class="text-theme-24"
                      @click="deleteId = row.id"
                    >
                      <Trash2Icon class="w-4 h-4" />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="mobile-table md:hidden mt-8">
            <mobile-table-comp
              v-for="(row, index) in rows"
              :key="index"
              :row="row"
              class="md:hidden"
            >
              <div class="field">
                <span>{{ i18n('comments.table.comment') }} </span>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ row.comment }}
                </div>
              </div>
              <div class="field">
                <span> {{ i18n('comments.table.createdBy') }} :</span>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterDate(row, 'createdBy') }}
                </div>
              </div>
              <div class="field">
                <span> {{ i18n('comments.table.createdAt') }} :</span>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterDate(row, 'createdAt') }}
                </div>
              </div>
              <div class="field">
                <span> {{ i18n('common.table.actions') }} :</span>
                <div
                  class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer gap-4"
                >
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    class="text-theme-24"
                    @click="deleteId = row.id"
                  >
                    <Trash2Icon class="w-4 h-4" />
                  </a>
                </div>
              </div>
            </mobile-table-comp>
          </div>
          <app-empty-page
            v-if="!rows.length && !loading"
            :label="i18n('empty.comment')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
        <!-- <Pagination
                          v-if="rows.length"
                          :page="currentPage"
                          :pageSize="pagination.limit"
                          :rowsNumber="pagination.total"
                          :showSizeChanger="true"
                          :isFirstPage="isFirstPage"
                          :isLastPage="isLastPage"
                          :disabled="!rows.length"
                          position="center"
                          @next-page="doNextPage"
                          @prev-change="doPrevPage"
                          @size-change="doChangePaginationPageSize"
                        ></Pagination> -->
      </div>
      <DeleteModal
        item="article"
        @delete="doDestroy({ articleId: id, id: deleteId })"
      />
    </div>
  </div>
</template>

<script>
import DeleteModal from '@/components/modals/delete-modal.vue'
import { mapActions, mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  async created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.articles')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.articles')
    }
  },
  components: {
    DeleteModal
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'article/list/comments',
      // currentPage: 'article/list/currentPage',
      // isFirstPage: 'article/list/isFirstPage',
      // isLastPage: 'article/list/isLastPage',
      loading: 'article/list/loading'
      // pagination: 'article/list/pagination'
    }),
    filterFields() {
      return [
        {
          label: this.i18n('comments.table.title'),
          value: 'title',
          type: 'text'
        }
      ]
    },
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  setup() {
    const deleteId = ''
    const filterInput = []

    return {
      deleteId,
      filterInput
    }
  },
  async mounted() {
    await this.doFetch(this.id)
    console.log(this.rows)
  },
  methods: {
    ...mapActions({
      doFetch: 'article/list/doFetchComment',
      // doFetchNextPage: 'article/list/doFetchNextPage',
      // doFetchPreviousPage: 'article/list/doFetchPreviousPage',
      // doChangePaginationCurrentPage:
      //   'article/list/doChangePaginationCurrentPage',
      // doChangePaginationPageSize: 'article/list/doChangePaginationPageSize',
      doDestroy: 'article/list/doDestroyComment'
    }),
    async doFilter(filter) {
      this.filterInput = {}
      if (!filter) return
      this.filterInput = filter
      await this.doFetchFirstPage()
    },
    async doResetFilter() {
      this.filterInput = {}
      await this.doFetchFirstPage()
    },
    // async doFetchFirstPage() {
    //   await this.doChangePaginationCurrentPage(1)
    //   await this.doFetch({
    //     filter: {},
    //     pagination: {
    //       limit: 10,
    //       sortBy: 'asc'
    //     }
    //   })
    // },
    // async doNextPage() {
    //   await this.doFetchNextPage()
    // },
    // async doPrevPage() {
    //   await this.doFetchPreviousPage()
    // },
    // select() {
    //   this.selected = []
    //   if (!this.selectAll) {
    //     this.rows.forEach((element) => {
    //       this.selected.push(element.id)
    //     })
    //   }
    // },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterArr(row, fieldName) {
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].join(', ')
        : 'ـــ'
    },
    presenterArrLocalized(row, fieldName) {
      const locale = this.isRTL ? 'ar' : 'en'
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].map((val) => val[locale]).join(',')
        : 'ـــ'
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    viewArticle(id) {
      this.$router.push(`/article/${id}`)
    },
    editArtical(id) {
      this.$router.push(`/article-form-page/${id}`)
    },
    openCreateArticle() {
      this.$router.push('/article-form-page')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
