/* eslint-disable */

import branchListStore from '@/store/branch/branch-list-store'
import branchViewStore from '@/store/branch/branch-view-store'
import branchFormStore from '@/store/branch/branch-form-store'

export default {
  namespaced: true,

  modules: {
    list: branchListStore,
    form: branchFormStore,
    view: branchViewStore
    // destroy: branchDestroyStore
  }
}
