/* eslint-disable */

import parkingListStore from '@/store/parking/parking-list-store'
import parkingFormStore from '@/store/parking/parking-form-store'
import parkingViewStore from '@/store/parking/parking-view-store'

export default {
  namespaced: true,

  modules: {
    list: parkingListStore,
    form: parkingFormStore,
    view: parkingViewStore
  }
}
