<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('users.userDetails.title') }}
      </h2>
    </div>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box dark:bg-dark-8 px-5 pt-5 mt-5">
      <div style="width: 100%; height: 30vh" class="flex justify-center" v-if="loading">
        <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
      </div>
      <div v-if="record" class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5">
        <div class="flex flex-1 px-5 items-center justify-center lg:justify-start">
          <div class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
            <img alt="Icewall Tailwind HTML Admin Template" class="rounded-full" data-action="zoom"
              :src="record.avatar ? record.avatar : '/images/default-avatar.svg'" />
          </div>
          <div class="ml-5 mr-5">
            <div class="w-24 sm:w-40 sm:whitespace-normal font-medium text-lg">
              {{ record.fullName }}
            </div>
          </div>
        </div>
        <div
          class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0">
          <div class="font-medium text-center lg:mt-3" :class="isRTL ? 'lg:text-right' : 'lg:text-left'">
            {{ i18n('users.userDetails.contactDetails') }}
          </div>
          <div class="flex flex-col justify-center items-center lg:items-start mt-4">
            <div class="truncate sm:whitespace-normal flex items-center">
              <MailIcon class="w-4 h-4 mr-2 ml-2" />
              {{ record.email }}
            </div>
            <div class="truncate sm:whitespace-normal flex items-center mt-3">
              <PhoneIcon class="w-4 h-4 mr-2 ml-2" />
              <span style="direction:ltr !important">{{ record.phoneNumber }}</span>
            </div>
          </div>
          <div class="font-medium text-center lg:mt-3" :class="isRTL ? 'lg:text-right' : 'lg:text-left'">
            {{ i18n('users.userDetails.status') }}
          </div>
          <div class="flex flex-col justify-center items-center lg:items-start mt-4">
            <div class="truncate sm:whitespace-normal flex items-center" :class="{
              'text-theme-10': activated,
              'text-theme-24': !activated
            }">
              {{
                activated ? i18n('common.activated') : i18n('common.disabled')
              }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start" role="tablist">
        <a id="articles-tab" data-toggle="tab" data-target="#articles" href="javascript:;" class="py-4 sm:mr-8 active"
          role="tab" aria-controls="articles" aria-selected="true" @click="tab = 'articles'">{{
              i18n('users.userDetails.tabs.articles')
          }}</a>
        <a id="ads-tab" data-toggle="tab" data-target="#ads" href="javascript:;" class="py-4 sm:mr-8" role="tab"
          aria-selected="false" @click="tab = 'ads'">{{ i18n('users.userDetails.tabs.ads') }}</a>
        <a id="auctions-tab" data-toggle="tab" data-target="#auctions" href="javascript:;" class="py-4 sm:mr-8"
          role="tab" aria-selected="false" @click="tab = 'auctions'">{{ i18n('users.userDetails.tabs.auctions') }}</a>
      </div> -->
    </div>
    <!-- END: Profile Info -->
    <!-- <div class="intro-y tab-content mt-5">
      <ArticlesTab v-if="tab == 'articles'" />
      <AdsTab v-if="tab == 'ads'" />
      <AuctionsTab v-if="tab == 'auctions'" />
    </div> -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
// import ArticlesTab from '@/views/users/articles-tab.vue'
// import AdsTab from '@/views/users/ads-tab.vue'
// import AuctionsTab from '@/views/users/auctions-tab.vue'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    if (!this.currentUser.roles.includes('owner')) {
      this.$router.push('/404')
    }
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    }
  },
  // components: {
  //   ArticlesTab,
  //   AdsTab,
  //   AuctionsTab
  // },
  computed: {
    ...mapGetters({
      record: 'user/view/record',
      loading: 'user/view/loading',
      currentUser: 'auth/currentUser'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    activated() {
      return this.record && !this.record.disabled
    }
  },
  setup() {
    const tab = ref('articles')
    onMounted(() => {
      // document.getElementById('notifications').style.display = 'none'
    })
    return {
      tab
    }
  },
  async mounted() {
    await this.doFind(this.id)
  },
  methods: {
    ...mapActions({
      doFind: 'user/view/doFind'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
