/* eslint-disable */

import telecomListStore from '@/store/telecom/telecom-list-store'
// import telecomViewStore from '@/store/telecom/telecom-view-store'
import telecomFormStore from '@/store/telecom/telecom-form-store'

export default {
  namespaced: true,

  modules: {
    list: telecomListStore,
    form: telecomFormStore,
    // view: telecomViewStore
  }
}
