/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlCultural)

export class CulturalService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query CULTURAL_LIST(
          $filter: CulturalFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          culturalList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              banner
              description {
                en
                ar
              }
              branchId
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.culturalList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query CULTURAL_FIND($id: String!) {
          culturalFind(id: $id) {
            id
            banner
            description {
              en
              ar
            }
            branchId
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id
      }
    })

    return response.data.culturalFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CULTURAL_CREATE($data: CulturalInput!) {
          culturalCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.culturalCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CULTURAL_UPDATE($id: String!, $data: CulturalInput!) {
          culturalUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.culturalUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CULTURAL_DESTROY($id: String!) {
          culturalDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.culturalDestroy
  }
}
