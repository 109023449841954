import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import i18n from './vueI18n'
import './libs'

// SASS Theme
import './assets/sass/app.scss'
import 'material-icons/iconfont/material-icons.css'

import 'epic-spinners/dist/lib/epic-spinners.min.css'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)

globalComponents(app)
utils(app)

app.mount('#app')
