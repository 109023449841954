<template>
  <div>
    <h1 class="text-2xl mt-5">{{i18n('sports.view.title')}}</h1>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y"
        @click="goToTeam()"
      >
        <div class="report-box zoom-in">
          <div class="box p-5 dark:bg-dark-2">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              src="/images/png/teams/smouha.jpeg"
            />
            <div
              class="text-3xl font-medium leading-8 mt-6 flex justify-center"
            >
              {{i18n('sports.view.firstTeam')}}
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y"
        @click="goToTeam()"
      >
        <div class="report-box zoom-in">
          <div class="box p-5 dark:bg-dark-2">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              src="/images/png/teams/smouha.jpeg"
            />
            <div
              class="text-3xl font-medium leading-8 mt-6 flex justify-center"
            >
              {{i18n('sports.view.u21')}}
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y"
        @click="goToTeam()"
      >
        <div class="report-box zoom-in">
          <div class="box p-5 dark:bg-dark-2">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              src="/images/png/teams/smouha.jpeg"
            />
            <div
              class="text-3xl font-medium leading-8 mt-6 flex justify-center"
            >
              {{i18n('sports.view.u18')}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.sports')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.sports')
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    goToTeam() {
      this.$router.push('/team-view-page')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style></style>
