<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n
              code="administration.title.edit"
              v-if="isEditing"
            ></app-i18n>
            <app-i18n code="administration.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !model"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('administration.form.description') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    rows="3"
                    id="title-en"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.description.en"
                    :placeholder="i18n('common.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <textarea
                    rows="3"
                    id="title-ar"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.description.ar"
                    :placeholder="i18n('common.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('administration.form.presidentName') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.presidentName.en"
                    :placeholder="i18n('common.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.presidentName.ar"
                    :placeholder="i18n('common.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('administration.form.presidentPhoto') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <div
                    class="
                      w-20
                      h-20
                      sm:w-24 sm:h-24
                      flex-none
                      lg:w-32 lg:h-32
                      image-fit
                      relative
                      m-auto
                      cursor-pointer
                    "
                  >
                    <div>
                      <img
                        alt="Icewall Tailwind HTML Admin Template"
                        class="rounded-full"
                        :src="
                          model.presidentPhoto
                            ? model.presidentPhoto?.publicUrl ||
                              '/images/default-avatar.svg'
                            : '/images/default-avatar.svg'
                        "
                      />
                      <div
                        class="
                          absolute
                          mb-1
                          mr-1
                          flex
                          items-center
                          justify-center
                          bottom-0
                          right-0
                          bg-theme-17
                          rounded-full
                          p-2
                        "
                      >
                        <CameraIcon class="w-4 h-4 text-white" />
                      </div>
                    </div>
                    <input
                      type="file"
                      id="profileImage"
                      name="upload"
                      accept="image/png, image/jpeg, image/jpg"
                      style="position: absolute; top: 0%; opacity: 0 !important"
                      class="
                        cursor-pointer
                        w-20
                        h-20
                        sm:w-24 sm:h-24
                        flex-none
                        lg:w-32 lg:h-32
                        image-fit
                        relative
                        m-auto
                      "
                      @input="emitEvent($event)"
                    />
                  </div>
                </div>
              </div>
              <!-- END -->
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="
                    btn btn-primary
                    w-24
                    cursor-pointer
                    text-white
                    cursor-pointer
                  "
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading || uploadLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="
                    btn
                    bg-theme-31
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  "
                  @click="doCancel"
                >
                  {{ i18n('common.cancel') }}
                </button>
              </div>
            </form>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import Message from '@/shared/message/toastify'
import { mapGetters, mapActions } from 'vuex'
import { FileUploader } from '@/shared/uploader/file-uploader'
import { collection, doc, getFirestore } from 'firebase/firestore'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.parking')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.parking')
    }
  },
  props: {
    id: String
  },
  data() {
    return {
      model: null,
      errorMessage: null,
      imageUrl: '',
      uploadLoading: false,
      image: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'administration/form/record',
      findLoading: 'administration/form/findLoading',
      saveLoading: 'administration/form/saveLoading'
    })
  },
  async mounted() {
    await this.doFind()
    this.model = this.record || {
      description: {
        en: '',
        ar: ''
      },
      presidentName: {
        en: '',
        ar: ''
      },
      presidentPhoto: {
        name: '',
        publicUrl: ''
      }
    }
  },
  methods: {
    ...mapActions({
      doFind: 'administration/form/doFindAdministration',
      doCreate: 'administration/form/doEdit'
    }),
    isFormValid() {
      if (
        this.model.description.en.length == 0 ||
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      if (
        this.model.presidentName.en.length == 0 ||
        this.model.presidentName.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.presidentPhoto.publicUrl.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      return true
    },
    doCancel() {
      this.$router.push('/administration')
    },
    emitEvent(event) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (e) => {
        const image = {
          name: event.target.files[0].name,
          size: event.target.files[0].size,
          lastModifiedDate: event.target.files[0].lastModifiedDate,
          base64: reader.result
        }
        this.image = image
        const file = event.target.files[0]
        this.imageUrl = URL.createObjectURL(file)
        this.model.presidentPhoto.publicUrl = this.imageUrl
      }
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      if (this.image) {
        this.uploadLoading = true
        const profileImage = document.getElementById('profileImage').files[0]
        const id = doc(collection(getFirestore(), 'ids')).id
        const path = `administration/presidentPhoto/${id}`
        const { publicUrl, name } = await FileUploader.upload(
          path,
          profileImage,
          'presidentPhoto'
        )
        this.model.presidentPhoto = {
          name,
          publicUrl
        }
        this.uploadLoading = false
      }
      const { description, presidentName, presidentPhoto } = this.model
      await this.doCreate({
        description,
        presidentName,
        presidentPhoto
      })
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
