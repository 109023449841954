/* eslint-disable */

import culturalServiceListStore from '@/store/culturalService/culturalService-list-store'
import culturalServiceFormStore from '@/store/culturalService/culturalService-form-store'
// import culturalServiceViewStore from '@/store/culturalService/culturalService-view-store'

export default {
  namespaced: true,

  modules: {
    list: culturalServiceListStore,
    form: culturalServiceFormStore
    // view: culturalServiceViewStore
  }
}
