/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlClinic)

export class ClinicService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query CLINIC_LIST(
          $filter: ClinicFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          clinicList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              description {
                en
                ar
              }
              adminName {
                en
                ar
              }
              adminPhoneNumber
              branchId
              clinics
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.clinicList
  }
  static async editSubActivities(id, itemIndex, newValue) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation EDIT(
          $id: String!
          $itemIndex: Int!
          $newValue: ClinicDetailsInput
        ) {
          editSubClinic(id: $id, itemIndex: $itemIndex, newValue: $newValue)
        }
      `,
      variables: {
        id,
        itemIndex,
        newValue
      }
    })
    return response.data.removeSubActivities
  }
  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query CLINIC_FIND($id: String!) {
          clinicFind(id: $id) {
            id
            description {
              en
              ar
            }
            adminName {
              en
              ar
            }
            adminPhoneNumber
            branchId
            clinics
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id
      }
    })

    return response.data.clinicFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CLINIC_CREATE($data: ClinicInput!) {
          clinicCreate(data: $data) {
            id
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.clinicCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CLINIC_UPDATE($id: String!, $data: ClinicInput!) {
          clinicUpdate(id: $id, data: $data) {
            id
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.clinicUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CLINIC_DESTROY($id: String!) {
          clinicDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.clinicDestroy
  }

  static async addClinics(id, clinics) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADD_NEW_CLINICS(
          $id: String!
          $clinics: [ClinicDetailsInput!]
        ) {
          addNewClinicsToMedicalClinic(id: $id, clinics: $clinics)
        }
      `,
      variables: {
        id,
        clinics
      }
    })
    return response.data.addNewClinicsToMedicalClinic
  }

  static async removeClinics(id, clinics) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REMOVE_CLINICS($id: String!, $clinics: [ClinicDetailsInput!]) {
          removeClinicsFromMedicalClinic(id: $id, clinics: $clinics)
        }
      `,
      variables: {
        id,
        clinics
      }
    })
    return response.data.removeClinicsFromMedicalClinic
  }
}
