<template>
  <div class="flex justify-center">
    <div class="intro-y news xl:w-3/5 p-5 box dark:bg-dark-8 mt-8">
      <h2 class="intro-y font-medium text-xl sm:text-2xl">
        {{ $f()[0].news[0].title }}
      </h2>
      <div
        class="intro-y text-gray-700 dark:text-gray-600 mt-3 text-xs sm:text-sm"
      >
        15 June 2021 at 12:00 PM<span class="mx-1">•</span>
        <a
          class="p-2 text-white"
          style="border-radius:20px; background: #d42428"
          href=""
          >Mobile</a
        >
        <a
          class="ml-2 p-2"
          style="border-radius:20px; background: #fcefeb; color: #d42428"
          href=""
          >Web</a
        >
      </div>
      <div class="intro-y mt-6">
        <div class="news__preview image-fit">
          <div class="validity">
            {{ i18n('ads.view.validUntil') }} 12/12/2021
          </div>
          <img
            alt="Icewall Tailwind HTML Admin Template"
            class="rounded-md"
            :src="require(`@/assets/images/${$f()[0].images[0]}`)"
          />
        </div>
      </div>
      <div class="intro-y flex relative pt-16 sm:pt-6 items-center pb-6">
        <div
          class="absolute sm:relative -mt-12 sm:mt-0 w-full flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm"
        >
          <div class="intro-x mr-1 sm:mr-3"></div>
          <div class="intro-x mr-1 sm:mr-3">
            {{ i18n('ads.view.views') }}:
            <span class="font-medium">{{ $f()[0].totals[1] }}</span>
          </div>
        </div>
        <Tippy
          tag="a"
          href=""
          class="intro-x w-8 h-8 sm:w-10 sm:h-10 flex flex-none items-center justify-center rounded-full bg-theme-31 dark:bg-dark-5 dark:text-gray-300 text-theme-26 ml-auto sm:ml-0"
          content="Share"
        >
          <Share2Icon class="w-3 h-3" />
        </Tippy>
      </div>
      <div class="intro-y text-justify leading-relaxed">
        <p class="mb-5">{{ $f()[1].news[0].content }}</p>
        <p class="mb-5">{{ $f()[2].news[0].content }}</p>
        <p>{{ $f()[3].news[0].content }}</p>
      </div>
      <div
        class="intro-y flex text-xs sm:text-sm flex-col sm:flex-row items-center mt-5 pt-5 border-t border-gray-200 dark:border-dark-5"
      >
        <div class="flex items-center">
          <div class="w-12 h-12 flex-none image-fit">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="rounded-full"
              :src="require(`@/assets/images/${$f()[0].photos[0]}`)"
            />
          </div>
          <div class="mx-3">
            <a href="" class="font-medium">Ahmed Mohamed</a>
          </div>
        </div>
        <div
          class="flex items-center text-gray-700 dark:text-gray-600  mt-5 sm:mt-0"
          :class="isRTL ? 'sm:mr-auto' : 'sm:ml-auto'"
        >
          {{ i18n('ads.view.share') }}
          <Tippy
            tag="a"
            href=""
            class="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center border dark:border-dark-5 mx-2 text-gray-500 zoom-in"
            content="Facebook"
          >
            <FacebookIcon class="w-3 h-3 fill-current" />
          </Tippy>
          <Tippy
            tag="a"
            href=""
            class="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-500 zoom-in"
            content="Twitter"
          >
            <TwitterIcon class="w-3 h-3 fill-current" />
          </Tippy>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.ads')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.ads')
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach(element => {
          this.selected.push(element.id)
        })
      }
    },
    goToUser() {
      this.$router.push('/user-view-page')
    },
    viewArticle() {
      this.$router.push('/article-view-page')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
<style scoped>
.validity {
  position: absolute !important;
  z-index: 20 !important;
  background: #d42428 !important;
  padding: 15px !important;
  border-bottom-right-radius: 20px !important;
  color: white !important;
}
</style>
