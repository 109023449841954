<template>
  <div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box dark:bg-dark-8 p-5 mt-5">
      <div class="overflow-x-auto g">
        <table class="table table-report sm:mt-2">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                {{ i18n('sports.teamStandings.position') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('sports.teamStandings.team') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('sports.teamStandings.mp') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('sports.teamStandings.pts') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('sports.teamStandings.w') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('sports.teamStandings.d') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('sports.teamStandings.l') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="team in 5" :key="team">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  {{ team }}
                </div>
              </td>
              <td class="text-center flex items-center justify-center">
                <div class="">
                  <div class="flex-none image-fit rounded-md overflow-hidden">
                    <div class="w-10 h-10 justify-center m-auto">
                      <img
                        class="rounded-full"
                        :src="require(`@/assets/images/profile-1.jpg`)"
                      />
                    </div>
                  </div>
                  <div class="mt-2">Smouha SC</div>
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  30
                </div>
              </td>
              <td class="text-center">
                <div
                  class="font-medium whitespace-nowrap truncate cursor-pointer"
                >
                  52
                </div>
              </td>
              <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap truncate">
                  4
                </div>
              </td>
              <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap truncate">
                  2
                </div>
              </td>
              <td class="text-center">
                <div href="" class="font-medium whitespace-nowrap truncate">
                  2
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'

export default defineComponent({
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  setup() {
    const rows = ref([
      {
        id: '1',
        username: 'Ahmed',
        profilePicture: 'profile-1.jpg',
        phoneNumber: '+966123456',
        joinDate: '05/12/2012',
        activated: true
      },
      {
        id: '2',
        username: 'Ahmed',
        profilePicture: 'profile-1.jpg',
        phoneNumber: '+966123456',
        joinDate: '05/12/2012',
        activated: false
      },
      {
        id: '3',
        username: 'Ahmed',
        profilePicture: 'profile-1.jpg',
        phoneNumber: '+966123456',
        joinDate: '05/12/2012',
        activated: true
      },
      {
        id: '4',
        username: 'Ahmed',
        profilePicture: 'profile-1.jpg',
        phoneNumber: '+966123456',
        joinDate: '05/12/2012',
        activated: false
      },
      {
        id: '5',
        username: 'Ahmed',
        profilePicture: 'profile-1.jpg',
        phoneNumber: '+966123456',
        joinDate: '05/12/2012',
        activated: true
      },
      {
        id: '6',
        username: 'Ahmed',
        profilePicture: 'profile-1.jpg',
        phoneNumber: '+966123456',
        joinDate: '05/12/2012',
        activated: false
      },
      {
        id: '7',
        username: 'Ahmed',
        profilePicture: 'profile-1.jpg',
        phoneNumber: '+966123456',
        joinDate: '05/12/2012',
        activated: true
      },
      {
        id: '8',
        username: 'Ahmed',
        profilePicture: 'profile-1.jpg',
        phoneNumber: '+966123456',
        joinDate: '05/12/2012',
        activated: false
      },
      {
        id: '9',
        username: 'Ahmed',
        profilePicture: 'profile-1.jpg',
        phoneNumber: '+966123456',
        joinDate: '05/12/2012',
        activated: true
      },
      {
        id: '10',
        username: 'Ahmed',
        profilePicture: 'profile-1.jpg',
        phoneNumber: '+966123456',
        joinDate: '05/12/2012',
        activated: false
      }
    ])
    const tableLimit = ref(10)
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)
    const filter = reactive({
      field: 'username',
      value: ''
    })

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'username'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      rows,
      selected,
      selectAll,
      tableLimit,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint
    }
  },
  methods: {
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach((element) => {
          this.selected.push(element.id)
        })
      }
    },
    goToUser() {
      this.$router.push('/user-view-page')
    },
    viewAd() {
      this.$router.push('/ad-view-page')
    },
    openCreateAd() {
      this.$router.push('/ad-form-page')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
