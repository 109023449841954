<template>
  <div class="flex justify-center">
    <div class="intro-y news xl:w-3/5 p-5 box dark:bg-dark-8 mt-8">
      <div class="intro-y mt-6">
        <div class="news__preview image-fit">
          <img
            alt="Icewall Tailwind HTML Admin Template"
            class="rounded-md"
            :src="require(`@/assets/images/${$f()[0].images[0]}`)"
          />
        </div>
      </div>
      <div class="intro-y text-justify leading-relaxed mt-5">
        <div class="flex grid grid-cols-12">
          <h2 class="text-xl col-span-2">{{i18n('sports.playerView.name')}}</h2>
          <p class="flex items-center font-medium col-span-10">Ahmed Mohammed</p>
        </div>
        <div class="flex mt-3 grid grid-cols-12">
          <h2 class="text-xl col-span-2">{{i18n('sports.playerView.position')}}</h2>
          <p class="flex items-center font-medium col-span-10">Striker</p>
        </div>
        <div class="flex mt-3 grid grid-cols-12">
          <h2 class="text-xl col-span-2">{{i18n('sports.playerView.country')}}</h2>
          <p class="flex items-center font-medium col-span-10">Egypt</p>
        </div>
        <div class="flex mt-3 grid grid-cols-12">
          <h2 class="text-xl col-span-2">{{i18n('sports.playerView.age')}}</h2>
          <p class="flex items-center font-medium col-span-10">21</p>
        </div>
        <div class="flex mt-3 grid grid-cols-12">
          <h2 class="text-xl col-span-2">{{i18n('sports.playerView.height')}}</h2>
          <p class="flex items-center font-medium col-span-10">180 cm</p>
        </div>
        <div class="flex mt-3 grid grid-cols-12">
          <h2 class="text-xl col-span-2">{{i18n('sports.playerView.weight')}}</h2>
          <p class="flex items-center font-medium col-span-10">80 Kg</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.sports')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.sports')
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach(element => {
          this.selected.push(element.id)
        })
      }
    },
    goToUser() {
      this.$router.push('/user-view-page')
    },
    viewArticle() {
      this.$router.push('/article-view-page')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
