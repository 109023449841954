
<template>
    <div class="mobile-table-card">
        <img class="right-part" :class="isShowen ? `` : 'closed'" src="@/assets/images/arrow-up.svg" @click="toggle"
            alt=">">
        <div class="left-part" id="left-part" :class="isShowen ? 'menuOpen' : ''">
            <slot></slot>
        </div>
    </div>
</template>

<script>
/* eslint-disable eol-last */
export default {
    data() {
        return {
            isShowen: false
        }
    },
    methods: {
        toggle() {
            this.isShowen = !this.isShowen
        }
    }
}
</script>

<style scoped>
* {
    transition: all 1s ease !important;
}

/* eslint-disable eol-last */
.mobile-table-card {
    display: flex;
    gap: 20px;
    padding: 20px 20px;
    border-top: 2px solid #eee;
    transition: all 1s ease !important;

}

@media (max-width:500px) {
    .mobile-table-card {
        padding: 20px 0px;
    }
}

.mobile-table-card>.right-part {
    background: rgb(10, 4, 138);
    width: 27px;
    height: 27px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;

}

.mobile-table-card>.left-part {
    display: grid;
    gap: 20px;
    align-items: center;
    transition: all 1s ease !important;
    overflow: hidden;
    max-height: 30px;
    width: 100%;

}

.mobile-table-card>.left-part ::v-deep .field {
    min-height: 27px;
    overflow: hidden;
    width: 100% !important;
    vertical-align: middle;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;

}

.mobile-table-card>.left-part ::v-deep .field span {
    word-break: keep-all;
    white-space: nowrap;
}

.closed {
    transform: rotate(180deg);
}

.menuOpen {
    max-height: 600px !important;
    overflow: visible
}

/* .left-part ::v-deep .field:not(.field:first-child) {
    transform: scaleY(0);
    transition: all 0.2s linear;
}
*/
/* #left-part ::v-deep .field div {
    max-height: 27px;
    transition: all 1s ease;
}

.menuOpen ::v-deep .field div {
    max-height: 1000px !important;
    transition: all 1s ease;
} */
</style>