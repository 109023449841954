/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlCategory)

export class CategoryService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query CATEGORY_LIST(
          $filter: CategoryFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          categoryList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              name {
                en
                ar
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.categoryList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query CATEGORY_FIND($id: String!) {
          categoryFind(id: $id) {
            id
            name {
              en
              ar
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.categoryFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CATEGORY_CREATE($data: CategoryInput!) {
          categoryCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.categoryCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CATEGORY_UPDATE($id: String!, $data: CategoryInput!) {
          categoryUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.categoryUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CATEGORY_DESTROY($id: String!) {
          categoryDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.categoryDestroy
  }
}
