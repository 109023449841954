<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="parking.title.edit" v-if="isEditing"></app-i18n>
            <app-i18n code="parking.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !model"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('parking.form.description') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    rows="3"
                    id="title-en"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.description.en"
                    :placeholder="i18n('common.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <textarea
                    rows="3"
                    id="title-ar"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.description.ar"
                    :placeholder="i18n('common.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('parking.form.adminName') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.adminName.en"
                    :placeholder="i18n('common.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.adminName.ar"
                    :placeholder="i18n('common.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('telecom.form.time') }}
                </h4>
                <div
                  class="
                    lg:col-span-8
                    col-span-12
                    lg:mt-0
                    mt-2
                    grid
                    gap-y-2
                    items-center
                  "
                >
                  <div
                    class="grid grid-cols-12 relative"
                    v-for="(input, index) in model.openHours"
                    :key="`time-${index}`"
                  >
                    <div class="lg:col-span-6 col-span-11 lg:mt-0 mt-2">
                      <input
                        id="crud-form-1"
                        type="text"
                        class="form-control w-full dark:placeholder-white"
                        v-model="input.en"
                        :placeholder="i18n('common.english')"
                      />
                    </div>
                    <div
                      class="lg:col-span-6 col-span-11 lg:mt-0 mt-2"
                      :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                    >
                      <input
                        id="crud-form-1"
                        type="text"
                        class="form-control w-full dark:placeholder-white"
                        v-model="input.ar"
                        :placeholder="i18n('common.arabic')"
                      />
                    </div>
                    <svg
                      v-if="index === model.openHours.length - 1"
                      @click="addField()"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="
                        ml-2
                        cursor-pointer
                        absolute
                        top-10
                        lg:top-2
                        right-10
                        lg:-right-8
                      "
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="green"
                        d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                      />
                    </svg>
                    <svg
                      v-show="model.openHours.length > 1"
                      @click="removeField(index)"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="
                        ml-2
                        cursor-pointer
                        absolute
                        top-10
                        lg:top-2 lg:-right-14
                        right-4
                      "
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="#ff0000"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('parking.form.phoneNumber') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  style="dir: ltr"
                >
                  <vue-tel-input
                    v-model="model.adminPhoneNumber"
                    mode="international"
                    validCharactersOnly
                    :dropdownOptions="telDropOptions"
                    :inputOptions="isRTL ? telOptionsAr : telOptions"
                  ></vue-tel-input>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('parking.table.annualSubscription') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="crud-form-1"
                    type="number"
                    class="form-control w-full dark:placeholder-white"
                    v-model="model.annualSubscription"
                    :placeholder="i18n('parking.table.annualSubscription')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('branches.fields.branch') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 items-center"
                >
                  <v-select
                    :options="branchOptions || []"
                    :label="labelLocalized"
                    v-model="model.branch"
                    class="select-center"
                    title="Select item"
                  >
                    <template #no-options
                      ><div class="loading-loader"></div> </template
                  ></v-select>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('restaurants.fields.banner') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && bannerUrl">
                    <img
                      :src="bannerUrl"
                      class="object-cover object-center"
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="
                        delete-icon
                        w-12
                        h-12
                        absolute
                        bottom-0
                        left-0
                        cursor-pointer
                      "
                      @click="model.banner = undefined"
                    />
                  </div>
                  <Uploader ref="uploaderBanner" v-else></Uploader>
                </div>
              </div>
              <!-- END -->
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="
                    btn btn-primary
                    w-24
                    cursor-pointer
                    text-white
                    cursor-pointer
                  "
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="
                    btn
                    bg-theme-31
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  "
                  @click="doCancel"
                >
                  {{ i18n('common.cancel') }}
                </button>
              </div>
            </form>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import Message from '@/shared/message/toastify'
import { mapGetters, mapActions } from 'vuex'
import Uploader from '@/views/parking/uploader.vue'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.parking')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.parking')
    }
  },
  props: {
    id: String
  },
  components: {
    Uploader
  },
  data() {
    return {
      model: null,
      branchOptions: [],
      errorMessage: null,
      telDropOptions: { showFlags: true },
      telOptions: {
        placeholder: 'Enter Admin Phone Number',
        showDialCode: true
      },
      telOptionsAr: { placeholder: 'ادخل رقم المشرف', showDialCode: true }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'parking/form/record',
      branches: 'branch/list/rows',
      findLoading: 'parking/form/findLoading',
      saveLoading: 'parking/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    },
    labelLocalized() {
      return this.isRTL ? 'nameAr' : 'nameEn'
    },
    filesToSaveBanner() {
      return this.$refs.uploaderBanner?.filesToSave || []
    },
    bannerUrl() {
      return this.model && this.model.banner ? this.model.banner : null
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    await this.doFetchBranches({
      filter: {},
      pagination: {
        limit: 10,
        sortBy: 'asc'
      }
    })
    this.branchOptions = this.branches.map((branch) => ({
      ...branch,
      nameAr: branch.name.ar,
      nameEn: branch.name.en
    }))
    this.model = this.record || {
      description: {
        en: '',
        ar: ''
      },
      adminName: {
        en: '',
        ar: ''
      },
      branch: '',
      adminPhoneNumber: '',
      annualSubscription: undefined,
      openHours: [
        {
          en: '',
          ar: ''
        }
      ],
      banner: '',
      places: []
    }
    if (this.isEditing) {
      this.model.openHours =
        this.record && this.record.openHours?.length > 0
          ? this.record.openHours
          : [
              {
                en: '',
                ar: ''
              }
            ]
      this.model.places = this.record.places
      this.model.branch = this.branchOptions.filter(
        (branch) => branch.id === this.record.branchId
      )[0]
    }
  },
  methods: {
    ...mapActions({
      doNew: 'parking/form/doNew',
      doFetchBranches: 'branch/list/doFetch',
      doFind: 'parking/form/doFind',
      doCreate: 'parking/form/doCreate',
      doUpdate: 'parking/form/doUpdate'
    }),
    addField() {
      this.model.openHours.push({ en: '', ar: '' })
    },
    removeField(index) {
      this.model.openHours.splice(index, 1)
    },
    isFormValid() {
      if (
        this.model.description.en.length == 0 ||
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      if (
        this.model.adminName.en.length == 0 ||
        this.model.adminName.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.openHours.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.adminPhoneNumber.length < 4) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (!this.model.branch || this.model.branch.id.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.isEditing) {
        if (!(this.bannerUrl || this.filesToSaveBanner.length !== 0)) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      } else {
        if (this.filesToSaveBanner.length === 0) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/parking')
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const {
        description,
        adminName,
        adminPhoneNumber,
        branch,
        places,
        annualSubscription,
        openHours
      } = this.model
      if (this.isEditing) {
        await this.doUpdate({
          id: this.id,
          description,
          adminName,
          adminPhoneNumber,
          branchId: branch.id,
          places,
          annualSubscription,
          openHours,
          banner: this.bannerUrl || this.filesToSaveBanner[0]
        })
      } else {
        await this.doCreate({
          description,
          adminName,
          adminPhoneNumber,
          branchId: branch.id,
          places,
          annualSubscription,
          openHours,
          banner: this.filesToSaveBanner[0]
        })
      }
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
