/* eslint-disable */ 
import articleListStore from '@/store/article/article-list-store';
import articleFormStore from '@/store/article/article-form-store';
import articleViewStore from '@/store/article/article-view-store';
import articleDestroyStore from '@/store/article/article-destroy-store';

export default {
  namespaced: true,

  modules: {
    list: articleListStore,
    form: articleFormStore,
    view: articleViewStore,
    destroy: articleDestroyStore
  },
};
