const state = () => {
  return {
    menu: [
      // {
      //   icon: 'HomeIcon',
      //   pageName: 'home',
      //   title: 'Recent Materials'
      // },

      {
        icon: 'administration',
        pageName: 'administration',
        title: 'Administration'
      },
      {
        icon: 'UsersIcon',
        pageName: 'admins',
        title: 'Admins'
      },
      {
        icon: 'FileTextIcon',
        pageName: 'article',
        title: 'Articles'
      },
      {
        icon: 'ImageIcon',
        pageName: 'media',
        title: 'Media'
      },
      {
        icon: 'VideoIcon',
        pageName: 'live-videos',
        title: 'Live Videos'
      },
      {
        icon: 'ShoppingBagIcon',
        pageName: 'ads',
        title: 'Ads'
      },
      {
        icon: 'logs',
        pageName: 'logs',
        title: 'logs'
      },
      {
        icon: 'GridIcon',
        pageName: 'others',
        title: 'Others'
      }
      // {
      //   icon: 'SportsIcon',
      //   pageName: 'sports',
      //   title: 'Sports'
      // },
      // {
      //   icon: 'MapPinIcon',
      //   pageName: 'branch',
      //   title: 'Branches'
      // },
      // // {
      // //   icon: 'GridIcon',
      // //   pageName: 'notifications',
      // //   title: 'Categories'
      // // },
      // {
      //   icon: 'ChefIcon',
      //   pageName: 'restaurant',
      //   title: 'Restaurants'
      // },
      // {
      //   icon: 'LayersIcon',
      //   pageName: 'auction',
      //   title: 'Auctions'
      // },
      // {
      //   icon: 'BookOpenIcon',
      //   pageName: 'library-view-page',
      //   title: 'Library'
      // },
      // {
      //   icon: 'BriefcaseIcon',
      //   pageName: 'clinic',
      //   title: 'Clinic'
      // }
      // 'devider'
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
