<template>
  <section>
    <router-view />
    <ErrorNotify message="--text--"></ErrorNotify>
    <SuccessNotify message="--text--"></SuccessNotify>
  </section>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { useStore } from '@/store'
import { getLanguageCode } from '@/locales/i18n'

export default defineComponent({
  async created() {
    console.log('VUE_APP_ENVIRONMENT =', process.env.VUE_APP_ENVIRONMENT)
    await this.doInit()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapGetters({
      language: 'layout/currentLanguageCode'
    })
  },
  methods: {
    ...mapActions({
      doInitFirebase: 'auth/doInitFirebase',
      doInit: 'auth/doInit',
      resize: 'layout/resize'
    }),
    handleResize() {
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
  },
  setup() {
    const store = useStore()
    const anonymousToken = ref('')
    onMounted(() => {
      const language = getLanguageCode() || localStorage.getItem('language')
      console.log('language =', language)
      language == 'ar'
        ? document.body.setAttribute('dir', 'rtl')
        : document.body.setAttribute('dir', 'ltr')
    })
    return {
      store,
      anonymousToken
    }
  }
})
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

[dir='rtl'] body {
  font-family: 'Tajawal', sans-serif !important;
}

[dir='ltr'] body {
  font-family: 'Tajawal', sans-serif !important;
}

#app {
  overflow: hidden !important;
}

.dropzone {
  height: fit-content !important;
}

.loading-loader {
  width: 30px;
  height: 30px;
  margin: 10px auto;
  border-top: 5px solid #292929;
  border-right: 5px solid #efefef;
  border-bottom: 5px solid #efefef;
  border-left: 5px solid #efefef;
  border-radius: 100px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width:1279px) {
  .top-menu--active img {
    filter: invert(69%) sepia(0%) saturate(7500%) hue-rotate(94deg) brightness(91%) contrast(109%) !important
  }
}
</style>
