/* eslint-disable */

import kindergartenListStore from '@/store/kindergarten/kindergarten-list-store'
import kindergartenViewStore from '@/store/kindergarten/kindergarten-view-store'
import kindergartenFormStore from '@/store/kindergarten/kindergarten-form-store'

export default {
  namespaced: true,

  modules: {
    list: kindergartenListStore,
    form: kindergartenFormStore,
    view: kindergartenViewStore
    // destroy: kindergartenDestroyStore
  }
}
