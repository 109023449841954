/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(
  config.backendUrlAdministration
)

export class AdministrationService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST(
          $filter: DirectorFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          directorList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              order
              avatar {
                name
                publicUrl
              }
              name {
                en
                ar
              }
              jobTitle {
                en
                ar
              }
              facebookUrl
              instagramUrl
              twitterUrl

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy: 'order',
        pagination
      }
    })

    return response.data.directorList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query FIND($id: String!) {
          directorFind(id: $id) {
            id
            order
            avatar {
              name
              publicUrl
            }
            name {
              en
              ar
            }
            jobTitle {
              en
              ar
            }
            facebookUrl
            instagramUrl
            twitterUrl

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id
      }
    })

    return response.data.directorFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE($data: DirectorInput!) {
          directorCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.directorCreate
  }
  static async createMultiple(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE($data: [DirectorInput!]) {
          addMultipleDirectors(data: $data)
        }
      `,
      variables: {
        data
      }
    })
    return response.data.addMultipleDirectors
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation UPDATE($id: String!, $data: DirectorInput!) {
          directorUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.directorUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation DESTROY($id: String!) {
          directorDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.directorDestroy
  }
  static async saveAdministration(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADMINISTRATION_SAVE($data: AdministrationInput!) {
          administrationSave(data: $data)
        }
      `,
      variables: {
        data
      }
    })
    return response.data.administrationSave
  }
  static async findAdministration() {
    const response = await graphqlClient.query({
      query: gql`
        query {
          administrationFindDefault {
            id
            description {
              en
              ar
            }
            presidentName {
              en
              ar
            }
            presidentPhoto {
              name
              publicUrl
            }
            # directors
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `
    })

    return response.data.administrationFindDefault
  }
}
