<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('media.title.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box dark:bg-dark-8 p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            class="btn btn-primary sm:mt-2 mx-3 w-full sm:w-28 p-1"
            @click="createMedia()"
          >
            <PlusIcon />
            {{ i18n('common.create') }}
          </button>
        </div>
      </div>
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading || !rows"
      />
      <div class="overflow-x-auto g">
        <table class="table table-report sm:mt-2 hidden md:table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('media.form.title') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('media.form.description') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('common.table.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" :key="index">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ index + 1 }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row, 'title') }}
                </div>
              </td>
              <td class="text-center w-72">
                <div
                  class="font-medium whitespace-nowrap cursor-pointer w-72 truncate"
                >
                  {{ presenterLocalization(row, 'description') }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div
                  class="flex justify-center items-center gap-3 text-theme-14 dark:text-white cursor-pointer"
                >
                  <div @click="viewMedia(row.id)">
                    <EyeIcon class="w-4 h-4 mr-1" />
                  </div>
                  <div @click="editMedia(row.id)">
                    <EditIcon class="w-4 h-4 mr-1" />
                  </div>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    class="text-theme-24"
                    @click="deleteId = row.id"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mobile-table md:hidden mt-8">
          <mobile-table-comp
            v-for="(row, index) in rows"
            :key="index"
            :row="row"
            class="md:hidden"
          >
            <div class="field">
              <span> {{ i18n('media.form.title') }} </span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterLocalization(row, 'title') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('media.form.description') }} :</span>
              <div
                class="font-medium whitespace-nowrap cursor-pointer truncate"
              >
                {{ presenterLocalization(row, 'description') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('common.table.actions') }} :</span>
              <div
                class="flex justify-center items-center gap-3 text-theme-14 dark:text-white cursor-pointer"
              >
                <div @click="viewMedia(row.id)">
                  <EyeIcon class="w-4 h-4 mr-1" />
                </div>
                <div @click="editMedia(row.id)">
                  <EditIcon class="w-4 h-4 mr-1" />
                </div>
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-modal-preview"
                  class="text-theme-24"
                  @click="deleteId = row.id"
                >
                  <Trash2Icon class="w-4 h-4 mr-1" />
                </a>
              </div>
            </div>
          </mobile-table-comp>
        </div>
        <app-empty-page
          v-if="!rows.length && !loading"
          :label="i18n('empty.media')"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
      <Pagination
        v-if="rows.length"
        :page="currentPage"
        :pageSize="pagination.limit"
        :rowsNumber="pagination.total"
        :showSizeChanger="true"
        :isFirstPage="isFirstPage"
        :isLastPage="isLastPage"
        :disabled="!rows.length"
        position="center"
        @next-page="doNextPage"
        @prev-change="doPrevPage"
        @size-change="doChangePaginationPageSize"
      >
      </Pagination>

      <!-- <div class="mobile-table-card">
            <img class="right-part" :class="isShowen ? `` : 'closed'" src="@/assets/images/arrow-up.svg" @click="toggle"
              alt=">">
            <div class="left-part " :class="isShowen ? `` : ''">
              <div class="field">
                <span>Name</span>
                <span>Data-content-data-asds</span>
              </div>
              <div class="field">
                <span>Name</span>
                <span>Data-content-data-asds</span>
              </div>
              <div class="field">
                <span>Name</span>
                <span>Data-content-data-asds</span>
              </div>
              <div class="field">
                <span>Name</span>
                <span>Data-content-data-asds</span>
              </div>
              <div class="field">
                <span>Name</span>
                <span>Data-content-data-asds</span>
              </div>
            </div>

          </div> -->
      <!-- <div class="mobile-table-card">
            <img class="right-part" :class="isShowen ? `` : 'closed'" src="@/assets/images/arrow-up.svg" @click="toggle"
              alt=">">
            <div class="left-part" id="left-part" :class="isShowen ? 'menuOpen' : ''">

              <div class="field" v-for="n, i in county" :key="i">
                <span>Name</span>
                <span>Data-content-data-asds</span>
              </div>
            </div>
          </div> -->
    </div>
    <!-- END: HTML Table Data -->
    <DeleteModal item="media" @delete="doDestroy(deleteId)" />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import feather from 'feather-icons'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { mapActions, mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.media')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.media')
    }
  },
  components: {
    DeleteModal
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'media/list/rows',
      currentPage: 'media/list/currentPage',
      isFirstPage: 'media/list/isFirstPage',
      isLastPage: 'media/list/isLastPage',
      loading: 'media/list/loading',
      pagination: 'media/list/pagination'
    })
  },
  setup() {
    const router = useRouter()
    const tabulator = ref()
    const county = ref(7)
    const isShowen = ref(false)

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    const editMedia = (id) => {
      router.push(`/media-form-page/${id}`)
    }

    const viewMedia = (id) => {
      router.push(`/media/${id}`)
    }

    const createMedia = () => {
      router.push('/media-form-page')
    }

    return {
      viewMedia,
      editMedia,
      createMedia,
      isShowen,
      county
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    toggle() {
      this.isShowen = !this.isShowen
      console.log(this.isShowen)
    },
    ...mapActions({
      doFetch: 'media/list/doFetch',
      doFetchNextPage: 'media/list/doFetchNextPage',
      doFetchPreviousPage: 'media/list/doFetchPreviousPage',
      doChangePaginationCurrentPage: 'media/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'media/list/doChangePaginationPageSize',
      doDestroy: 'media/list/doDestroy'
    }),
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: {},
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}

.mobile-table-card {
  display: flex;
  gap: 20px;
  padding: 20px;
  border-top: 2px solid #eee;
  transition: all 1s ease !important;
}

.mobile-table-card > .right-part {
  background: rgb(10, 4, 138);
  width: 27px;
  height: 27px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
}

.mobile-table-card > .left-part {
  display: grid;
  gap: 20px;
  align-items: center;
  transition: all 1s ease !important;
  overflow: hidden;
  max-height: 27px;
}

.mobile-table-card > .left-part > .field {
  min-height: 27px;
  vertical-align: middle;
  display: flex;
  gap: 20px;
  align-items: center;
}

.closed {
  transform: rotate(180deg);
}

.menuOpen {
  max-height: 1000px !important;
  overflow: visible;
}
</style>
