<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">
        <app-i18n code="administration.view.directors"></app-i18n>
      </h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="
                  flex flex-col
                  sm:flex-row sm:items-end
                  xl:items-start
                  justify-end
                ">
        <div class="flex mt-5 sm:mt-0">
          <button class="btn btn-primary  sm:mt-2 mx-3 w-full sm:w-28 p-2" @click="$router.push(`administration/add`)">
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
        </div>
      </div>
      <div ref="table" class="relative">
        <loading-spinner type="half-circle" :duration="1000" :size="40" color="#000" :loading="loading || !rows" />
        <div class="overflow-x-auto">
          <table class="table table-report sm:mt-2 hidden md:table" v-if="rows">
            <thead>
              <tr>
                <th class="whitespace-nowrap">#</th>
                <th v-for="col in columns" :key="col.name" class="text-center whitespace-nowrap">
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                <td class="w-40">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                    {{ index + 1 }}
                  </div>
                </td>
                <td class="text-center flex items-center justify-center">
                  <app-list-item-image :value="row.avatar?.publicUrl"></app-list-item-image>
                </td>
                <td>
                  <div class="font-medium whitespace-nowrap truncate text-center">
                    {{ presenterLocalization(row, 'name') }}
                  </div>
                </td>
                <td>
                  <div class="font-medium whitespace-nowrap truncate text-center">
                    {{ presenterLocalization(row, 'jobTitle') }}
                  </div>
                </td>
                <td class="table-report__action w-60">
                  <div class="flex justify-center items-center gap-4">
                    <div class="cursor-pointer" @click="$router.push(`administration/director/${row.id}`)">
                      <EditIcon class="w-4 h-4 mr-1" />
                      {{ i18n('common.edit') }}
                    </div>
                    <a href="javascript:;" data-toggle="modal" data-target="#delete-modal-preview"
                      @click="deleteId = row.id" class="
                                text-theme-24
                                flex
                                items-center
                                gap-1
                                whitespace-nowrap
                              ">
                      <Trash2Icon class="w-4 h-4" /> {{ i18n('common.delete') }}
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="mobile-table md:hidden mt-8">

            <mobile-table-comp v-for="(row, index) in rows" :key="index" :row="row" class="md:hidden">
              <div class="field">
                <span>{{ i18n('administration.form.name') }} </span>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row, 'name') }}
                </div>
              </div>
              <div class="field">
                <span> {{ i18n('administration.form.avatar') }} :</span>
                <app-list-item-image :value="row.avatar?.publicUrl"></app-list-item-image>
              </div>
              <div class="field">
                <span> {{ i18n('administration.form.jobTitle') }} :</span>
                <div class="font-medium text-right">
                  {{ presenterLocalization(row, 'jobTitle') }}
                </div>
              </div>
              <div class="field">
                <span> {{ i18n('common.actions') }} :</span>
                <div class="flex justify-center items-center gap-4">
                  <div class="cursor-pointer" @click="$router.push(`administration/director/${row.id}`)">
                    <EditIcon class="w-4 h-4 mr-1" />
                    {{ i18n('common.edit') }}
                  </div>
                  <a href="javascript:;" data-toggle="modal" data-target="#delete-modal-preview"
                    @click="deleteId = row.id" class="
                      text-theme-24
                      flex
                      items-center
                      gap-1
                      whitespace-nowrap
                    ">
                    <Trash2Icon class="w-4 h-4" /> {{ i18n('common.delete') }}
                  </a>
                </div>
              </div>

            </mobile-table-comp>
          </div>
          <div>
            <app-empty-page v-if="!rows?.length" :label="i18n('empty.directors')" label-class="text-lg font-medium"
              icon-size="4x" class="box w-full py-64"></app-empty-page>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal item="directors" @delete="doDestroy(deleteId)" />
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import DeleteModal from '@/components/modals/delete-modal.vue'

export default {
  name: 'table-view-page',
  components: {
    DeleteModal
  },
  props: ['id', 'places'],
  data() {
    return {
      deleteId: null,
      columns: [
        {
          name: 'avatar',
          field: 'avatar',
          label: 'administration.form.avatar',
          align: 'center'
        },
        {
          name: 'name',
          field: 'name',
          label: 'administration.form.name',
          align: 'center'
        },
        {
          name: 'jobTitle',
          field: 'jobTitle',
          label: 'administration.form.jobTitle',
          align: 'center'
        },
        // {
        //   name: 'facebook',
        //   field: 'facebook',
        //   label: 'administration.form.facebook',
        //   align: 'center'
        // },
        // {
        //   name: 'instagram',
        //   field: 'instagram',
        //   label: 'administration.form.instagram',
        //   align: 'center'
        // },
        // {
        //   name: 'twitter',
        //   field: 'twitter',
        //   label: 'administration.form.twitter',
        //   align: 'center'
        // },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'administration/list/rows',
      currentPage: 'administration/list/currentPage',
      isFirstPage: 'administration/list/isFirstPage',
      branches: 'branch/list/rows',
      branchesLoading: 'branch/list/loading',
      isLastPage: 'administration/list/isLastPage',
      loading: 'administration/list/loading',
      pagination: 'administration/list/pagination'
    })
  },
  async mounted() {
    await this.doFetch({
      filter: {},
      pagination: {
        limit: 100,
        sortBy: 'asc'
      }
    })
    console.log(this.rows)
  },
  methods: {
    ...mapActions({
      doFetch: 'administration/list/doFetch',
      doFetchNextPage: 'administration/list/doFetchNextPage',
      doFetchBranches: 'branch/list/doFetch',
      doFetchPreviousPage: 'administration/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'administration/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'administration/list/doChangePaginationPageSize',
      doDestroy: 'administration/list/doDestroy'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      if (!row) return 'ـــ'
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      if (!row) return 'ـــ'
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      if (!row) return 'ـــ'
      return GenericModel.presenterLocalization(row, fieldName)
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
