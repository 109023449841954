<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">
        <app-i18n code="parking.view.places"></app-i18n>
      </h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="
            flex flex-col
            sm:flex-row sm:items-end
            xl:items-start
            justify-end
          ">
        <div class="flex mt-5 sm:mt-0">
          <button class="btn btn-primary sm:w-auto pb-1 pt-1" @click="$router.push(`/parking/${id}/places-form-page`)">
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
        </div>
      </div>
      <div ref="table" class="relative">
        <loading-spinner type="half-circle" :duration="1000" :size="40" color="#000" :loading="loading" />
        <div class="overflow-x-auto">
          <table class="table table-report sm:mt-2" v-if="places?.length">
            <thead>
              <tr>
                <th class="whitespace-nowrap">#</th>
                <th v-for="col in columns" :key="col.name" class="text-center whitespace-nowrap">
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in places" :key="index" class="intro-x">
                <td class="w-40">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                    {{ index + 1 }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap truncate">
                    {{ isRTL ? row.ar : row.en }}
                  </div>
                </td>
                <td class="table-report__action w-60">
                  <div class="flex justify-center items-center gap-4">
                    <a href="javascript:;" data-toggle="modal" data-target="#delete-modal-preview"
                      @click="selectedPlace = row" class="
                          text-theme-24
                          flex
                          items-center
                          gap-1
                          whitespace-nowrap
                        ">
                      <Trash2Icon class="w-4 h-4" /> {{ i18n('common.delete') }}
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <app-empty-page v-if="!places?.length" :label="i18n('empty.places')" label-class="text-lg font-medium"
              icon-size="4x" class="box w-full py-64"></app-empty-page>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal item="place" @delete="doDelete()" />
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import DeleteModal from '@/components/modals/delete-modal.vue'

export default {
  name: 'table-view-page',
  components: {
    DeleteModal
  },
  props: ['id', 'places'],
  data() {
    return {
      selectedPlace: null,
      columns: [
        {
          name: 'name',
          field: 'name',
          label: 'clinic.form.name',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    })
  },
  methods: {
    ...mapActions({
      doDestroy: 'parking/list/doDestroyPlace'
    }),
    async doDelete() {
      await this.doDestroy({ id: this.id, places: this.selectedPlace })
      this.$emit('doRefresh')
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      if (!row) return 'ـــ'
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      if (!row) return 'ـــ'
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      if (!row) return 'ـــ'
      return GenericModel.presenterLocalization(row, fieldName)
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
