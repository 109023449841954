/* eslint-disable */ import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { getLanguageCode } from '@/locales/i18n'
import { RetryLink } from 'apollo-link-retry'
import { AuthToken } from '@/store/auth/auth-token'
// import config from '@/config';
// let backendUrl = config.backendUrl + '/user'

export default class GraphqlClient {
  static initApolloClient(backendUrl) {
    if (!backendUrl) {
      console.log('The backendUrl is required')
      return
    }

    const retryLink = new RetryLink({
      attempts: {
        max: 2
      }
    })

    const authLink = setContext(async (_, { headers }) => {
      let token = await AuthToken.get()
      console.log('Token =', token)

      return {
        headers: {
          ...headers,
          'Access-Control-Allow-Origin': '*',
          Authorization: token ? `Bearer ${token}` : '',
          'Accept-Language': getLanguageCode(),
          'application-type': 'web',
          'account-type': 'media'
        }
      }
    })

    const httpLink = createHttpLink({
      uri: `${backendUrl}`
    })

    const defaultOptions = {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    }
    const graphqlClient = new ApolloClient({
      link: retryLink.concat(authLink.concat(httpLink)),
      cache: new InMemoryCache({
        addTypename: false
      }),
      defaultOptions: defaultOptions
    })

    return graphqlClient
  }
}
