/* eslint-disable */

import clinicListStore from '@/store/clinic/clinic-list-store'
import clinicFormStore from '@/store/clinic/clinic-form-store'
import clinicViewStore from '@/store/clinic/clinic-view-store'

export default {
  namespaced: true,

  modules: {
    list: clinicListStore,
    form: clinicFormStore,
    view: clinicViewStore
  }
}
