/* eslint-disable */ import { ArticleService } from '@/store/article/article-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.record = null
    },

    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },

    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },

    ADD_STARTED(state) {
      state.saveLoading = true
    },

    ADD_SUCCESS(state) {
      state.saveLoading = false
    },

    ADD_ERROR(state) {
      state.saveLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED')
        const record = await ArticleService.find(id)
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/article')
      }
    },

    async doAdd({ commit, rootGetters }, values) {
      try {
        console.log('valuesssss', values)
        commit('ADD_STARTED')
        console.log('Real valuesssss', values)
        const record = await ArticleService.create(values)
        commit('ADD_SUCCESS')
        Message.success(i18n('articles.create.success'))

        // const currentArticle = rootGetters['auth/currentArticle'];
        // const hasPermissionToAccessPages = new PermissionChecker(currentArticle).match(Permissions.values.pagesAccessRead)

        // if (hasPermissionToAccessPages) {
        //   router.push(`/admins/${record.id}/access-pages`);
        // } else {
        //   router.push('/admins');
        // }
        router.push('/article')
      } catch (error) {
        Errors.handle(error)
        commit('ADD_ERROR')
      }
    },

    async doUpdate({ commit, rootGetters, dispatch }, { id, ...values }) {
      try {
        commit('UPDATE_STARTED')
        await ArticleService.edit(id, values)
        commit('UPDATE_SUCCESS')
        // const currentArticle = rootGetters['auth/currentArticle'];
        // if (currentArticle.id === id) {
        //   dispatch('auth/doRefreshCurrentArticle');
        // }
        Message.success(i18n('articles.update.success'))
        router.push('/article')
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    }

    // async doSetAdminAccess({ commit, rootGetters, dispatch }, { id, pagesAccess }) {
    //   try {
    //     commit('UPDATE_STARTED');
    //     await ArticleService.setAdminAccess(id, pagesAccess);
    //     commit('UPDATE_SUCCESS');
    //     const currentArticle = rootGetters['auth/currentArticle'];
    //     if (currentArticle.id === id) {
    //       dispatch('auth/doRefreshCurrentArticle');
    //     }
    //     Message.success(i18n('admin.update.success'));
    //     router.push('/admins');
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('UPDATE_ERROR');
    //   }
    // },
  }
}
