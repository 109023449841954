<template>
  <div>
    <div class="intro-y px-5 flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">
        {{ i18n('culturalService.title.title') }}
      </h2>
      <div
        class="btn btn-primary mt-6 sm:mt-2 mx-3 w-full sm:w-28 p-1"
        :class="isRTL ? 'mr-auto' : 'ml-auto'"
        @click="createTelecom()"
      >
        <PlusIcon class="mr-2" /> {{ i18n('common.create') }}
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box dark:bg-dark-8 p-5 sm:mt-5">
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <div class="overflow-x-auto g">
        <table class="table table-report sm:mt-2 hidden md:table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('culturalService.table.logo') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('culturalService.table.description') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('common.table.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" :key="index">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ index + 1 }}
                </div>
              </td>
              <td class="text-center flex items-center justify-center">
                <app-list-item-image :value="row.banner"></app-list-item-image>
              </td>
              <td class="text-center w-72">
                <div
                  class="font-medium whitespace-nowrap text-center w-72 truncate"
                >
                  {{ toString(row.description) }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div
                  class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer"
                >
                  <div @click="editTelecom(row.id)">
                    <EditIcon class="w-4 h-4 mr-1" />
                  </div>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    class="text-theme-24 ml-2"
                    @click="deleteId = row.id"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mobile-table md:hidden mt-8">
          <mobile-table-comp
            v-for="(row, index) in rows"
            :key="index"
            :row="row"
            class="md:hidden"
          >
            <div class="field">
              <span>{{ i18n('culturalService.table.logo') }} </span>
              <app-list-item-image :value="row.banner"></app-list-item-image>
            </div>
            <div class="field">
              <span> {{ i18n('culturalService.table.description') }} :</span>
              <div class="font-medium whitespace-nowrap text-center truncate">
                {{ toString(row.description) }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('common.table.actions') }} :</span>
              <div
                class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer"
              >
                <div @click="editTelecom(row.id)">
                  <EditIcon class="w-4 h-4 mr-1" />
                </div>
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-modal-preview"
                  class="text-theme-24 ml-2"
                  @click="deleteId = row.id"
                >
                  <Trash2Icon class="w-4 h-4 mr-1" />
                </a>
              </div>
            </div>
          </mobile-table-comp>
        </div>
        <app-empty-page
          v-if="!rows.length && !loading"
          :label="i18n('empty.culturalService')"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
      <Pagination
        v-if="rows.length"
        :page="currentPage"
        :pageSize="pagination.limit"
        :rowsNumber="pagination.total"
        :showSizeChanger="true"
        :isFirstPage="isFirstPage"
        :isLastPage="isLastPage"
        :disabled="!rows.length"
        position="center"
        @next-page="doNextPage"
        @prev-change="doPrevPage"
        @size-change="doChangePaginationPageSize"
      >
      </Pagination>
    </div>
    <!-- END: HTML Table Data -->
    <DeleteModal item="culturalService" @delete="doDestroy(deleteId)" />
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { GenericModel } from '@/shared/model/generic-model'
import { mapActions, mapGetters } from 'vuex'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.culturalService')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.culturalService')
    }
  },
  components: {
    DeleteModal
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'cultural/list/rows',
      currentPage: 'cultural/list/currentPage',
      isFirstPage: 'cultural/list/isFirstPage',
      isLastPage: 'cultural/list/isLastPage',
      loading: 'cultural/list/loading',
      pagination: 'cultural/list/pagination'
    })
  },
  setup() {
    const router = useRouter()

    const deleteId = ''

    const editTelecom = (id) => {
      router.push(`/cultural-form-page/${id}`)
    }

    const createTelecom = () => {
      router.push('/cultural-form-page')
    }

    return {
      deleteId,
      editTelecom,
      createTelecom
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
    console.log(this.rows)
  },
  methods: {
    ...mapActions({
      doFetch: 'cultural/list/doFetch',
      doFetchNextPage: 'cultural/list/doFetchNextPage',
      doFetchPreviousPage: 'cultural/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'cultural/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'cultural/list/doChangePaginationPageSize',
      doDestroy: 'cultural/list/doDestroy'
    }),
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: {},
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    toString(html) {
      return this.isRTL
        ? html.ar.replace(/<[^>]+>/g, '')
        : html.en.replace(/<[^>]+>/g, '')
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterArr(row, fieldName) {
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].join(', ')
        : 'ـــ'
    },
    presenterArrLocalized(row, fieldName) {
      const locale = this.isRTL ? 'ar' : 'en'
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].map((val) => val[locale]).join(',')
        : 'ـــ'
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    }
  }
}
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
