/* eslint-disable */

import mediaListStore from '@/store/media/media-list-store'
import mediaFormStore from '@/store/media/media-form-store'
// import mediaViewStore from '@/store/media/media-view-store'

export default {
  namespaced: true,

  modules: {
    list: mediaListStore,
    form: mediaFormStore,
    // view: mediaViewStore
  }
}
