<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('nonAthletes.view.title') }}
      </h2>
    </div>

    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        style="width: 100%; height: 30vh"
        class="flex justify-center"
        v-if="loading"
      >
        <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
      </div>
      <div v-if="record && !loading">
        <div class="p-5">
          <div class="flex flex-col px-5 gap-3 items-center justify-center">
            <div
              class="
                w-40
                h-40
                sm:w-full sm:h-24
                flex-none
                lg:w-full lg:h-32
                image-fit
                object-fit
                relative
              "
            >
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class=""
                data-action="zoom"
                :src="record.logo ? record.logo : '/images/preview-1.jpg'"
              />
            </div>
          </div>
        </div>
        <div class="p-5 border-t">
          <div class="relative flex items-center">
            <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
              <div class="font-medium mb-1">
                {{ i18n('nonAthletes.form.name') }}
              </div>
              <div class="text-gray-600 dark:text-gray-300">
                {{ presenterLocalization(record, 'name') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12">
      <table-view-page
        :subActivity="record?.details || []"
        :id="id"
        @doRefresh="doFind(id)"
      ></table-view-page>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import TableViewPage from '@/views/nonAthletes/nonAthletes-view-table-page.vue'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    [TableViewPage.name]: TableViewPage
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'activity/form/record',
      loading: 'activity/form/findLoading'
    })
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.nonAthletes')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.nonAthletes')
    }
  },
  async mounted() {
    await this.doFind(this.id)
    console.log(this.record)
  },
  methods: {
    ...mapActions({
      doFind: 'activity/form/doFind'
    }),
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
