/* eslint-disable */
// import { useStore } from '../store'
import { useStore } from '@/store'
// import PermissionChecker from '@/security/permission-checker';

export default {
  async beforeRouteEnter(to, from, next) {
    if (!to.meta) {
      next();
      return;
    }

    const store = useStore()
    let currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      await store.dispatch('auth/doWaitUntilInit');
    }

    //#region [1] auth-guard-mixin
    if (to.meta.auth) {
      if (!store.getters['auth/signedIn']) {
        next({ path: '/auth/login' });
        return;
      }
  
      // if (
      //   to.path !== '/auth/email-unverified' &&
      //   !store.getters['auth/currentUser'].emailVerified
      // ) {
      //   next({ path: '/auth/email-unverified' });
      //   return;
      // }
  
      // if (
      //   to.path !== '/auth/empty-permissions' &&
      //   store.getters['auth/currentUser'].emailVerified &&
      //   // !store.getters['auth/roles'] === 'admin'
      //   // !store.getters['auth/roles'].length
      //   !store.getters['auth/role']
      // ) {
      //   next({ path: '/auth/empty-permissions' });
      //   return;
      // }
    }
    //#endregion

    //#region [2] unauth-guard-mixin
    if (to.meta.unauth) {
      if (store.getters['auth/signedIn']) {
        next({ path: '/' });
        return;
      }
    }
    //#endregion

    //#region [4] not-empty-permissions-guard-mixin
    if (to.meta.notEmptyPermissions) {
      if (
        store.getters['auth/signedIn'] &&
        store.getters['auth/roles'].length
        // store.getters['auth/role'] === 'admin'
        // (store.getters['auth/role'] === 'owner' || store.getters['auth/role'] === 'admin')
      ) {
        next('/');
      } else {
        next();
      }
    }
    //#endregion

    //#region [5] permission-guard-mixin
    // if (!to.meta || !to.meta.permission) {
    //   if (new PermissionChecker(store.getters['auth/currentUser']).match(to.meta.permission)) {
    //     next();
    //   } else {
    //     next('/403');
    //   }
    // }
    //#endregion

    next();
  },
};
