<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('discounts.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box dark:bg-dark-8 p-5 sm:mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            class="btn btn-primary sm:mt-2 mx-3 w-full sm:w-28 p-1"
            @click="creatediscounts()"
          >
            <PlusIcon />
            {{ i18n('common.create') }}
          </button>
        </div>
      </div>
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <div class="overflow-x-auto g">
        <table class="table table-report sm:mt-2 hidden md:table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('discounts.table.title') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('discounts.table.description') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('discounts.type') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('discounts.table.phoneNumber') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('common.table.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" :key="index">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ index + 1 }}
                </div>
              </td>
              <td>
                <div
                  class="font-medium whitespace-nowrap truncate w-48 text-center"
                >
                  {{ presenterLocalization(row, 'name') }}
                </div>
              </td>
              <td>
                <div
                  class="font-medium whitespace-nowrap truncate w-48 text-center truncate"
                >
                  {{ presenterLocalization(row, 'description') }}
                </div>
              </td>
              <td>
                <div
                  class="font-medium whitespace-nowrap truncate w-48 text-center"
                >
                  {{ typePresenter(row.type) }}
                </div>
              </td>
              <td class="text-center">
                <div
                  class="font-medium whitespace-nowrap truncate cursor-pointer"
                >
                  {{ presenterArr(row, 'pohoneNumbers') }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div
                  class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer"
                >
                  <div @click="viewDiscounts(row.id, language, index)">
                    <EyeIcon class="w-4 h-4 mx-1" />
                  </div>
                  <div @click="editdiscounts(row.id)">
                    <EditIcon class="w-4 h-4 mr-1" />
                  </div>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    class="text-theme-24 ml-2"
                    @click="deleteId = row.id"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mobile-table md:hidden mt-8">
          <mobile-table-comp
            v-for="(row, index) in rows"
            :key="index"
            :row="row"
            class="md:hidden"
          >
            <div class="field">
              <span>{{ i18n('discounts.table.title') }} </span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterLocalization(row, 'name') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('discounts.table.description') }} :</span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterLocalization(row, 'description') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('discounts.table.address') }} :</span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ typePresenter(row.type) }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('discounts.table.phoneNumber') }} :</span>
              <div
                class="font-medium whitespace-nowrap truncate cursor-pointer"
              >
                {{ presenterArr(row, 'pohoneNumbers') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('common.table.actions') }} :</span>
              <div
                class="flex justify-center items-center text-theme-14 dark:text-white cursor-pointer"
              >
                <div @click="viewDiscounts(row.id, language, index)">
                  <EyeIcon class="w-4 h-4 mx-1" />
                </div>
                <div @click="editdiscounts(row.id)">
                  <EditIcon class="w-4 h-4 mr-1" />
                </div>
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-modal-preview"
                  class="text-theme-24 ml-2"
                  @click="deleteId = row.id"
                >
                  <Trash2Icon class="w-4 h-4 mr-1" />
                </a>
              </div>
            </div>
          </mobile-table-comp>
        </div>
        <app-empty-page
          v-if="!rows.length && !loading"
          :label="i18n('empty.discounts')"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
      <Pagination
        v-if="rows.length"
        :page="currentPage"
        :pageSize="pagination.limit"
        :rowsNumber="pagination.total"
        :showSizeChanger="true"
        :isFirstPage="isFirstPage"
        :isLastPage="isLastPage"
        :disabled="!rows.length"
        position="center"
        @next-page="doNextPage"
        @prev-change="doPrevPage"
        @size-change="doChangePaginationPageSize"
      >
      </Pagination>
    </div>
    <!-- END: HTML Table Data -->
    <DeleteModal item="discounts" @delete="doDestroy(deleteId)" />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import feather from 'feather-icons'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { mapActions, mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.discounts')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.discounts')
    }
  },
  components: {
    DeleteModal
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'discount/list/rows',
      currentPage: 'discount/list/currentPage',
      isFirstPage: 'discount/list/isFirstPage',
      isLastPage: 'discount/list/isLastPage',
      loading: 'discount/list/loading',
      pagination: 'discount/list/pagination'
    })
  },
  setup() {
    const router = useRouter()
    const tabulator = ref()

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }
    onMounted(() => {
      reInitOnResizeWindow()
    })

    const deleteId = ''

    const editdiscounts = (id) => {
      router.push(`/discounts-form-page/${id}`)
    }

    const creatediscounts = () => {
      router.push('/discounts-form-page')
    }

    return {
      deleteId,
      editdiscounts,
      creatediscounts
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
    console.log(this.rows)
  },
  methods: {
    ...mapActions({
      doFetch: 'discount/list/doFetch',
      doFetchNextPage: 'discount/list/doFetchNextPage',
      doFetchPreviousPage: 'discount/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'discount/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'discount/list/doChangePaginationPageSize',
      doDestroy: 'discount/list/doDestroy'
    }),
    viewDiscounts(id, lang, index) {
      this.rows[index].type == 'medical'
        ? window.open(
            `https://www.smouhaclub.com/${lang}/medical-discounts/${id}`
          )
        : window.open(
            `https://www.smouhaclub.com/${lang}/restaurants-discounts/${id}`
          )
    },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: {},
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach((element) => {
          this.selected.push(element.id)
        })
      }
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    typePresenter(type) {
      return this.isRTL && type == 'restaurant'
        ? 'مطاعم'
        : type == 'restaurant'
        ? 'restaurants'
        : this.isRTL && type == 'medical'
        ? 'طبي'
        : 'Medical'
    },
    presenterArr(row, fieldName) {
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].join(', ')
        : 'ـــ'
    },
    presenterArrLocalized(row, fieldName) {
      const locale = this.isRTL ? 'ar' : 'en'
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].map((val) => val[locale]).join(',')
        : 'ـــ'
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
