<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="
          inline-flex
          w-full
          justify-center
          rounded-md
          border border-gray-300
          bg-white
          px-4
          py-2
          text-sm
          font-medium
          text-gray-700
          shadow-sm
          hover:bg-gray-50
          focus:outline-none
        "
      >
        {{ language === 'ar' ? i18n('common.arabic') : i18n('common.english') }}
        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="
          absolute
          right-0
          z-10
          mt-2
          w-28
          text-center
          origin-top-right
          rounded-md
          bg-white
          shadow-lg
          ring-1 ring-black ring-opacity-5
          focus:outline-none
        "
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <a
              href="javascript:;"
              @click="switchLang('en')"
              class="flex"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm'
              ]"
              ><ActivityIcon class="w-4 h-4 mx-2" />
              {{ i18n('common.english') }}
            </a>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <a
              href="javascript:;"
              @click="switchLang('ar')"
              class="flex"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm'
              ]"
              ><ActivityIcon class="w-4 h-4 mx-2" />{{
                i18n('common.arabic')
              }}</a
            >
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    switchLang(lang) {
      this.$emit('setSelectedLang', lang)
    }
  }
}
</script>
