/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(
  config.backendUrlKindergarten
)

export class KindergartenService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query KINDERGARTEN_LIST(
          $filter: KindergartenFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          kindergartenList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              branchId
              name {
                en
                ar
              }
              description {
                en
                ar
              }
              adminName {
                en
                ar
              }
              adminPhoneNumber
              openHours {
                en
                ar
              }
              activities {
                en
                ar
              }
              logo_thumbnail
              logo
              banner
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })
    console.log(response.data.kindergartenList)

    return response.data.kindergartenList
  }

  static async find(id) {
    // graphqlClient.cache.reset()

    const response = await graphqlClient.query({
      query: gql`
        query KINDERGARTEN_FIND($id: String!) {
          kindergartenFind(id: $id) {
            id
            branchId
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            adminName {
              en
              ar
            }
            adminPhoneNumber
            openHours {
              en
              ar
            }
            activities {
              en
              ar
            }
            logo_thumbnail
            logo
            banner

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id
      }
    })
    console.log('from sasdasd', response.data.kindergartenFind)
    // console.log('find', response.data.kindergartenFind)
    // console.log('find', response.data.kindergartenFind.activities)
    return response.data.kindergartenFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation KINDERGARTEN_CREATE($data: KindergartenInput!) {
          kindergartenCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.kindergartenCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation KINDERGARTEN_UPDATE($id: String!, $data: KindergartenInput!) {
          kindergartenUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.kindergartenUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation KINDERGARTEN_DESTROY($id: String!) {
          kindergartenDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.kindergartenDestroy
  }
}
