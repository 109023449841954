/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(
  config.backendUrlRestaurant
)

export class RestaurantService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query RESTAURANT_LIST(
          $filter: RestaurantFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          restaurantList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              branchId
              name {
                en
                ar
              }
              description {
                en
                ar
              }
              location {
                en
                ar
              }
              phoneNumber
              openHours {
                en
                ar
              }
              logo_thumbnail
              logo
              banner
              menus
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.restaurantList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query RESTAURANT_FIND($id: String!) {
          restaurantFind(id: $id) {
            id
            branchId
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            location {
              en
              ar
            }
            phoneNumber
            openHours {
              en
              ar
            }
            logo_thumbnail
            logo
            banner
            menus
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,
      variables: {
        id
      }
    })

    return response.data.restaurantFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation RESTAURANT_CREATE($data: RestaurantInput!) {
          restaurantCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.restaurantCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation RESTAURANT_UPDATE($id: String!, $data: RestaurantInput!) {
          restaurantUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.restaurantUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation RESTAURANT_DESTROY($id: String!) {
          restaurantDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.restaurantDestroy
  }
}
