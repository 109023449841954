/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlActivity)

export class ActivityService {
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query ACTIVITY_LIST(
          $filter: ActivityFilterInput
          $orderBy: String
          $pagination: PaginationInput
        ) {
          activityList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              branchId
              logo
              name {
                en
                ar
              }
              details {
                title {
                  en
                  ar
                }
                subject {
                  en
                  ar
                }
              }

              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy: 'createdAt',
        pagination
      }
    })

    return response.data.activityList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query ACTIVITY_FIND($id: String!) {
          activityFind(id: $id) {
            id
            branchId
            logo
            name {
              en
              ar
            }
            details {
              title {
                en
                ar
              }
              subject {
                en
                ar
              }
            }

            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.activityFind
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ACTIVITY_CREATE($data: ActivityInput!) {
          activityCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.activityCreate
  }

  static async addSubActivities(id, details) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADD($id: String!, $details: [DetailsInput!]) {
          addSubActivities(id: $id, details: $details)
        }
      `,
      variables: {
        id,
        details
      }
    })
    return response.data.addSubActivities
  }

  static async removeSubActivities(id, details) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REMOVE($id: String!, $details: [DetailsInput!]) {
          removeSubActivities(id: $id, details: $details)
        }
      `,
      variables: {
        id,
        details
      }
    })
    return response.data.removeSubActivities
  }

  static async editSubActivities(id, itemIndex, newValue) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation EDIT($id: String!, $itemIndex: Int!, $newValue: DetailsInput) {
          editSubActivity(id: $id, itemIndex: $itemIndex, newValue: $newValue)
        }
      `,
      variables: {
        id,
        itemIndex,
        newValue
      }
    })
    return response.data.removeSubActivities
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ACTIVITY_UPDATE($id: String!, $data: ActivityInput!) {
          activityUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.activityUpdate
  }

  static async delete(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ACTIVITY_DESTROY($id: String!) {
          activityDestroy(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.activityDestroy
  }
}
