/* eslint-disable */

import categoryListStore from '@/store/category/category-list-store'
import categoryFormStore from '@/store/category/category-form-store'
// import categoryViewStore from '@/store/category/category-view-store'

export default {
  namespaced: true,

  modules: {
    list: categoryListStore,
    form: categoryFormStore,
    // view: categoryViewStore
  }
}
