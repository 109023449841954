<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="branches.form.title.edit" v-if="isEditing"></app-i18n>
            <app-i18n code="branches.form.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div class="flex justify-center" style="width: 100%; height: 80vh" v-if="findLoading || !model">
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                      text-lg
                      font-medium
                      leading-none
                      items-center
                      mr-2
                      lg:col-span-2
                    ">
                  {{ i18n('branches.fields.name') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input id="title-en" type="text" class="
                        form-control
                        w-full
                        dark:bg-dark-9 dark:placeholder-white
                      " v-model="model.name.en" :placeholder="i18n('branches.fields.english')" />
                </div>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2" :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'">
                  <input id="title-ar" type="text" class="
                        form-control
                        w-full
                        dark:bg-dark-9 dark:placeholder-white
                      " v-model="model.name.ar" :placeholder="i18n('branches.fields.arabic')" />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                      text-lg
                      font-medium
                      leading-none
                      items-center
                      mr-2
                      lg:col-span-2
                    ">
                  {{ i18n('branches.fields.address') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input id="title-en" type="text" class="
                        form-control
                        w-full
                        dark:bg-dark-9 dark:placeholder-white
                      " v-model="model.address.en" :placeholder="i18n('branches.fields.english')" />
                </div>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2" :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'">
                  <input id="title-ar" type="text" class="
                        form-control
                        w-full
                        dark:bg-dark-9 dark:placeholder-white
                      " v-model="model.address.ar" :placeholder="i18n('branches.fields.arabic')" />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                      text-lg
                      font-medium
                      leading-none
                      items-center
                      mr-2
                      lg:col-span-2
                    ">
                  {{ i18n('branches.fields.description') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea id="description-en" :rows="4" class="
                        form-control
                        w-full
                        dark:bg-dark-9 dark:placeholder-white
                      " v-model="model.description.en" :placeholder="i18n('branches.fields.english')"></textarea>
                </div>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2" :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'">
                  <textarea id="description-ar" :rows="4" class="
                        form-control
                        w-full
                        dark:bg-dark-9 dark:placeholder-white
                      " v-model="model.description.ar" :placeholder="i18n('branches.fields.arabic')"></textarea>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                      text-lg
                      font-medium
                      leading-none
                      items-center
                      mr-2
                      lg:col-span-2
                    ">
                  {{ i18n('branches.fields.thumbnail') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && thumbnailUrl">
                    <img :src="thumbnailUrl" class="object-cover object-center" style="width: 100%; height: 150px" />
                    <img src="/images/deleteIcon.png" alt="" class="
                          delete-icon
                          w-12
                          h-12
                          absolute
                          bottom-0
                          left-0
                          cursor-pointer
                        " @click="model.thumbnail = undefined" />
                  </div>
                  <Uploader ref="uploaderThumbnail" v-else></Uploader>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                      text-lg
                      font-medium
                      leading-none
                      items-center
                      mr-2
                      lg:col-span-2
                    ">
                  {{ i18n('branches.fields.backgroundVideo') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && backgroundVideoUrl">
                    <!-- <img
                      :src="backgroundVideoUrl"
                      alt=""
                      style="width: 100%; height: 150px"
                    /> -->
                    <video controls autoplay style="width: 100%; height: 150px" type="video/*">
                      <source :src="backgroundVideoUrl" />
                      Your browser does not support the video tag.
                    </video>
                    <img src="/images/deleteIcon.png" alt="" class="
                          delete-icon
                          w-12
                          h-12
                          absolute
                          bottom-0
                          left-0
                          cursor-pointer
                        " @click="model.backgroundVideo = undefined" />
                  </div>
                  <Uploader ref="uploaderVideo" folder="video" v-else></Uploader>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4 class="
                      text-lg
                      font-medium
                      leading-none
                      items-center
                      mr-2
                      lg:col-span-2
                    ">
                  {{ i18n('branches.fields.images') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && imagesUrls">
                    <div class="flex gap-0 flex-wrap">
                      <div class="relative h-32 w-36" v-for="(image, index) in imagesUrls" :key="index">
                        <img :src="image" class="object-cover h-full w-full" />
                        <img src="/images/deleteIcon.png" alt="" class="
                              delete-icon
                              w-12
                              h-12
                              absolute
                              bottom-0
                              left-0
                              cursor-pointer
                            " @click="deleteImage(index)" />
                      </div>
                    </div>
                    <Uploader ref="uploaderImagesAdd" message="common.addMoreImages" :multiple="true"></Uploader>
                  </div>
                  <Uploader ref="uploaderImagesNew" :multiple="true" v-else></Uploader>
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton type="button" class="btn bg-theme-36 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''" :disabled="saveLoading || findLoading || uploadLoading"
                  :loading="saveLoading" @click="doSubmit()">
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px" />
                  </template>
                </AppButton>
                <button type="button" class="
                      btn
                      bg-theme-31
                      text-black
                      w-24
                      ml-3
                      mr-3
                      cursor-pointer
                    " :disabled="saveLoading || findLoading || uploadLoading" @click="doCancel">
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import Uploader from '@/views/branch/uploader.vue'

export default {
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: {
    Uploader
  },
  data() {
    return {
      model: null,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'branch/form/record',
      findLoading: 'branch/form/findLoading',
      saveLoading: 'branch/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
      // return true
    },
    filesToSaveThumbnail() {
      return this.$refs.uploaderThumbnail?.filesToSave || []
    },
    filesToSaveVideo() {
      return this.$refs.uploaderVideo?.filesToSave || []
    },
    filesToSaveImagesAdd() {
      return this.$refs.uploaderImagesAdd?.filesToSave || []
    },
    filesToSaveImagesNew() {
      return this.$refs.uploaderImagesNew?.filesToSave || []
    },
    thumbnailUrl() {
      return this.model && this.model.thumbnail ? this.model.thumbnail : null
    },
    backgroundVideoUrl() {
      return this.model && this.model.backgroundVideo
        ? this.model.backgroundVideo
        : null
    },
    imagesUrls() {
      return this.model && this.model.images && this.model.images.length > 0
        ? this.model.images
        : null
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.branches')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.branches')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }

    this.model = this.record || {
      name: {
        en: '',
        ar: ''
      },
      description: {
        en: '',
        ar: ''
      },
      address: {
        en: '',
        ar: ''
      },
      images: [],
      backgroundVideo: '',
      thumbnail: ''
    }
    if (this.isEditing) {
      this.model.name = this.record.name
      this.model.description = this.record.description
    }
  },
  methods: {
    ...mapActions({
      doNew: 'branch/form/doNew',
      doFind: 'branch/form/doFind',
      doCreate: 'branch/form/doCreate',
      doUpdate: 'branch/form/doUpdate'
    }),
    deleteImage(index) {
      this.model.images.splice(index, 1)
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.name.en.length == 0 || this.model.name.ar.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      if (
        this.model.description.en.length == 0 ||
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.address.en.length == 0 ||
        this.model.address.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      if (this.isEditing) {
        if (!(this.thumbnailUrl || this.filesToSaveThumbnail.length !== 0)) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
        if (!(this.backgroundVideoUrl || this.filesToSaveVideo.length !== 0)) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
        if (
          !(
            this.imagesUrls ||
            this.filesToSaveImagesAdd.length !== 0 ||
            this.filesToSaveImagesNew.length !== 0
          )
        ) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      } else {
        if (
          this.filesToSaveThumbnail.length === 0 ||
          this.filesToSaveVideo.length === 0 ||
          (this.filesToSaveImagesAdd.length === 0 &&
            this.filesToSaveImagesNew.length === 0)
        ) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      // this.$router.back()
      this.$router.push('/branch')
    },
    doReset() {
      // this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        // console.log(Message.error(ERROR))
        return Message.error(ERROR)
      }

      // const { id, ...values } = formSchema.cast(this.model)
      if (this.isEditing) {
        const { name, address, description } = this.model
        await this.doUpdate({
          id: this.id,
          name,
          address,
          description,
          thumbnail: this.thumbnailUrl || this.filesToSaveThumbnail[0],
          backgroundVideo: this.backgroundVideoUrl || this.filesToSaveVideo[0],
          images: this.imagesUrls
            ? [...this.imagesUrls, ...this.filesToSaveImagesAdd]
            : this.filesToSaveImagesNew
        })
      } else {
        console.log('VALUES', {
          model: this.model,
          imageThumb: this.filesToSaveThumbnail,
          video: this.filesToSaveVideo,
          images: this.filesToSaveImagesNew
        })
        await this.doCreate({
          ...this.model,
          thumbnail: this.filesToSaveThumbnail[0],
          backgroundVideo: this.filesToSaveVideo[0],
          images: this.filesToSaveImagesNew
        })
      }
    }
  }
}
</script>

<style lang="scss">
.dropzone {
  .dz-preview {
    .dz-details {
      font-size: 12px !important;
      padding: 0em 1em !important;
    }

    .dz-image {
      width: 80px !important;
      height: 80px !important;
    }
  }
}
</style>
