<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('clinic.title.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box dark:bg-dark-8 p-5 sm:mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            class="btn btn-primary sm:mt-2 mx-3 w-full sm:w-28 p-1"
            @click="createClinic()"
          >
            <PlusIcon />
            {{ i18n('common.create') }}
          </button>
        </div>
      </div>
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading || branchesLoading || !records"
      />
      <div class="overflow-x-auto g">
        <table class="table table-report sm:mt-2 hidden md:table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('branches.fields.branch') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('clinic.form.description') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('clinic.form.adminName') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('clinic.table.phoneNumber') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('common.table.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in records" :key="index">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ index + 1 }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row.branch, 'name') }}
                </div>
              </td>
              <td class="text-center w-72">
                <div
                  class="font-medium whitespace-nowrap cursor-pointer w-72 truncate"
                >
                  {{ presenterLocalization(row, 'description') }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row, 'adminName') }}
                </div>
              </td>
              <td class="text-center">
                <div
                  class="font-medium whitespace-nowrap truncate cursor-pointer"
                >
                  {{ row.adminPhoneNumber }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div
                  class="flex justify-center items-center gap-3 text-theme-14 dark:text-white cursor-pointer"
                >
                  <div @click="viewClinic(row.id)">
                    <EyeIcon class="w-4 h-4 mr-1" />
                  </div>
                  <div @click="editClinic(row.id)">
                    <EditIcon class="w-4 h-4 mr-1" />
                  </div>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    class="text-theme-24"
                    @click="deleteId = row.id"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mobile-table md:hidden mt-8">
          <mobile-table-comp
            v-for="(row, index) in rows"
            :key="index"
            :row="row"
            class="md:hidden"
          >
            <div class="field">
              <span>{{ i18n('branches.fields.branch') }} </span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterLocalization(row.branch, 'name') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('clinic.form.description') }} :</span>
              <div
                class="font-medium whitespace-nowrap truncate cursor-pointer truncate"
              >
                {{ presenterLocalization(row, 'description') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('clinic.form.adminName') }} :</span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterLocalization(row, 'adminName') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('clinic.table.phoneNumber') }} :</span>
              <div
                class="font-medium whitespace-nowrap truncate cursor-pointer"
              >
                {{ row.adminPhoneNumber }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('common.table.actions') }} :</span>
              <div
                class="flex justify-center items-center gap-3 text-theme-14 dark:text-white cursor-pointer"
              >
                <div @click="viewClinic(row.id)">
                  <EyeIcon class="w-4 h-4 mr-1" />
                </div>
                <div @click="editClinic(row.id)">
                  <EditIcon class="w-4 h-4 mr-1" />
                </div>
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-modal-preview"
                  class="text-theme-24"
                  @click="deleteId = row.id"
                >
                  <Trash2Icon class="w-4 h-4 mr-1" />
                </a>
              </div>
            </div>
          </mobile-table-comp>
        </div>
        <app-empty-page
          v-if="!rows.length && !loading"
          :label="i18n('empty.clinics')"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
      <Pagination />
    </div>
    <!-- END: HTML Table Data -->
    <DeleteModal item="clinic" @delete="doDestroy(deleteId)" />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import feather from 'feather-icons'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { mapActions, mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.clinics')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.clinic')
    },
    rows() {
      this.records = this.rows.map((row) => ({
        ...row,
        branch: this.branches.filter((branch) => branch.id === row.branchId)[0]
      }))
    }
  },
  components: {
    DeleteModal
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'clinic/list/rows',
      currentPage: 'clinic/list/currentPage',
      isFirstPage: 'clinic/list/isFirstPage',
      branchesLoading: 'branch/list/loading',
      branches: 'branch/list/rows',
      isLastPage: 'clinic/list/isLastPage',
      loading: 'clinic/list/loading',
      pagination: 'clinic/list/pagination'
    })
  },
  setup() {
    const router = useRouter()
    const tabulator = ref()
    const records = ref([])

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    const editClinic = (id) => {
      router.push(`/clinic-form-page/${id}`)
    }

    const viewClinic = (id) => {
      router.push(`/clinic/${id}`)
    }

    const createClinic = () => {
      router.push('/clinic-form-page')
    }

    return {
      records,
      viewClinic,
      editClinic,
      createClinic
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
    console.log(this.rows)
    await this.doFetchBranches({
      filter: {},
      pagination: {
        limit: 10,
        sortBy: 'asc'
      }
    })
    this.records = this.rows.map((row) => ({
      ...row,
      branch: this.branches.filter((branch) => branch.id === row.branchId)[0]
    }))
    console.log(this.records)
  },
  methods: {
    ...mapActions({
      doFetch: 'clinic/list/doFetch',
      doFetchNextPage: 'clinic/list/doFetchNextPage',
      doFetchBranches: 'branch/list/doFetch',
      doFetchPreviousPage: 'clinic/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'clinic/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'clinic/list/doChangePaginationPageSize',
      doDestroy: 'clinic/list/doDestroy'
    }),
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: {},
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    }
  }
})
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
