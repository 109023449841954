<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            {{ i18n('nonAthletes.form.title') }}
          </h2>
          <div class="intro-y col-span-12 lg:col-span-8 mt-5 grid grid-cols-12 gap-6 ">
            <input type="text"
              class="intro-y form-control py-3 px-4 box dark:bg-dark-8 pr-10 placeholder-theme-8 dark:placeholder-white col-span-12 xl:col-span-8"
              :placeholder="i18n('nonAthletes.form.titlePlaceholder')" v-model="form.title" />
            <div class="post intro-y overflow-hidden box dark:bg-dark-8 mt-5 col-span-12 xl:col-span-8">
              <div class="post__tabs nav nav-tabs flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600"
                role="tablist">
                <Tippy id="content-tab" tag="a" content="Fill in the article content" data-toggle="tab"
                  data-target="#content" href="javascript:;"
                  class="w-full sm:w-40 py-4 text-center flex justify-center items-center active" role="tab"
                  aria-controls="content" aria-selected="true">
                  <FileTextIcon class="w-4 h-4 mr-2" />
                  {{ i18n('nonAthletes.form.description') }}
                </Tippy>
              </div>
              <div class="post__content tab-content">
                <div id="content" class="tab-pane p-5 active" role="tabpanel" aria-labelledby="content-tab">
                  <div class="border border-gray-200 dark:border-dark-5 rounded-md p-5">
                    <div class="font-medium flex items-center border-b border-gray-200 dark:border-dark-5 pb-5">
                      <ChevronDownIcon class="w-4 h-4 mr-2" />
                      {{ i18n('nonAthletes.form.descriptionText') }}
                    </div>
                    <div class="mt-5">
                      <CKEditor v-model="form.description" :editor="classicEditor" :config="editorConfig" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END -->
          <div class="mt-5">
            <button type="button" class="btn btn-primary w-24 cursor-pointer" @click="doSubmit()">
              {{ i18n('common.save') }}
            </button>
            <button type="button" class="btn btn-secondary w-24 ml-3 mr-3 cursor-pointer" @click="doCancel()">
              {{ i18n('common.cancel') }}
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
    <ErrorNotification :message="errorMessage" />
    <SuccessNotification message="Sport Created Successfully" button="Click Here to go back" link="sports" />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import ErrorNotification from '@/components/notifications/error-notification.vue'
import SuccessNotification from '@/components/notifications/success-notification-with-buttons.vue'
import Toastify from 'toastify-js'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import CodePlugin from '@ckeditor/ckeditor5-basic-styles/src/code'
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import CloudServicesPlugin from '@ckeditor/ckeditor5-cloud-services/src/cloudservices'
import Font from '@ckeditor/ckeditor5-font/src/font'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import HeadingButtonsUI from '@ckeditor/ckeditor5-heading/src/headingbuttonsui'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.nonAthletes')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.nonAthletes')
    }
  },
  components: {
    ErrorNotification,
    SuccessNotification
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  setup() {
    const route = useRoute()
    const form = reactive({
      title: '',
      description: ''
    })
    const errorMessage = ref('')
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: -1,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    const classicEditor = ClassicEditor
    const editorConfig = reactive({
      cloudServices: {
        tokenUrl: '',
        uploadUrl: ''
      },
      plugins: [
        Font,
        Alignment,
        EssentialsPlugin,
        BoldPlugin,
        UnderlinePlugin,
        StrikethroughPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        CodePlugin,
        SubscriptPlugin,
        SuperscriptPlugin,
        EasyImagePlugin,
        ImagePlugin,
        ImageUploadPlugin,
        CloudServicesPlugin,
        Heading,
        HeadingButtonsUI,
        Highlight
      ],
      toolbar: {
        items: [
          'fontSize',
          'alignment',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'code',
          'subscript',
          'superscript',
          'link',
          'undo',
          'redo',
          'imageUpload',
          'highlight'
        ]
      }
    })
    const editorData = ref('')

    const isEditing = computed(() => !!route.params.id)

    onMounted(() => {
      console.log(isEditing.value)
      if (isEditing.value) {
        // request data
        form.title = 'النشاط الثقافي'
        form.description = '<p style="text-align:right;"><span style="color:hsl(0, 0%, 0%);"><strong>الأطباء ينصحون كبار السن</strong></span></p><p style="text-align:right;"><span style="color:hsl(0, 0%, 0%);">بقراءة الكتب لتقوية الزاكرة و تساعد أي شخص يشعر بالوحدة فى التفكير بشأن الأنضمام إلى مجموعة من القراء يتناقشون حول الكتب التي يقرؤونها ويتوفر هذا النشاط داخل النادى فى الكلوب هاوس بالدور الأول من الساعة 9 صباحا إلى الساعة 9 مساء</span></p><p style="text-align:right;"><span style="color:hsl(0, 0%, 0%);">ومن أجل أبنائنا الطلبة قام النادى بنوقير المكان المناسب للإستذكار و تلك هى مكتبة توشكى من 16 سنة المواعيد من ال 9 صباحا إلى 9 مساء</span></p><p>&nbsp;</p><p>&nbsp;</p>'
      }
    })

    return {
      form,
      errorMessage,
      errorNotificationToggle,
      classicEditor,
      editorConfig,
      editorData
    }
  },
  methods: {
    successNotificationToggle() {
      Toastify({
        node: cash('#success-notification-with-buttons')
          .clone()
          .removeClass('hidden')[0],
        duration: -1,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
        onClick: () => {
          this.$router.push('/notifications')
          const element = document.getElementById(
            'success-notification-with-buttons'
          )
          element.remove()
        }
      }).showToast()
    },
    doSubmit() {
      console.log('THIS.FORM===>', this.form)
      // if (this.isFormValid()) {
      //   this.successNotificationToggle()
      // } else {
      //   setTimeout(() => {
      //     this.errorNotificationToggle()
      //   }, 100)
      // }
    },
    doCancel() {
      this.$router.push('/nonAthletes')
    },
    isFormValid() {
      for (var key in this.form) {
        var value = this.form[key]
        for (var groupKey in value) {
          var value2 = value[groupKey]
          if (value2.length == 0) {
            this.errorMessage = 'notifications.create.popup.emptyFields'
            return false
          }
        }
      }
      console.log(this.form)
      return true
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {

  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
