/* eslint-disable */
import { ArticleService } from '@/store/article/article-service'
import Errors from '@/shared/error/errors'
import {
  doc,
  onSnapshot,
  collection,
  query,
  where,
  getFirestore
} from 'firebase/firestore'

const INITIAL_PAGE_SIZE = 10
const INITIAL_PAGINATION_INPUT = {
  limit: INITIAL_PAGE_SIZE,
  sortBy: 'asc'
}

export default {
  namespaced: true,

  state: {
    rejected: 0,
    rows: [],
    count: 0,
    isFirstPage: null,
    isLastPage: null,
    loading: false,
    filter: null,
    currentPage: 1,
    pagination: INITIAL_PAGINATION_INPUT,
    comments: [],
    commentsCount: 0,
    hasMoreComment: false,
    inReview: 0,
    published: 0
  },

  getters: {
    published: state => state.published,
    inReview: state => state.inReview,
    rejected: state => state.rejected,
    loading: state => state.loading,
    rows: state => state.rows || [],
    comments: state => state.comments || [],
    count: state => state.count,
    commentsCount: state => state.commentsCount,
    hasMoreComment: state => state.hasMoreComment,
    isFirstPage: state => state.isFirstPage,
    isLastPage: state => state.isLastPage,
    hasRows: (state, getters) => getters.count > 0,
    filter: state => state.filter,
    currentPage: state => state.currentPage,
    limit: state => {
      const pagination = state.pagination

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE
      }

      return pagination.pageSize
    },
    offset: state => {
      const pagination = state.pagination

      if (!pagination || !pagination.pageSize) {
        return 0
      }

      const currentPage = pagination.currentPage || 1

      return (currentPage - 1) * pagination.pageSize
    },
    pagination: state => state.pagination
  },

  mutations: {
    RESETED(state) {
      state.rows = []
      state.count = 0
      state.loading = false
      state.filter = []
      state.pagination = INITIAL_PAGINATION_INPUT
      state.currentPage = 1
    },
    LISTEN_SUCCESS(state, payload) {
      state.inReview = payload
    },
    LISTEN_SUCCESS_2(state, payload) {
      state.published = payload
    },
    LISTEN_SUCCESS_3(state, payload) {
      state.rejected = payload
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || INITIAL_PAGINATION_INPUT
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      state.currentPage =
        payload && payload > 0 ? payload : state.currentPage || 1
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT

      state.currentPage = state.currentPage || 1
      state.pagination = {
        ...previousPagination,
        limit: payload || INITIAL_PAGE_SIZE
      }
    },

    FETCH_STARTED(state, payload) {
      state.loading = true
      state.filter = payload && payload.filter ? payload.filter : state.filter
      state.pagination =
        payload && payload.pagination
          ? payload.pagination
          : payload.keepPagination
          ? state.pagination
          : {}
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false
      state.rows = payload.rows
      state.count = payload.count
      state.isFirstPage = payload.isFirstPage
      state.isLastPage = payload.isLastPage
    },

    FETCH_ERROR(state) {
      state.loading = false
      state.rows = []
      state.count = 0
    },
    FETCH_COMMENTS_STARTED(state) {
      state.loading = true
    },

    FETCH_COMMENTS_SUCCESS(state, payload) {
      state.loading = false
      state.comments = payload.comments
      state.commentsCount = payload.totalCount
      state.hasMoreComment = payload.hasMoreComment
    },

    FETCH_COMMENTS_ERROR(state) {
      state.loading = false
      state.comments = []
      state.commentsCount = 0
    },
    ADD_STARTED(state) {
      state.loading = true
    },
    ADD_SUCCESS(state) {
      state.loading = false
    },
    ADD_ERROR(state) {
      state.loading = false
    },
    DESTROY_STARTED(state) {
      state.loading = true
    },

    DESTROY_SUCCESS(state) {
      state.loading = false
    },

    DESTROY_ERROR(state) {
      state.loading = false
    }
  },

  actions: {
    async doReset({ commit, dispatch }) {
      commit('RESETED')
      return dispatch('doFetch')
    },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize)
      const filter = getters.filter
      const pagination = getters.pagination
      dispatch('doFetch', { filter, pagination, keepPagination: true })
    },

    doChangePaginationCurrentPage({ commit }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage)
    },

    async doFetchNextPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      if (rows.length) {
        const currentPage = getters.currentPage || 1
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const pagination = {
          ...previousPagination,
          action: 'next',
          page: currentPage
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage + 1)
        await dispatch('doFetch', { pagination })
      }
    },
    async doFetchPreviousPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      const currentPage = getters.currentPage || 1
      if (currentPage > 1 && rows.length) {
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const pagination = {
          ...previousPagination,
          action: 'prev',
          page: currentPage
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage - 1)
        await dispatch('doFetch', { pagination })
      }
    },
    async doFetch(
      { commit, getters },
      { filter, pagination, keepPagination = true } = {}
    ) {
      try {
        commit('FETCH_STARTED', { filter, pagination, keepPagination })

        const PaginationInput = {
          ...pagination,
          limit: pagination?.limit || getters.pagination.limit,
          sortBy: pagination?.sortBy || getters.pagination.sortBy
        }
        const response = await ArticleService.fetchArticles(
          getters.filter,
          PaginationInput
        )
        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
          isFirstPage: response.pagination?.isFirstPage,
          isLastPage: response.pagination?.isLastPage
        })
      } catch (error) {
        Errors.handle(error)
        commit('FETCH_ERROR')
      }
    },
    async doListenToInreivew({ commit }) {
      const q = query(
        collection(getFirestore(), 'article'),
        where('inReview', '==', true)
      )
      onSnapshot(q, querySnapshot => {
        commit('LISTEN_SUCCESS', querySnapshot.size)
      })

      const q2 = query(
        collection(getFirestore(), 'article'),
        where('status', '==', 'accepted')
      )
      onSnapshot(q2, querySnapshot => {
        commit('LISTEN_SUCCESS_2', querySnapshot.size)
      })

      const q3 = query(
        collection(getFirestore(), 'article'),
        where('status', '==', 'rejected')
      )
      onSnapshot(q3, querySnapshot => {
        commit('LISTEN_SUCCESS_3', querySnapshot.size)
      })
    },
    async doFetchComment({ commit }, id) {
      try {
        commit('FETCH_COMMENTS_STARTED')
        const response = await ArticleService.listComments(id)
        commit('FETCH_COMMENTS_SUCCESS', {
          comments: response.rows,
          totalCount: response.totalCount,
          hasMoreComment: response.hasMoreComment
        })
      } catch (error) {
        Errors.handle(error)
        commit('FETCH_COMMENTS_ERROR')
      }
    },
    async doDestroy({ commit, dispatch }, id) {
      try {
        commit('DESTROY_STARTED')
        await ArticleService.removeArticles(id)
        commit('DESTROY_SUCCESS')
        await dispatch('doFetch')
      } catch (error) {
        Errors.handle(error)
        commit('DESTROY_ERROR')
      }
    },
    async doAccept({ commit, getters, dispatch }, id) {
      try {
        commit('DESTROY_STARTED')
        await ArticleService.accept(id)
        await dispatch('doFetch', {
          pagination: getters.pagination,
          filter: getters.filter
        })
        commit('DESTROY_SUCCESS')
      } catch (error) {
        Errors.handle(error)
        commit('DESTROY_ERROR')
      }
    },
    async doReject({ commit, getters, dispatch }, { id, reason }) {
      try {
        commit('DESTROY_STARTED')
        await ArticleService.reject(id, reason)
        await dispatch('doFetch', {
          pagination: getters.pagination,
          filter: getters.filter
        })
        commit('DESTROY_SUCCESS')
      } catch (error) {
        Errors.handle(error)
        commit('DESTROY_ERROR')
      }
    },
    async doDestroyComment({ commit, dispatch }, { articleId, id }) {
      try {
        commit('DESTROY_STARTED')
        await ArticleService.removeComment(articleId, id)
        commit('DESTROY_SUCCESS')
        await dispatch('doFetchComment', articleId)
      } catch (error) {
        Errors.handle(error)
        commit('DESTROY_ERROR')
      }
    }
  }
}
