/* eslint-disable */

import sponsorListStore from '@/store/sponsor/sponsor-list-store'
import sponsorFormStore from '@/store/sponsor/sponsor-form-store'

export default {
  namespaced: true,

  modules: {
    list: sponsorListStore,
    form: sponsorFormStore,
    // destroy: sponsorDestroyStore
  }
}
