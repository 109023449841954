/* eslint-disable */

import libraryListStore from '@/store/library/library-list-store'
import libraryViewStore from '@/store/library/library-view-store'
import libraryFormStore from '@/store/library/library-form-store'

export default {
  namespaced: true,

  modules: {
    list: libraryListStore,
    form: libraryFormStore,
    view: libraryViewStore
    // destroy: libraryDestroyStore
  }
}
