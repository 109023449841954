<template>
  <div class="flex justify-center">
    <div style="width: 100%; height: 30vh" class="flex justify-center" v-if="loading">
      <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
    </div>
    <div class="intro-y news xl:w-3/5 p-5 box dark:bg-dark-8 mt-8" v-if="record">
      <!-- BEGIN: Blog Layout -->
      <h2 class="intro-y font-medium text-xl sm:text-2xl">
        {{ presenterLocalization(record, 'name') }}
      </h2>
      <div class="intro-y mt-6">
        <div class="news__preview image-fit">
          <video controls autoplay class="rounded-md" style="width: 100%; height: 100%;" type="video/*">
            <source :src="record.backgroundVideo" />
            Your browser does not support the video tag.
          </video>
          <!-- <img
            alt="Icewall Tailwind HTML Admin Template"
            class="rounded-md"
            src="https://firebasestorage.googleapis.com/v0/b/smouha-club.appspot.com/o/public%2Fbranches%2Fimages%2Fburj-alarab2.webp?alt=media&token=9dda58b3-2da9-4d46-b8a2-51238af53c5f"
          /> -->
        </div>
      </div>
      <div class="intro-y text-justify leading-relaxed mt-10">
        <p class="mb-5">
          {{ presenterLocalization(record, 'description') }}
        </p>
      </div>
      <div class="intro-y p-5 mt-5" style="height: 220px; direction: ltr !important">
        <TinySlider :options="{
          autoplay: false,
          controls: true,
          items: 1,
          nav: true,
          responsive: {
            600: {
              items: 3
            },
            480: {
              items: 2
            }
          }
        }">
          <div class="h-40 px-2" v-for="image in record.images" :key="image">
            <div class="h-full image-fit rounded-md overflow-hidden">
              <img alt="Icewall Tailwind HTML Admin Template" :src="image" data-action="zoom" />
            </div>
          </div>
        </TinySlider>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.branches')
  },
  props: ['id'],
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.branches')
    }
  },
  async mounted() {
    await this.doFind(this.id)
    console.log('record', this.record)
  },
  computed: {
    ...mapGetters({
      record: 'branch/view/record',
      loading: 'branch/view/loading'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    ...mapActions({
      doFind: 'branch/view/doFind'
    }),
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style scoped>
.validity {
  position: absolute !important;
  z-index: 20 !important;
  background: #d42428 !important;
  padding: 15px !important;
  border-bottom-right-radius: 20px !important;
  color: white !important;
}
</style>
