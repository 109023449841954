<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-xl font-medium  border-b pb-5" :class="isRTL ? `ml-auto` : `mr-auto`">
        <app-i18n code="auctions.form.title.edit" v-if="isEditing"></app-i18n>
        <app-i18n code="auctions.form.title.new" v-else></app-i18n>
      </h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0 gap-2">
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton class="
                          inline-flex
                          w-full
                          justify-center
                          rounded-md
                          border border-gray-300
                          bg-white
                          px-4
                          py-2
                          text-sm
                          font-medium
                          text-gray-700
                          shadow-sm
                          hover:bg-gray-50
                          focus:outline-none
                        ">
              {{
                lang === 'ar'
                ? i18n('articles.form.languages.arabic')
                : i18n('articles.form.languages.english')
              }}
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>
          <transition enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="
                          absolute
                          right-0
                          z-10
                          mt-2
                          w-28
                          text-center
                          origin-top-right
                          rounded-md
                          bg-white
                          shadow-lg
                          ring-1 ring-black ring-opacity-5
                          focus:outline-none
                        ">
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                <a href="javascript:;" @click="switchLang" :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm'
                ]">
                  {{ i18n('articles.form.languages.english') }}
                </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                <a href="javascript:;" @click="switchLang" :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm'
                ]">{{ i18n('articles.form.languages.arabic') }}</a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
        <!-- <div class="dropdown mr-2">
          <button
            class="dropdown-toggle btn box dark:bg-dark-8 text-gray-700 dark:text-gray-300 flex items-center"
            aria-expanded="false"
          >
            {{i18n('auctions.form.languages.english')}} <ChevronDownIcon class="w-4 h-4 mx-2" />
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <div
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"
              >
                <ActivityIcon class="w-4 h-4 mx-2" />
                <span class="truncate">{{i18n('auctions.form.languages.english')}}</span>
              </div>
              <div
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"
              >
                <ActivityIcon class="w-4 h-4 mx-2" />
                <span class="truncate">{{i18n('auctions.form.languages.arabic')}}</span>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <button
          type="button"
          class="
            btn
            box
            text-gray-700
            dark:bg-dark-8 dark:text-gray-300
            mx-2
            flex
            items-center
          "
        >
          <EyeIcon class="w-4 h-4 mx-2" /> {{ i18n('auctions.form.preview') }}
        </button> -->
        <!-- <div class="dropdown">
          <button
            class="dropdown-toggle btn btn-primary shadow-md flex items-center"
            aria-expanded="false"
          >
            {{ i18n('common.save') }} <ChevronDownIcon class="w-4 h-4 mx-2" />
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                href=""
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  bg-white
                  dark:bg-dark-1
                  hover:bg-gray-200
                  dark:hover:bg-dark-2
                  rounded-md
                "
              >
                <FileTextIcon class="w-4 h-4 mx-2" />
                {{ i18n('auctions.form.save.asNewPost') }}
              </a>
              <a
                href=""
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  bg-white
                  dark:bg-dark-1
                  hover:bg-gray-200
                  dark:hover:bg-dark-2
                  rounded-md
                "
              >
                <FileTextIcon class="w-4 h-4 mx-2" />{{
                  i18n('auctions.form.save.asDraft')
                }}
              </a>
            </div>
          </div>
        </div> -->
        <button class="btn btn-primary shadow-md flex items-center px-4" @click="doSubmit">
          {{ i18n('common.save') }}
        </button>
        <button class="btn bg-white shadow-md flex items-center px-4" @click="$router.back()">
          {{ i18n('common.cancel') }}
        </button>
      </div>
    </div>
    <div class="flex justify-center" style="width: 100%; height: 80vh" v-if="findLoading || !model">
      <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
    </div>
    <div class="pos intro-y grid grid-cols-12 gap-5 mt-5" v-if="model">
      <!-- BEGIN: Post Content -->
      <div class="intro-y col-span-12 lg:col-span-8">
        <div class="post intro-y overflow-hidden box dark:bg-dark-8">
          <div class="
                        post__tabs
                        nav nav-tabs
                        flex-col
                        sm:flex-row
                        bg-gray-300
                        dark:bg-dark-2
                        text-gray-600
                      " role="tablist">
            <div class="
                          w-full
                          sm:w-40
                          py-4
                          text-center
                          flex
                          justify-center
                          items-center
                          bg-white
                          font-bold
                        ">
              <FileTextIcon class="w-4 h-4 mx-2" />
              {{ i18n('auctions.form.content') }}
            </div>
          </div>
          <div class="post__content tab-content">
            <div id="content" class="tab-pane p-5 active" role="tabpanel" aria-labelledby="content-tab">
              <div class="border border-gray-200 dark:border-dark-5 rounded-md p-5">
                <div class="
                              font-medium
                              flex
                              items-center
                              border-b border-gray-200
                              dark:border-dark-5
                              pb-5
                            ">
                  <ChevronDownIcon class="w-4 h-4 mx-2" />
                  {{ i18n('auctions.form.textContent') }}
                </div>
                <div class="mt-5">
                  <input type="text" class="
                                intro-y
                                form-control
                                py-3
                                px-4
                                pr-10
                                placeholder-theme-8
                                dark:placeholder-white
                              " v-model="title" :placeholder="i18n('auctions.form.titlePlaceholder')" />
                </div>
                <div class="mt-5">
                  <label class="form-label">{{
                    i18n('auctions.form.description')
                  }}</label>
                  <CKEditor v-model="description" :editor="classicEditor" :config="editorConfig" />
                </div>
                <div class="mt-5">
                  <label class="form-label">{{
                    i18n('auctions.form.termsAndConditions')
                  }}</label>
                  <CKEditor v-model="termsAndConditions" :editor="classicEditor" :config="editorConfig" />
                </div>
              </div>
              <div class="
                            border border-gray-200
                            dark:border-dark-5
                            rounded-md
                            p-5
                            mt-5
                          ">
                <div class="
                              font-medium
                              flex
                              items-center
                              border-b border-gray-200
                              dark:border-dark-5
                              pb-5
                            ">
                  <!-- <ChevronDownIcon class="w-4 h-4 mr-2" /> -->
                  {{ i18n('auctions.form.tcTerms') }}
                </div>
                <div class="mt-5">
                  <div class="preview">
                    <div class="relative" v-if="isEditing && TCTermsUrl">
                      <embed :src="TCTermsUrl" class="object-cover object-center" style="width: 100%; height: 150px" />
                      <a :href="TCTermsUrl" target="_black" class="
                                    delete-icon
                                    w-8
                                    h-8
                                    flex
                                    items-center
                                    justify-center
                                    absolute
                                    bottom-2
                                    rounded
                                    left-12
                                    cursor-pointer
                                  " style="background: #f0f4f8">
                        <EyeIcon class="w-4 h-4" />
                      </a>
                      <img src="/images/deleteIcon.png" alt="" class="
                                    delete-icon
                                    w-11
                                    h-11
                                    absolute
                                    bottom-0
                                    left-0
                                    cursor-pointer
                                  " @click="model.TCTerms = undefined" />
                    </div>
                    <Uploader ref="uploaderTCTerms" folder="TCTerms" v-else></Uploader>
                  </div>
                </div>
              </div>
              <div class="
                            border border-gray-200
                            dark:border-dark-5
                            rounded-md
                            p-5
                            mt-5
                          ">
                <div class="
                              font-medium
                              flex
                              items-center
                              border-b border-gray-200
                              dark:border-dark-5
                              pb-5
                            ">
                  <!-- <ChevronDownIcon class="w-4 h-4 mr-2" /> -->
                  {{ i18n('auctions.form.banner') }}
                </div>
                <div class="mt-5">
                  <div class="preview">
                    <div class="relative" v-if="isEditing && bannerUrl">
                      <img :src="bannerUrl" class="object-cover object-center" style="width: 100%; height: 150px" />
                      <img src="/images/deleteIcon.png" alt="" class="
                                    delete-icon
                                    w-12
                                    h-12
                                    absolute
                                    bottom-0
                                    left-0
                                    cursor-pointer
                                  " @click="model.banner = undefined" />
                    </div>
                    <Uploader ref="uploaderBanner" folder="banner" v-else></Uploader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Post Content -->
      <!-- BEGIN: Post Info -->
      <div class="col-span-12 lg:col-span-4">
        <div class="intro-y box p-5">
          <label for="post-form-2" class="form-label">{{
            i18n('auctions.form.validUntil')
          }}</label>
          <Litepicker id="post-form-2" v-model="model.expiryDate" :options="{
            autoApply: false,
            showWeekNumbers: true,
            dropdowns: {
              minYear: 1990,
              maxYear: null,
              months: true,
              years: true
            }
          }" class="form-control" />
          <label for="post-form-2" class="form-label mt-3">{{
            i18n('auctions.form.startPrice')
          }}</label>
          <input type="number" v-model="model.reservePrice"
            class="intro-y form-control py-3 px-4 pr-10 placeholder-theme-8" />
          <div class="mt-3">
            <label for="post-form-2" class="form-label">{{
              i18n('auctions.form.postDate')
            }}</label>
            <Litepicker id="post-form-2" v-model="model.postDate" :options="{
              autoApply: false,
              showWeekNumbers: true,
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true
              }
            }" class="form-control" />
          </div>
          <div class="mt-3">
            <label for="post-form-2" class="form-label">{{
              i18n('branches.fields.branch')
            }}</label>
            <v-select :options="branchOptions || []" :label="labelLocalized" v-model="model.branch" class="select-center"
              title="Select item">
              <template #no-options>
                <div class="loading-loader"></div>
              </template></v-select>
          </div>
        </div>
      </div>
      <!-- END: Post Info -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import CodePlugin from '@ckeditor/ckeditor5-basic-styles/src/code'
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import CloudServicesPlugin from '@ckeditor/ckeditor5-cloud-services/src/cloudservices'
import Font from '@ckeditor/ckeditor5-font/src/font'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import HeadingButtonsUI from '@ckeditor/ckeditor5-heading/src/headingbuttonsui'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import Uploader from '@/views/auction/uploader.vue'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.auctions')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.auctions')
    }
  },
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Uploader
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    await this.doFetchBranches({
      filter: {},
      pagination: {
        limit: 10,
        sortBy: 'asc'
      }
    })
    this.branchOptions = this.branches.map((branch) => ({
      ...branch,
      nameAr: branch.name.ar,
      nameEn: branch.name.en
    }))
    this.model = this.record || {
      title: { en: '', ar: '' },
      description: { en: '', ar: '' },
      termsAndConditions: { en: '', ar: '' },
      postDate: '',
      expiryDate: '',
      reservePrice: undefined,
      status: 'published',
      TCTerms: '',
      banner: ''
    }
    if (this.isEditing) {
      this.model.branch = this.branchOptions.filter(
        (branch) => branch.id === this.record.branchId
      )[0]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      branches: 'branch/list/rows',
      record: 'auction/form/record',
      findLoading: 'auction/form/findLoading',
      saveLoading: 'auction/form/saveLoading'
    }),
    labelLocalized() {
      return this.isRTL ? 'nameAr' : 'nameEn'
    },
    isEditing() {
      return !!this.id
    },
    filesToSaveBanner() {
      return this.$refs.uploaderBanner?.filesToSave || []
    },
    filesToSaveTCTerms() {
      return this.$refs.uploaderTCTerms?.filesToSave || []
    },
    TCTermsUrl() {
      return this.model && this.model.TCTerms ? this.model.TCTerms : null
    },
    bannerUrl() {
      return this.model && this.model.banner ? this.model.banner : null
    },
    title: {
      get() {
        return this.lang === 'ar' ? this.model?.title.ar : this.model?.title.en
      },
      set(val) {
        this.lang === 'ar'
          ? (this.model.title.ar = val)
          : (this.model.title.en = val)
      }
    },
    description: {
      get() {
        return this.lang === 'ar'
          ? this.model?.description.ar
          : this.model?.description.en
      },
      set(val) {
        this.lang === 'ar'
          ? (this.model.description.ar = val)
          : (this.model.description.en = val)
      }
    },
    termsAndConditions: {
      get() {
        return this.lang === 'ar'
          ? this.model?.termsAndConditions.ar
          : this.model?.termsAndConditions.en
      },
      set(val) {
        this.lang === 'ar'
          ? (this.model.termsAndConditions.ar = val)
          : (this.model.termsAndConditions.en = val)
      }
    }
  },
  data() {
    return {
      model: null,
      errorMessage: null,
      branchOptions: []
    }
  },
  setup() {
    const lang = ref('en')
    const switchLang = () => {
      lang.value = lang.value === 'en' ? 'ar' : 'en'
    }
    const classicEditor = ClassicEditor
    const editorConfig = {
      cloudServices: {
        tokenUrl: '',
        uploadUrl: ''
      },
      plugins: [
        Font,
        EssentialsPlugin,
        BoldPlugin,
        UnderlinePlugin,
        StrikethroughPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        CodePlugin,
        SubscriptPlugin,
        SuperscriptPlugin,
        EasyImagePlugin,
        ImagePlugin,
        ImageUploadPlugin,
        CloudServicesPlugin,
        Heading,
        HeadingButtonsUI,
        Highlight
      ],
      toolbar: {
        items: [
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'code',
          'subscript',
          'superscript',
          'link',
          'undo',
          'redo',
          'imageUpload',
          'highlight'
        ]
      }
    }
    const editorData = ref('')

    return {
      lang,
      switchLang,
      classicEditor,
      editorConfig,
      editorData
    }
  },
  methods: {
    ...mapActions({
      doNew: 'auction/form/doNew',
      doFetchBranches: 'branch/list/doFetch',
      doFind: 'auction/form/doFind',
      doCreate: 'auction/form/doCreate',
      doUpdate: 'auction/form/doUpdate'
    }),
    isFormValid() {
      if (this.model.title.en.length == 0 || this.model.title.ar.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.description.en.length == 0 ||
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.termsAndConditions.en.length == 0 ||
        this.model.termsAndConditions.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.postDate.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.expiryDate.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (!this.model.branch || this.model.branch.id.length === 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.reservePrice.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.isEditing) {
        if (!(this.TCTermsUrl || this.filesToSaveTCTerms.length !== 0)) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
        if (!(this.bannerUrl || this.filesToSaveBanner.length !== 0)) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      } else {
        if (
          this.filesToSaveTCTerms.length === 0 ||
          this.filesToSaveBanner.length === 0
        ) {
          this.errorMessage = 'restaurants.edit.errors.emptyFields'
          return false
        }
      }
      return true
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const {
        title,
        termsAndConditions,
        description,
        postDate,
        expiryDate,
        branch,
        reservePrice
      } = this.model
      if (this.isEditing) {
        await this.doUpdate({
          id: this.id,
          title,
          termsAndConditions,
          description,
          postDate: new Date(postDate),
          expiryDate: new Date(expiryDate),
          reservePrice,
          branchId: branch.id,
          TCTerms: this.TCTermsUrl || this.filesToSaveTCTerms[0],
          banner: this.bannerUrl || this.filesToSaveBanner[0]
        })
      } else {
        await this.doCreate({
          title,
          termsAndConditions,
          description,
          postDate: new Date(postDate),
          expiryDate: new Date(expiryDate),
          reservePrice,
          branchId: branch.id,
          TCTerms: this.filesToSaveTCTerms[0],
          banner: this.filesToSaveBanner[0]
        })
      }
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
