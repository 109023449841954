<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('parking.title.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box dark:bg-dark-8 p-5 sm:mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            class="btn btn-primary sm:mt-2 mx-3 w-full sm:w-28 p-1"
            @click="createParking"
          >
            <PlusIcon />
            {{ i18n('common.create') }}
          </button>
        </div>
      </div>
      <loading-spinner
        type="half-circle"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading || branchesLoading || !records"
      />
      <div class="overflow-x-auto g">
        <table class="table table-report sm:mt-2 hidden md:table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('branches.fields.branch') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('clinic.form.description') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('clinic.form.adminName') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('clinic.table.phoneNumber') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('parking.table.annualSubscription') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('telecom.form.time') }}
              </th>
              <th class="text-center whitespace-nowrap">
                {{ i18n('common.table.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in records" :key="index">
              <td class="w-40">
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  #{{ index + 1 }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row.branch, 'name') }}
                </div>
              </td>
              <td class="text-center w-72">
                <div
                  class="font-medium whitespace-nowrap cursor-pointer w-72 truncate"
                >
                  {{ presenterLocalization(row, 'description') }}
                </div>
              </td>
              <td>
                <div class="font-medium whitespace-nowrap truncate text-center">
                  {{ presenterLocalization(row, 'adminName') }}
                </div>
              </td>
              <td class="text-center">
                <div
                  class="font-medium whitespace-nowrap truncate cursor-pointer"
                >
                  {{ row.adminPhoneNumber }}
                </div>
              </td>
              <td class="text-center">
                <div
                  class="font-medium whitespace-nowrap truncate cursor-pointer"
                >
                  {{ formatPrice(row.annualSubscription) }}
                </div>
              </td>
              <td class="text-center flex items-center justify-center">
                <div
                  class="font-medium whitespace-nowrap truncate text-gray-600"
                >
                  {{ presenterArrLocalized(row, 'openHours') }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div
                  class="flex justify-center items-center gap-3 text-theme-14 dark:text-white cursor-pointer"
                >
                  <div @click="viewParking(row.id)">
                    <EyeIcon class="w-4 h-4 mr-1" />
                  </div>
                  <div @click="editParking(row.id)">
                    <EditIcon class="w-4 h-4 mr-1" />
                  </div>
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-modal-preview"
                    class="text-theme-24"
                    @click="deleteId = row.id"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mobile-table md:hidden mt-8">
          <mobile-table-comp
            v-for="(row, index) in rows"
            :key="index"
            :row="row"
            class="md:hidden"
          >
            <div class="field">
              <span>{{ i18n('branches.fields.branch') }} </span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterLocalization(row.branch, 'name') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('clinic.form.description') }} :</span>
              <div
                class="font-medium whitespace-nowrap cursor-pointer truncate"
              >
                {{ presenterLocalization(row, 'description') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('clinic.form.adminName') }} :</span>
              <div class="font-medium whitespace-nowrap truncate text-center">
                {{ presenterLocalization(row, 'adminName') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('clinic.table.phoneNumber') }} :</span>
              <div
                class="font-medium whitespace-nowrap truncate cursor-pointer"
              >
                {{ row.adminPhoneNumber }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('parking.table.annualSubscription') }} :</span>
              <div
                class="font-medium whitespace-nowrap truncate cursor-pointer"
              >
                {{ formatPrice(row.annualSubscription) }}
              </div>
            </div>
            <div class="field">
              <span>{{ i18n('telecom.form.time') }} :</span>
              <div class="font-medium whitespace-nowrap truncate text-gray-600">
                {{ presenterArrLocalized(row, 'openHours') }}
              </div>
            </div>
            <div class="field">
              <span> {{ i18n('common.table.actions') }} :</span>
              <div
                class="flex justify-center items-center gap-3 text-theme-14 dark:text-white cursor-pointer"
              >
                <div @click="viewParking(row.id)">
                  <EyeIcon class="w-4 h-4 mr-1" />
                </div>
                <div @click="editParking(row.id)">
                  <EditIcon class="w-4 h-4 mr-1" />
                </div>
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-modal-preview"
                  class="text-theme-24"
                  @click="deleteId = row.id"
                >
                  <Trash2Icon class="w-4 h-4 mr-1" />
                </a>
              </div>
            </div>
          </mobile-table-comp>
        </div>
        <app-empty-page
          v-if="!rows.length && !loading"
          :label="i18n('empty.parking')"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
      <Pagination
        v-if="rows.length"
        :page="currentPage"
        :pageSize="pagination.limit"
        :rowsNumber="pagination.total"
        :showSizeChanger="true"
        :isFirstPage="isFirstPage"
        :isLastPage="isLastPage"
        :disabled="!rows.length"
        position="center"
        @next-page="doNextPage"
        @prev-change="doPrevPage"
        @size-change="doChangePaginationPageSize"
      >
      </Pagination>
    </div>
    <!-- END: HTML Table Data -->
    <DeleteModal item="parking" @delete="doDestroy(deleteId)" />
  </div>
</template>

<script>
import DeleteModal from '@/components/modals/delete-modal.vue'
import { mapActions, mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.parking')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.parking')
    },
    rows() {
      this.records = this.rows.map((row) => ({
        ...row,
        branch: this.branches.filter((branch) => branch.id === row.branchId)[0]
      }))
    }
  },
  components: {
    DeleteModal
  },
  data() {
    return {
      records: []
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'parking/list/rows',
      currentPage: 'parking/list/currentPage',
      isFirstPage: 'parking/list/isFirstPage',
      branches: 'branch/list/rows',
      branchesLoading: 'branch/list/loading',
      isLastPage: 'parking/list/isLastPage',
      loading: 'parking/list/loading',
      pagination: 'parking/list/pagination'
    })
  },
  async mounted() {
    await this.doFetchFirstPage()
    console.log(this.rows)
    await this.doFetchBranches({
      filter: {},
      pagination: {
        limit: 10,
        sortBy: 'asc'
      }
    })
    this.records = this.rows.map((row) => ({
      ...row,
      branch: this.branches.filter((branch) => branch.id === row.branchId)[0]
    }))
  },
  methods: {
    ...mapActions({
      doFetch: 'parking/list/doFetch',
      doFetchNextPage: 'parking/list/doFetchNextPage',
      doFetchBranches: 'branch/list/doFetch',
      doFetchPreviousPage: 'parking/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'parking/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'parking/list/doChangePaginationPageSize',
      doDestroy: 'parking/list/doDestroy'
    }),
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: {},
        pagination: {
          limit: 10,
          sortBy: 'asc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    editParking(id) {
      this.$router.push(`/parking-form-page/${id}`)
    },
    viewParking(id) {
      this.$router.push(`/parking/${id}`)
    },
    createParking() {
      this.$router.push('/parking-form-page')
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterArr(row, fieldName) {
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].join(', ')
        : 'ـــ'
    },
    presenterArrLocalized(row, fieldName) {
      const locale = this.isRTL ? 'ar' : 'en'
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].map((val) => val[locale]).join(',')
        : 'ـــ'
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} جنية` : `${PRICE} EGP`
    }
  }
}
</script>
<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
