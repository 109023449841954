<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n
              code="parking.places.title.edit"
              v-if="isEditing"
            ></app-i18n>
            <app-i18n code="parking.places.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !model"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('clinic.form.name') }}
                </h4>
                <div
                  class="
                    lg:col-span-8
                    col-span-12
                    lg:mt-0
                    mt-2
                    grid
                    gap-y-2
                    items-center
                  "
                >
                  <div
                    class="grid grid-cols-12 relative"
                    v-for="(input, index) in model.places"
                    :key="`time-${index}`"
                  >
                    <div class="lg:col-span-6 col-span-11 lg:mt-0 mt-2">
                      <input
                        id="crud-form-1"
                        type="text"
                        class="form-control w-full dark:placeholder-white"
                        v-model="input.en"
                        :placeholder="i18n('common.english')"
                      />
                    </div>
                    <div
                      class="lg:col-span-6 col-span-11 lg:mt-0 mt-2"
                      :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                    >
                      <input
                        id="crud-form-1"
                        type="text"
                        class="form-control w-full dark:placeholder-white"
                        v-model="input.ar"
                        :placeholder="i18n('common.arabic')"
                      />
                    </div>
                    <svg
                      v-if="index === model.places.length - 1"
                      @click="addField()"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="
                        ml-2
                        cursor-pointer
                        absolute
                        top-10
                        lg:top-2
                        right-10
                        lg:-right-8
                      "
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="green"
                        d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                      />
                    </svg>
                    <svg
                      v-show="model.places.length > 1"
                      @click="removeField(index)"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="
                        ml-2
                        cursor-pointer
                        absolute
                        top-10
                        lg:top-2 lg:-right-14
                        right-4
                      "
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="#ff0000"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <!-- END -->
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="
                    btn btn-primary
                    w-24
                    cursor-pointer
                    text-white
                    cursor-pointer
                  "
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="
                    btn
                    bg-theme-31
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  "
                  @click="doCancel"
                >
                  {{ i18n('common.cancel') }}
                </button>
              </div>
            </form>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import Message from '@/shared/message/toastify'
import { mapGetters, mapActions } from 'vuex'

export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.parking')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.parking')
    }
  },
  props: {
    id: String
  },
  data() {
    return {
      model: null,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      saveLoading: 'parking/form/saveLoading'
    })
  },
  async mounted() {
    this.doNew()
    this.model = {
      places: [
        {
          en: '',
          ar: ''
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      doNew: 'parking/form/doNew',
      doCreate: 'parking/form/doAddPlaces'
    }),
    addField() {
      this.model.places.push({ en: '', ar: '' })
    },
    removeField(index) {
      this.model.places.splice(index, 1)
    },
    isFormValid() {
      if (this.model.places.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      return true
    },
    doCancel() {
      this.$router.push('/parking')
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const { places } = this.model
      await this.doCreate({
        id: this.id,
        places
      })
      // }
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
