<template>
  <div class="flex justify-center">
    <div style="width: 100%; height: 30vh" class="flex justify-center" v-if="loading || !record">
      <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
    </div>
    <div class="intro-y news xl:w-3/5 p-5 box dark:bg-dark-8 mt-8" v-else>
      <!-- BEGIN: Blog Layout -->
      <h2 class="intro-y font-medium text-xl sm:text-2xl">
        {{ presenterLocalization(record, 'title') }}
      </h2>
      <div class="intro-y text-gray-700 dark:text-gray-600 mt-3 text-xs sm:text-sm">
        {{ presenterDate(record, 'createdAt') }}<span class="mx-1">•</span>
        <a class="p-2 text-white mx-1" style="border-radius: 20px; background: #d42428" href=""
          v-for="category in record.categories" :key="category">{{ presenterLocalization(category, 'name') }}</a>
      </div>
      <div class="intro-y mt-6">
        <div class="news__preview image-fit">
          <img v-if="record.images.length > 0" alt="Icewall Tailwind HTML Admin Template" class="rounded-md" :src="
            record.images[0] ||
            require(`@/assets/images/${$f()[0].images[0]}`)
          " />
          <iframe v-else :src="record.videoUrl" frameborder="0" width="100%" height="100%"></iframe>
        </div>
      </div>
      <div class="intro-y flex relative pt-16 sm:pt-6 items-center pb-6">
        <!-- <Tippy
                tag="a"
                href=""
                class="intro-x w-8 h-8 sm:w-10 sm:h-10 flex flex-none items-center justify-center rounded-full border border-gray-400 dark:border-dark-5 dark:bg-dark-5 dark:text-gray-300 text-gray-600 mr-3"
                content="Bookmark"
              >
                <BookmarkIcon class="w-3 h-3" />
              </Tippy> -->
        <!-- <div class="intro-x flex mr-3">
                <div class="intro-x w-8 h-8 sm:w-10 sm:h-10 image-fit">
                  <Tippy
                    tag="img"
                    alt="Icewall Tailwind HTML Admin Template"
                    class="rounded-full border border-white zoom-in"
                    :src="require(`@/assets/images/${$f()[0].photos[0]}`)"
                    :content="$f()[0].users[0].name"
                  />
                </div>
                <div class="intro-x w-8 h-8 sm:w-10 sm:h-10 image-fit -ml-4">
                  <Tippy
                    tag="img"
                    alt="Icewall Tailwind HTML Admin Template"
                    class="rounded-full border border-white zoom-in"
                    :src="require(`@/assets/images/${$f()[0].photos[1]}`)"
                    :content="$f()[0].users[1].name"
                  />
                </div>
                <div class="intro-x w-8 h-8 sm:w-10 sm:h-10 image-fit -ml-4">
                  <Tippy
                    tag="img"
                    alt="Icewall Tailwind HTML Admin Template"
                    class="rounded-full border border-white zoom-in"
                    :src="require(`@/assets/images/${$f()[0].photos[2]}`)"
                    :content="$f()[0].users[2].name"
                  />
                </div>
              </div> -->
        <div class="
                  absolute
                  sm:relative
                  -mt-12
                  sm:mt-0
                  w-full
                  flex
                  text-gray-700
                  dark:text-gray-600
                  text-xs
                  sm:text-sm
                ">
          <div class="intro-x mr-1 sm:mr-3">
            {{ i18n('articles.view.comments') }}
            <span class="font-medium">{{ record.commentsCount }}</span>
          </div>
          <div class="intro-x mr-1 sm:mr-3">
            {{ i18n('articles.view.views') }}
            <span class="font-medium">{{ record.viewsCount }}</span>
          </div>
          <div class="intro-x sm:mr-3" :class="isRTL ? 'mr-auto' : 'ml-auto'">
            {{ i18n('articles.view.likes') }}
            <span class="font-medium">{{ record.likes.length }}</span>
          </div>
        </div>
        <!-- <Tippy
                tag="a"
                href=""
                class="
                  intro-x
                  w-8
                  h-8
                  sm:w-10 sm:h-10
                  flex flex-none
                  items-center
                  justify-center
                  rounded-full
                  bg-theme-31
                  dark:bg-dark-5 dark:text-gray-300
                  text-theme-26
                  ml-auto
                  sm:ml-0
                "
                content="Share"
              >
                <Share2Icon class="w-3 h-3" />
              </Tippy> -->
        <!-- <Tippy
                tag="a"
                href=""
                class="intro-x w-8 h-8 sm:w-10 sm:h-10 flex flex-none items-center justify-center rounded-full bg-theme-17 text-white ml-3"
                content="Download PDF"
              >
                <ShareIcon class="w-3 h-3" />
              </Tippy> -->
      </div>
      <div class="intro-y text-justify leading-relaxed break-words">
        <span v-html="record.content.en" v-if="!isRTL"></span>
        <span v-html="record.content.ar" v-else></span>
      </div>
      <!-- <div
              class="
                intro-y
                flex
                text-xs
                sm:text-sm
                flex-col
                sm:flex-row
                items-center
                mt-5
                pt-5
                border-t border-gray-200
                dark:border-dark-5
              "
            >
              <div
                class="
                  flex
                  items-center
                  text-gray-700
                  dark:text-gray-600
                  mt-5
                  sm:mt-0
                "
                :class="isRTL ? 'sm:mr-auto' : 'sm:ml-auto'"
              >
                {{ i18n('articles.view.share') }}
                <Tippy
                  tag="a"
                  href=""
                  class="
                    w-8
                    h-8
                    sm:w-10 sm:h-10
                    rounded-full
                    flex
                    items-center
                    justify-center
                    border
                    dark:border-dark-5
                    mx-2
                    text-gray-500
                    zoom-in
                  "
                  content="Facebook"
                >
                  <FacebookIcon class="w-3 h-3 fill-current" />
                </Tippy>
                <Tippy
                  tag="a"
                  href=""
                  class="
                    w-8
                    h-8
                    sm:w-10 sm:h-10
                    rounded-full
                    flex
                    items-center
                    justify-center
                    border
                    dark:border-dark-5
                    mx-2
                    text-gray-500
                    zoom-in
                  "
                  content="Twitter"
                >
                  <TwitterIcon class="w-3 h-3 fill-current" />
                </Tippy>
                <Tippy
                  tag="a"
                  href=""
                  class="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-500 zoom-in"
                  content="Linked In"
                >
                  <LinkedinIcon class="w-3 h-3 fill-current" />
                </Tippy>
              </div>
            </div> -->
      <!-- END: Blog Layout -->
      <!-- BEGIN: Comments -->
      <!-- <div
              class="intro-y mt-5 pt-5 border-t border-gray-200 dark:border-dark-5"
            >
              <div class="text-base sm:text-lg font-medium">
                2 {{ i18n('articles.view.comment') }}
              </div>
              <div class="news__input relative mt-5">
                <MessageCircleIcon
                  class="w-5 h-5 absolute my-auto inset-y-0 mx-6 text-gray-600"
                  :class="isRTL ? 'right-0' : 'left-0'"
                />
                <textarea
                  class="
                    form-control
                    border-transparent
                    bg-gray-200
                    px-16
                    py-6
                    placeholder-theme-8
                    resize-none
                  "
                  rows="1"
                  :placeholder="i18n('articles.view.commentPlaceholder')"
                ></textarea>
              </div>
            </div> -->
      <!-- <div class="intro-y mt-5 pb-10">
              <div class="pt-5">
                <div class="flex">
                  <div class="w-10 h-10 sm:w-12 sm:h-12 flex-none image-fit">
                    <img
                      alt="Icewall Tailwind HTML Admin Template"
                      class="rounded-full"
                      :src="require(`@/assets/images/${$f()[0].photos[0]}`)"
                    />
                  </div>
                  <div class="mx-3 flex-1">
                    <div class="flex items-center">
                      <a href="" class="font-medium">Ahmed Mohammed</a>
                      <div class="dropdown" :class="isRTL ? 'mr-auto' : 'ml-auto'">
                        <button class="dropdown-toggle" aria-expanded="false">
                          <div
                            class="dropdown-toggle options cursor-pointer mr-4"
                            role="button"
                            aria-expanded="false"
                          >
                            <MoreHorizontalIcon />
                          </div>
                        </button>
                        <div class="dropdown-menu w-20">
                          <div
                            class="
                              dropdown-menu__content
                              notificationDropDown
                              box
                              dark:bg-dark-1
                              p-3
                              w-20
                            "
                          >
                            <div class="notification-content__title mb-2">
                              {{ i18n('common.delete') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-gray-600 text-xs sm:text-sm">
                      {{ $f()[0].formattedTimes[0] }}
                    </div>
                    <div class="mt-2">{{ $f()[0].news[0].shortContent }}</div>
                  </div>
                </div>
              </div>
              <div class="mt-5 pt-5 border-t border-gray-200 dark:border-dark-5">
                <div class="flex">
                  <div class="w-10 h-10 sm:w-12 sm:h-12 flex-none image-fit">
                    <img
                      alt="Icewall Tailwind HTML Admin Template"
                      class="rounded-full"
                      :src="require(`@/assets/images/${$f()[0].photos[1]}`)"
                    />
                  </div>
                  <div class="mx-3 flex-1">
                    <div class="flex items-center">
                      <a href="" class="font-medium">Ahmed Mohammed</a>
                      <div class="dropdown" :class="isRTL ? 'mr-auto' : 'ml-auto'">
                        <button class="dropdown-toggle" aria-expanded="false">
                          <div
                            class="dropdown-toggle options cursor-pointer mr-4"
                            role="button"
                            aria-expanded="false"
                          >
                            <MoreHorizontalIcon />
                          </div>
                        </button>
                        <div class="dropdown-menu w-20">
                          <div
                            class="
                              dropdown-menu__content
                              notificationDropDown
                              box
                              dark:bg-dark-1
                              p-3
                              w-20
                            "
                          >
                            <div class="notification-content__title mb-2">Delete</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-gray-600 text-xs sm:text-sm">
                      {{ $f()[1].formattedTimes[0] }}
                    </div>
                    <div class="mt-2">{{ $f()[1].news[0].shortContent }}</div>
                  </div>
                </div>
              </div>
            </div> -->
      <!-- END: Comments -->
      <div class="flex justify-center border-t-2 mt-3 pt-3 gap-5">
        <button class="btn btn-primary shadow-md flex items-center px-4 self-center"
          @click="$router.push(`/article/${this.id}/comments`)">
          {{ i18n('articles.view.viewComment') }}
        </button>
        <button class="btn btn-secondary shadow-md flex gap-2 items-center px-4 self-center" @click="$router.back()">
          <CornerUpLeftIcon class="w-4 h-4" />
          {{ i18n('common.back') }}
        </button>
      </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  async created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.articles')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.articles')
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'article/view/record',
      loading: 'article/view/loading'
    })
  },
  async mounted() {
    await this.doFind(this.id)
    console.log(this.record)
  },
  methods: {
    ...mapActions({
      doFind: 'article/view/doFind'
    }),
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} جنية` : `${PRICE} EGP`
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
