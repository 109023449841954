<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="clinic.title.edit" v-if="isEditing"></app-i18n>
            <app-i18n code="clinic.title.new" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !model"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('clinic.form.name') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.name.en"
                    :placeholder="i18n('clinic.fields.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.name.ar"
                    :placeholder="i18n('clinic.fields.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('clinic.form.doctor') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.doctorName.en"
                    :placeholder="i18n('clinic.fields.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.doctorName.ar"
                    :placeholder="i18n('clinic.fields.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('clinic.form.specialization') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.specialization.en"
                    :placeholder="i18n('clinic.fields.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.specialization.ar"
                    :placeholder="i18n('clinic.fields.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('clinic.form.time') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.appointmentTime.en"
                    :placeholder="i18n('clinic.fields.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.appointmentTime.ar"
                    :placeholder="i18n('clinic.fields.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('clinic.form.price') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="crud-form-1"
                    type="number"
                    class="form-control w-full dark:placeholder-white"
                    v-model="model.price"
                    :placeholder="i18n('clinic.form.price')"
                  />
                </div>
              </div>
              <!-- <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('clinic.form.phoneNumber') }}
                </h4>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  style="dir: ltr"
                >
                  <vue-tel-input
                    v-model="model.phoneNumber"
                    mode="international"
                    validCharactersOnly
                    :dropdownOptions="telDropOptions"
                    :inputOptions="isRTL ? telOptionsAr : telOptions"
                  ></vue-tel-input>
                </div>
              </div> -->
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('clinic.form.drPicture') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <div
                    class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative m-auto cursor-pointer"
                  >
                    <div>
                      <img
                        alt="Icewall Tailwind HTML Admin Template"
                        class="rounded-full"
                        :src="
                          model.pictureDr
                            ? model.pictureDr
                            : '/images/default-avatar.svg'
                        "
                      />
                      <div
                        class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-theme-17 rounded-full p-2"
                      >
                        <CameraIcon class="w-4 h-4 text-white" />
                      </div>
                    </div>
                    <input
                      type="file"
                      id="profileImage"
                      name="upload"
                      accept="image/png, image/jpeg, image/jpg"
                      style="position: absolute; top: 0%; opacity: 0 !important"
                      class="cursor-pointer w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative m-auto"
                      @input="emitEvent($event)"
                    />
                  </div>
                </div>
              </div>
              <!-- END -->
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn btn-primary w-24 cursor-pointer text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :id="dis ? 'test' : ``"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer"
                  @click="doCancel"
                >
                  {{ i18n('common.cancel') }}
                </button>
              </div>
            </form>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import Message from '@/shared/message/toastify'
import { FileUploader } from '@/shared/uploader/file-uploader'
import { mapGetters, mapActions } from 'vuex'
import { collection, doc, getFirestore } from 'firebase/firestore'

export default {
  async created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.clinic')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.clinic')
    }
  },
  props: {
    id: String
  },
  data() {
    return {
      dis: false,
      model: null,
      imageUrl: '',
      uploadLoading: false,
      image: null,
      errorMessage: null,
      telDropOptions: { showFlags: true },
      telOptions: {
        placeholder: 'Enter clinic Phone Number',
        showDialCode: true
      },
      telOptionsAr: { placeholder: 'ادخل رقم العيادة', showDialCode: true }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'clinic/form/record',
      findLoading: 'clinic/form/findLoading',
      saveLoading: 'clinic/form/saveLoading'
    }),
    isEditing() {
      return this.$route.fullPath.includes('edit')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.$route.query.id)
    } else {
      this.doNew()
    }
    this.model = this.record.clinics[[parseInt(this.$route.query.index)]] || {
      name: {
        en: '',
        ar: ''
      },
      doctorName: {
        en: '',
        ar: ''
      },
      specialization: {
        en: '',
        ar: ''
      },
      price: '',
      appointmentTime: {
        en: '',
        ar: ''
      },
      pictureDr: ''
    }
    console.log(this.model)
  },

  methods: {
    ...mapActions({
      doNew: 'clinic/form/doNew',
      doFind: 'clinic/form/doFind',
      doCreate: 'clinic/form/doAddClinics',
      doSubUpdate: 'clinic/form/doSubUpdate'
    }),
    isFormValid() {
      if (this.model.name.en.length == 0 || this.model.name.ar.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.specialization.en.length == 0 ||
        this.model.specialization.ar.length == 0
      ) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }

      // if (this.model.phoneNumber.length == 0) {
      //   this.errorMessage = 'restaurants.edit.errors.emptyFields'
      //   return false
      // }
      if (this.model.appointmentTime.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.pictureDr.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      if (this.model.price.length == 0) {
        this.errorMessage = 'restaurants.edit.errors.emptyFields'
        return false
      }
      return true
    },
    doCancel() {
      this.$router.push('/clinic')
    },
    emitEvent(event) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = (e) => {
        const image = {
          name: event.target.files[0].name,
          size: event.target.files[0].size,
          lastModifiedDate: event.target.files[0].lastModifiedDate,
          base64: reader.result
        }
        this.image = image
        const file = event.target.files[0]
        this.imageUrl = URL.createObjectURL(file)
        this.model.pictureDr = this.imageUrl
      }
    },
    async doSubmit() {
      this.dis = true
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        this.dis = true
        return Message.error(ERROR)
      }
      if (this.image) {
        this.uploadLoading = true
        const profileImage = document.getElementById('profileImage').files[0]
        const id = doc(collection(getFirestore(), 'ids')).id
        const path = `clinic/pictureDr/${id}`
        const { publicUrl } = await FileUploader.upload(
          path,
          profileImage,
          'pictureDr'
        )
        this.model.pictureDr = publicUrl
      }
      const {
        name,
        doctorName,
        specialization,
        price,
        appointmentTime,
        pictureDr
      } = this.model

      if (this.isEditing) {
        await this.doSubUpdate({
          id: this.$route.query.id,
          itemIndex: parseInt(this.$route.query.index),
          name,
          doctorName,
          specialization,
          price,
          appointmentTime,
          pictureDr
        })
      } else {
        await this.doCreate({
          id: this.id,
          name,
          doctorName,
          specialization,
          price: parseFloat(price),
          appointmentTime,
          pictureDr
        })
      }
      this.dis = false
      // }
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
#test {
  pointer-events: none !important;
}

.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
