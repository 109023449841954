<template>
  <div class="flex justify-center">
    <div class="intro-y news xl:w-3/5 p-5 box dark:bg-dark-8 mt-8">
      <!-- BEGIN: Blog Layout -->
      <h2 class="intro-y font-medium text-xl sm:text-2xl">
        McDonalds
      </h2>
      <div class="intro-y mt-6">
        <div class="news__preview image-fit">
          <img
            alt="Icewall Tailwind HTML Admin Template"
            class="rounded-md"
            :src="require(`@/assets/images/${$f()[0].images[0]}`)"
          />
        </div>
        <div class="sm:whitespace-normal flex items-center mt-3">
          <PhoneIcon class="w-6 h-6 mx-2" />
          01001234567
        </div>
        <div class="sm:whitespace-normal flex items-center mt-3">
          <MapPinIcon class="w-6 h-6 mx-2" />
          Next to Gate 3
        </div>
      </div>
      <div class="intro-y text-justify leading-relaxed mt-3">
        <p class="mb-5">{{ $f()[1].news[0].content }}</p>
        <p class="mb-5">{{ $f()[2].news[0].content }}</p>
        <p>{{ $f()[3].news[0].content }}</p>
      </div>
      <h2 class="mt-5 intro-y font-medium text-xl sm:text-2xl">
        {{ i18n('restaurants.view.menu') }}
      </h2>
      <div
        class="intro-y p-5 mt-5"
        style="height:220px; direction: ltr !important"
      >
        <TinySlider
          :options="{
            autoplay: false,
            controls: true,
            items: 1,
            nav: true,
            responsive: {
              600: {
                items: 3
              },
              480: {
                items: 2
              }
            }
          }"
        >
          <div class="h-40 px-2">
            <div class="h-full image-fit rounded-md overflow-hidden">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                :src="require(`@/assets/images/${$f()[0].images[0]}`)"
                data-action="zoom"
              />
            </div>
          </div>
          <div class="h-40 px-2">
            <div class="h-full image-fit rounded-md overflow-hidden">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                :src="require(`@/assets/images/${$f()[0].images[0]}`)"
                data-action="zoom"
              />
            </div>
          </div>
          <div class="h-40 px-2">
            <div class="h-full image-fit rounded-md overflow-hidden">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                :src="require(`@/assets/images/${$f()[0].images[0]}`)"
                data-action="zoom"
              />
            </div>
          </div>
          <div class="h-40 px-2">
            <div class="h-full image-fit rounded-md overflow-hidden">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                :src="require(`@/assets/images/${$f()[0].images[0]}`)"
                data-action="zoom"
              />
            </div>
          </div>
        </TinySlider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.restaurants')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.restaurants')
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach(element => {
          this.selected.push(element.id)
        })
      }
    },
    goToUser() {
      this.$router.push('/user-view-page')
    },
    viewArticle() {
      this.$router.push('/article-view-page')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style scoped>
.validity {
  position: absolute !important;
  z-index: 20 !important;
  background: #d42428 !important;
  padding: 15px !important;
  border-bottom-right-radius: 20px !important;
  color: white !important;
}
</style>
