/* eslint-disable */
import service from '@/store/auth/auth-service'
import crypto from '@/shared/crypto'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
// import ProgressBar from '@/shared/progress-bar/progress-bar';
// import { routerAsync } from '@/app-module';
import router from '@/router'
import FirebaseRepository from '@/shared/firebase/firebase-repository'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { doc, onSnapshot } from 'firebase/firestore'

export default {
  namespaced: true,

  state: {
    authenticationUser: null,
    currentUser: null,
    currentUserSocial: null,

    loadingInit: true,
    loadingEmailConfirmation: false,
    loadingPasswordResetEmail: false,
    loadingUpdateProfile: false,
    loadingChangeMyPassword: false,

    loading: false,
    loadingGoogle: false,
    loadingFacebook: false,
    loadingTwitter: false,
    loadingYahoo: false
  },

  getters: {
    authenticationUser: state => state.authenticationUser,
    currentUser: state => state.currentUser,
    currentUserSocial: state => state.currentUserSocial,

    currentUserDetails: (state, getters) =>
      getters.currentUser ? getters.currentUser : null,

    currentUserFirstName: (state, getters) =>
      getters.currentUser ? getters.currentUser.fullName.split(' ')[0] : null,

    currentUserLastName: (state, getters) =>
      getters.currentUser ? getters.currentUser.fullName.split(' ')[1] : null,

    currentUserEmail: (state, getters) =>
      getters.currentUser ? getters.currentUser.email : null,

    currentUserFullName: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.fullName || getters.currentUser.userName
        : '',

    role: (state, getters) =>
      getters.currentUser ? getters.currentUser.role || null : null,

    roles: (state, getters) =>
      getters.currentUser ? getters.currentUser.roles || [] : [],

    signedIn: (state, getters) =>
      !!getters.currentUser && !!getters.currentUser.id,
    emptyPermissions: (state, getters) =>
      !getters.roles || !getters.roles.length,

    loading: state => !!state.loading,
    loadingGoogle: state => !!state.loadingGoogle,
    loadingFacebook: state => !!state.loadingFacebook,
    loadingTwitter: state => !!state.loadingTwitter,
    loadingYahoo: state => !!state.loadingYahoo,
    loadingInit: state => !!state.loadingInit,
    loadingEmailConfirmation: state => !!state.loadingEmailConfirmation,
    loadingPasswordResetEmail: state => !!state.loadingPasswordResetEmail,
    loadingUpdateProfile: state => !!state.loadingUpdateProfile,
    loadingChangeMyPassword: state => !!state.loadingChangeMyPassword,

    currentUserNameOrEmailPrefix: (state, getters) => {
      if (!getters.currentUser) {
        return null
      }

      if (
        getters.currentUserFullName &&
        getters.currentUserFullName.length < 25
      ) {
        return getters.currentUserFullName
      }

      if (getters.currentUser.firstName) {
        return getters.currentUser.firstName
      }

      return getters.currentUser.email
        ? getters.currentUser.email.split('@')[0]
        : null
    },

    currentUserAvatar: (state, getters) => {
      if (
        !getters.currentUser ||
        !getters.currentUser.avatar
        // !getters.currentUser.avatars ||
        // !getters.currentUser.avatars.length ||
        // !getters.currentUser.avatars[0].publicUrl
      ) {
        // let defaultUrl= '../../../public/images/imgUploader.svg'
        return null
      }

      return getters.currentUser.avatar
      // return getters.currentUser.avatars[0].publicUrl;
    },

    cuurentUserSocialAvatar: (state, getters) => {
      return getters.currentUserSocial
    }
  },

  mutations: {
    AUTH_START(state, payload) {
      switch (payload) {
        case 'google':
          state.loadingGoogle = true
          break
        case 'facebook':
          state.loadingFacebook = true
          break
        case 'twitter':
          state.loadingTwitter = true
          break
        case 'yahoo':
          state.loadingYahoo = true
          break
        default:
          state.loading = true
          break
      }
    },
    AUTH_SUCCESS(state, payload) {
      state.authenticationUser = payload.authenticationUser || null
      state.currentUser = payload.currentUser || null
      state.currentUserSocial = payload.currentUserSocial || null
      state.loading = false
      state.loadingGoogle = false
      state.loadingFacebook = false
      state.loadingTwitter = false
      state.loadingYahoo = false
    },
    AUTH_ERROR(state) {
      state.authenticationUser = null
      state.currentUser = null
      state.loading = false
      state.loadingGoogle = false
      state.loadingFacebook = false
      state.loadingTwitter = false
      state.loadingYahoo = false
    },

    EMAIL_CONFIRMATION_START(state) {
      state.loadingEmailConfirmation = true
    },
    EMAIL_CONFIRMATION_SUCCESS(state) {
      state.loadingEmailConfirmation = false
    },
    EMAIL_CONFIRMATION_ERROR(state) {
      state.loadingEmailConfirmation = false
    },

    PASSWORD_RESET_START(state) {
      state.loadingPasswordResetEmail = true
    },
    PASSWORD_RESET_SUCCESS(state) {
      state.loadingPasswordResetEmail = false
    },
    PASSWORD_RESET_ERROR(state) {
      state.loadingPasswordResetEmail = false
    },

    UPDATE_PROFILE_START(state) {
      state.loadingUpdateProfile = true
    },
    UPDATE_PROFILE_SUCCESS(state) {
      state.loadingUpdateProfile = false
    },
    UPDATE_PROFILE_ERROR(state) {
      state.loadingUpdateProfile = false
    },

    CHANGE_MY_PASSWORD_START(state) {
      state.loadingChangeMyPassword = true
    },
    CHANGE_MY_PASSWORD_SUCCESS(state) {
      state.loadingChangeMyPassword = false
    },
    CHANGE_MY_PASSWORD_ERROR(state) {
      state.loadingChangeMyPassword = false
    },

    AUTH_INIT_SUCCESS(state, payload) {
      state.authenticationUser = payload.authenticationUser || null
      state.currentUser = payload.currentUser || null
      state.loadingInit = false
    },
    AUTH_INIT_ERROR(state) {
      state.authenticationUser = null
      state.currentUser = null
      state.loadingInit = false
    },
    CURRENT_USER_REFRESH_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null
    }
  },

  actions: {
    //#region [ Init ]
    doInitFirebase() {
      service.init()
    },
    async doInit({ commit, dispatch }) {
      service.init()
      let currentUser = service.fetchLocalCurrentUser()
      if (!currentUser) {
        commit('AUTH_INIT_SUCCESS', {
          currentUser: null,
          authenticationUser: null
        })
        // ProgressBar.done();
        return
      }

      const unsubscribe = service.onAuthStateChanged(
        authenticationUser => {
          dispatch('doSigninFromAuthChange', authenticationUser)
          unsubscribe()
        },
        error => {
          console.error(error)
          commit('AUTH_INIT_ERROR')
        }
      )
    },
    async doWaitUntilInit({ getters }) {
      if (!getters.loadingInit) {
        return Promise.resolve()
      }

      return new Promise(resolve => {
        const waitUntilInitInterval = setInterval(() => {
          if (!getters.loadingInit) {
            clearInterval(waitUntilInitInterval)
            resolve()
          }
        }, 500)
      })
    },
    //#endregion

    //#region [ Listeners ]
    async doRunCurrentUserChangedListener({ getters, dispatch }) {
      try {
        const currentUser = getters.currentUser
        const db = firebase.firestore()
        onSnapshot(doc(db, 'users', currentUser.id), doc => {
          const user = doc.data()
          if (
            !user ||
            user.status == 'disabled' ||
            user.status == 'removed' ||
            user.disabled
          ) {
            dispatch('doSignout')
          }
          dispatch('doRefreshCurrentUser')
        })
      } catch (error) {
        throw error
      }
    },
    //#endregion

    //#region [ Send Email ]
    async doSendEmailConfirmation({ commit, getters }) {
      try {
        commit('EMAIL_CONFIRMATION_START')
        await service.sendEmailVerification(getters.authenticationUser)
        // Message.success(i18n('auth.verificationEmailSuccess'));
        commit('EMAIL_CONFIRMATION_SUCCESS')
      } catch (error) {
        Errors.handle(error)
        commit('EMAIL_CONFIRMATION_ERROR')
      }
    },

    async doSendPasswordResetEmail({ commit }, email) {
      try {
        commit('PASSWORD_RESET_START')
        firebase.auth().languageCode = localStorage.getItem('language')
        await service.sendPasswordResetEmail(email)
        Message.success(i18n('auth.passwordResetEmailSuccess'))
        commit('PASSWORD_RESET_SUCCESS')
      } catch (error) {
        // Message.error(error);
        Errors.handle(error)
        commit('PASSWORD_RESET_ERROR')
      }
    },
    //#endregion

    //#region [ Authentication With Phone Number ]
    async signPhoneNumber({ commit }, { appverifier, phoneNum }) {
      commit('AUTH_START')

      await firebase
        .auth()
        .signInWithPhoneNumber(phoneNum, appverifier)
        .then(function(confirmationResult) {
          // console.log(confirmationResult)
          localStorage.setItem('verfId', confirmationResult.verificationId)
          localStorage.setItem('phoneNum', phoneNum)

          commit('AUTH_END')
          // routerAsync().push('/auth/phone-unverified');
          router.push('/auth/phone-unverified')
          window.confirmationResult = confirmationResult
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    async signUpPhoneNumberCredential({ commit }, code) {
      try {
        commit('AUTH_START')

        let currentUser
        let authenticationUser
        let verId = localStorage.getItem('verfId')
        var credential = firebase.auth.PhoneAuthProvider.credential(verId, code)
        const credentials = await firebase
          .auth()
          .signInWithCredential(credential)
        console.log('credentials', credentials)

        if (credentials.additionalUserInfo.isNewUser) {
          authenticationUser = credentials.user
          currentUser = await service.fetchMe()
          console.log('current user', currentUser)
          currentUser.emailVerified = authenticationUser.emailVerified
          currentUser.lang = localStorage.getItem('language')
          currentUser.accountType = 'company'
          currentUser.phoneNumber = authenticationUser.phoneNumber
          //#region [old]
          // update profile
          // await service.updateProfile(
          //   currentUser.firstName,
          //   currentUser.lastName,
          //   currentUser.phoneNumber,
          //   currentUser.countryCode,
          //   currentUser.lang,
          //   currentUser.avatars,
          //   currentUser.accountType,
          // );
          //#endregion

          console.log(currentUser)

          // await service.updateProfile(
          //   // currentUser.firstName + currentUser.lastName,
          //   null,
          //   currentUser.phoneNumber,
          //   currentUser.countryCode,
          //   currentUser.lang,
          //   currentUser.avatars,
          //   'company',
          //   null,
          //   null,
          //   null
          // )
          // debugger

          // if (currentUser.accountType == 'company') {
          //   routerAsync().push('/dashboard');
          // } else {
          //   await service.signout();
          //   Errors.handle('User permission denied ');
          // }

          service.reauthenticateWithStorageToken()

          commit('AUTH_SUCCESS', {
            currentUser,
            authenticationUser
          })
          console.log(currentUser)
          service.saveLocalCurrentUser(currentUser)
          console.log('currentUSer after', service.fetchLocalCurrentUser())
          // routerAsync().push('/dashboard');
        } else {
          service.reauthenticateWithStorageToken()
          commit('AUTH_SUCCESS', {
            currentUser,
            authenticationUser
          })
          service.saveLocalCurrentUser(currentUser)
        }
        console.log('>>>> pin verified')
        return 'verified'
      } catch (error) {
        console.log('error in (auth-store) ' + error)
        console.log(error)
        // await service.signout();
        Errors.handle(error)
        commit('AUTH_ERROR')
        return 'error'
      }
    },

    async signPhoneNumberCredential({ commit }, code) {
      try {
        commit('AUTH_START')

        let currentUser
        let authenticationUser
        let verId = localStorage.getItem('verfId')
        var credential = await firebase.auth.PhoneAuthProvider.credential(
          verId,
          code
        )
        const credentials = await firebase
          .auth()
          .signInWithCredential(credential)
        console.log('credentials', credentials.additionalUserInfo.isNewUser)
        if (!credentials.additionalUserInfo.isNewUser) {
          console.log('no addi info', credentials.user)
          authenticationUser = credentials.user
          currentUser = await service.fetchMe()
          console.log('currentUser', currentUser)
          currentUser.emailVerified = authenticationUser.emailVerified

          if (currentUser.accountType != 'company') {
            throw {
              code: 'auth/user-not-found',
              message:
                'There is no user record corresponding to this identifier. The user may have been deleted.',
              a: null
            }
          }
        } else {
          console.log('with add info')
          await service.deleteCurrentUserAccount()
          throw {
            code: 'auth/user-not-found',
            message:
              'There is no user record corresponding to this identifier. The user may have been deleted.',
            a: null
          }
        }

        service.reauthenticateWithStorageToken()

        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser
        })
        service.saveLocalCurrentUser(currentUser)
        // const language = localStorage.getItem('language')
        // routerAsync().push(`/`);
        router.push(`/`)
      } catch (error) {
        await service.signout()
        Errors.handle(error)
        commit('AUTH_ERROR')
      }
    },
    //#endregion

    //#region [ Authentication With Social ]
    async doSigninSocial({ commit }, { provider, rememberMe }) {
      try {
        commit('AUTH_START', provider)
        let authenticationUser = null
        let currentUser = null
        // debugger

        const credentials = await service.signinWithSocial(provider, rememberMe)
        // debugger

        if (credentials && credentials.user) {
          authenticationUser = credentials.user
          console.log('is new user ?', credentials.additionalUserInfo.isNewUser)

          if (credentials.additionalUserInfo.isNewUser) {
            const id = authenticationUser.uid || FirebaseRepository.newId()
            let avatar, userName, firstName, lastName, phoneNumber, email

            // google
            if (provider == 'google') {
              userName = credentials.additionalUserInfo.profile.name || null
              firstName =
                credentials.additionalUserInfo.profile.given_name || null
              lastName =
                credentials.additionalUserInfo.profile.family_name || null
              phoneNumber =
                credentials.additionalUserInfo.profile.phoneNumber || null
              email = credentials.additionalUserInfo.profile.email || null
              avatar = credentials.additionalUserInfo.profile.picture || null

              // if (credentials.additionalUserInfo.profile.picture) {
              //   avatar = [{
              //     id: '',
              //     name: 'photo.jpg',
              //     privateUrl: '',
              //     publicUrl: credentials.additionalUserInfo.profile.picture,
              //   }];
              // }
            }

            // facebook
            if (provider == 'facebook') {
              firstName = credentials.additionalUserInfo.profile.first_name
              lastName = credentials.additionalUserInfo.profile.last_name

              if (credentials.additionalUserInfo.profile.picture.data.url) {
                avatar = [
                  {
                    id: '',
                    name: 'photo.jpg',
                    privateUrl: '',
                    publicUrl:
                      credentials.additionalUserInfo.profile.picture.data.url
                  }
                ]
              }
            }

            // twitter
            if (provider == 'twitter') {
              firstName = credentials.user.displayName.split(' ')[0]
              lastName = credentials.user.displayName.split(' ')[1]

              if (credentials.user.photoURL) {
                avatar = [
                  {
                    id: '',
                    name: 'photo.jpg',
                    privateUrl: '',
                    publicUrl: credentials.user.photoURL
                  }
                ]
              }
            }

            // yahoo
            if (provider == 'yahoo') {
              firstName = credentials.user.displayName
                ? credentials.user.displayName.split(' ')[0]
                : null
              lastName = credentials.user.displayName
                ? credentials.user.displayName.split(' ')[1]
                : null
              phoneNumber = credentials.user.phoneNumber || null
              email = credentials.user.email || null
              avatar = credentials.user.photoURL || null

              // if (credentials.additionalUserInfo.profile.picture) {
              //   avatar = [{
              //     id: '',
              //     name: 'photo.jpg',
              //     privateUrl: '',
              //     publicUrl: credentials.additionalUserInfo.profile.picture,
              //   }];
              // }
            }

            currentUser = {
              id: id,
              avatar: avatar,
              email: email,
              userName: userName,
              phoneNumber: phoneNumber,
              pagesAccess: [],
              deviceTokens: [],
              roles: ['owner'],
              status: 'enabled',
              lang: localStorage.getItem('language') || 'ar',
              createdBy: id,
              createdAt: FirebaseRepository.serverTimestamp(),
              updatedBy: id,
              updatedAt: FirebaseRepository.serverTimestamp()
            }
            await firebase
              .firestore()
              .collection('user')
              .doc(id)
              .set(currentUser)
            // await service.updateProfile(
            //   firstName,
            //   lastName,
            //   credentials.user.phoneNumber,
            //   countryCode,
            //   lang,
            //   avatar,
            //   currentUser.accountType
            // );
            // currentUser.avatars = avatar;
            // currentUser.accountType = 'company';
          } else {
            currentUser = await service.fetchMe()
            currentUser.emailVerified = true
            currentUser.lang = localStorage.getItem('language') || 'en'
          }
        }

        // in background
        service.reauthenticateWithStorageToken()

        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser
        })
        service.saveLocalCurrentUser(currentUser)
        // routerAsync().push('/');
        router.push('/')
      } catch (error) {
        await service.signout()
        Errors.handle(error)
        commit('AUTH_ERROR')
      }
    },
    //#endregion

    //#region [ Authentication With Email and Password ]
    async doRegisterEmailLinkedPhone(
      { commit },
      { email, password, countryCode }
    ) {
      try {
        commit('AUTH_START')

        let currentUser
        let authenticationUser
        console.log(firebase.auth().currentUser)

        const credential = await firebase.auth.EmailAuthProvider.credential(
          email,
          password
        )

        console.log(credential)

        await firebase
          .auth()
          .currentUser.linkWithCredential(credential)
          .then(
            function(userCredential) {
              var user = userCredential.user
              currentUser = user
              console.log('Account linking success', user)
            },
            function(error) {
              console.log('Account linking error', error)
            }
          )

        const credentials = await firebase
          .auth()
          .signInWithCredential(credential)
        await service.sendEmailVerification(credentials.user)

        if (credentials && credentials.user) {
          authenticationUser = credentials.user
          currentUser = await service.fetchMe()
          currentUser.emailVerified = authenticationUser.emailVerified
          currentUser.phoneNumber = authenticationUser.phoneNumber
          currentUser.accountType = 'company'
          currentUser.lang = localStorage.getItem('language') || 'en'
          currentUser.countryCode = countryCode
          console.log(currentUser)
          //#region [old]
          // await service.updateProfile(
          //   currentUser.firstName,
          //   currentUser.lastName,
          //   currentUser.phoneNumber,
          //   currentUser.countryCode,
          //   currentUser.lang,
          //   currentUser.avatars,
          //   currentUser.accountType,
          // );
          //#endregion

          // await service.updateProfile(
          //   // currentUser.firstName + currentUser.lastName,
          //   currentUser.firstName,
          //   currentUser.phoneNumber,
          //   currentUser.countryCode,
          //   currentUser.lang,
          //   currentUser.avatars,
          //   'company',
          //   null,
          //   null,
          //   null
          // )
        }

        // in background
        service.reauthenticateWithStorageToken()

        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser
        })

        // encrypt cerrentUser and Save currentUser in localStorage
        localStorage.setItem(
          'currentUser',
          crypto.encryption(currentUser, 'secret-c-u')
        )

        // routerAsync().push('/auth/complete-profile');
        router.push('/auth/complete-profile')
      } catch (error) {
        await service.signout()
        Errors.handle(error)
        commit('AUTH_ERROR')
        console.log('auth-store doregisteremail >>', error)
      }
    },

    async doRegisterEmailAndPassword({ commit }, { email, password }) {
      try {
        commit('AUTH_START')
        const authenticationUser = await service.registerWithEmailAndPassword(
          email,
          password
        )
        console.log('authenticationUser', authenticationUser)
        // let currentUser = await service.fetchMe();
        // currentUser.emailVerified = authenticationUser.emailVerified;
        // currentUser.lang = localStorage.getItem('language');
        // currentUser.accountType = 'company';

        // firebase.auth().languageCode = localStorage.getItem('language');

        // // update profile
        // await service.updateProfile(
        //   currentUser.firstName,
        //   currentUser.lastName,
        //   currentUser.phoneNumber,
        //   currentUser.countryCode,
        //   currentUser.lang,
        //   currentUser.avatars,
        //   currentUser.accountType,
        // );

        // // in background
        // service.reauthenticateWithStorageToken();

        // commit('AUTH_SUCCESS', {
        //   currentUser,
        //   authenticationUser,
        // });

        // routerAsync().push('/dashboard');
      } catch (error) {
        await service.signout()
        Errors.handle(error)
        commit('AUTH_ERROR')
      }
    },

    async doSigninWithEmailAndPassword(
      { commit },
      { email, password, rememberMe }
    ) {
      try {
        commit('AUTH_START')
        // debugger

        let authenticationUser = null
        let currentUser = null
        const credentials = await service.signinWithEmailAndPassword(
          email,
          password,
          rememberMe
        )

        if (credentials && credentials.user) {
          authenticationUser = credentials.user
          console.log('authenticationUser =', authenticationUser)
          currentUser = await service.fetchMe()
          console.log('currentUser ===> ', currentUser)
          currentUser.emailVerified = !!authenticationUser.emailVerified
        }

        service.reauthenticateWithStorageToken()
        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser
        })
        service.saveLocalCurrentUser(currentUser)
        // routerAsync().push(`/`);
        router.push(`/`)
      } catch (error) {
        console.log(error)
        await service.signout()
        Errors.handle(error)
        commit('AUTH_ERROR')
      }
    },

    async doEmailVerified({ commit }) {
      try {
        // await service.checkEmailVerified();
        commit('VERIFY_START')
        await firebase.auth().currentUser.reload()
        firebase.auth().onAuthStateChanged(authenticationUser => {
          // authenticationUser.emailVerified = true;
          // console.log(authenticationUser.emailVerified)
          if (authenticationUser.emailVerified) {
            commit('VERIFY_SUCCESS')
            document.location.reload()
            // routerAsync().push('/');
            router.push(`/`)
          } else {
            commit('VERIFY_SUCCESS')
            // Message.error(i18n('Email not verified yet'));
          }
        })
      } catch (error) {
        service.signout()
        Errors.handle(error)
        commit('VERIFY_ERROR')
      }
    },
    //#endregion

    async doSignout({ commit }) {
      try {
        commit('AUTH_START')
        await service.signout()
        // const lang = localStorage.getItem('language');
        // localStorage.setItem('language', lang);
        localStorage.clear()
        commit('AUTH_SUCCESS', {
          authenticationUser: null,
          currentUser: null
        })

        // routerAsync().push('/auth/signin');
        // router.push('/auth/signin');
        router.push('/auth/login')
      } catch (error) {
        Errors.handle(error)
        commit('AUTH_ERROR')
      }
    },

    async doSigninFromAuthChange({ commit }, authenticationUser) {
      try {
        // debugger
        let currentUser = await service.fetchMe()
        service.saveLocalCurrentUser(currentUser)

        // let currentUser = service.fetchLocalCurrentUser()
        if (authenticationUser) {
          // if (!currentUser) {
          //   currentUser = await service.fetchMe();
          //   service.saveLocalCurrentUser(currentUser)
          // }

          service.reauthenticateWithStorageToken() // In background
          currentUser.emailVerified = !!authenticationUser.emailVerified
        }
        commit('AUTH_INIT_SUCCESS', {
          currentUser,
          authenticationUser: authenticationUser
        })
        // ProgressBar.done();
      } catch (error) {
        service.signout()
        Errors.handle(error)
        commit('AUTH_INIT_ERROR', error)
        // ProgressBar.done();
      }
    },

    async doRefreshCurrentUser({ commit, getters }) {
      try {
        const authenticationUser = getters.authenticationUser
        const currentUser = await service.fetchMe()
        service.saveLocalCurrentUser(currentUser)

        // let currentUser = service.fetchLocalCurrentUser()
        // if (!currentUser) {
        //   currentUser = await service.fetchMe();
        //   service.saveLocalCurrentUser(currentUser)
        // }

        currentUser.emailVerified = !!authenticationUser.emailVerified
        service.reauthenticateWithStorageToken() // in background

        commit('CURRENT_USER_REFRESH_SUCCESS', { currentUser })
      } catch (error) {
        service.signout()
        Errors.handle(error)
        commit('CURRENT_USER_REFRESH_ERROR', error)
      }
    },

    async doUpdateProfile(
      { commit, dispatch },
      {
        firstName,
        lastName,
        phoneNumber
        // email,
        // pagesAccess = [],
        // lang
      }
    ) {
      try {
        commit('UPDATE_PROFILE_START')
        let currentUser = service.fetchLocalCurrentUser()
        console.log('CURRENT USER ==> ', currentUser)

        if (currentUser) {
          currentUser.firstName = firstName || currentUser.firstName
          currentUser.lastName = lastName || currentUser.lastName
          currentUser.phoneNumber = phoneNumber || currentUser.phoneNumber
          // currentUser.avatar = avatar || currentUser.avatar
          // currentUser.email = email || currentUser.email
          // currentUser.pagesAccess = pagesAccess || currentUser.pagesAccess
          // currentUser.lang = lang || currentUser.lang

          service.saveLocalCurrentUser(currentUser)
        }

        await service.updateProfile({
          firstName,
          lastName,
          phoneNumber
          // email,
          // pagesAccess = [],
          // lang
        })

        commit('UPDATE_PROFILE_SUCCESS')
        await dispatch('doRefreshCurrentUser')
        Message.success(i18n('auth.profile.success'))
        router.push('/')
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_PROFILE_ERROR')
      }
    },

    async doChangeMyPassword(
      { commit, dispatch },
      { oldPassword, newPassword }
    ) {
      try {
        commit('CHANGE_MY_PASSWORD_START')
        await service.changeMyPassword(oldPassword, newPassword)
        // service.reauthenticateWithStorageToken(); // in background
        commit('CHANGE_MY_PASSWORD_SUCCESS')
        Message.success(i18n('auth.profile.changePassword'))
        // router.push('/profile')
        dispatch('doSignout')
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_MY_PASSWORD_ERROR')
      }
    },

    async verifyOwner({ commit }) {
      try {
        const response = await FirebaseRepository.findDocument(
          '--sharedInfo--',
          '--owner--'
        )
        const isExist = response ? response.isExist : false
        commit('VERIFY_OWNER', isExist)
      } catch (error) {
        console.log(error)
      }
    },
    async signUpAction({ commit }, payload) {
      try {
        const { email, password, firstName, lastName } = payload
        const response = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)
        console.log('RESPONSE==>', response)
        const id = firebase
          .firestore()
          .collection('ids')
          .doc().id
        const user = {
          id,
          firstName,
          lastName,
          email,
          createdAt: firebase.firestore.Timestamp.now()
        }
        // console.log('USER==>', user)
        // const res = await firebase
        //   .firestore()
        //   .collection('media_users').get()
        // res.docs.forEach(doc => console.log('DOCS==>', doc.data()))
        await firebase
          .firestore()
          .collection('media_users')
          .doc(id)
          .set(user)
        // console.log('RES==>', res)
      } catch (error) {
        console.error(error)
      }
      // firebase
      //   .auth()
      //   .createUserWithEmailAndPassword(payload.email, payload.password)
      //   .then(async response => {
      //     console.log('RESPONSE==>', response)
      //     var id = firebase
      //       .firestore()
      //       .collection('ids')
      //       .doc().id
      //     console.log(commit)
      //     console.log(payload)
      //     const user = {
      //       id: id,
      //       firstName: payload.firstName,
      //       lastName: payload.lastName,
      //       email: payload.email,
      //       createdAt: firebase.firestore.Timestamp.now()
      //     }
      //     await firebase
      //       .firestore()
      //       .collection('media_users')
      //       .doc(id)
      //       .set(user)
      //     // @TODO mutate user state.
      //   })
      //   .catch(error => {
      //     console.log(error)
      //     // @TODO mutate error state.
      //   })
    },
    async signInAction({ commit }, payload) {
      try {
        const { email, password } = payload
        const response = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
        console.log('RESPONSE ==> ', response)
        // debugger
        commit('setUser', response.user)
        console.log('IAM HERE ==>', firebase.auth().currentUser)
        const auth = firebase.auth.getAuth()
        console.log('AUTH ==> ', auth)
        return
      } catch (error) {
        commit('setError', error.message)
      }
      // return await firebase
      //   .auth()
      //   .signInWithEmailAndPassword(payload.email, payload.password)
      //   .then(response => {
      //     commit('setUser', response.user)
      //     console.log(response)
      //     debugger
      //     const auth = firebase.auth().getAuth()
      //     console.log(auth)
      //     // const user = auth.currentUser
      //     // console.log(user)
      //     debugger
      //   })
      //   .catch(error => {
      //     commit('setError', error.message)
      //   })
    }
  }
}
